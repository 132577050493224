import { Action } from '../state';
import { UserState } from './store';
import { UserActions } from './actions';
import { parseUserPermissionMap, transformToPaymentUserName } from './payload';

export const UserReducer = (state: UserState, action: Action): UserState => {
  switch (action.type) {
    case UserActions.setKeycloakProfile:
      return {
        ...state,
        keycloakProfile: action.payload,
      };
    case UserActions.setRoles:
      return {
        ...state,
        roles: action.payload,
      };
    case UserActions.setKeycloakId:
      return {
        ...state,
        keycloakId: action.payload,
      };
    case UserActions.requestGetProfileTokenAttempt:
      return {
        ...state,
        isFetching:
          state.permissions === null
            ? {
                ...state.isFetching,
                permissions: true,
              }
            : state.isFetching,
        errors: {
          ...state.errors,
          permissions: null,
        },
      };
    case UserActions.requestGetProfileTokenSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          permissions: false,
        },
        permissions: parseUserPermissionMap(action.payload),
      };
    case UserActions.requestGetProfileTokenFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          permissions: false,
        },
        errors: {
          ...state.errors,
          permissions: action.payload,
        },
      };
    case UserActions.requestPostCaptchaAttempt:
      return {
        ...state,
        captchaValid: action.payload,
      };
    case UserActions.requestPostCaptchaSuccess:
      return {
        ...state,
        captchaValid: action.payload,
      };
    case UserActions.requestPostCaptchaFailure:
      return {
        ...state,
        captchaValid: action.payload,
      };
    case UserActions.requestUserNameSuccess:
      return {
        ...state,
        paymentUserNames: transformToPaymentUserName(
          state.paymentUserNames,
          action.payload
        ),
      };
    default:
      return state;
  }
};
