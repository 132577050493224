import { Action } from '../state';
import { WatchmanActions, WatchmanState } from './store';

export const WatchmanReducer = (
  state: WatchmanState,
  action: Action
): WatchmanState => {
  switch (action.type) {
    //GET
    case WatchmanActions.requestGetWatchmanTasksAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, getTasks: true },
        errors: { ...state.errors, getTasks: null },
      };
    case WatchmanActions.requestGetWatchmanTasksSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, getTasks: false },
        tasks: action.payload,
      };
    case WatchmanActions.requestGetWatchmanTasksFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, getTasks: false },
        errors: { ...state.errors, getTasks: action.payload },
      };
    //PUT
    case WatchmanActions.requestPutWatchmanTasksAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, putTasks: true },
        errors: { ...state.errors, putTasks: null },
      };
    case WatchmanActions.requestPutWatchmanTasksSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, putTasks: false },
      };
    case WatchmanActions.requestPutWatchmanTasksFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, putTasks: false },
        errors: { ...state.errors, putTasks: action.payload },
      };
    default:
      return state;
  }
};
