import styled, { css } from 'styled-components/macro';
import { theme } from '../../../styling/theme/index';

export enum Padding {
  XXSMALL = '8px',
  XSMALL = '16px',
  SMALL = '24px',
  MEDIUM = '48px',
  LARGE = '60px',
}

export enum Margin {
  XSMALL = '16px',
  SMALL = '24px',
  MEDIUM = '48px',
  LARGE = '60px',
}

export enum Alignment {
  START = 'flex-start',
  CENTER = 'center',
  END = 'flex-end',
  SPACE_BETWEEN = 'space-between',
  BASELINE = 'baseline',
}

export enum Direction {
  ROW = 'row',
  COLUMN = 'column',
  COLUMNREVERSE = 'column-reverse',
  ROWREVERSE = 'row-reverse',
}

export interface BoxProps {
  flex?: boolean;
  expand?: boolean;
  align?: Alignment;
  justify?: Alignment;
  marginTop?: Margin;
  marginBottom?: Margin;
  marginLeft?: Margin;
  marginRight?: Margin;
  padding?: Padding;
  paddingTop?: Padding;
  paddingBottom?: Padding;
  paddingLeft?: Padding;
  paddingRight?: Padding;
  direction?: Direction;
  border?: boolean;
  round?: boolean;
  width?: string;
  maxHeight?: string;
  scroll?: boolean;
  rowGap?: boolean;
  columnGap?: boolean;
  position?: 'relative' | 'absolute' | 'fixed' | 'static';
  fullWidth?: boolean;
}

export const Box = styled.div<BoxProps>`
  box-sizing: border-box;
  position: relative;
  color: ${props => theme(props).COLOUR.TEXT_STANDARD};

  ${props =>
    props.flex &&
    css`
      display: flex;
    `}

  ${props =>
    props.position &&
    css`
      position: props.position;
    `}

  ${props =>
    props.expand &&
    css`
      flex: 1;
    `}

  ${props =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}

  ${props =>
    props.align &&
    css`
      align-items: ${props.align};
    `}

  ${props =>
    props.direction &&
    css`
      flex-direction: ${props.direction};
    `};

  margin: ${props =>
    `${props.marginTop || 0} ${props.marginRight || 0} ${
      props.marginBottom || 0
    } ${props.marginLeft || 0}`};

  padding: ${props =>
    `${props.paddingTop || 0} ${props.paddingRight || 0} ${
      props.paddingBottom || 0
    } ${props.paddingLeft || 0}`};

  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}

  ${props =>
    props.border &&
    css`
      border: 1px solid ${theme(props).COLOUR.BACKGROUND_DARKEST};
    `}

  ${props =>
    props.round &&
    css`
      border-radius: ${theme(props).LAYOUT.BORDER_RADIUS};
    `}

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${props =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight};
    `}

  ${props =>
    props.scroll &&
    css`
      overflow-y: auto;
    `}

  ${props =>
    props.rowGap &&
    css`
      row-gap: 8px;
    `}

  ${props =>
    props.columnGap &&
    css`
      column-gap: 8px;
    `}

    ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
`;
