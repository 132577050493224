import { Action } from '../state';
import { SocialActions, SocialState } from './store';
import {
  clearInstaContent,
  clearInstaInsights,
  parseInsightsPayload,
  transformGetError,
  transformGetInsights,
  transformGetInstaContentPayload,
  transformGetLoading,
  transformGetYoutubeContentPayload,
} from './transformers';

export const SocialReducer = (
  state: SocialState,
  action: Action
): SocialState => {
  switch (action.type) {
    //get instagram insights
    case SocialActions.requestInstagramInsightsAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getInstagramInsights: transformGetLoading(
            state.isLoading.getInstagramInsights,
            action.payload.key,
            true
          ),
        },
        errors: {
          ...state.errors,
          getInstagramInsights: transformGetError(
            state.errors.getInstagramInsights,
            action.payload.key,
            null
          ),
        },
      };
    case SocialActions.requestInstagramInsightsSuccess:
      return {
        ...state,
        instagramInsights: transformGetInsights(
          state.instagramInsights,
          action.payload.key,
          action.payload.content
        ),
        isLoading: {
          ...state.isLoading,
          getInstagramInsights: transformGetLoading(
            state.isLoading.getInstagramInsights,
            action.payload.key,
            false
          ),
        },
      };
    case SocialActions.requestInstagramInsightsFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getInstagramInsights: transformGetLoading(
            state.isLoading.getInstagramInsights,
            action.payload.key,
            false
          ),
        },
        errors: {
          ...state.errors,
          getInstagramInsights: transformGetError(
            state.errors.getInstagramInsights,
            action.payload.key,
            action.payload.content
          ),
        },
      };

    //get instagram content
    case SocialActions.requestInstagramContentAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getInstagramContent: transformGetLoading(
            state.isLoading.getInstagramContent,
            action.payload.key,
            true
          ),
        },
        errors: {
          ...state.errors,
          getInstagramContent: transformGetError(
            state.errors.getInstagramContent,
            action.payload.key,
            null
          ),
        },
      };
    case SocialActions.requestInstagramContentSuccess:
      return {
        ...state,
        instagramContent: transformGetInstaContentPayload(
          action.payload.content,
          state.instagramContent,
          action.payload.key
        ),
        isLoading: {
          ...state.isLoading,
          getInstagramContent: transformGetLoading(
            state.isLoading.getInstagramContent,
            action.payload.key,
            false
          ),
        },
      };
    case SocialActions.requestInstagramContentFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getInstagramContent: transformGetLoading(
            state.isLoading.getInstagramContent,
            action.payload.key,
            false
          ),
        },
        errors: {
          ...state.errors,
          getInstagramContent: transformGetError(
            state.errors.getInstagramContent,
            action.payload.key,
            action.payload.content
          ),
        },
      };

    //get youtube insights
    case SocialActions.requestYoutubeInsightsAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, getYoutubeInsights: true },
        errors: { ...state.errors, getYoutubeInsights: null },
      };
    case SocialActions.requestYoutubeInsightsSuccess:
      return {
        ...state,
        youtubeInsights: action.payload,
        isLoading: { ...state.isLoading, getYoutubeInsights: false },
      };
    case SocialActions.requestYoutubeInsightsFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, getYoutubeInsights: false },
        errors: { ...state.errors, getYoutubeInsights: action.payload },
      };

    //get youtube content
    case SocialActions.requestYoutubeContentAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, getYoutubeContent: true },
        errors: { ...state.errors, getYoutubeContent: null },
      };
    case SocialActions.requestYoutubeContentSuccess:
      return {
        ...state,
        youtubeContent: transformGetYoutubeContentPayload(
          action.payload,
          state.youtubeContent
        ),
        isLoading: { ...state.isLoading, getYoutubeContent: false },
      };
    case SocialActions.requestYoutubeContentFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, getYoutubeContent: false },
        errors: { ...state.errors, getYoutubeContent: action.payload },
      };
    //influencer instagram data
    case SocialActions.requestInfluencerInstaInsightsAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, getInfluencerInstaInsights: true },
        errors: { ...state.errors, getInfluencerInstaInsights: null },
      };
    case SocialActions.requestInfluencerInstaInsightsSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, getInfluencerInstaInsights: false },
        influencerInsights: parseInsightsPayload(action.payload),
      };
    case SocialActions.requestInfluencerInstaInsightsFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, getInfluencerInstaInsights: false },
        errors: { ...state.errors, getInfluencerInstaInsights: action.payload },
      };
    case SocialActions.clearInstagramContent:
      return {
        ...state,
        instagramContent: clearInstaContent(
          state.instagramContent,
          action.payload
        ),
      };
    case SocialActions.clearInstagramInsights:
      return {
        ...state,
        instagramInsights: clearInstaInsights(
          state.instagramInsights,
          action.payload
        ),
      };
    case SocialActions.setInstagramQueries:
      return {
        ...state,
        instagramQueries: {
          ...state.instagramQueries,
          [action.payload.type]: action.payload.queries,
        },
      };
    case SocialActions.clearYoutubeContent:
      return {
        ...state,
        youtubeContent: null,
      };
    case SocialActions.setYoutubeQueries:
      return {
        ...state,
        youtubeQueries: action.payload,
      };
    default:
      return state;
  }
};
