import dayjs from 'dayjs';
import { Payment } from '../store/Payments/store';

const symbols = ['', 'K', 'M', 'B', 'T'];

export const format = (influencerStat: number): string => {
  const symbolsIndex = (Math.log10(influencerStat) / 3) | 0;
  if (symbolsIndex === 0) return influencerStat.toString();

  const symbol = symbols[symbolsIndex];
  const scale = Math.pow(10, symbolsIndex * 3);

  const scaled = influencerStat / scale;

  return scaled.toFixed(1) + symbol;
};

export const formatDate = (dateTime: string | dayjs.Dayjs): string => {
  return dayjs(dateTime).format('DD-MMM-YY');
};

export const formatDateFullYear = (dateTime: string | dayjs.Dayjs): string => {
  return dayjs(dateTime).format('DD-MMM-YYYY');
};

export const formatDateTime = (dateTime: string): string => {
  return dayjs(dateTime).format('DD-MMM-YY HH:MM');
};

export const formatDateDiscountCode = (
  dateTime: string | dayjs.Dayjs | undefined
): string => {
  return dateTime ? dayjs(dateTime).format('YYYY-MM-DD') : '';
};

export const formatName = (name: string) => {
  const capitalisedWords = name
    .toLowerCase()
    .replace(/ [a-zA-Z]/g, str => ` ${str[1].toUpperCase()}`);
  return `${capitalisedWords.slice(0, 1).toUpperCase()}${capitalisedWords.slice(
    1
  )}`;
};

export const formatFullName = (firstName: string, lastName: string): string => {
  return `${firstName} ${lastName}`;
};

export const formatSentenceCase = (value: string) => {
  return `${value.substring(0, 1).toUpperCase()}${value.substring(1)}`;
};

export const formatSnakeCaseSentence = (value: string) => {
  const spaced = value.replace(/([-_]\w)/g, g => ` ${g[1].toLowerCase()}`);
  return formatSentenceCase(spaced);
};

export const formatSnakeCaseToCaps = (value: string) => {
  const camel = value.replace(/([-_]\w)/g, g => g[1].toUpperCase());
  const spaced = camel.replace(/([A-Z])/g, ' $1');
  const title = spaced.toUpperCase();
  return title;
};

export const formatToPlural = (text: string) => {
  return `${text}${text.substr(text.length - 1) === 's' ? 'e' : ''}s`;
};

// XXX means no currency symbol as per international standards
export const formatCurrency = (
  currency = 'XXX',
  amount: number,
  maximumFractionDigits = 2
) => {
  return new Intl.NumberFormat('en-GB', {
    currency,
    style: 'currency',
    maximumFractionDigits: maximumFractionDigits,
  }).format(amount);
};

export const formatPaymentAmount =
  (isNewPaymentModelEnabled: boolean) => (payment: Payment) => {
    return isNewPaymentModelEnabled
      ? formatCurrency(
          payment.currency.toUpperCase(),
          Number(
            (Number(payment.amount) + Number(payment.vat?.amount ?? 0)).toFixed(
              2
            )
          )
        )
      : formatCurrency(payment.currency.toUpperCase(), Number(payment.amount));
  };

export const transformToDatePickerFormat = (
  dateTime: string | dayjs.Dayjs | undefined
): string => {
  return formatDateDiscountCode(dateTime);
};

export const formatNumber = (num: number, digits = 0) => {
  if (digits < 0) return num.toString();

  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup.findLast(item => num >= item.value);
  return item
    ? (num / item.value).toFixed(digits).replace(regexp, '').concat(item.symbol)
    : '0';
};

export const formatString = (original: string, args: (string | number)[]) => {
  return original.replace(/{(\d+)}/g, (match, number) => {
    return typeof args[number] !== 'undefined'
      ? args[number].toString()
      : match;
  });
};
