import React, { ChangeEvent } from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup as RadioGroupComponent,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { RadioGroupWrapper, RadioStyleTypeProp } from './RadioGroup.styles';

export interface RadioOption {
  value: any;
  label: string;
  isDisabled?: boolean;
  checked?: boolean;
}

export interface RadioProps {
  title: string;
  legend: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  radioOptions?: RadioOption[];
  defaultValue?: string;
  testId?: string;
  styleType?: RadioStyleTypeProp;
}

export const RadioGroup: React.FC<RadioProps> = ({
  title,
  legend,
  handleChange,
  radioOptions,
  defaultValue,
  testId = `t_radio_group_${title}`,
  styleType,
}) => {
  return (
    <RadioGroupWrapper styleType={styleType}>
      <FormControl component="fieldset">
        <FormLabel
          data-testid={`${testId}_label`}
          htmlFor={`${title}`}
          id={'radio_legend'}
          component="legend"
        >
          {legend}
        </FormLabel>
        <RadioGroupComponent
          data-testid={`${testId}_group`}
          aria-label={title}
          name={`${title}`}
          id={'radio_group'}
          defaultValue={defaultValue}
          onChange={handleChange}
        >
          {radioOptions?.map(option => (
            <FormControlLabel
              data-testid={`${testId}_${option.value}`}
              id={'radio_item'}
              control={
                <Radio
                  classes={{ checked: 'checkedRadio' }}
                  style={{ color: '#5d5d5d' }}
                />
              }
              key={option.label}
              {...option}
            />
          ))}
        </RadioGroupComponent>
      </FormControl>
    </RadioGroupWrapper>
  );
};
