import React from 'react';
import styled from 'styled-components';
import { LogoCollapsedSVG } from './LogoCollapsedSVG';
import { LogoSVG } from './LogoSVG';

interface LogoProps {
  collapsed?: boolean;
}

export const SocietyLogo = styled.div`
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const Logo: React.FC<LogoProps> = ({ collapsed }) => (
  <SocietyLogo title={'THG Society'}>
    {collapsed ? (
      <Container data-testid="t_logo_collapsed">
        <LogoCollapsedSVG />
      </Container>
    ) : (
      <Container data-testid="t_logo_expanded">
        <LogoSVG />
      </Container>
    )}
  </SocietyLogo>
);
