import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from '@material-ui/core/Badge';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import React from 'react';
import { IconButton } from '../../common/IconButton';
import { NotificationCenter } from '../NotificationCenter/NotificationCenter';
import { Toast } from '../../common/Toast';
import {
  NotificationContainer,
  NotificationCount,
  NotificationIcon,
} from './NotificationController.styles';
import { useNotificationController } from './useNotificationController';

export const NotificationController: React.FC = () => {
  const {
    unreadCount,
    toggleHandler,
    isShowingNotifications,
    hasError,
    isMobile,
    hasViewedNotifications,
  } = useNotificationController();

  return (
    <>
      <NotificationContainer isMobile={isMobile}>
        <Badge
          color="secondary"
          data-testid="t_notifications_badge"
          invisible={hasViewedNotifications}
          badgeContent={
            <NotificationCount data-testid={'t_notifications_badge_count'}>
              {unreadCount}
            </NotificationCount>
          }
          overlap={isMobile ? 'circle' : 'rectangular'}
          variant={isMobile ? 'dot' : 'standard'}
        >
          {!isMobile ? (
            <IconButton
              onClick={() => toggleHandler(!isShowingNotifications)}
              tabIndex={0}
              aria-label="toggle notifications"
              aria-expanded={isShowingNotifications}
              data-testid="t_notifications_button"
              tooltip="Toggle Notifications"
              icon="bell"
            />
          ) : (
            <NotificationIcon>
              <FontAwesomeIcon
                icon="bell"
                onClick={() => toggleHandler(!isShowingNotifications)}
                data-testid="t_notifications_icon"
              />
            </NotificationIcon>
          )}
        </Badge>

        {isShowingNotifications && (
          <ClickAwayListener onClickAway={() => toggleHandler(false)}>
            <div>
              <NotificationCenter />
            </div>
          </ClickAwayListener>
        )}
      </NotificationContainer>

      {!!hasError && (
        <Toast
          message={hasError}
          type={'error'}
          testId={`t_notifications_error_toast`}
        />
      )}
    </>
  );
};
