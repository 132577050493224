import dayjs from 'dayjs';
import {
  Notification,
  NotificationAPIModel,
  NotificationGroup,
  NotificationGroupAPI,
  NotificationSubscription,
  NotificationSubscriptionAPI,
  NotificationSubscriptionType,
  NotificationSubscriptionTypeAPI,
} from './store';

export const transformNotification = (
  notification: NotificationAPIModel
): Notification => {
  return {
    id: notification.id,
    keycloakId: notification.keycloak_id,
    message: notification.message,
    url: notification.url,
    priority: notification.priority,
    type: notification.type,
    createdAt: dayjs(notification.created_at),
    read: notification.read,
    readAt: notification.read_at ? dayjs(notification.read_at) : null,
  };
};

export const transformNotifications = (
  notifications: NotificationAPIModel[]
): Notification[] => {
  return notifications.map(notif => transformNotification(notif));
};

export const updateNotification = (
  notification: Notification,
  notifications: Notification[]
): Notification[] => {
  return notifications.map(notif => {
    return notif.id === notification.id ? { ...notif, ...notification } : notif;
  });
};

export const transformSubscription = (
  notificationSubscription: NotificationSubscriptionAPI
): NotificationSubscription => {
  return {
    enabled: notificationSubscription.enabled,
    notificationGroupId: notificationSubscription.notification_group_id,
    notificationGroupSubscriptionTypeId:
      notificationSubscription.notification_group_subscription_type_id,
  };
};

export const transformSubscriptions = (
  notificationSubscriptions: NotificationSubscriptionAPI[]
): NotificationSubscription[] => {
  return notificationSubscriptions.map(subscription =>
    transformSubscription(subscription)
  );
};

export const transformSubscriptionType = (
  subscriptionType: NotificationSubscriptionTypeAPI
): NotificationSubscriptionType => {
  return {
    id: subscriptionType.id,
    name: subscriptionType.name,
    displayName: subscriptionType.display_name,
    default: subscriptionType.default,
  };
};

export const transformSubscriptionsTypes = (
  subscriptionTypes: NotificationSubscriptionTypeAPI[]
): NotificationSubscriptionType[] => {
  return subscriptionTypes.map(subscriptionType =>
    transformSubscriptionType(subscriptionType)
  );
};

export const transformNotificationGroup = (
  notificationGroup: NotificationGroupAPI
): NotificationGroup => {
  return {
    id: notificationGroup.id,
    name: notificationGroup.name,
    displayName: notificationGroup.display_name,
    description: notificationGroup.description,
  };
};

export const transformNotificationGroups = (
  notificationGroups: NotificationGroupAPI[]
): NotificationGroup[] => {
  return notificationGroups.map(notificationGroup =>
    transformNotificationGroup(notificationGroup)
  );
};
