import { EnvironmentConfig } from '../../environments/environment';
import client, { Microservice } from '../../utils/http.utils';
import { captureException } from '../../utils/sentry.utils';
import { DispatchFn } from '../state';
import { toFeatureFlags } from './transformers';
import { AggregatedFeaturesApi, FeatureEnv } from './types';

export enum EnvironmentsActions {
  requestFeatureFlagsAttempt = 'REQUEST_FEATURE_FLAGS_ATTEMPT',
  requestFeatureFlagsSuccess = 'REQUEST_FEATURE_FLAGS_SUCCESS',
  requestFeatureFlagsFailure = 'REQUEST_FEATURE_FLAGS_FAILURE',
}

export const RequestFeatureFlags = async (dispatch: DispatchFn) => {
  try {
    dispatch({
      type: EnvironmentsActions.requestFeatureFlagsAttempt,
    });

    const env = takeFeatureEnv(window._env.environment);
    const response = await client.request<AggregatedFeaturesApi>(
      Microservice.FEATURE,
      `/feature/${env}/aggregated`,
      'GET'
    );

    dispatch({
      type: EnvironmentsActions.requestFeatureFlagsSuccess,
      payload: toFeatureFlags(response),
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: EnvironmentsActions.requestFeatureFlagsFailure,
      payload: error,
    });
    throw new Error();
  }
};

const takeFeatureEnv = (
  appEnv: EnvironmentConfig['environment']
): FeatureEnv => {
  if (appEnv === 'live') return 'prod';
  if (appEnv === 'gb5mocked') return 'uat';
  if (appEnv === 'gb5') return 'uat';
  return 'dev';
};
