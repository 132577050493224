import styled from 'styled-components';
import { px2rem } from '../../../styling/sizing';
import { theme } from '../../../styling/theme';
import { TextSmall } from '../../Typography';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.1rem;
  flex-wrap: wrap;
`;

const Label = styled.label`
  margin-bottom: 0.5em;
  padding: 0;
  color: ${props => theme(props).COLOUR.TEXT_STANDARD};
  line-height: initial;
  font-size: ${px2rem(16)};
  font-weight: 900;
`;

const Error = styled(TextSmall)`
  color: ${props => theme(props).COLOUR.ALERT_DARK};
`;

export default {
  Wrapper,
  Label,
  Error,
};
