export * from './actions';
export * from './reducer';
export * from './types';

import { EnvironmentsState } from './types';

export const defaultEnvironmentsState: EnvironmentsState = {
  isLoading: {
    flags: false,
  },
  errors: {
    flags: null,
  },
  flags: {
    isNewPaymentModelEnabled: false,
  },
};
