import { KeycloakConfig } from 'keycloak-js';

export interface EnvironmentConfig {
  authConfig: KeycloakConfig;
  apiUrl: string;
  invitationUrl?: string;
  influencerUrl: string;
  influencerRole: string;
  marketerRole: string;
  adminRole: string;
  approverRole: string;
  permissionManagerRole: string;
  environment: '' | 'local' | 'gb5' | 'gb5mocked' | 'live';
  captchaToken: string;
  canSeeCampaigns: boolean;
  canSeeApplications: boolean;
  canSeeInvoices: boolean;
  canSeeTaxInformation: boolean;
  canSeeAgencies: boolean;
}

declare global {
  interface Window {
    _env: EnvironmentConfig;
    profileToken: string | null;
  }
}

const defaultEnvironmentConfig: EnvironmentConfig = {
  authConfig: {
    url: '',
    realm: '',
    clientId: '',
  },
  apiUrl: '',
  invitationUrl: '',
  influencerUrl: '',
  influencerRole: '',
  marketerRole: '',
  adminRole: '',
  approverRole: '',
  environment: '',
  permissionManagerRole: '',
  captchaToken: '',
  canSeeCampaigns: false,
  canSeeApplications: false,
  canSeeInvoices: false,
  canSeeTaxInformation: true,
  canSeeAgencies: false,
};

export default window._env || defaultEnvironmentConfig;
