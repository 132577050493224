import styled, { css, keyframes } from 'styled-components';
import { px2rem } from '../../../styling/sizing';
import { theme } from '../../../styling/theme';

const setItem = (item: number) => {
  switch (item) {
    case 0:
      return PulseBubbleStyleOne;
    case 1:
      return PulseBubbleStyleTwo;
    case 2:
      return PulseBubbleStyleThree;
  }
};

const pulse = () => keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0.25;
    transform: scale(0.75);
  }
`;

export const PulseBubbleStyleOne = css`
  animation: ${pulse} 0.4s ease 0s infinite alternate;
`;

export const PulseBubbleStyleTwo = css`
  animation: ${pulse} 0.4s ease 0.2s infinite alternate;
`;

export const PulseBubbleStyleThree = css`
  animation: ${pulse} 0.4s ease 0.4s infinite alternate;
`;

export const PulseBubble = styled.div<{ item: number }>`
  width: ${px2rem(20)};
  height: ${px2rem(20)};
  border-radius: ${props => theme(props).EFFECTS.RADIUS_HALF};
  background-color: ${props => theme(props).COLOUR.PRIMARY};

  ${props => setItem(props.item)}
`;

export const PulseContainer = styled.div`
  width: ${px2rem(120)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
