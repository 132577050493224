import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTachometerAlt,
  faPlus,
  faHeart,
  faCalendar,
  faCog,
  faChartPie,
  faUsers,
  faStar,
  faComments,
  faSearch,
  faBell,
  faComment,
  faChevronLeft,
  faChevronDown,
  faBars,
  faUser,
  faHashtag,
  faTimes,
  faPercent,
  faChevronRight,
  faEllipsisV,
  faCheck,
  faSignOutAlt,
  faBullhorn,
  faImages,
  faMapMarkerAlt,
  faAt,
  faLock,
  faChartLine,
  faPencilAlt,
  faTrash,
  faVideo,
  faList,
  faArrowRight,
  faTag,
  faCompass,
  faFilter,
  faCreditCard,
  faCheckCircle,
  faChevronCircleRight,
  faMoneyBill,
  faThumbsUp,
  faThumbsDown,
  faHome,
  faArrowLeft,
  faSortAlphaDown,
  faSortAlphaUp,
  faCircle,
  faPause,
  faClock,
  faPlay,
  faUserPlus,
  faEdit,
  faTags,
  faWallet,
  faFileDownload,
  faDownload,
  faInfo,
  faMailBulk,
  faMars,
  faGenderless,
  faVenus,
  faGlobeEurope,
  faCity,
  faUserShield,
  faExclamationTriangle,
  faLink,
  faPen,
  faFileSignature,
  faMinusCircle,
  faEllipsisH,
  faExclamationCircle,
  faInfoCircle,
  faLanguage,
  faTimesCircle,
  faCopy,
  faDesktop,
  faTabletAlt,
  faCalendarAlt,
  faCamera,
  faPlusCircle,
  faHistory,
  faCalendarWeek,
  faTrashAlt,
  faUserCheck,
  faToolbox,
  faObjectGroup,
  faFileExport,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCircle as frCircle,
  faLaugh as frLaugh,
  faSmile as frSmile,
  faMeh as frMeh,
  faFrown as frFrown,
  faFrownOpen as frFrownOpen,
} from '@fortawesome/free-regular-svg-icons';
import {
  faInstagram,
  faFacebook,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

const buildIconLibrary = () => {
  library.add(
    faTachometerAlt,
    faPlus,
    faHeart,
    faCalendar,
    faCog,
    faChartPie,
    faUsers,
    faStar,
    faComments,
    faSearch,
    faBell,
    faComment,
    faChevronLeft,
    faChevronDown,
    faBars,
    faUser,
    faHashtag,
    faTimes,
    faPercent,
    faChevronRight,
    faEllipsisV,
    faCheck,
    faSignOutAlt,
    faBullhorn,
    faImages,
    faMapMarkerAlt,
    faAt,
    faLock,
    faChartLine,
    faPencilAlt,
    faTrash,
    faVideo,
    faList,
    faArrowRight,
    faTag,
    faInstagram,
    faYoutube,
    faCompass,
    faFilter,
    faCreditCard,
    faCheckCircle,
    faFacebook,
    faChevronCircleRight,
    faMoneyBill,
    faThumbsUp,
    faThumbsDown,
    faHome,
    faArrowLeft,
    faSortAlphaDown,
    faSortAlphaUp,
    faCircle,
    faPlay,
    faPause,
    faClock,
    faUserPlus,
    faEdit,
    faTags,
    faWallet,
    faFileDownload,
    faDownload,
    faInfo,
    faMailBulk,
    faMars,
    faVenus,
    faGenderless,
    faGlobeEurope,
    faCity,
    frCircle,
    faUserShield,
    faExclamationTriangle,
    faImages,
    faLink,
    faPen,
    faFileSignature,
    faPencilAlt,
    faMinusCircle,
    faEllipsisH,
    faExclamationCircle,
    faInfoCircle,
    faLanguage,
    faTimesCircle,
    faCopy,
    faDesktop,
    faTabletAlt,
    faCalendarAlt,
    faCamera,
    faPlusCircle,
    faHistory,
    faCalendarWeek,
    faTrashAlt,
    faUserCheck,
    faToolbox,
    faObjectGroup,
    frLaugh,
    frSmile,
    frMeh,
    frFrown,
    frFrownOpen,
    faFileExport
  );
};

export default buildIconLibrary;
