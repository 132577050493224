export const currencyLookup = (currency: string) => {
  const currencies: { [index: string]: string } = {
    GBP: '£',
    EUR: '€',
    CAD: '$',
    JPY: '¥',
    CHF: 'CHF',
    AUD: '$',
    SEK: 'kr',
    DKK: 'kr',
    NOK: 'kr',
    ZAR: 'R',
    USD: '$',
    MXN: '$',
    HKD: '$',
    NZD: '$',
    SGD: '$',
  };

  return currencies[currency] || '?';
};

export const countryNamesTaxApproved: { [key: string]: string } = {
  GB: 'United Kingdom',
  LV: 'Latvia',
  DK: 'Denmark',
  FI: 'Finland',
  SE: 'Sweden',
  NO: 'Norway',
};

export const countryNamesAlpha2 = {
  GB: 'United Kingdom',
  US: 'United States',
  AU: 'Australia',
  AT: 'Austria',
  BE: 'Belgium',
  BG: 'Bulgaria',
  CY: 'Cyprus',
  CZ: 'Czechia',
  DK: 'Denmark',
  EE: 'Estonia',
  FI: 'Finland',
  FR: 'France',
  DE: 'Germany',
  GR: 'Greece',
  HU: 'Hungary',
  IE: 'Ireland',
  IT: 'Italy',
  LV: 'Latvia',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MT: 'Malta',
  NL: 'Netherlands',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SK: 'Slovakia',
  SI: 'Slovenia',
  ES: 'Spain',
  SE: 'Sweden',
  IS: 'Iceland',
  LI: 'Liechtenstein',
  NO: 'Norway',
  CH: 'Switzerland',
  MC: 'Monaco',
  HR: 'Croatia',
  JP: 'Japan',
  CN: 'China',
  SG: 'Singapore',
  VN: 'Viet Nam',
  RS: 'Serbia',
  AD: 'Andorra',
  AF: 'Afghanistan',
  AG: 'Antigua and Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AO: 'Angola',
  AR: 'Argentina',
  AS: 'American Samoa',
  AW: 'Aruba',
  AZ: 'Azerbaijan',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BF: 'Burkina Faso',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BM: 'Bermuda',
  BN: 'Brunei Darussalam',
  BO: 'Bolivia (Plurinational State of)',
  BR: 'Brazil',
  BS: 'Bahamas',
  BT: 'Bhutan',
  BW: 'Botswana',
  BY: 'Belarus',
  BZ: 'Belize',
  CA: 'Canada',
  CD: 'Congo (the Democratic Republic of the)',
  CF: 'Central African Republic',
  CG: 'Congo',
  CI: "Côte d'Ivoire",
  CK: 'Cook Islands',
  CL: 'Chile',
  CM: 'Cameroon',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CV: 'Cabo Verde',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  DZ: 'Algeria',
  EC: 'Ecuador',
  EG: 'Egypt',
  ER: 'Eritrea',
  ET: 'Ethiopia',
  FJ: 'Fiji',
  FK: 'Falkland Islands [Malvinas]',
  FO: 'Faroe Islands',
  GA: 'Gabon',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'French Guiana',
  GG: 'Guernsey',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Equatorial Guinea',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HN: 'Honduras',
  HT: 'Haiti',
  ID: 'Indonesia',
  IL: 'Israel',
  IM: 'Isle of Man',
  IN: 'India',
  IQ: 'Iraq',
  JE: 'Jersey',
  JM: 'Jamaica',
  JO: 'Jordan',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'Saint Kitts and Nevis',
  KR: 'Korea (the Republic of)',
  KW: 'Kuwait',
  KY: 'Cayman Islands',
  KZ: 'Kazakhstan',
  LA: "Lao People's Democratic Republic",
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LY: 'Libya',
  MA: 'Morocco',
  MD: 'Moldova (the Republic of)',
  ME: 'Montenegro',
  MG: 'Madagascar',
  MK: 'Republic of North Macedonia',
  ML: 'Mali',
  MN: 'Mongolia',
  MO: 'Macao',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NP: 'Nepal',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PM: 'Saint Pierre and Miquelon',
  PR: 'Puerto Rico',
  PY: 'Paraguay',
  QA: 'Qatar',
  RE: 'Réunion',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  SA: 'Saudi Arabia',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  ST: 'Sao Tome and Principe',
  SV: 'El Salvador',
  SZ: 'Eswatini',
  TC: 'Turks and Caicos Islands',
  TD: 'Chad',
  TG: 'Togo',
  TH: 'Thailand',
  TJ: 'Tajikistan',
  TL: 'Timor-Leste',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TR: 'Turkey',
  TT: 'Trinidad and Tobago',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TZ: 'Tanzania, United Republic of',
  UA: 'Ukraine',
  UG: 'Uganda',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Holy See',
  VC: 'Saint Vincent and the Grenadines',
  VE: 'Venezuela (Bolivarian Republic of)',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  VU: 'Vanuatu',
  WS: 'Samoa',
  YE: 'Yemen',
  YT: 'Mayotte',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  AE: 'United Arab Emirates',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  SX: 'Sint Maarten (Dutch part)',
};

export enum Currency {
  GBP = 'GBP',
  EUR = 'EUR',
  CAD = 'CAD',
  JPY = 'JPY',
  CHF = 'CHF',
  AUD = 'AUD',
  SEK = 'SEK',
  DKK = 'DKK',
  NOK = 'NOK',
  ZAR = 'ZAR',
  USD = 'USD',
  MXN = 'MXN',
  HKD = 'HKD',
  NZD = 'NZD',
  SGD = 'SGD',
}
export const countryAlpha2Codes: {
  [index: string]: keyof typeof countryNamesAlpha2;
} = {
  GB: 'GB',
  US: 'US',
  AU: 'AU',
  AT: 'AT',
  BE: 'BE',
  BG: 'BG',
  CY: 'CY',
  CZ: 'CZ',
  DK: 'DK',
  EE: 'EE',
  FI: 'FI',
  FR: 'FR',
  DE: 'DE',
  GR: 'GR',
  HU: 'HU',
  IE: 'IE',
  IT: 'IT',
  LV: 'LV',
  LT: 'LT',
  LU: 'LU',
  MT: 'MT',
  NL: 'NL',
  PL: 'PL',
  PT: 'PT',
  RO: 'RO',
  SK: 'SK',
  SI: 'SI',
  ES: 'ES',
  SE: 'SE',
  IS: 'IS',
  LI: 'LI',
  NO: 'NO',
  CH: 'CH',
  MC: 'MC',
  HR: 'HR',
  JP: 'JP',
  CN: 'CN',
  SG: 'SG',
  VN: 'VN',
  RS: 'RS',
  AD: 'AD',
  AF: 'AF',
  AG: 'AG',
  AI: 'AI',
  AL: 'AL',
  AM: 'AM',
  AO: 'AO',
  AR: 'AR',
  AS: 'AS',
  AW: 'AW',
  AZ: 'AZ',
  BA: 'BA',
  BB: 'BB',
  BD: 'BD',
  BF: 'BF',
  BH: 'BH',
  BI: 'BI',
  BJ: 'BJ',
  BM: 'BM',
  BN: 'BN',
  BO: 'BO',
  BR: 'BR',
  BS: 'BS',
  BT: 'BT',
  BW: 'BW',
  BY: 'BY',
  BZ: 'BZ',
  CA: 'CA',
  CD: 'CD',
  CF: 'CF',
  CG: 'CG',
  CI: 'CI',
  CK: 'CK',
  CL: 'CL',
  CM: 'CM',
  CO: 'CO',
  CR: 'CR',
  CV: 'CV',
  DJ: 'DJ',
  DM: 'DM',
  DO: 'DO',
  DZ: 'DZ',
  EC: 'EC',
  EG: 'EG',
  ER: 'ER',
  ET: 'ET',
  FJ: 'FJ',
  FK: 'FK',
  FO: 'FO',
  GA: 'GA',
  GD: 'GD',
  GE: 'GE',
  GF: 'GF',
  GG: 'GG',
  GH: 'GH',
  GI: 'GI',
  GL: 'GL',
  GM: 'GM',
  GN: 'GN',
  GP: 'GP',
  GQ: 'GQ',
  GT: 'GT',
  GU: 'GU',
  GW: 'GW',
  GY: 'GY',
  HK: 'HK',
  HN: 'HN',
  HT: 'HT',
  ID: 'ID',
  IL: 'IL',
  IM: 'IM',
  IN: 'IN',
  IQ: 'IQ',
  JE: 'JE',
  JM: 'JM',
  JO: 'JO',
  KE: 'KE',
  KG: 'KG',
  KH: 'KH',
  KI: 'KI',
  KM: 'KM',
  KN: 'KN',
  KR: 'KR',
  KW: 'KW',
  KY: 'KY',
  KZ: 'KZ',
  LA: 'LA',
  LB: 'LB',
  LC: 'LC',
  LK: 'LK',
  LR: 'LR',
  LS: 'LS',
  LY: 'LY',
  MA: 'MA',
  MD: 'MD',
  ME: 'ME',
  MG: 'MG',
  MK: 'MK',
  ML: 'ML',
  MN: 'MN',
  MO: 'MO',
  MQ: 'MQ',
  MR: 'MR',
  MS: 'MS',
  MU: 'MU',
  MV: 'MV',
  MW: 'MW',
  MX: 'MX',
  MY: 'MY',
  MZ: 'MZ',
  NA: 'NA',
  NC: 'NC',
  NE: 'NE',
  NG: 'NG',
  NI: 'NI',
  NP: 'NP',
  NZ: 'NZ',
  OM: 'OM',
  PA: 'PA',
  PE: 'PE',
  PF: 'PF',
  PG: 'PG',
  PH: 'PH',
  PK: 'PK',
  PM: 'PM',
  PR: 'PR',
  PY: 'PY',
  QA: 'QA',
  RE: 'RE',
  RU: 'RU',
  RW: 'RW',
  SA: 'SA',
  SB: 'SB',
  SC: 'SC',
  SL: 'SL',
  SM: 'SM',
  SN: 'SN',
  SO: 'SO',
  SR: 'SR',
  ST: 'ST',
  SV: 'SV',
  SZ: 'SZ',
  TC: 'TC',
  TD: 'TD',
  TG: 'TG',
  TH: 'TH',
  TJ: 'TJ',
  TL: 'TL',
  TM: 'TM',
  TN: 'TN',
  TO: 'TO',
  TR: 'TR',
  TT: 'TT',
  TV: 'TV',
  TW: 'TW',
  TZ: 'TZ',
  UA: 'UA',
  UG: 'UG',
  UY: 'UY',
  UZ: 'UZ',
  VA: 'VA',
  VC: 'VC',
  VE: 'VE',
  VG: 'VG',
  VI: 'VI',
  VU: 'VU',
  WS: 'WS',
  YE: 'YE',
  YT: 'YT',
  ZA: 'ZA',
  ZM: 'ZM',
  ZW: 'ZW',
  AE: 'AE',
  BQ: 'BQ',
  SX: 'SX',
};

export const currencyList = Object.values(Currency);

export const getCountryNameWithAlpha2 = (country: string) => {
  const countryCode = countryAlpha2Codes[country];
  return countryNamesAlpha2[countryCode] || '?';
};
