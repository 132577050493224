import { Paginated } from '../../utils/pageControllers.utils';

export interface LevelRolesApiModel {
  global: string[];
  programme_group: { [index: string]: string[] };
  programme: { [index: string]: string[] };
}

export interface LevelRoles {
  global: string[];
  programmeGroup: { [index: string]: string[] };
  programme: { [index: string]: string[] };
}

export interface LevelPermissionsApiModel {
  global: string[];
  programme_group: { [index: string]: string[] };
  programme: { [index: string]: string[] };
}

export interface LevelPermissions {
  global: string[];
  programmeGroup: { [index: string]: string[] };
  programme: { [index: string]: string[] };
}

export interface ProfileUserApiModel {
  keycloak_id: string;
  username: string;
  first_name: string;
  last_name: string;
  created_at: string;
  roles: LevelRolesApiModel;
  permissions: LevelPermissionsApiModel;
}

export interface ProfileUser {
  keycloakId: string;
  username: string;
  name: string;
  createdAt: string;
  roles: LevelRoles;
  permissions: LevelPermissions;
}

export interface PermissionDefinition {
  name: string;
  description: string;
  level: string;
}

export interface RoleDefinition {
  name: string;
  level: string;
  permissions: string[];
}

export interface UpdateAssignmentsPayload {
  roles: LevelRolesApiModel;
  permissions: LevelPermissionsApiModel;
}

export interface PermissionsState {
  pageLimits: number[];
  users: ProfileUser[] | null;
  paginatedUsers: Paginated<ProfileUser> | null;
  userQueries: UserQueries;

  permissionDefinitions: PermissionDefinition[] | null;
  roleDefinitions: RoleDefinition[] | null;

  isFetching: {
    users: boolean;
    permissionDefinitions: boolean;
    roleDefinitions: boolean;
  };
  isUpdating: {
    userAssignments: boolean;
  };
  errors: {
    users: string | null;
    permissionDefinitions: string | null;
    roleDefinitions: string | null;
    userAssignments: string | null;
  };
}

export interface UserQueries {
  page: number;
  limit: number;
  search: string | null;
}

export interface UserQueriesAPI extends Omit<UserQueries, 'search'> {
  search_param: string | null;
}

const pageLimits = [15, 30, 60];
export const defaultUserQueries: UserQueries = {
  page: 0,
  limit: pageLimits[0],
  search: null,
};

export const defaultPermissionsState: PermissionsState = {
  users: null,
  permissionDefinitions: null,
  roleDefinitions: null,

  pageLimits: pageLimits,
  paginatedUsers: null,
  userQueries: defaultUserQueries,

  isFetching: {
    users: false,
    permissionDefinitions: false,
    roleDefinitions: false,
  },
  isUpdating: {
    userAssignments: false,
  },
  errors: {
    users: null,
    permissionDefinitions: null,
    roleDefinitions: null,
    userAssignments: null,
  },
};

export * from './reducer';
export * from './actions';
