import { booleanStringToBoolean } from '../../utils/string.utils';
import { AggregatedFeaturesApi, EnvironmentsState } from './types';

export const toFeatureFlags = (
  payload: AggregatedFeaturesApi
): EnvironmentsState['flags'] => {
  return {
    isNewPaymentModelEnabled: booleanStringToBoolean(
      payload['feature.newPaymentModelEnabled']
    ),
  };
};
