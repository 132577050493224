import { useResponsive } from '../../../hooks/useResponsive';
import { useStore } from '../../../store/state';

export const useHeader = () => {
  const [{ userState }] = useStore();
  const { layout } = useResponsive();
  const name =
    userState.keycloakProfile &&
    `${userState.keycloakProfile.firstName} ${userState.keycloakProfile.lastName}`;

  return {
    keycloakProfile: userState.keycloakProfile,
    layout: layout,
    name: name,
  };
};
