import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Heading1, Heading3 } from '../../components/Typography';
import { UnauthorizedPageContainer } from './UnauthorizedPage.styles';

export const UnauthorizedPage: React.FC = () => (
  <UnauthorizedPageContainer data-testid="unauthorized_page">
    <FontAwesomeIcon icon="exclamation-triangle" />
    <Heading1>Unauthorized</Heading1>
    <Heading3>You do not have permission to view this page</Heading3>
  </UnauthorizedPageContainer>
);
