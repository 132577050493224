import { DispatchFn } from '../state';
import client, { Microservice } from '../../utils/http.utils';
import { transformUserQueryForBuilder } from '../../utils/pageControllers.utils';
import { UpdateAssignmentsPayload, UserQueries } from './store';
import { captureException } from '../../utils/sentry.utils';

export enum PermissionsActions {
  requestGetUsersAttempt = 'REQUEST_GET_USERS_ATTEMPT',
  requestGetUsersSuccess = 'REQUEST_GET_USERS_SUCCESS',
  requestGetUsersSingleSuccess = 'REQUEST_GET_USERS_SINGLE_SUCCESS',
  requestGetUsersFailure = 'REQUEST_GET_USERS_FAILURE',

  requestGetRolesAttempt = 'REQUEST_GET_ROLES_ATTEMPT',
  requestGetRolesSuccess = 'REQUEST_GET_ROLES_SUCCESS',
  requestGetRolesFailure = 'REQUEST_GET_ROLES_FAILURE',

  requestGetPermissionsAttempt = 'REQUEST_GET_PERMISSIONS_ATTEMPT',
  requestGetPermissionsSuccess = 'REQUEST_GET_PERMISSIONS_SUCCESS',
  requestGetPermissionsFailure = 'REQUEST_GET_PERMISSIONS_FAILURE',

  requestPutUserAssignmentsAttempt = 'REQUEST_PUT_USER_ASSIGNMENTS_ATTEMPT',
  requestPutUserAssignmentsSuccess = 'REQUEST_PUT_USER_ASSIGNMENTS_SUCCESS',
  requestPutUserAssignmentsFailure = 'REQUEST_PUT_USER_ASSIGNMENTS_FAILURE',

  clearUsers = 'CLEAR_USERS',
  setUserQueries = 'SET_USERS_QUERIES',
}
export const RequestGetUsersAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  try {
    dispatch({ type: PermissionsActions.requestGetUsersAttempt });
    const response = await client.request(Microservice.PROFILE, '/user', 'GET');
    dispatch({
      type: PermissionsActions.requestGetUsersSingleSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PermissionsActions.requestGetUsersFailure,
      payload: 'An error occurred while fetching users',
    });
  }
};

export const RequestGetUsersPaginatedAsync = async (
  dispatch: DispatchFn,
  queries: UserQueries
): Promise<void> => {
  let count = 0;
  const maxTries = 2;
  let isSuccess = false;
  do {
    try {
      dispatch({ type: PermissionsActions.requestGetUsersAttempt });
      const response = await client.request(
        Microservice.PROFILE,
        `/user/paginated${transformUserQueryForBuilder(queries)}`,
        'GET'
      );
      if (response) isSuccess = true;
      dispatch({
        type: PermissionsActions.requestGetUsersSuccess,
        payload: response,
      });
    } catch (error) {
      if (++count == maxTries) captureException(error);
      dispatch({
        type: PermissionsActions.requestGetUsersFailure,
        payload: 'An error occurred while fetching users',
      });
      await new Promise(resolve => setTimeout(resolve, 3000));
    }
  } while (!isSuccess && count < maxTries);
};

export const SetUserQueries = async (
  dispatch: DispatchFn,
  filters: UserQueries
): Promise<void> => {
  dispatch({
    type: PermissionsActions.setUserQueries,
    payload: filters,
  });
};

export const ClearUsers = (dispatch: DispatchFn) => {
  dispatch({
    type: PermissionsActions.clearUsers,
  });
};

export const RequestGetRolesAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  try {
    dispatch({ type: PermissionsActions.requestGetRolesAttempt });
    const response = await client.request(
      Microservice.PROFILE,
      '/roles',
      'GET'
    );
    dispatch({
      type: PermissionsActions.requestGetRolesSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PermissionsActions.requestGetRolesFailure,
      payload: 'An error occured while fetching roles',
    });
  }
};

export const RequestGetPermissionsAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  try {
    dispatch({ type: PermissionsActions.requestGetPermissionsAttempt });
    const response = await client.request(
      Microservice.PROFILE,
      '/permissions',
      'GET'
    );
    dispatch({
      type: PermissionsActions.requestGetPermissionsSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PermissionsActions.requestGetPermissionsFailure,
      payload: 'An error occured while fetching permissions',
    });
  }
};

export const RequestPutUserAssignments = async (
  dispatch: DispatchFn,
  keycloakId: string,
  payload: UpdateAssignmentsPayload
): Promise<void> => {
  try {
    dispatch({ type: PermissionsActions.requestPutUserAssignmentsAttempt });
    await client.request(
      Microservice.PROFILE,
      `/user/${keycloakId}/assignments`,
      'PUT',
      payload
    );
    dispatch({
      type: PermissionsActions.requestPutUserAssignmentsSuccess,
      payload: payload,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PermissionsActions.requestPutUserAssignmentsFailure,
      payload: 'An error occurred while updating user assignments',
    });
    throw new Error();
  }
};
