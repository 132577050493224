import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Notification,
  NotificationPriority,
} from '../../../store/Notification/store';
import { NotificationIconWrapper } from './NotificationItem.styles';

export const NotificationItemIcon: React.FC<{ notification: Notification }> = ({
  notification,
}) => {
  const renderPriorityIcon = (priority: string) => {
    switch (priority) {
      case NotificationPriority.HIGH:
        return (
          <NotificationIconWrapper priority={NotificationPriority.HIGH}>
            <FontAwesomeIcon icon={'exclamation-circle'} />
          </NotificationIconWrapper>
        );

      case NotificationPriority.MEDIUM:
        return (
          <NotificationIconWrapper priority={NotificationPriority.MEDIUM}>
            <FontAwesomeIcon icon={'info-circle'} />
          </NotificationIconWrapper>
        );

      default:
        return (
          <NotificationIconWrapper priority={NotificationPriority.LOW}>
            <FontAwesomeIcon icon={'check-circle'} />
          </NotificationIconWrapper>
        );
    }
  };

  return renderPriorityIcon(notification.priority);
};
