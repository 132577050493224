import React from 'react';
import { useLocation } from 'react-router';
import { useResponsive } from '../../hooks/useResponsive';
import { Breakpoint } from '../../styling/breakpoints';
import { Breadcrumbs } from '../Breadcrumbs';
import { useBreadcrumbs } from '../Breadcrumbs/useBreadcrumbs';
import { Header } from '../common/Header';
import {
  AppContainer,
  ContentContainer,
  PageOuterContainer,
} from './Navigation.styles';
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';

export const Navigation: React.FC = ({ children }) => {
  const { layout } = useResponsive();
  const location = useLocation();
  const pathname = location.pathname;
  const breadcrumbsProps = useBreadcrumbs(pathname, layout);

  const isProgrammeContentDownloadRoute = pathname.startsWith('/download/');

  if (isProgrammeContentDownloadRoute) {
    return (
      <AppContainer>
        <ContentContainer>
          <PageOuterContainer aria-label="main content">
            {children}
          </PageOuterContainer>
        </ContentContainer>
      </AppContainer>
    );
  }

  return (
    <AppContainer>
      {layout === Breakpoint.DESKTOP && <Sidebar />}
      <ContentContainer>
        {layout === Breakpoint.DESKTOP ? (
          <Header>
            <Breadcrumbs {...breadcrumbsProps} />
          </Header>
        ) : (
          <>
            <Topbar />
            <Breadcrumbs {...breadcrumbsProps} />
          </>
        )}
        <PageOuterContainer aria-label="main content">
          {children}
        </PageOuterContainer>
      </ContentContainer>
    </AppContainer>
  );
};
