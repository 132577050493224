/* eslint-disable no-prototype-builtins */
import styled from 'styled-components/macro';
import { defaultTheme, theme } from '../../../styling/theme';

interface ButtonProps {
  variant?: string;
  direction?: string;
  fullWidth?: boolean;
  minWidth?: string;
}

const OptionsBackground: { [index: string]: string } = {
  default: 'transparent',
  primary: defaultTheme.COLOUR.PRIMARY,
  secondary: '#d1d3e2',
  primaryOutline: 'transparent',
  criticalOutline: 'transparent',
  warningOutline: 'transparent',
  successOutline: 'transparent',
};

const OptionsColor: { [index: string]: string } = {
  default: '#5c43c5',
  primary: '#fff',
  secondary: '#858796',
  primaryOutline: defaultTheme.COLOUR.PRIMARY,
  criticalOutline: defaultTheme.COLOUR.ALERT,
  warningOutline: defaultTheme.COLOUR.WARNING,
  successOutline: defaultTheme.COLOUR.SUCCESS,
};

const OptionsBorder: { [index: string]: string } = {
  default: '2px solid #5c43c5',
  primary: 'none',
  secondary: '2px solid #d1d3e2',
  primaryOutline: `2px solid ${defaultTheme.COLOUR.PRIMARY}`,
  criticalOutline: `2px solid ${defaultTheme.COLOUR.ALERT}`,
  warningOutline: `2px solid ${defaultTheme.COLOUR.WARNING}`,
  successOutline: `2px solid ${defaultTheme.COLOUR.SUCCESS}`,
};

const OptionsBackgroundHover: { [index: string]: string } = {
  default: '#5c43c5',
  primary: defaultTheme.COLOUR.PRIMARY_DARK,
  secondary: '#5c43c5',
  primaryOutline: defaultTheme.COLOUR.PRIMARY_DARK,
  criticalOutline: defaultTheme.COLOUR.ALERT,
  warningOutline: defaultTheme.COLOUR.WARNING,
  successOutline: defaultTheme.COLOUR.SUCCESS,
};

const OptionsColorHover: { [index: string]: string } = {
  default: '#fff',
  primary: '#fff',
  secondary: '#fff',
  criticalOutline: '#fff',
  warningOutline: '#fff',
  successOutline: '#fff',
};

const OptionsBorderHover: { [index: string]: string } = {
  default: '2px solid #5c43c5',
  primary: 'none',
  secondary: '2px solid #5c43c5',
  primaryOutline: `2px solid ${defaultTheme.COLOUR.PRIMARY_DARK}`,
  criticalOutline: `2px solid ${defaultTheme.COLOUR.ALERT}`,
  warningOutline: `2px solid ${defaultTheme.COLOUR.WARNING}`,
  successOutline: `2px solid ${defaultTheme.COLOUR.SUCCESS}`,
};

const getButtonBackground = (variant?: string) => {
  if (variant) {
    return OptionsBackground.hasOwnProperty(variant)
      ? OptionsBackground[variant]
      : OptionsBackground.default;
  } else {
    return OptionsBackground.default;
  }
};

const getButtonColor = (variant?: string) => {
  if (variant) {
    return OptionsColor.hasOwnProperty(variant)
      ? OptionsColor[variant]
      : OptionsColor.default;
  } else {
    return OptionsColor.default;
  }
};

const getBorder = (variant?: string) => {
  if (variant) {
    return OptionsBorder.hasOwnProperty(variant)
      ? OptionsBorder[variant]
      : OptionsBorder.default;
  } else {
    return OptionsBorder.default;
  }
};

const getButtonBackgroundHover = (variant?: string) => {
  if (variant) {
    return OptionsBackgroundHover.hasOwnProperty(variant)
      ? OptionsBackgroundHover[variant]
      : OptionsBackgroundHover.default;
  } else {
    return OptionsBackgroundHover.default;
  }
};

const getButtonColorHover = (variant?: string) => {
  if (variant) {
    return OptionsColorHover.hasOwnProperty(variant)
      ? OptionsColorHover[variant]
      : OptionsColorHover.default;
  } else {
    return OptionsColorHover.default;
  }
};

const getBorderHover = (variant?: string) => {
  if (variant) {
    return OptionsBorderHover.hasOwnProperty(variant)
      ? OptionsBorderHover[variant]
      : OptionsBorderHover.default;
  } else {
    return OptionsBorderHover.default;
  }
};

export const Button = styled.button<ButtonProps>`
  all: unset;
  background: ${props => getButtonBackground(props.variant)};
  color: ${props => getButtonColor(props.variant)};
  border: ${props => getBorder(props.variant)};
  cursor: pointer;
  border-radius: ${props => theme(props).EFFECTS.RADIUS_STANDARD};
  padding: 0 1rem;
  ${props => theme(props).TYPOGRAPHY.BODY_TEXT};
  height: ${props => theme(props).SIZE.XLARGE};
  box-sizing: border-box;
  text-align: center;
  font-weight: 900;
  ${props => props.fullWidth && 'width:100%;'};
  ${props => props.minWidth && `min-width:${props.minWidth}`};

  svg {
    ${props => !props.direction && `margin-left: 1em`}
    ${props => props.direction === 'right' && `margin-right: 1em`}
    ${props => props.direction === 'left' && `margin-left: 1em`}
  }

  &:hover,
  &:focus {
    background: ${props => getButtonBackgroundHover(props.variant)};
    border: ${props => getBorderHover(props.variant)};
    color: ${props => getButtonColorHover(props.variant)};
    transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};
  }

  &:disabled {
    cursor: not-allowed;
    background: ${props => theme(props).COLOUR.BACKGROUND_LIGHTEST};
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    border: none;
  }
`;

export const ButtonIcon = styled.button<ButtonProps>`
  background: ${props => getButtonBackground(props.variant)};
  color: ${props => getButtonColor(props.variant)};
  border-radius: ${props => theme(props).LAYOUT.BORDER_RADIUS_CIRCLE};
  font-size: 1em;
  min-width: 2rem;
  width: ${props => theme(props).SIZE.XLARGE};
  height: ${props => theme(props).SIZE.XLARGE};
  padding: 0.5em;
  border: ${props => getBorder(props.variant)};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  &:hover {
    background: ${props => getButtonBackgroundHover(props.variant)};
    border: ${props => getBorderHover(props.variant)};
    color: ${props => getButtonColorHover(props.variant)};
    transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};
  }
`;

export const LinkButton = styled.a<ButtonProps>`
  text-decoration: none;
  color: ${props => getButtonColor(props.variant)};
  justify-content: left;
  font-weight: normal;
  text-align: left;
  font-size: 1rem;
  min-width: ${props => theme(props).SIZE.XLARGE};
  height: ${props => theme(props).SIZE.XLARGE};
  padding: 0.5em;
  cursor: pointer;

  span {
    color: ${props => getButtonColor(props.variant)};
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  svg {
    ${props => !props.direction && `margin-left: 1em`}
    ${props => props.direction === 'right' && `margin-right: 1em`}
    ${props => props.direction === 'left' && `margin-left: 1em`}
  }
`;
