import React from 'react';
import { LoadingPageContainer, LogoContainer } from './LoadingPage.styles';
import { Logo } from '../Logo';
import { Spinner } from '../Spinner';

export const LoadingPage: React.FC = () => {
  return (
    <LoadingPageContainer data-testid="t_loading_page">
      <LogoContainer>
        <Logo collapsed={false} />
      </LogoContainer>
      <Spinner size={60} colour="white" />
    </LoadingPageContainer>
  );
};
