import { LocaleAPIModel, Locale, LocalisedMessage } from './store';

export const transformLocaleFromApi = (payload: LocaleAPIModel): Locale => {
  return {
    code: payload.code,
    name: payload.name,
    isActive: payload.is_active,
  };
};

export const transformLocalesFromApi = (
  payload: LocaleAPIModel[]
): Locale[] => {
  return payload.map(loc => transformLocaleFromApi(loc));
};

export const transformPutActiveLocaleResponse = (
  currentLocale: Locale,
  response: boolean
): Locale => {
  return {
    ...currentLocale,
    isActive: response,
  };
};

export const transformSingleMessageResponse = (
  currentMessages: LocalisedMessage[] | null,
  response: LocalisedMessage
): LocalisedMessage[] => {
  return (
    currentMessages?.map(obj => (response.id === obj.id ? response : obj)) || []
  );
};

export const transformSingleLocalisedMessageResponse = (
  currentMessages: LocalisedMessage[] | null,
  response: LocalisedMessage
): LocalisedMessage[] => {
  return (
    currentMessages?.map(obj =>
      response.key === obj.key
        ? { ...obj, translatedText: response.translatedText, id: response.id }
        : obj
    ) || []
  );
};
