import { DispatchFn } from '../../store/state';
import { apiError, ErrorMessage } from '../../types/error.types';
import client, { Microservice } from '../../utils/http.utils';
import { captureException } from '../../utils/sentry.utils';
import { InfluencerQueries, CreatedByQueries } from '../Discovery/store';
import { WithdrawalToSelfbillMappingPaginated } from '../Payments/store';
import {
  InfluencersAPI,
  PaginatedUserAccountsAPI,
  transformUserQueryForBuilder,
} from '../Discovery/transformers';
import { transactionFromApi } from '../Programmes/payload';
import {
  PaymentType,
  TransactionApiModel,
  TransactionStatus,
} from '../Programmes/store';
import { PaginatedScheduledPaymentsAPI, ScheduledPaymentAPI } from './payload';
import {
  CommissionQueries,
  CommissionUpdate,
  EditBonusAPIPayload,
  EditRecurringAPIPayload,
  InvoiceQueries,
  IssuerDetails,
  OrderIdentifierQueries,
  PaginatedInvoicesAPI,
  PaginatedOrderIdentifiersAPI,
  PaginatedWithdrawalsAPI,
  Payment,
  PaymentQueries,
  RecurringPaymentBody,
  RejectBulkApiModel,
  ScheduledPayment,
  ScheduledPaymentsQueries,
  StandardPaymentFilters,
  WithdrawalsQueries,
} from './store';

export enum PaymentsActions {
  requestGetPaymentStatusesAttempt = 'REQUEST_GET_PAYMENT_STATUSES_ATTEMPT',
  requestGetPaymentStatusesSuccess = 'REQUEST_GET_PAYMENT_STATUSES_SUCCESS',
  requestGetPaymentStatusesFailure = 'REQUEST_GET_PAYMENT_STATUSES_FAILURE',

  requestGetPaymentsAttempt = 'REQUEST_GET_PAYMENTS_ATTEMPT',
  requestGetPaymentsSuccess = 'REQUEST_GET_PAYMENTS_SUCCESS',
  requestGetPaymentsFailure = 'REQUEST_GET_PAYMENTS_FAILURE',

  requestGetPaymentsCSVAttempt = 'REQUEST_GET_PAYMENTS_CSV_ATTEMPT',
  requestGetPaymentsCSVSuccess = 'REQUEST_GET_PAYMENTS_CSV_SUCCESS',
  requestGetPaymentsCSVFailure = 'REQUEST_GET_PAYMENTS_CSV_FAILURE',

  setFilterQuery = 'SET_FILTER_QUERY',

  clearStandardPayments = 'CLEAR_STANDARD_PAYMENTS',
  clearScheduledPayments = 'CLEAR_SCHEDULED_PAYMENTS',
  clearCommissions = 'CLEAR_COMMISSIONS',

  resetStandardFilters = 'RESET_STANDARD_FILTERS',
  setStandardFilters = 'SET_STANDARD_FILTERS',

  resetScheduledFilters = 'RESET_SCHEDULED_FILTERS',
  setScheduledFilters = 'SET_SCHEDULED_FILTERS',

  resetCommissionFilters = 'RESET_COMMISSION_FILTERS',
  setCommissionFilters = 'SET_COMMISSION_FILTERS',

  resetInvoiceFilters = 'RESET_INVOICE_FILTERS',
  setInvoiceFilters = 'SET_INVOICE_FILTERS',

  resetWithdrawalFilters = 'RESET_WITHDRAWAL_FILTERS',
  setWithdrawalFilters = 'SET_WITHDRAWAL_FILTERS',

  clearSelectedPayments = 'CLEAR_SELECTED_PAYMENTS',
  clearFixedPaymentsVat = 'CLEAR_FIXED_PAYMENTS_VAT',

  setSelectedPayments = 'SET_SELECTED_PAYMENTS',
  setAllSelectedPayments = 'SET_ALL_SELECTED_PAYMENTS',

  requestPostBulkPaymentsApproveAttempt = 'REQUEST_POST_BULK_PAYMENTS_APPROVE_ATTEMPT',
  requestPostBulkPaymentsApproveSuccess = 'REQUEST_POST_BULK_PAYMENTS_APPROVE_SUCCESS',
  requestPostBulkPaymentsApproveFailure = 'REQUEST_POST_BULK_PAYMENTS_APPROVE_FAILURE',

  requestPostBulkPaymentsRejectAttempt = 'REQUEST_POST_BULK_PAYMENTS_REJECT_ATTEMPT',
  requestPostBulkPaymentsRejectSuccess = 'REQUEST_POST_BULK_PAYMENTS_REJECT_SUCCESS',
  requestPostBulkPaymentsRejectFailure = 'REQUEST_POST_BULK_PAYMENTS_REJECT_FAILURE',

  requestPostRecurringPaymentAttempt = 'REQUEST_POST_RECURRING_PAYMENT_ATTEMPT',
  requestPostRecurringPaymentSuccess = 'REQUEST_POST_RECURRING_PAYMENT_SUCCESS',
  requestPostRecurringPaymentFailure = 'REQUEST_POST_RECURRING_PAYMENT_FAILURE',

  requestGetScheduledPaymentsAttempt = 'REQUEST_GET_SCHEDULED_PAYMENTS_ATTEMPT',
  requestGetScheduledPaymentsSuccess = 'REQUEST_GET_SCHEDULED_PAYMENTS_SUCCESS',
  requestGetScheduledPaymentsFailure = 'REQUEST_GET_SCHEDULED_PAYMENTS_FAILURE',

  requestGetRequestStatusesAttempt = 'REQUEST_GET_REQUEST_STATUSES_ATTEMPT',
  requestGetRequestStatusesSuccess = 'REQUEST_GET_REQUEST_STATUSES_SUCCESS',
  requestGetRequestStatusesFailure = 'REQUEST_GET_REQUEST_STATUSES_FAILURE',

  requestGetScheduleStatusesAttempt = 'REQUEST_GET_SCHEDULE_STATUSES_ATTEMPT',
  requestGetScheduleStatusesSuccess = 'REQUEST_GET_SCHEDULE_STATUSES_SUCCESS',
  requestGetScheduleStatusesFailure = 'REQUEST_GET_SCHEDULE_STATUSES_FAILURE',

  requestPatchScheduleApprovalAttempt = 'REQUEST_PATCH_SCHEDULE_APPROVAL_ATTEMPT',
  requestPatchScheduleApprovalSuccess = 'REQUEST_PATCH_SCHEDULE_APPROVAL_SUCCESS',
  requestPatchScheduleApprovalFailure = 'REQUEST_PATCH_SCHEDULE_APPROVAL_FAILURE',

  requestPatchScheduleRejectionAttempt = 'REQUEST_PATCH_SCHEDULE_REJECTION_ATTEMPT',
  requestPatchScheduleRejectionSuccess = 'REQUEST_PATCH_SCHEDULE_REJECTION_SUCCESS',
  requestPatchScheduleRejectionFailure = 'REQUEST_PATCH_SCHEDULE_REJECTION_FAILURE',

  requestPatchScheduleCancellationAttempt = 'REQUEST_PATCH_SCHEDULE_CANCELLATION_ATTEMPT',
  requestPatchScheduleCancellationSuccess = 'REQUEST_PATCH_SCHEDULE_CANCELLATION_SUCCESS',
  requestPatchScheduleCancellationFailure = 'REQUEST_PATCH_SCHEDULE_CANCELLATION_FAILURE',

  requestPatchSchedulePauseAttempt = 'REQUEST_PATCH_SCHEDULE_PAUSE_ATTEMPT',
  requestPatchSchedulePauseSuccess = 'REQUEST_PATCH_SCHEDULE_PAUSE_SUCCESS',
  requestPatchSchedulePauseFailure = 'REQUEST_PATCH_SCHEDULE_PAUSE_FAILURE',

  requestPatchDeleteBonusPaymentAttempt = 'REQUEST_PATCH_DELETE_BONUS_PAYMENT_ATTEMPT',
  requestPatchDeleteBonusPaymentSuccess = 'REQUEST_PATCH_DELETE_BONUS_PAYMENT_SUCCESS',
  requestPatchDeleteBonusPaymentFailure = 'REQUEST_PATCH_DELETE_BONUS_PAYMENT_FAILURE',

  requestPatchDeleteSchedulePaymentAttempt = 'REQUEST_PATCH_DELETE_SCHEDULE_PAYMENT_ATTEMPT',
  requestPatchDeleteSchedulePaymentSuccess = 'REQUEST_PATCH_DELETE_SCHEDULE_PAYMENT_SUCCESS',
  requestPatchDeleteSchedulePaymentFailure = 'REQUEST_PATCH_DELETE_SCHEDULE_PAYMENT_FAILURE',

  requestGetIssuerDetailsAttempt = 'REQUEST_GET_ISSUER_DETAILS_ATTEMPT',
  requestGetIssuerDetailsSuccess = 'REQUEST_GET_ISSUER_DETAILS_SUCCESS',
  requestGetIssuerDetailsFailure = 'REQUEST_GET_ISSUER_DETAILS_FAILURE',

  requestPutRecurringPaymentAttempt = 'REQUEST_PUT_RECURRING_PAYMENT_ATTEMPT',
  requestPutRecurringPaymentSuccess = 'REQUEST_PUT_RECURRING_PAYMENT_SUCCESS',
  requestPutRecurringPaymentFailure = 'REQUEST_PUT_RECURRING_PAYMENT_FAILURE',

  requestPutBonusPaymentAttempt = 'REQUEST_PUT_BONUS_PAYMENT_ATTEMPT',
  requestPutBonusPaymentSuccess = 'REQUEST_PUT_BONUS_PAYMENT_SUCCESS',
  requestPutBonusPaymentFailure = 'REQUEST_PUT_BONUS_PAYMENT_FAILURE',

  requestGetBonusPaymentAsyncSuccess = 'REQUEST_GET_BONUS_PAYMENT_ASYNC_SUCCESS',

  requestGetCommissionsListAttempt = 'REQUEST_GET_COMMISSIONS_ATTEMPT',
  requestGetCommissionsListSuccess = 'REQUEST_GET_COMMISSIONS_SUCCESS',
  requestGetCommissionsListFailure = 'REQUEST_GET_COMMISSIONS_FAILURE',

  requestGetCommissionAttempt = 'REQUEST_GET_COMMISSION_ATTEMPT',
  requestGetCommissionSuccess = 'REQUEST_GET_COMMISSION_SUCCESS',
  requestGetCommissionFailure = 'REQUEST_GET_COMMISSION_FAILURE',

  requestPostDesiredCommissionStatusAttempt = 'REQUEST_POST_COMMISSION_ATTEMPT',
  requestPostDesiredCommissionStatusSuccess = 'REQUEST_POST_COMMISSION_SUCCESS',
  requestPostDesiredCommissionStatusFailure = 'REQUEST_POST_COMMISSION_FAILURE',

  requestGetInfluencersAttempt = 'REQUEST_GET_INFLUENCER_ATTEMPT',
  requestGetInfluencersSuccess = 'REQUEST_GET_INFLUENCER_SUCCESS',
  requestGetInfluencersFailure = 'REQUEST_GET_INFLUENCER_FAILURE',

  requestGetNonInfluencersAttempt = 'REQUEST_GET_NON_INFLUENCER_ATTEMPT',
  requestGetNonInfluencersSuccess = 'REQUEST_GET_NON_INFLUENCER_SUCCESS',
  requestGetNonInfluencersFailure = 'REQUEST_GET_NON_INFLUENCER_FAILURE',

  requestSelfbillSearchAttempt = 'REQUEST_SELF_BILL_SEARCH_ATTEMPT',
  requestSelfbillSearchSuccess = 'REQUEST_SELF_BILL_SEARCH_SUCCESS',
  requestSelfbillSearchFailure = 'REQUEST_SELF_BILL_SEARCH_FAILURE',

  requestGetInvoicesAttempt = 'REQUEST_GET_INVOICES_ATTEMPT',
  requestGetInvoicesSuccess = 'REQUEST_GET_INVOICES_SUCCESS',
  requestGetInvoicesFailure = 'REQUEST_GET_INVOICES_FAILURE',
  clearInvoices = 'CLEAR_INVOICES',

  requestGetWithdrawalsAttempt = 'REQUEST_GET_WITHDRAWALS_ATTEMPT',
  requestGetWithdrawalsSuccess = 'REQUEST_GET_WITHDRAWALS_SUCCESS',
  requestGetWithdrawalsFailure = 'REQUEST_GET_WITHDRAWALS_FAILURE',
  clearWithdrawals = 'CLEAR_WITHDRAWALS',

  clearBulkPaymentsError = 'CLEAR_BULK_PAYMENTS_ERROR',
  setOrderIdentifierQueries = 'SET_ORDER_IDENTIFIER_QUERIES',
  requestGetOrderIdentifiersSuccess = 'REQUEST_GET_ORDER_IDENTIFIERS_SUCCESS',
  requestGetOrderIdentifiersAttempt = 'REQUEST_GET_ORDER_IDENTIFIERS_ATTEMPT',
  requestGetOrderIdentifiersFailure = 'REQUEST_GET_ORDER_IDENTIFIERS_FAILURE',
}

export const RequestGetPaymentStatusesAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  try {
    dispatch({ type: PaymentsActions.requestGetPaymentStatusesAttempt });
    const response = await client.request<string[]>(
      Microservice.TRANSACTION,
      '/transaction/statuses',
      'GET'
    );
    dispatch({
      type: PaymentsActions.requestGetPaymentStatusesSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestGetPaymentStatusesFailure,
      payload: 'An error occured while fetching payment statuses',
    });
  }
};

const getQuerySuffix = (queryName: string): string => {
  if (queryName === 'date_from' || queryName === 'date_to') {
    return 'T00:00:00Z';
  } else {
    return '';
  }
};

const queryBuilder = (
  queries:
    | PaymentQueries
    | ScheduledPaymentsQueries
    | StandardPaymentFilters
    | CommissionQueries
    | InfluencerQueries
    | InvoiceQueries
    | WithdrawalsQueries
    | OrderIdentifierQueries
    | CreatedByQueries
): string => {
  let query = '';
  Object.entries(queries).forEach(
    ([queryName, queryValue]: [
      string,
      string | TransactionStatus | PaymentType | null,
    ]) => {
      if (queryValue != null) {
        query += `&${queryName}=${queryValue}${getQuerySuffix(queryName)}`;
      }
    }
  );
  return query.length > 0 ? `?${query.substr(1, query.length)}` : '';
};

export const RequestGetPaymentsAsync = async (
  dispatch: DispatchFn,
  paymentQueries: PaymentQueries
): Promise<void> => {
  const queries = queryBuilder(paymentQueries);
  try {
    dispatch({ type: PaymentsActions.requestGetPaymentsAttempt });
    const response = await client.request(
      Microservice.TRANSACTION,
      `/transaction/bonus-transactions${queries}`,
      'GET'
    );
    dispatch({
      type: PaymentsActions.requestGetPaymentsSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestGetPaymentsFailure,
      payload: 'An error occured while fetching payments',
    });
  }
};
export const RequestGetBonusPaymentAsync = async (
  dispatch: DispatchFn,
  transactionId: string
) => {
  try {
    const response = await client.request<TransactionApiModel>(
      Microservice.TRANSACTION,
      `/transaction/${transactionId}`,
      'GET'
    );
    dispatch({
      type: PaymentsActions.requestGetBonusPaymentAsyncSuccess,
      payload: transactionFromApi(response),
    });
  } catch (error) {
    captureException(error);
  }
};
export const RequestGetScheduledPaymentsAsync = async (
  dispatch: DispatchFn,
  queries: ScheduledPaymentsQueries
) => {
  try {
    dispatch({ type: PaymentsActions.requestGetScheduledPaymentsAttempt });
    const response = await client.request<PaginatedScheduledPaymentsAPI>(
      Microservice.TRANSACTION,
      `/transaction/recurring_payment${queryBuilder(queries)}`,
      'GET'
    );
    dispatch({
      type: PaymentsActions.requestGetScheduledPaymentsSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestGetScheduledPaymentsFailure,
      payload: 'An error occured while fetching scheduled payments',
    });
  }
};

export const RequestPatchScheduledApprovalAsync = async (
  dispatch: DispatchFn,
  recurringPayment: ScheduledPayment
) => {
  try {
    dispatch({ type: PaymentsActions.requestPatchScheduleApprovalAttempt });
    const response = await client.request<ScheduledPaymentAPI>(
      Microservice.TRANSACTION,
      `/transaction/recurring_payment/${recurringPayment.id}/approval`,
      'PATCH'
    );
    dispatch({
      type: PaymentsActions.requestPatchScheduleApprovalSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestPatchScheduleApprovalFailure,
      payload:
        error.status === ErrorMessage.BAD_REQUEST &&
        error.customMessage['UpdateStateError']
          ? error.customMessage['UpdateStateError']
          : 'An error occured while approving the payment',
    });

    throw apiError(error.status, error.message);
  }
};

export const RequestPatchScheduledRejectionAsync = async (
  dispatch: DispatchFn,
  recurringPayment: ScheduledPayment
) => {
  try {
    dispatch({ type: PaymentsActions.requestPatchScheduleRejectionAttempt });
    const response = await client.request<ScheduledPaymentAPI>(
      Microservice.TRANSACTION,
      `/transaction/recurring_payment/${recurringPayment.id}/rejection`,
      'PATCH'
    );
    dispatch({
      type: PaymentsActions.requestPatchScheduleRejectionSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestPatchScheduleRejectionFailure,
      payload:
        error.status === ErrorMessage.BAD_REQUEST &&
        error.customMessage['UpdateStateError']
          ? error.customMessage['UpdateStateError']
          : 'An error occured while rejecting the payment',
    });

    throw apiError(error.status, error.message);
  }
};

export const RequestPatchScheduledCancellationAsync = async (
  dispatch: DispatchFn,
  recurringPayment: ScheduledPayment
) => {
  try {
    dispatch({ type: PaymentsActions.requestPatchScheduleCancellationAttempt });
    const response = await client.request<ScheduledPaymentAPI>(
      Microservice.TRANSACTION,
      `/transaction/recurring_payment/${recurringPayment.id}/cancellation`,
      'PATCH'
    );
    dispatch({
      type: PaymentsActions.requestPatchScheduleCancellationSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestPatchScheduleCancellationFailure,
      payload: 'An error occured while cancelling the schedule',
    });
    throw new Error();
  }
};

export const RequestPatchScheduledPauseAsync = async (
  dispatch: DispatchFn,
  recurringPayment: ScheduledPayment
) => {
  try {
    dispatch({ type: PaymentsActions.requestPatchSchedulePauseAttempt });
    const response = await client.request<ScheduledPaymentAPI>(
      Microservice.TRANSACTION,
      `/transaction/recurring_payment/${recurringPayment.id}/pause`,
      'PATCH'
    );
    dispatch({
      type: PaymentsActions.requestPatchSchedulePauseSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestPatchSchedulePauseFailure,
      payload: 'An error occured while pausing/unpausing the schedule',
    });
    throw new Error();
  }
};

export const RequestPostBulkApprovalAsync = async (
  dispatch: DispatchFn,
  payments: string[]
): Promise<void> => {
  try {
    dispatch({ type: PaymentsActions.requestPostBulkPaymentsApproveAttempt });
    const response: TransactionApiModel[] = await client.request(
      Microservice.TRANSACTION,
      `/transaction/bulk-approve`,
      'POST',
      payments
    );
    dispatch({
      type: PaymentsActions.requestPostBulkPaymentsApproveSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestPostBulkPaymentsApproveFailure,
      payload:
        error.status === ErrorMessage.BAD_REQUEST &&
        error.customMessage['UpdateStateError']
          ? error.customMessage['UpdateStateError']
          : 'An error occured while bulk approving the payment',
    });
    throw apiError(error.status, error.message);
  }
};

export const RequestPatchDeleteBonusPaymentAsync = async (
  dispatch: DispatchFn,
  transactionId: string
): Promise<void> => {
  try {
    dispatch({ type: PaymentsActions.requestPatchDeleteBonusPaymentAttempt });
    await client.request(
      Microservice.TRANSACTION,
      `/transaction/${transactionId}/delete`,
      'PATCH'
    );
    dispatch({
      type: PaymentsActions.requestPatchDeleteBonusPaymentSuccess,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestPatchDeleteBonusPaymentFailure,
      payload:
        error.status === ErrorMessage.BAD_REQUEST &&
        error.customMessage['UpdateStateError']
          ? error.customMessage['UpdateStateError']
          : 'An error occured while deleting the payment',
    });

    throw apiError(error.status, error.message);
  }
};

export const RequestPatchDeleteSchedulePaymentAsync = async (
  dispatch: DispatchFn,
  recurringId: string
): Promise<void> => {
  try {
    dispatch({
      type: PaymentsActions.requestPatchDeleteSchedulePaymentAttempt,
    });
    await client.request(
      Microservice.TRANSACTION,
      `/transaction/recurring_payment/${recurringId}/delete`,
      'PATCH'
    );
    dispatch({
      type: PaymentsActions.requestPatchDeleteSchedulePaymentSuccess,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestPatchDeleteSchedulePaymentFailure,
      payload:
        error.status === ErrorMessage.BAD_REQUEST &&
        error.customMessage['UpdateStateError']
          ? error.customMessage['UpdateStateError']
          : 'An error occured while deleting the payment',
    });

    throw apiError(error.status, error.message);
  }
};

export const RequestPostRecurringPaymentAsync = async (
  dispatch: DispatchFn,
  requestBody: RecurringPaymentBody
): Promise<void> => {
  try {
    dispatch({ type: PaymentsActions.requestPostRecurringPaymentAttempt });
    const response = await client.request(
      Microservice.TRANSACTION,
      `/transaction/recurring_payment`,
      'POST',
      requestBody
    );
    dispatch({
      type: PaymentsActions.requestPostRecurringPaymentSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: PaymentsActions.requestPostRecurringPaymentFailure,
      payload: 'An error occured while posting the recurring payment',
    });
    throw new Error(e);
  }
};

export const RequestPostBulkRejectAsync = async (
  dispatch: DispatchFn,
  bulkRejection: RejectBulkApiModel
): Promise<void> => {
  try {
    dispatch({ type: PaymentsActions.requestPostBulkPaymentsRejectAttempt });
    const response = await client.request(
      Microservice.TRANSACTION,
      `/transaction/bulk-reject`,
      'POST',
      bulkRejection
    );
    dispatch({
      type: PaymentsActions.requestPostBulkPaymentsRejectSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestPostBulkPaymentsRejectFailure,
      payload:
        error.status === ErrorMessage.BAD_REQUEST &&
        error.customMessage['UpdateStateError']
          ? error.customMessage['UpdateStateError']
          : 'An error occured while rejecting the payments',
    });
    throw apiError(error.status, error.message);
  }
};

export const RequestGetPaymentsCSVAsync = async (
  dispatch: DispatchFn,
  paymentFilters: StandardPaymentFilters
): Promise<void> => {
  const queries = queryBuilder(paymentFilters);
  try {
    dispatch({ type: PaymentsActions.requestGetPaymentsCSVAttempt });
    const response = await client.request(
      Microservice.TRANSACTION,
      `/transaction/csv/bonus-transactions${queries}`,
      'GET',
      null,
      'text/csv'
    );
    dispatch({
      type: PaymentsActions.requestGetPaymentsCSVSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestGetPaymentsCSVFailure,
      payload: 'An error occured while fetching payments',
    });
  }
};
export const RequestGetRequestStatusesAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  try {
    dispatch({ type: PaymentsActions.requestGetRequestStatusesAttempt });
    const response = await client.request<string[]>(
      Microservice.TRANSACTION,
      '/transaction/request_statuses',
      'GET'
    );
    dispatch({
      type: PaymentsActions.requestGetRequestStatusesSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestGetRequestStatusesFailure,
      payload: 'An error occured while fetching request statuses',
    });
  }
};

export const RequestGetScheduleStatusesAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  try {
    dispatch({ type: PaymentsActions.requestGetScheduleStatusesAttempt });
    const response = await client.request<string[]>(
      Microservice.TRANSACTION,
      '/transaction/schedule_statuses',
      'GET'
    );
    dispatch({
      type: PaymentsActions.requestGetScheduleStatusesSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestGetScheduleStatusesFailure,
      payload: 'An error occured while fetching schedule statuses',
    });
  }
};

export const RequestGetIssuerDetailsAsync = async (
  dispatch: DispatchFn,
  keycloakId: string
): Promise<IssuerDetails | undefined> => {
  try {
    dispatch({ type: PaymentsActions.requestGetIssuerDetailsAttempt });
    const response = await client.request(
      Microservice.PROFILE,
      `/user/${keycloakId}`,
      'GET'
    );
    dispatch({
      type: PaymentsActions.requestGetIssuerDetailsSuccess,
    });
    return response as IssuerDetails;
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestGetIssuerDetailsFailure,
      payload: 'An error occured while fetching issuer details',
    });
  }
};

export const RequestPutRecurringPaymentAsync = async (
  dispatch: DispatchFn,
  recurringId: string,
  editPayload: EditRecurringAPIPayload
): Promise<void> => {
  try {
    dispatch({ type: PaymentsActions.requestPutRecurringPaymentAttempt });
    const response = await client.request(
      Microservice.TRANSACTION,
      `/transaction/recurring_payment/${recurringId}`,
      'PUT',
      editPayload
    );
    dispatch({
      type: PaymentsActions.requestPutRecurringPaymentSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestPutRecurringPaymentFailure,
      payload:
        error.status === ErrorMessage.BAD_REQUEST &&
        error.customMessage['EditTransactionError']
          ? error.customMessage['EditTransactionError']
          : 'An error occured while editing this schedule',
    });
    throw apiError(error.status, error.message);
  }
};

export const RequestPutBonusPaymentAsync = async (
  dispatch: DispatchFn,
  transactionId: string,
  editPayload: EditBonusAPIPayload
): Promise<void> => {
  try {
    dispatch({ type: PaymentsActions.requestPutBonusPaymentAttempt });
    const response = await client.request(
      Microservice.TRANSACTION,
      `/transaction/${transactionId}`,
      'PUT',
      editPayload
    );
    dispatch({
      type: PaymentsActions.requestPutBonusPaymentSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestPutBonusPaymentFailure,
      payload:
        error.status === ErrorMessage.BAD_REQUEST &&
        error.customMessage['EditTransactionError']
          ? error.customMessage['EditTransactionError']
          : 'An error occured while editing this payment',
    });
    throw apiError(error.status, error.message);
  }
};

export const SetOrderIdentifierQueries = async (
  dispatch: DispatchFn,
  filters: OrderIdentifierQueries
): Promise<void> => {
  dispatch({
    type: PaymentsActions.setOrderIdentifierQueries,
    payload: filters,
  });
};

export const ResetStandardFilters = (dispatch: DispatchFn) => {
  dispatch({
    type: PaymentsActions.resetStandardFilters,
  });
};

export const ResetScheduledFilters = (dispatch: DispatchFn) => {
  dispatch({
    type: PaymentsActions.resetScheduledFilters,
  });
};

export const ResetCommissionFilters = (dispatch: DispatchFn) => {
  dispatch({
    type: PaymentsActions.resetCommissionFilters,
  });
};

export const ResetInvoiceFilters = async (
  dispatch: DispatchFn
): Promise<void> => {
  dispatch({
    type: PaymentsActions.resetInvoiceFilters,
  });
};

export const ResetWithdrawalFilters = async (
  dispatch: DispatchFn
): Promise<void> => {
  dispatch({
    type: PaymentsActions.resetWithdrawalFilters,
  });
};

export const ClearStandardPayments = (dispatch: DispatchFn) => {
  dispatch({
    type: PaymentsActions.clearStandardPayments,
  });
};
export const ClearScheduledPayments = (dispatch: DispatchFn) => {
  dispatch({
    type: PaymentsActions.clearScheduledPayments,
  });
};
export const ClearCommissions = (dispatch: DispatchFn) => {
  dispatch({
    type: PaymentsActions.clearCommissions,
  });
};

export const SetStandardPaymentFilters = async (
  dispatch: DispatchFn,
  filters: PaymentQueries
): Promise<void> => {
  dispatch({
    type: PaymentsActions.setStandardFilters,
    payload: filters,
  });
};

export const SetScheduledPaymentFilters = async (
  dispatch: DispatchFn,
  filters: ScheduledPaymentsQueries
): Promise<void> => {
  dispatch({
    type: PaymentsActions.setScheduledFilters,
    payload: filters,
  });
};

export const SetCommissionFilters = async (
  dispatch: DispatchFn,
  filters: CommissionQueries
): Promise<void> => {
  dispatch({
    type: PaymentsActions.setCommissionFilters,
    payload: filters,
  });
};

export const SetInvoiceFilters = async (
  dispatch: DispatchFn,
  filters: InvoiceQueries
): Promise<void> => {
  dispatch({
    type: PaymentsActions.setInvoiceFilters,
    payload: filters,
  });
};

export const SetWithdrawalFilters = async (
  dispatch: DispatchFn,
  filters: WithdrawalsQueries
): Promise<void> => {
  dispatch({
    type: PaymentsActions.setWithdrawalFilters,
    payload: filters,
  });
};

export const SetSelectedPayments = (dispatch: DispatchFn, payment: Payment) => {
  dispatch({
    type: PaymentsActions.setSelectedPayments,
    payload: payment,
  });
};

export const SetAllSelectedPayments = (
  dispatch: DispatchFn,
  payments: Payment[]
) => {
  dispatch({
    type: PaymentsActions.setAllSelectedPayments,
    payload: payments,
  });
};

export const ClearSelectedPayments = (dispatch: DispatchFn) => {
  dispatch({
    type: PaymentsActions.clearSelectedPayments,
  });
};

export const ClearBulkPaymentsError = (dispatch: DispatchFn) => {
  dispatch({
    type: PaymentsActions.clearBulkPaymentsError,
  });
};

export const ClearFixedPaymentsVat = (dispatch: DispatchFn) => {
  dispatch({
    type: PaymentsActions.clearFixedPaymentsVat,
  });
};
export const RequestGetCommissionsListAsync = async (
  dispatch: DispatchFn,
  queries: CommissionQueries
) => {
  try {
    dispatch({ type: PaymentsActions.requestGetCommissionsListAttempt });

    const response = await client.request(
      Microservice.COMMISSION,
      `/commission/list${queryBuilder(queries)}`,
      'GET'
    );

    dispatch({
      type: PaymentsActions.requestGetCommissionsListSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestGetCommissionsListFailure,
      payload: 'An error occured while fetching commissions list',
    });
    throw new Error(error);
  }
};

export const RequestGetCommissionAsync = async (
  dispatch: DispatchFn,
  id: string
): Promise<void> => {
  try {
    dispatch({ type: PaymentsActions.requestGetCommissionAttempt });

    const response = await client.request(
      Microservice.COMMISSION,
      `/commission/${id}`,
      'GET'
    );

    dispatch({
      type: PaymentsActions.requestGetCommissionSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestGetCommissionFailure,
      payload: 'An error occurred while fetching commission.',
    });
    throw new Error();
  }
};

export const RequestPostCommission = async (
  dispatch: DispatchFn,
  commissions: CommissionUpdate
): Promise<void> => {
  try {
    dispatch({
      type: PaymentsActions.requestPostDesiredCommissionStatusAttempt,
    });

    await client.request(
      Microservice.COMMISSION,
      `/commission/bulk_decision`,
      'POST',
      commissions
    );

    dispatch({
      type: PaymentsActions.requestPostDesiredCommissionStatusSuccess,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestPostDesiredCommissionStatusFailure,
      payload: 'An error occurred while submitting commission.',
    });
    throw new Error();
  }
};

export const RequestPaymentPaginatedInfluencersAsync = async (
  dispatch: DispatchFn,
  queries: InfluencerQueries,
  shouldClear?: boolean
) => {
  try {
    dispatch({ type: PaymentsActions.requestGetInfluencersAttempt });
    const response = await client.request<InfluencersAPI>(
      Microservice.INFLUENCER,
      `/influencer/pagination${queryBuilder(queries)}`,
      'GET'
    );
    dispatch({
      type: PaymentsActions.requestGetInfluencersSuccess,
      payload: { content: response, shouldClear: shouldClear },
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestGetInfluencersFailure,
      payload: 'an error occured while fetching influencers',
    });
  }
};

export const RequestPaginatedUserAccountsAsync = async (
  dispatch: DispatchFn,
  queries: CreatedByQueries,
  shouldClear?: boolean
) => {
  try {
    dispatch({ type: PaymentsActions.requestGetNonInfluencersAttempt });
    const response = await client.request<PaginatedUserAccountsAPI>(
      Microservice.PROFILE,
      `/user/paginated${transformUserQueryForBuilder(queries)}`,
      'GET'
    );
    dispatch({
      type: PaymentsActions.requestGetNonInfluencersSuccess,
      payload: { content: response, shouldClear: shouldClear },
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestGetNonInfluencersFailure,
      payload: 'an error occured while fetching marketers',
    });
  }
};

export const RequestSelfbillSearchAsync = async (
  dispatch: DispatchFn,
  selfbillId: string
) => {
  try {
    dispatch({ type: PaymentsActions.requestSelfbillSearchAttempt });
    const response = await client.request<WithdrawalToSelfbillMappingPaginated>(
      Microservice.TRANSACTION,
      `/transaction/selfbill_id/search/${selfbillId}`,
      'GET'
    );
    dispatch({
      type: PaymentsActions.requestSelfbillSearchSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestSelfbillSearchFailure,
      payload: "an error occured while fetching self bill Id's",
    });
  }
};

export const RequestPaymentPaginatedOrderIdentifiersAsync = async (
  dispatch: DispatchFn,
  queries: OrderIdentifierQueries
) => {
  try {
    dispatch({ type: PaymentsActions.requestGetOrderIdentifiersAttempt });
    const response = await client.request<PaginatedOrderIdentifiersAPI>(
      Microservice.COMMISSION,
      `/commission/orders/search${queryBuilder(queries)}`,
      'GET'
    );
    dispatch({
      type: PaymentsActions.requestGetOrderIdentifiersSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestGetOrderIdentifiersFailure,
      payload: 'an error occured while fetching order identifiers',
    });
  }
};

export const RequestGetPartnerizeInvoicesAsync = async (
  dispatch: DispatchFn,
  queries: InvoiceQueries
) => {
  try {
    dispatch({ type: PaymentsActions.requestGetInvoicesAttempt });
    const response = await client.request<PaginatedInvoicesAPI>(
      Microservice.TRANSACTION,
      `/partnerize_invoice${queryBuilder(queries)}`,
      'GET'
    );
    dispatch({
      type: PaymentsActions.requestGetInvoicesSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestGetInvoicesFailure,
    });
  }
};

export const ClearPaginatedInvoices = (dispatch: DispatchFn) => {
  dispatch({ type: PaymentsActions.clearInvoices });
};

export const RequestGetWithdrawalsAsync = async (
  dispatch: DispatchFn,
  queries: WithdrawalsQueries
) => {
  try {
    dispatch({ type: PaymentsActions.requestGetWithdrawalsAttempt });
    const response = await client.request<PaginatedWithdrawalsAPI>(
      Microservice.TRANSACTION,
      `/transaction/withdrawal/selfbills${queryBuilder(queries)}`,
      'GET'
    );
    dispatch({
      type: PaymentsActions.requestGetWithdrawalsSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: PaymentsActions.requestGetWithdrawalsFailure,
    });
  }
};

export const ClearPaginatedWithdrawals = (dispatch: DispatchFn) => {
  dispatch({ type: PaymentsActions.clearWithdrawals });
};
