import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: grid;
  place-items: center;
`;

export default {
  Wrapper,
};
