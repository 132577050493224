import { AgenciesQuery } from '../../components/Agencies/types';
import { DispatchFn } from '../state';

import client, { Microservice } from '../../utils/http.utils';
import { captureException } from '../../utils/sentry.utils';
import { queryBuilder } from '../../utils/pageControllers.utils';

export enum AgencyActions {
  requestPaginatedAgenciesAttempt = 'REQUEST_PAGINATED_AGENCIES_ATTEMPT',
  requestPaginatedAgenciesSuccess = 'REQUEST_PAGINATED_AGENCIES_SUCCESS',
  requestPaginatedAgenciesFailure = 'REQUEST_PAGINATED_AGENCIES_FAILURE',

  requestGetAgencyApplicationsAttempt = 'REQUEST_GET_AGENCY_APPLICATIONS_ATTEMPT',
  requestGetAgencyApplicationsSuccess = 'REQUEST_GET_AGENCY_APPLICATIONS_SUCCESS',
  requestGetAgencyApplicationsFailure = 'REQUEST_GET_AGENCY_APPLICATIONS_FAILURE',

  requestGetAgencyApplicationAttempt = 'REQUEST_GET_AGENCY_APPLICATION_ATTEMPT',
  requestGetAgencyApplicationSuccess = 'REQUEST_GET_AGENCY_APPLICATION_SUCCESS',
  requestGetAgencyApplicationFailure = 'REQUEST_GET_AGENCY_APPLICATION_FAILURE',

  requestGetAgencyOptionsAttempt = 'REQUEST_GET_AGENCY_OPTIONS_ATTEMPT',
  requestGetAgencyOptionsSuccess = 'REQUEST_GET_AGENCY_OPTIONS_SUCCESS',
  requestGetAgencyOptionsFailure = 'REQUEST_GET_AGENCY_OPTIONS_FAILURE',

  clearAgencyList = 'CLEAR_AGENCY_LIST',
  setAgencyFilters = 'SET_AGENCY_FILTERS',

  clearAgencyApplicationList = 'CLEAR_AGENCY_APPLICATION_LIST',
  setAgencyApplicationFilters = 'SET_AGENCY_APPLICATION_FILTERS',

  requestAgencyAttempt = 'REQUEST_AGENCY_ATTEMPT',
  requestAgencySuccess = 'REQUEST_AGENCY_SUCCESS',
  requestAgencyFailure = 'REQUEST_AGENCY_FAILURE',

  resetAgencyApplication = 'RESET_AGENCY_APPLICATION',

  requestGetInfluencerMappedAgencyAttempt = 'REQUEST_GET_INFLUENCER_MAPPED_AGENCY_ATTEMPT',
  requestGetInfluencerMappedAgencySuccess = 'REQUEST_GET_INFLUENCER_MAPPED_AGENCY_SUCCESS',
  requestGetInfluencerMappedAgencyFailure = 'REQUEST_GET_INFLUENCER_MAPPED_AGENCY_FAILURE',
}

export const RequestPaginatedAgenciesAsync = async (
  dispatch: DispatchFn,
  queries: AgenciesQuery,
  shouldClear = false
) => {
  try {
    dispatch({
      type: AgencyActions.requestPaginatedAgenciesAttempt,
    });
    const response = await client.request(
      Microservice.AGENCY,
      `/agency/paginated${queryBuilder<AgenciesQuery>(queries)}`,
      'GET'
    );
    dispatch({
      type: AgencyActions.requestPaginatedAgenciesSuccess,
      payload: { content: response, shouldClear: shouldClear },
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: AgencyActions.requestPaginatedAgenciesFailure,
      payload: error,
    });
    throw new Error();
  }
};

export const ClearAgencyList = (dispatch: DispatchFn) => {
  dispatch({
    type: AgencyActions.clearAgencyList,
  });
};

export const ClearAgencyApplicationList = (dispatch: DispatchFn) => {
  dispatch({
    type: AgencyActions.clearAgencyApplicationList,
  });
};

export const SetAgencyFilters = (
  dispatch: DispatchFn,
  queries: AgenciesQuery
) => {
  dispatch({
    type: AgencyActions.setAgencyFilters,
    payload: queries,
  });
};

export const SetAgencyApplicationFilters = (
  dispatch: DispatchFn,
  queries: AgenciesQuery
) => {
  dispatch({
    type: AgencyActions.setAgencyApplicationFilters,
    payload: queries,
  });
};

export const RequestGetAgencyApplications = async (
  dispatch: DispatchFn,
  queries: AgenciesQuery,
  shouldClear = false
): Promise<void> => {
  try {
    dispatch({ type: AgencyActions.requestGetAgencyApplicationsAttempt });
    const response = await client.request(
      Microservice.AGENCY,
      `/agency_application/paginated${queryBuilder(queries)}`,
      'GET'
    );
    dispatch({
      type: AgencyActions.requestGetAgencyApplicationsSuccess,
      payload: { content: response, shouldClear: shouldClear },
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: AgencyActions.requestGetAgencyApplicationsFailure,
      payload: 'An error occurred while fetching agency applications',
    });
  }
};

export const RequestGetAgencyApplication = async (
  dispatch: DispatchFn,
  applicationId: string
): Promise<void> => {
  try {
    dispatch({ type: AgencyActions.requestGetAgencyApplicationAttempt });
    const response = await client.request(
      Microservice.AGENCY,
      `/agency_application/${applicationId}`,
      'GET'
    );
    dispatch({
      type: AgencyActions.requestGetAgencyApplicationSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: AgencyActions.requestGetAgencyApplicationFailure,
      payload: 'An error occurred while fetching agency application',
    });
  }
};

export const ResetAgencyApplication = (dispatch: DispatchFn) => {
  dispatch({
    type: AgencyActions.resetAgencyApplication,
  });
};

export const RequestGetAgencyApplicationFormOptions = async (
  dispatch: DispatchFn
): Promise<void> => {
  try {
    dispatch({
      type: AgencyActions.requestGetAgencyOptionsAttempt,
    });
    const response = await client.request(
      Microservice.AGENCY,
      `/agency_application/form_options`,
      'GET'
    );
    dispatch({
      type: AgencyActions.requestGetAgencyOptionsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgencyActions.requestGetAgencyOptionsFailure,
      payload: 'An error occurred while fetching agency options',
    });
  }
};
export const RequestAgencyAsync = async (
  dispatch: DispatchFn,
  agencyId: string
) => {
  try {
    dispatch({
      type: AgencyActions.requestAgencyAttempt,
    });
    const response = await client.request(
      Microservice.AGENCY,
      `/agency/${agencyId}`,
      'GET'
    );
    dispatch({
      type: AgencyActions.requestAgencySuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: AgencyActions.requestAgencyFailure,
      payload: error,
    });
    throw new Error();
  }
};

export const RequestGetInfluencerMappedAgency = async (
  dispatch: DispatchFn,
  agencyId: string
) => {
  try {
    dispatch({
      type: AgencyActions.requestGetInfluencerMappedAgencyAttempt,
    });
    const response = await client.request(
      Microservice.INFLUENCER,
      `/influencer/agency?agency_id=${agencyId}`,
      'GET'
    );
    dispatch({
      type: AgencyActions.requestGetInfluencerMappedAgencySuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: AgencyActions.requestGetInfluencerMappedAgencyFailure,
      payload: error,
    });
    throw error;
  }
};
