import styled from 'styled-components';
import { Heading1, Heading3 } from '../../components/Typography';
import { theme } from '../../styling/theme';

export const UnauthorizedPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  svg,
  ${Heading3}, ${Heading1} {
    color: ${props => theme(props).COLOUR.BACKGROUND_MEDIUM};
  }

  svg {
    font-size: 7rem;
    margin-bottom: ${props => theme(props).SIZE.SMALL};
  }
`;
