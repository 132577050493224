import React, { VFC } from 'react';
import s from './Label.styles';
import { LabelProps } from './Label.types';

export const Label: VFC<LabelProps> = ({
  htmlFor,
  label,
  error,
  inputTestId,
}) => {
  const labelTestId = inputTestId ? `${inputTestId}_label` : '';
  const errorTestId = inputTestId ? `${inputTestId}_error` : '';

  return (
    <s.Wrapper>
      <s.Label htmlFor={htmlFor} data-testid={labelTestId}>
        {label}
      </s.Label>
      {error && <s.Error data-testid={errorTestId}>{error}</s.Error>}
    </s.Wrapper>
  );
};
