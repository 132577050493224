import React, { FC } from 'react';
import { useClipboard } from 'use-clipboard-copy';
import Snackbar from '@material-ui/core/Snackbar';
import {
  Container,
  TextArea,
  Button,
  CopyButton,
  ButtonContainer,
} from './Clipboard.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

interface ClipboardProps {
  value?: string | null;
  duration?: number;
  testId?: string | null;
  type?: string | null;
  id?: string;
}

export const Clipboard: FC<ClipboardProps> = ({
  value,
  id = 'clipboard',
  duration = 900,
  testId = '',
  type,
}) => {
  const { target, copy, copied } = useClipboard({
    copiedTimeout: duration,
  });
  const isLink = value?.indexOf('://') !== -1;
  if (type === 'Button')
    return (
      <ButtonContainer data-testid="t_clipboard">
        <CopyButton
          value={value || ''}
          onClick={() => copy(value)}
          disabled={value === undefined}
          title="click to copy"
          data-testid={`t_clipboard_input_${testId}`}
        >
          Copy
        </CopyButton>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={copied}
          autoHideDuration={duration}
          message="Copied to Clipboard"
        />
      </ButtonContainer>
    );
  return (
    <Container data-testid="t_clipboard">
      <TextArea
        data-testid={`t_clipboard_input${testId}`}
        isLink={isLink}
        disabled
        readOnly
        ref={target}
        value={value || '...'}
        id={id}
      />
      <Button
        onClick={copy}
        disabled={value === undefined}
        title="click to copy"
      >
        <FontAwesomeIcon icon={faCopy} />
      </Button>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={copied}
        autoHideDuration={duration}
        message="Copied to Clipboard"
      />
    </Container>
  );
};
