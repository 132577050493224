import styled from 'styled-components';
import { theme } from '../../styling/theme';
import { px2rem } from '../../styling/sizing';
import { Box } from '../common';

export const RedirectTitle = styled.h1`
  color: ${props => theme(props).COLOUR.WHITE};
  font-size: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  margin-bottom: ${props => theme(props).SIZE.XSMALL};
`;

export const RedirectText = styled.h3`
  color: ${props => theme(props).COLOUR.WHITE};
  font-size: 1rem;
  margin-bottom: ${props => theme(props).SIZE.LARGE};
`;

export const RedirectContainer = styled(Box)`
  height: 100vh;
  width: 100vw;
  background: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
  background-size: cover;
`;

export const RedirectContent = styled(Box)`
  border-radius: 8px;
  background-color: rgba(55, 79, 99, 0.76);
`;

export const LogoutBox = styled.span`
  align-self: end;
  justify-self: start;
  margin-top: 3rem;
  cursor: pointer;
`;

export const TextButtonLogo = styled.button`
  border: none;
  background-color: transparent;
  color: white;
  text-decoration: underline;

  min-width: fit-content;
  height: fit-content;
  padding: 0;
  box-shadow: none;
  font-size: 1.2rem;
  &:hover {
    cursor: pointer;
  }

  &:focus {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHTEST};
    text-decoration: underline;
    &:before {
      content: none;
    }
  }

  &:active {
    color: ${props => theme(props).COLOUR.PRIMARY};
  }

  &:disabled {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    background-color: transparent;
    border: none;
  }
  svg {
    margin-right: ${px2rem(8)};
  }
`;
