import { px2rem } from '../sizing';

export const SIZE = {
  TINY: px2rem(4),
  XSMALL: px2rem(8),
  SMALL: px2rem(16),
  AVERAGE: px2rem(24),
  LARGE: px2rem(32),
  XLARGE: px2rem(48),
  HUGE: px2rem(64),
  GIGANTIC: px2rem(72),
  MAX_CONTENT_WIDTH: px2rem(1280),
  SEARCH_WIDGET_WIDTH: px2rem(256),
};
