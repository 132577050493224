import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  NotificationZeroContainer,
  NotificationZeroIcon,
  NotificationZeroInfo,
} from './NotificationItem.styles';

export const NotificationZero: React.FC<{ isMobile: boolean }> = ({
  isMobile,
}) => {
  return (
    <NotificationZeroContainer
      data-testid={'t_notification_zero'}
      isMobile={isMobile}
    >
      <NotificationZeroIcon>
        <FontAwesomeIcon icon={'check'} />
      </NotificationZeroIcon>
      <NotificationZeroInfo>{'All caught up.'}</NotificationZeroInfo>
    </NotificationZeroContainer>
  );
};
