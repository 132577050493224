import { PaginatedMap } from '../types';
import { UserQueries, UserQueriesAPI } from '../store/Permissions/store';

export function handleGeneralPageLimitChange<T>(
  limit: number,
  setPageLimit: (a: number) => void,
  setCurrentPage: (a: number) => void,
  isInitialised: boolean,
  clear: () => void,
  queries: T,
  call: (queries: T) => void
) {
  setCurrentPage(0);
  setPageLimit(limit);
  if (isInitialised) {
    clear();
    call(queries);
  }
}

export function handleGeneralPageChange<T>(
  number: number,
  setCurrentPage: (a: number) => void,
  isUnvisitedPage: boolean,
  queries: T,
  call: (queries: T) => void
) {
  setCurrentPage(number);
  if (isUnvisitedPage) {
    call(queries);
  }
}

export function handleGeneralQueryChange<T>(
  initialised: boolean,
  queries: T,
  clearPaginatedResults: () => void,
  call: (queries: T) => void,
  setCurrentPage?: (a: number) => void
) {
  if (initialised) {
    setCurrentPage && setCurrentPage(0);
    clearPaginatedResults();
    call(queries);
  }
}

export interface PaginationQuery {
  limit: number;
  page: number;
}
export interface Pagination {
  total_count: number;
  limit: number;
  page: number;
}

export interface Paginated<Model> {
  pagination: Pagination;
  content: PaginatedMap<Model> | null;
}
export function paginationTransformer<api, internalModel>(
  pagination: Pagination,
  apiContent: api[],
  currentContent: PaginatedMap<internalModel> | null,
  parser: (response: api[]) => internalModel[],
  shouldClear?: boolean
): Paginated<internalModel> {
  const contentPageMap: PaginatedMap<internalModel> =
    currentContent && !shouldClear
      ? currentContent.set(pagination.page, parser(apiContent))
      : new Map().set(0, parser(apiContent));

  return {
    pagination: pagination,
    content: contentPageMap,
  };
}

export function transformUserQueryForBuilder(queries: UserQueries): string {
  return queryBuilder<UserQueriesAPI>({
    limit: queries.limit,
    page: queries.page,
    search_param: queries.search,
  });
}

interface AnyObject {
  [key: string]: any;
}

type QueryValue = string | string[] | number | null | undefined;

export function queryBuilder<Q extends AnyObject>(queries: Q): string {
  let query = '';
  Object.entries(queries).forEach(
    ([queryName, queryValue]: [string, QueryValue]) => {
      if (queryValue != null) {
        if (queryName === 'interests') {
          queryValue &&
            (queryValue as string[]).forEach(
              (interest: string) => (query += `&${queryName}=${interest}`)
            );
        } else {
          query += `&${queryName}=${queryValue}`;
        }
      }
    }
  );
  return query.length > 0 ? `?${query.substr(1, query.length)}` : '';
}

export const accumulateTruthyValues = (
  object: AnyObject,
  ignoredKeys = new Set()
): number =>
  Object.entries(object).reduce((acc, [key, value]) => {
    // Matches null, NaN, undefined, empty string, empty array, 0
    if (!value || value == false || ignoredKeys.has(key)) return acc;
    return acc + 1;
  }, 0);
