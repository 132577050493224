import React, { FC } from 'react';

export const LogoCollapsedSVG: FC = () => (
  <svg viewBox="0 0 880 880">
    <g>
      <path
        d="M 602.592 122.888 C 588.636 120.964 574.436 120 560.04 120 C 545.644 120 531.244 120.964 517.248 123.008 C 487.288 126.856 458.572 135.076 431.904 146.788 C 318.928 196.152 240 308.924 240 440.02 C 240 616.752 383.256 760 560 760 C 736.744 760 880 616.752 880 440.02 C 880 277.804 759.164 143.7 602.552 122.928 L 602.592 122.888 Z M 602.592 670.692 C 588.836 673.336 574.56 674.624 560.04 674.624 L 431.944 674.624 L 431.944 636.644 C 367.736 594.736 325.384 522.308 325.384 439.98 C 325.384 357.652 367.736 285.224 431.944 243.276 C 457.452 226.636 486.248 214.924 517.288 209.272 C 531.164 206.624 545.44 205.336 560.08 205.336 C 574.72 205.336 588.876 206.624 602.632 209.272 C 711.96 229.204 794.736 324.884 794.736 439.98 C 794.736 555.076 711.96 650.756 602.632 670.692 L 602.592 670.692 Z"
        fill="#0292fc"
      />
      <path
        d="M 0 760 L 40.028 760 L 160 440 L 120.092 440 L 0 760 Z"
        fill="#FAF9F2"
      />
    </g>
  </svg>
);
