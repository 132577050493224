import styled, { css } from 'styled-components/macro';
import { theme } from '../../../styling/theme';
import { px2rem } from '../../../styling/sizing';
import { Margin } from '../Box';

export const HeadingContainer = styled.header`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 1.5rem;
  transition: left ${props => theme(props).EFFECTS.TRANSITION_QUICK};
`;

export const HeadingContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: ${px2rem(64)};
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const UserName = styled.div`
  font-weight: 600;
  font-size: 1.25rem;
  margin-right: ${px2rem(20)};
`;

export const NavMobileStyles = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: ${px2rem(8)};
`;

export const NavDesktopStyles = css`
  position: fixed;
  top: 0;
  margin: ${px2rem(20)};
  z-index: 2;
`;

export const NotificationContainer = styled.div`
  margin-right: ${Margin.XSMALL};
`;
