import { createGlobalStyle } from 'styled-components';
import '../fonts/Poppins.css';
import { px2rem } from '../sizing';
import { GlobalFontFamily } from './fontfamily';
import { NotificationPaper } from './NotificationPaper';

// prettier-ignore
export default createGlobalStyle`

  html {
    ${GlobalFontFamily}
    font-size: 16px;
  }

  body {
    line-height: 1.6;
    font-weight: 300;
  }

  button {
    ${GlobalFontFamily}
  }

  .MuiTab-wrapper {
    ${GlobalFontFamily}
    font-size: ${px2rem(14)};
  }

  p {
    font-size: ${px2rem(14)};
    padding-bottom: ${px2rem(5)};
    line-height: 2;
  }

  * {
    box-sizing: border-box;
  }

  strong {
    font-weight: bolder;
  }

  em {
    font-style: italic;
  }

  a:focus, input:focus, button:focus {
    outline-style: none;
  }

  *[tabindex="0"] {
    cursor: pointer;
  }

  * .MuiTooltip-tooltip {
    ${GlobalFontFamily}
    font-size: 0.875rem !important;
  }

  ${NotificationPaper}
`;
