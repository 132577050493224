import { DispatchFn } from '../state';
import client, { Microservice } from '../../utils/http.utils';
import {
  UpdateLocalisedMessage,
  LocaleAPIModel,
  LocalisedMessage,
  UpdateMessage,
  Locale,
} from './store';
import { captureException } from '../../utils/sentry.utils';

export enum TranslationsAPIActions {
  requestGetAvailableLocalesAttempt = 'REQUEST_GET_AVAILABLE_LOCALES_ATTEMPT',
  requestGetAvailableLocalesSuccess = 'REQUEST_GET_AVAILABLE_LOCALES_SUCCESS',
  requestGetAvailableLocalesFailure = 'REQUEST_GET_AVAILABLE_LOCALES_FAILURE',

  requestGetLocalisedMessagesAttempt = 'REQUEST_GET_LOCALISED_MESSAGES_ATTEMPT',
  requestGetLocalisedMessagesSuccess = 'REQUEST_GET_LOCALISED_MESSAGES_SUCCESS',
  requestGetLocalisedMessagesFailure = 'REQUEST_GET_LOCALISED_MESSAGES_FAILURE',

  requestGetCurrentLocaleCountAttempt = 'REQUEST_GET_CURRENT_LOCALE_COUNT_ATTEMPT',
  requestGetCurrentLocaleCountSuccess = 'REQUEST_GET_CURRENT_LOCALE_COUNT_SUCCESS',
  requestGetCurrentLocaleCountFailure = 'REQUEST_GET_CURRENT_LOCALE_COUNT_FAILURE',

  requestPutMessageAttempt = 'REQUEST_PUT_MESSAGE_ATTEMPT',
  requestPutMessageSuccess = 'REQUEST_PUT_MESSAGE_SUCCESS',
  requestPutMessageFailure = 'REQUEST_PUT_MESSAGE_FAILURE',

  requestPostLocalisedMessageAttempt = 'REQUEST_POST_LOCALISED_MESSAGE_ATTEMPT',
  requestPostLocalisedMessageSuccess = 'REQUEST_POST_LOCALISED_MESSAGE_SUCCESS',
  requestPostLocalisedMessageFailure = 'REQUEST_POST_LOCALISED_MESSAGE_FAILURE',

  requestPutLocaleActiveAttempt = 'REQUEST_PUT_LOCALE_ACTIVE_ATTEMPT',
  requestPutLocaleActiveSuccess = 'REQUEST_PUT_LOCALE_ACTIVE_SUCCESS',
  requestPutLocaleActiveFailure = 'REQUEST_PUT_LOCALE_ACTIVE_FAILURE',
}

export enum TranslationsActions {
  setFilterMessageSearch = 'SET_FILTER_MESSAGE_SEARCH',

  setSelectedLocale = 'SET_SELECTED_LOCALE',

  setSelectedLocalisedMessage = 'SET_SELECTED_LOCALISED_MESSAGE',
}

export const RequestGetAvailableLocalesAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  try {
    dispatch({
      type: TranslationsAPIActions.requestGetAvailableLocalesAttempt,
    });
    const response = await client.request(
      Microservice.LOCALISATION,
      '/localisation/language',
      'GET'
    );
    dispatch({
      type: TranslationsAPIActions.requestGetAvailableLocalesSuccess,
      payload: response as LocaleAPIModel[],
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: TranslationsAPIActions.requestGetAvailableLocalesFailure,
      payload: 'An error occured while fetching available locales.',
    });
    throw new Error();
  }
};

export const RequestGetLocalisedMessagesAsync = async (
  dispatch: DispatchFn,
  locale: string
): Promise<void> => {
  try {
    dispatch({
      type: TranslationsAPIActions.requestGetLocalisedMessagesAttempt,
    });
    const response = await client.request(
      Microservice.LOCALISATION,
      `/localisation/message/${locale}`,
      'GET'
    );
    dispatch({
      type: TranslationsAPIActions.requestGetLocalisedMessagesSuccess,
      payload: response as LocalisedMessage[],
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: TranslationsAPIActions.requestGetLocalisedMessagesFailure,
      payload: 'An error occured while messages for the selected locale',
    });
    throw new Error();
  }
};

export const RequestGetCurrentLocaleCountAsync = async (
  dispatch: DispatchFn,
  locale: string
): Promise<void> => {
  try {
    dispatch({
      type: TranslationsAPIActions.requestGetCurrentLocaleCountAttempt,
    });
    const response = await client.request(
      Microservice.LOCALISATION,
      `/localisation/message/${locale}/count`,
      'GET'
    );
    dispatch({
      type: TranslationsAPIActions.requestGetCurrentLocaleCountSuccess,
      payload: response as number,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: TranslationsAPIActions.requestGetCurrentLocaleCountFailure,
      payload: 'An error occured while fetching locale messages count',
    });
    throw new Error();
  }
};

export const RequestPutMessageAsync = async (
  dispatch: DispatchFn,
  payload: UpdateMessage
): Promise<void> => {
  try {
    dispatch({ type: TranslationsAPIActions.requestPutMessageAttempt });
    const response = await client.request(
      Microservice.LOCALISATION,
      `/localisation/message`,
      'PUT',
      payload
    );
    dispatch({
      type: TranslationsAPIActions.requestPutMessageSuccess,
      payload: response as LocalisedMessage,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: TranslationsAPIActions.requestPutMessageFailure,
      payload: 'An error occurred while updating the message',
    });
    throw new Error();
  }
};

export const RequestPostLocalisedMessageAsync = async (
  dispatch: DispatchFn,
  locale: string,
  payload: UpdateLocalisedMessage
): Promise<void> => {
  try {
    dispatch({
      type: TranslationsAPIActions.requestPostLocalisedMessageAttempt,
    });
    const response = await client.request(
      Microservice.LOCALISATION,
      `/localisation/message/${locale}`,
      'POST',
      payload
    );
    dispatch({
      type: TranslationsAPIActions.requestPostLocalisedMessageSuccess,
      payload: response as LocalisedMessage,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: TranslationsAPIActions.requestPostLocalisedMessageFailure,
      payload: 'An error occurred while creating the message translation',
    });
    throw new Error();
  }
};

export const RequestPutLocaleActiveAsync = async (
  dispatch: DispatchFn,
  locale: string
): Promise<void> => {
  try {
    dispatch({
      type: TranslationsAPIActions.requestPutLocaleActiveAttempt,
    });
    const response = await client.request(
      Microservice.LOCALISATION,
      `/localisation/language/${locale}/active`,
      'PUT'
    );
    dispatch({
      type: TranslationsAPIActions.requestPutLocaleActiveSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: TranslationsAPIActions.requestPutLocaleActiveFailure,
      payload: 'An error occurred while publishing the lanuguage',
    });
    throw new Error();
  }
};

export const SetFilterMessageSearch = async (
  dispatch: DispatchFn,
  searchTerm: string
): Promise<void> => {
  dispatch({
    type: TranslationsActions.setFilterMessageSearch,
    payload: searchTerm,
  });
};

export const SetSelectedLocale = async (
  dispatch: DispatchFn,
  locale: Locale
): Promise<void> => {
  dispatch({
    type: TranslationsActions.setSelectedLocale,
    payload: locale,
  });
};

export const SetSelectedLocalisedMessage = async (
  dispatch: DispatchFn,
  localisedMsg: LocalisedMessage | null
): Promise<void> => {
  dispatch({
    type: TranslationsActions.setSelectedLocalisedMessage,
    payload: localisedMsg,
  });
};
