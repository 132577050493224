import {
  ProgrammeCommission,
  ProgrammeCommissionApi,
  ProgrammeCommissionsApi,
} from '../../../store/Programmes/store';
import { formatCurrency } from '../../../utils/format.utils';

export enum CommissionType {
  fixed = 'fixed',
  percentage = 'percentage',
}
export enum CustomerType {
  new = 'new',
  returning = 'returning',
}

export enum CommissionList {
  DEFAULT = 'default',
  CATEGORY = 'category',
}

export enum CommissionTarget {
  PROGRAMME = 'programme',
  CAMPAIGN = 'campaign',
}

export const blankProgrammeCommission: Commission = {
  category: '',
  type: CommissionType.percentage,
  new: 0,
  returning: 0,
  target: CommissionTarget.PROGRAMME,
};

export const blankCampaignCommission: Commission = {
  category: '',
  type: CommissionType.percentage,
  new: 0,
  returning: 0,
  target: CommissionTarget.CAMPAIGN,
};

export interface Commission extends ProgrammeCommission {
  category?: string;
  isNotEditable?: boolean;
  target?: CommissionTarget | null;
}

export interface CommissionError {
  has: boolean;
  message: string;
}

export const transformToCommissionArray = (
  commissions: ProgrammeCommission[],
  isNotEditable: boolean
): Commission[] => {
  return commissions.map(commission => {
    return {
      ...commission,
      target: commission.target || CommissionTarget.PROGRAMME,
      isNotEditable: isNotEditable,
    };
  });
};

export const transformToCommissionArrayAssociated = (
  commissions: ProgrammeCommission[],
  isNotEditable: boolean
): Commission[] => {
  return commissions.map(commission => {
    return {
      ...commission,
      target: CommissionTarget.CAMPAIGN,
      isNotEditable: isNotEditable,
    };
  });
};

export const formatCommissionAmount = (
  type: CommissionType,
  amount: number,
  default_currency: string
) => {
  return type === CommissionType.percentage
    ? `${amount}%`
    : formatCurrency(default_currency, amount);
};

export const transformToProgrammeCommissionsApi = (
  programmeFallback: ProgrammeCommission | null,
  categories: Commission[],
  campaignFallback?: ProgrammeCommission | null,
  associated?: boolean
): ProgrammeCommissionsApi => {
  if (campaignFallback)
    return {
      fallback: null,
      campaign_fallback:
        !associated && programmeFallback
          ? [programmeFallback, campaignFallback]
          : [campaignFallback],
      categories: transformToProgrammeCommission(categories),
    };
  else if (associated && programmeFallback) {
    return {
      fallback: null,
      campaign_fallback: [
        { ...programmeFallback, target: CommissionTarget.CAMPAIGN },
      ],
      categories: transformToProgrammeCommission(categories),
    };
  } else
    return {
      fallback: programmeFallback,
      categories: transformToProgrammeRequestCommissionMap(categories),
    };
};

export const transformToProgrammeCommission = (
  categoryCommissions: Commission[]
): ProgrammeCommission[] => {
  return categoryCommissions.map(commission => {
    return {
      type: commission.type,
      new: commission.new,
      returning: commission.returning,
      target: commission.target,
      category: commission.category,
    } as ProgrammeCommission;
  });
};

export const transformToProgrammeRequestCommissionMap = (
  categoryCommissions: Commission[]
): ProgrammeCommission[] => {
  return categoryCommissions.map(commission => {
    return {
      type: commission.type,
      new: commission.new,
      returning: commission.returning,
      target: commission.target
        ? commission.target
        : CommissionTarget.PROGRAMME,
      category: commission.category,
    } as ProgrammeCommission;
  });
};

export const transformToProgrammeRequestCommission = (
  programmeFallback: ProgrammeCommission,
  isAssocitaion?: boolean
): ProgrammeCommissionApi => {
  return {
    type: programmeFallback.type,
    new: programmeFallback.new,
    returning: programmeFallback.returning,
    target: isAssocitaion
      ? CommissionTarget.CAMPAIGN
      : programmeFallback.target
        ? programmeFallback.target
        : CommissionTarget.PROGRAMME,
  };
};

export const transformToInvitationCommission = (
  commission: ProgrammeCommissionApi
): ProgrammeCommission => {
  return {
    new: commission.new,
    returning: commission.returning,
    target: commission.target ? commission.target : CommissionTarget.PROGRAMME,
    type: commission.type,
  };
};

export const transformToViewCommission = (value: string) => {
  return value === 'true';
};

export const transformToViewCommissionString = (value: boolean) => {
  return value ? 'true' : 'false';
};
