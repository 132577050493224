export const defaultLocale: Locale = {
  code: 'en-GB',
  name: 'English (United Kingdom)',
  isActive: true,
};

export interface LocaleAPIModel {
  code: string;
  name: string;
  is_active: boolean;
}

export interface Locale {
  code: string;
  name: string;
  isActive: boolean;
}

export interface LocalisedMessage {
  id: string;
  key: string;
  descr: string | null;
  englishText: string | null;
  translatedText: string | null;
}

export interface UpdateLocalisedMessage {
  key: string;
  translatedText: string;
}

export interface UpdateMessage {
  id: string;
  key: string;
  translatedText: string;
  descr?: string;
}

export interface TranslationsState {
  availableLocales: Locale[] | null;
  selectedLocale: Locale;
  selectedLocalisedMessage: LocalisedMessage | null;
  currentLocaleCount: number | null;
  currentLocaleMessages: LocalisedMessage[] | null;

  isFetching: {
    availableLocales: boolean;
    localisedMessages: boolean;
    currentLocaleCount: boolean;
  };
  isUpdating: {
    message: boolean;
    localisedMessage: boolean;
    localeStatus: boolean;
  };
  errors: {
    fetchAvailableLocales: string | null;
    fetchCurrentLocaleCount: string | null;
    fetchLocalisedMessages: string | null;
    updateLocalisedMessage: string | null;
    updateMessage: string | null;
    updateLocaleStatus: string | null;
  };
  filters: {
    messageSearch: string;
  };
}

export const defaultTranslationsState: TranslationsState = {
  availableLocales: null,
  currentLocaleCount: null,
  currentLocaleMessages: null,
  selectedLocale: defaultLocale,
  selectedLocalisedMessage: null,

  isFetching: {
    availableLocales: false,
    currentLocaleCount: false,
    localisedMessages: false,
  },
  isUpdating: {
    message: false,
    localisedMessage: false,
    localeStatus: false,
  },
  errors: {
    fetchAvailableLocales: null,
    fetchCurrentLocaleCount: null,
    fetchLocalisedMessages: null,
    updateLocalisedMessage: null,
    updateMessage: null,
    updateLocaleStatus: null,
  },
  filters: {
    messageSearch: '',
  },
};

export * from './reducer';
export * from './actions';
