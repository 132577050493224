import { Action } from '../state';
import { EnvironmentsActions } from './actions';
import { EnvironmentsState } from './types';

export const EnvironmentsReducer = (
  state: EnvironmentsState,
  action: Action
): EnvironmentsState => {
  switch (action.type) {
    case EnvironmentsActions.requestFeatureFlagsAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          flags: true,
        },
        errors: {
          ...state.errors,
          flags: null,
        },
      };
    case EnvironmentsActions.requestFeatureFlagsSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          flags: false,
        },
        flags: action.payload,
      };
    case EnvironmentsActions.requestFeatureFlagsFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          flags: false,
        },
        errors: {
          ...state.errors,
          flags: action.payload,
        },
      };
    default:
      return state;
  }
};
