import styled from 'styled-components/macro';
import { theme } from '../../../styling/theme';
import { inputStyles } from '../Input';
import { px2rem } from '../../../styling/sizing';

const copyButtonWidth = '4.5rem';

export const Container = styled.div`
  position: relative;
`;

export const ButtonContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  width: ${px2rem(100)};
  margin: auto;
`;

export const TextArea = styled.input<{ isLink: boolean }>`
  ${inputStyles}
  height: 2.5rem;
  padding-right: ${copyButtonWidth};
`;

export const Button = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border: 1px solid ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
  border-radius: 0 ${props => theme(props).LAYOUT.BORDER_RADIUS}
    ${props => theme(props).LAYOUT.BORDER_RADIUS} 0;
  width: ${copyButtonWidth};
  cursor: pointer;

  background-color: ${props =>
    props.disabled
      ? theme(props).COLOUR.BACKGROUND_DARKEST
      : theme(props).COLOUR.PRIMARY};

  color: ${props =>
    props.disabled ? theme(props).COLOUR.TEXT_STANDARD : 'white'};
`;

export const CopyButton = styled.button`
  width: 100%;
  font-size: 1rem;
  height: 2.5rem;
  border: 1px solid ${props => theme(props).COLOUR.PRIMARY};
  border-radius: ${props => theme(props).LAYOUT.BORDER_RADIUS};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 0 1rem;
  background-color: white;
  color: ${props => theme(props).COLOUR.PRIMARY};

  &:hover {
    color: white;
    transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};

    background-color: ${props => theme(props).COLOUR.PRIMARY};
  }

  &:focus {
    color: white;
    transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};
    background-color: ${props => theme(props).COLOUR.PRIMARY};
  }

  &:disabled {
    background-color: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
  }
`;
