import { DispatchFn } from '../state';
import { KeycloakProfile } from 'keycloak-js';
import {
  CaptchaApi,
  RoleMap,
  UserNamesApi,
  UserPermissionMapApi,
} from './store';
import client, { Microservice } from '../../utils/http.utils';
import { parseJws } from '../../utils/token.utils';
import { captureException } from '../../utils/sentry.utils';

export enum UserActions {
  setKeycloakProfile = 'SET_KEYCLOAK_PROFILE',
  setRoles = 'SET_ROLES',
  setKeycloakId = 'SET_KEYCLOAK_ID',

  requestGetProfileTokenAttempt = 'REQUEST_GET_PROFILE_TOKEN_ATTEMPT',
  requestGetProfileTokenSuccess = 'REQUEST_GET_PROFILE_TOKEN_SUCCESS',
  requestGetProfileTokenFailure = 'REQUEST_GET_PROFILE_TOKEN_FAILURE',

  requestPostCaptchaAttempt = 'REQUEST_POST_CAPTCHA_ATTEMPT',
  requestPostCaptchaSuccess = 'REQUEST_POST_CAPTCHA_SUCCESS',
  requestPostCaptchaFailure = 'REQUEST_POST_CAPTCHA_FAILURE',

  requestUserNameSuccess = 'REQUEST_USER_NAME_SUCCESS',

  requestGetUserNamesAttempt = 'REQUEST_GET_USER_NAMES_ATTEMPT',
  requestGetUserNamesSuccess = 'REQUEST_GET_USER_NAMES_SUCCESS',
  requestGetUserNamesFailure = 'REQUEST_GET_USER_NAMES_FAILURE',
}

export const SetKeycloakProfile = (
  dispatch: DispatchFn,
  profile: KeycloakProfile
) => {
  dispatch({ type: UserActions.setKeycloakProfile, payload: profile });
};

export const SetRoles = (dispatch: DispatchFn, roles: RoleMap) => {
  dispatch({ type: UserActions.setRoles, payload: roles });
};

export const SetKeycloakId = (dispatch: DispatchFn, keycloakId: string) => {
  dispatch({ type: UserActions.setKeycloakId, payload: keycloakId });
};

export const RequestGetProfileTokenAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  try {
    dispatch({
      type: UserActions.requestGetProfileTokenAttempt,
    });
    const response = await client.requestMinusProfile<string>(
      Microservice.PROFILE,
      '/token',
      'GET',
      undefined,
      'text/plain'
    );
    const tokenBody = parseJws<UserPermissionMapApi>(response);
    window.profileToken = response;
    dispatch({
      type: UserActions.requestGetProfileTokenSuccess,
      payload: tokenBody,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: UserActions.requestGetProfileTokenFailure,
      payload: 'An error occurred while fetching profile token',
    });
  }
};

export const RequestPostCaptchaAsync = async (
  dispatch: DispatchFn,
  token: string
): Promise<void> => {
  try {
    const formData = new FormData();
    dispatch({ type: UserActions.requestPostCaptchaAttempt });
    const response: CaptchaApi = await client.requestMinusContentType(
      Microservice.IMAGE,
      `/file?recaptcha_token=${token}&purpose=programme_imagery`,
      'POST',
      formData
    );
    const responsePayload = response.success;
    dispatch({
      type: UserActions.requestPostCaptchaSuccess,
      payload: responsePayload,
    });
  } catch (error) {
    captureException(error);
    const failurePayload = false;
    dispatch({
      type: UserActions.requestPostCaptchaFailure,
      payload: failurePayload,
    });
  }
};

export const RequestGetUserNamesAsync = async (
  dispatch: DispatchFn,
  keycloakId: string
): Promise<UserNamesApi | null> => {
  try {
    const response: UserNamesApi = await client.request(
      Microservice.PROFILE,
      `/user/${keycloakId}/name`,
      'GET'
    );
    dispatch({
      type: UserActions.requestUserNameSuccess,
      payload: {
        firstName: response.first_name,
        lastName: response.last_name,
        keycloakId,
      },
    });
    //TODO remove the return when code is refactored in other places like in payments / standardPayments.
    return response;
  } catch (error) {
    captureException(error);
    dispatch({
      type: UserActions.requestUserNameSuccess,
      payload: {
        firstName: 'Name ',
        lastName: 'Unavailable',
        keycloakId,
      },
    });
    return null;
  }
};
