import styled, { css } from 'styled-components';
import { theme } from '../../../styling/theme';

export enum RadioStyleTypeProp {
  SocialsTab = 'socialsTab',
}

export const RadioGroupWrapper = styled.div<{ styleType?: RadioStyleTypeProp }>`
  ${props =>
    props.styleType == RadioStyleTypeProp.SocialsTab
      ? css`
          #radio_legend {
            color: black;
            padding-top: 10px;
          }
          #radio_group {
            padding-top: 10px;
            padding-bottom: 10px;
          }
          #radio_item {
            margin-left: -12px;
            span {
              padding: 4px 0 4px 5px;
            }
            .checkedRadio svg {
              color: ${props => theme(props).COLOUR.PRIMARY};
            }
          }
        `
      : css`
          flex-direction: row;
        `}
`;
