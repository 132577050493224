import { captureException } from '@sentry/react';
import client, { Microservice } from '../../utils/http.utils';
import { CommissionsSummaryAPI } from './store';

export const RequestGetProgrammeImageAsync = async (imageId: string) => {
  try {
    return await client.requestMedia(
      Microservice.IMAGE,
      `/file/${imageId}`,
      'GET'
    );
  } catch (error) {
    captureException(error);
    return null;
  }
};

export const RequestGetAssociationActiveCampaigns = async (
  programmeId: string,
  influencerId: string
): Promise<string[]> => {
  try {
    return await client.request<string[]>(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/influencer/${influencerId}/active-campaigns`,
      'GET'
    );
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const RequestGetInfluencerCommissionsSummaryAsync = async (
  programmeId: string,
  campaignId: string,
  influencerId: string
): Promise<CommissionsSummaryAPI | null> => {
  try {
    return await client.request(
      Microservice.COMMISSION,
      `/commission/programme/${programmeId}/campaign/${campaignId}/influencer/${influencerId}/summary`,
      'GET'
    );
  } catch (error) {
    captureException(error);
    return null;
  }
};
