import { StringNumberMap } from '../../types';
import { Programme } from '../Programmes/store';

export { ReportingReducer } from './reducer';

export * from './actions';

export enum ReportingPeriod {
  today = 'today',
  day = 'day',
  mtd = 'mtd',
  lm = 'lm',
  months6 = 'months6',
  ytd = 'ytd',
}

export interface MediaObjectAPI {
  link: string;
  file_upload_id: string;
  thumbnail_file_upload_id: string;
}
export interface MediaObject {
  link: string;
  fileUploadId: string;
  thumbnailFileUploadId: string;
}

export interface ReportFinancialsAPI {
  overview: {
    influencers: number;
    connected_influencers: number;
    invites: number;
    active_networks: string[];
    status: string;
  };
  financial: {
    clicks: number;
    total_orders: number;
    conversion_rate: number;
    order_value: number;
  };
  total_sales: StringNumberMap;
  top_earners: StringNumberMap;
}

export interface ReportSocialsAPI {
  top_posts: ReportingSocialPostsAPI;
  social_insights: {
    instagram: {
      impressions: number;
      reach: number;
      engagement: number;
      likes: number;
      reel_reach: number;
      story_reach: number;
    };
    youtube: {
      views: number;
      comments: number;
      likes: number;
      dislikes: number;
      engagement: number;
      likes_dislikes: number;
    };
  };
}

export interface ReportPaymentOverviewAPI {
  bonus_payment: number;
  commission_payment: number;
}

export interface ReportConversionPerformanceAPI {
  clicks: number;
  code_usage: number;
  total_orders: number;
  total_items: number;
  total_order_value: number;
  average_order_value: number;
}

export interface ReportingOverview {
  influencers: number;
  connectedInfluencers: number;
  invites: number;
  activeNetworks: string[];
  status: string;
}

export interface ReportingFinancial {
  clicks: number;
  totalOrders: number;
  conversionRate: number;
  orderValue: number;
}

export type ReportingSocialPostsAPI = { [index: string]: MediaObjectAPI[] };
export type ReportingSocialPosts = {
  [index: string]: MediaObject[];
};

export interface InstagramInsight {
  impressions: number;
  reach: number;
  engagement: number;
  likes: number;
  reelReach: number;
  storyReach: number;
}

export interface YoutubeInsight {
  views: number;
  comments: number;
  likes: number;
  dislikes: number;
  engagement: number;
  likesDislikes: number;
}
export interface ReportingInsights {
  instagram: InstagramInsight;
  youtube: YoutubeInsight;
}

export interface ReportFinancials {
  overview: ReportingOverview;
  financial: ReportingFinancial;
  totalSales: StringNumberMap;
  topEarners: StringNumberMap;
}

export interface ReportSocials {
  topPosts: ReportingSocialPosts;
  socialInsights: ReportingInsights;
}

export interface ReportPaymentOverview {
  bonusPayment: number;
  commissionPayment: number;
}

export interface ReportConversionPerformance {
  clicks: number;
  codeUsage: number;
  totalOrders: number;
  totalItems: number;
  totalOrderValue: number;
  averageOrderValue: number;
}

export interface ReportingQueries {
  programme: string;
  period: string;
}

export interface ReportingState {
  reportFinancials: ReportFinancials | null;
  reportSocials: ReportSocials | null;
  reportPaymentOverview: ReportPaymentOverview | null;
  reportConversionPerformance: ReportConversionPerformance | null;
  selectedReportingProgramme: Programme | null;
  selectedTimePeriod: ReportingPeriod;
  isLoading: {
    getReportFinancials: boolean;
    getReportSocials: boolean;
    getPaymentOverview: boolean;
    getConversionPerformance: boolean;
  };
  errors: {
    getReportFinancials: string | null;
    getReportSocials: string | null;
    getPaymentOverview: string | null;
    getConversionPerformance: string | null;
  };
}

export const defaultReportingState: ReportingState = {
  reportFinancials: null,
  reportSocials: null,
  reportPaymentOverview: null,
  reportConversionPerformance: null,
  selectedReportingProgramme: null,
  selectedTimePeriod: ReportingPeriod.today,
  isLoading: {
    getReportFinancials: false,
    getReportSocials: false,
    getPaymentOverview: false,
    getConversionPerformance: false,
  },
  errors: {
    getReportFinancials: null,
    getReportSocials: null,
    getPaymentOverview: null,
    getConversionPerformance: null,
  },
};
