import { useStore } from '../../store/state';
import { Breakpoint } from '../../styling/breakpoints';
import { Programme, ReadCampaign } from '../../store/Programmes/store';
import { InfluencerData } from '../../store/Discovery/store';
import { AgencyApplicationSingle } from '../../store/Agencies/types';
import { Agency } from '../Agencies/AgencyView/types';

export interface BreadcrumbsProps {
  isMobile: boolean;
  showBreadcrumb: boolean;
  path: string[];
  pathMobile: string;
  displayedPath: string[];
  displayedPathMobile: string;
}

const getDisplayedPath = (
  path: string[],
  programme: Programme | null,
  campaign: ReadCampaign | null,
  influencer: InfluencerData | null,
  agency: Agency | null,
  agencyApplication: AgencyApplicationSingle | null
) => {
  const length = path.length;
  let displayedPath = replaceIds(
    path,
    programme,
    campaign,
    influencer,
    agency,
    agencyApplication
  );

  const defaultProgrammePages = ['details', 'content'];
  const defaultInfluencerPages = ['details'];

  if (
    (programme && defaultProgrammePages.includes(displayedPath[length - 1])) ||
    (influencer && defaultInfluencerPages.includes(displayedPath[length - 1]))
  )
    displayedPath = displayedPath.slice(0, length - 1);

  if (path[0] === 'agencies')
    displayedPath = [displayedPath[0], ...displayedPath.slice(2, length - 1)];

  displayedPath = displayedPath.map(
    x => x.charAt(0).toUpperCase() + x.slice(1).split('-').join(' ')
  );

  return displayedPath;
};

const replaceIds = (
  path: string[],
  programme: Programme | null,
  campaign: ReadCampaign | null,
  influencer: InfluencerData | null,
  agency: Agency | null,
  agencyApplication: AgencyApplicationSingle | null
) => {
  const displayedPath = [...path];

  if (displayedPath[0] === 'programmes' && programme) {
    displayedPath[1] = programme.title;
    if (displayedPath[2] === 'campaigns' && campaign && displayedPath[3]) {
      displayedPath[3] = campaign.name;
    }
  } else if (displayedPath[0] === 'influencers' && influencer) {
    displayedPath[1] = `${influencer.influencerFirstName} ${influencer.influencerLastName}`;
  } else if (displayedPath[0] === 'agencies') {
    if (displayedPath[1] === 'agency' && displayedPath[2] && agency) {
      displayedPath[2] = agency.name;
    } else if (
      displayedPath[1] === 'agency-application' &&
      displayedPath[2] &&
      agencyApplication
    ) {
      displayedPath[2] = agencyApplication.name;
    }
  }

  return displayedPath;
};

const getMobilePaths = (path: string[], displayedPath: string[]) => {
  const length = path.length;

  let pathMobile = path.slice(0, length - 2).join('/');
  let displayedPathMobile = displayedPath[length - 3];

  if (path[length - 1] === 'youtube') {
    if (path[0] === 'influencers') displayedPathMobile = 'Social';
    else if (path[0] === 'programmes') displayedPathMobile = 'Insights';
  }

  if (path[4] === 'invite-influencers') {
    pathMobile = path.slice(0, length - 1).join('/');
    displayedPathMobile = displayedPath[length - 2];
  }

  if (path[1] === 'agency-application') {
    pathMobile = 'agencies/agencies-applications';
    displayedPathMobile = 'Agency Applications';
  }

  if (path[1] === 'agency') {
    pathMobile = 'agencies/agencies-list';
    displayedPathMobile = 'Agencies';
  }

  return { pathMobile, displayedPathMobile };
};

export const useBreadcrumbs = (
  pathname: string,
  layout: Breakpoint
): BreadcrumbsProps => {
  const [{ programmesState, discoveryState, agenciesState }] = useStore();
  const isMobile = layout !== Breakpoint.DESKTOP;

  const path = pathname.split('/').filter(x => x);

  const programme = programmesState.currentProgramme;
  const influencer = discoveryState.influencerDetails;
  const campaign = programmesState.campaign;
  const agency = agenciesState.currentAgency;
  const agencyApplication = agenciesState.agencyApplication;

  const shouldShowBreadcrumbs = () => {
    if (path.length < 3) return false;

    const isInfluencersSubRoute = !!(influencer && path[0] === 'influencers');
    if (isInfluencersSubRoute) {
      return true;
    }

    const isProgrammesSubRoute = !!(programme && path[0] === 'programmes');
    if (isProgrammesSubRoute) {
      return !!(path[2] !== 'campaigns' || !path[3] || campaign);
    }

    const isCommissionsSubRoute =
      path[0] === 'payments' && path[1] === 'commissions';
    if (isCommissionsSubRoute) {
      return true;
    }

    const isAgenciesSubRoute = path[1] === 'agency' && agency;
    if (isAgenciesSubRoute) return true;

    const isAgenciesApplicationSubRoute =
      path[1] === 'agency-application' && agencyApplication;
    if (isAgenciesApplicationSubRoute) return true;

    return false;
  };

  const showBreadcrumb = shouldShowBreadcrumbs();

  const displayedPath = getDisplayedPath(
    path,
    programme,
    campaign,
    influencer,
    agency,
    agencyApplication
  );

  const { pathMobile, displayedPathMobile } = getMobilePaths(
    path,
    displayedPath
  );

  return {
    isMobile,
    showBreadcrumb,
    path,
    pathMobile,
    displayedPath,
    displayedPathMobile,
  };
};
