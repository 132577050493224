import styled, { css } from 'styled-components';
import { theme } from '../../styling/theme';
import { GlobalFontFamily } from '../../styling/css/fontfamily';

interface TextProps {
  noMargin?: boolean;
}

const DefaultStyles = css<TextProps>`
  margin: ${props => (props.noMargin ? '0' : '0 0.5rem 0.5rem 0')};
  padding: 0;
  ${GlobalFontFamily}
  color: ${props => theme(props).COLOUR.TEXT_STANDARD};
`;

export const TextLargeStyle = css`
  ${DefaultStyles}
  font-size: 20px;
  font-weight: 300;
`;

export const TextLarge = styled.p`
  ${TextLargeStyle};
`;

export const TextMedium = styled.p`
  ${DefaultStyles};
  font-size: 16px;
  font-weight: 300;
`;

export const TextMediumStyle = css`
  ${DefaultStyles};
  font-size: 16px;
  font-weight: 300;
`;

export const TextSmallStyle = css`
  ${DefaultStyles};
  font-size: 14px;
  font-weight: 300;
`;

export const TextSmall = styled.p`
  ${TextSmallStyle};
`;
