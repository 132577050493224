import React, { useEffect, useState } from 'react';
import { Input } from '../Input';
import { IconButton } from '../IconButton';
import { IconHolder, SearchBox, SearchContainer } from './Search.styles';

export interface SearchV1Props {
  onChange: (val: string) => any;
  placeholder?: string;
  handleClear?: () => any;
  isMobile?: boolean;
  ariaLabel?: string;
  testId?: string;
  useDebounce?: boolean;
  debounceTimeout?: number;
}

export const SearchV1: React.FC<SearchV1Props> = ({
  onChange,
  placeholder = 'search',
  isMobile = false,
  handleClear = undefined,
  ariaLabel = 'search bar input',
  testId = 't_search_bar_input',
  useDebounce = true,
  debounceTimeout = 1000,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [oldSearchTerm, setOldSearchTerm] = useState<string>('');

  useEffect(() => {
    if (useDebounce) {
      if (
        (searchTerm !== oldSearchTerm && searchTerm.trim().length > 2) ||
        (searchTerm === '' && oldSearchTerm !== '')
      ) {
        const ref = setTimeout(() => {
          setOldSearchTerm(searchTerm);
          onChange(searchTerm);
        }, debounceTimeout);

        return () => {
          clearTimeout(ref);
        };
      }
    }
  }, [debounceTimeout, oldSearchTerm, onChange, searchTerm, useDebounce]);

  const onChangeEvent = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    if (!useDebounce) {
      onChange(e.currentTarget.value);
    }
    setSearchTerm(e.currentTarget.value);
  };

  const onClear = (e: React.MouseEvent) => {
    e.preventDefault();
    !!handleClear && handleClear();
    setSearchTerm('');
  };

  return (
    <SearchBox isMobile={isMobile}>
      <SearchContainer
        tab-index="0"
        isMobile={isMobile}
        data-testid={'t_search_bar'}
      >
        <Input
          type="text"
          name="Search"
          placeholder={placeholder}
          onChange={onChangeEvent}
          onInput={onChangeEvent}
          value={searchTerm}
          aria-label={ariaLabel}
          data-testid={testId}
        />
        <IconHolder>
          {searchTerm ? (
            !!handleClear && (
              <IconButton
                icon={'times'}
                onClick={onClear}
                tooltip={'Clear'}
                variant={'borderless-alt'}
                data-testid={'t_search_clear_btn'}
              />
            )
          ) : (
            <IconButton
              icon={'search'}
              variant={'borderless-alt'}
              disabled={true}
              aria-disabled={true}
              aria-label={'Search'}
              data-testid={'t_search_confirm_btn'}
            />
          )}
        </IconHolder>
      </SearchContainer>
    </SearchBox>
  );
};
