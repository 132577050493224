import styled from 'styled-components/macro';
import { px2rem } from '../../styling/sizing';

export const BreadcrumbContainer = styled.ol<{ isMobile?: boolean }>`
  display: flex;
  ${props => props.isMobile && 'padding: 1.5rem 0 0 1.25rem'};
  & > svg {
    ${props => props.isMobile && 'margin: 0.125rem 0.5rem 0 0.25rem'};
  }
`;

export const BreadcrumbItem = styled.li`
  display: flex;
  align-items: center;
  & > svg {
    margin: 0 ${px2rem(5)};
    padding: 0 0 0 ${px2rem(1)};
  }
`;
