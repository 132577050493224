import React from 'react';
import { StyledComponentPropsWithRef } from 'styled-components';
import { Button as ButtonComponent } from './Button';

interface ButtonPropsWithChildren
  extends StyledComponentPropsWithRef<'button'> {
  title?: string;
  variant?: 'primary' | 'primaryOutline' | 'secondary' | 'tertiary';
  direction?: string;
  fullWidth?: boolean;
  minWidth?: string;
}

interface ButtonPropsWithTitle extends StyledComponentPropsWithRef<'button'> {
  title: string;
  variant?: 'primary' | 'primaryOutline' | 'secondary' | 'tertiary';
  direction?: string;
  fullWidth?: boolean;
  minWidth?: string;
  children?: never;
}

export const Button: React.FC<
  ButtonPropsWithTitle | ButtonPropsWithChildren
> = ({ title, children, ...intrinsic }) => {
  return (
    <ButtonComponent {...intrinsic}>
      {children ? children : title}
    </ButtonComponent>
  );
};
