import { KeycloakProfile } from 'keycloak-js';
import { StringMap } from '../../types';

export interface RoleMap {
  [index: string]: boolean;
}

export interface KeycloakTokenMap {
  jti: string;
}

export interface UserNamesApi {
  first_name: string;
  last_name: string;
}

export interface UserName {
  keycloakId: string;
  firstName: string;
  lastName: string;
}

export type UserType = 'influencer' | 'non_influencer';
export const defaultRoles = {
  marketer: false,
  admin: false,
  approver: false,
  permissionManager: false,
};
export interface CaptchaApi {
  success: boolean;
  challenge_ts: string;
  hostname: string;
}
export interface UserPermissionMapApi {
  global_permissions: string[];
  group_permissions: StringMap<string[]>;
  programme_permissions: StringMap<string[]>;
  keycloak_id: string;
  keycloak_token_id: string;
  type: UserType;
}

export interface UserPermissionMap {
  globalPermissions: string[];
  groupPermissions: StringMap<string[]>;
  programmePermissions: StringMap<string[]>;
  keycloakId: string;
  keycloakTokenId: string;
  type: UserType;
}

export const defaultUserState: UserState = {
  keycloakProfile: null,
  fetchError: null,
  roles: defaultRoles,
  keycloakId: null,
  zipURL: null,
  isFetchingZip: false,
  zipFetchError: null,
  permissions: null,
  captchaValid: false,
  paymentUserNames: [],

  isFetching: {
    permissions: false,
  },

  errors: {
    permissions: null,
  },
};

export interface UserState {
  keycloakProfile: KeycloakProfile | null;
  fetchError: Error | null;
  roles: RoleMap;
  keycloakId: string | null;
  zipURL: string | null;
  isFetchingZip: boolean;
  zipFetchError: string | null;
  permissions: UserPermissionMap | null;
  captchaValid: boolean;
  paymentUserNames: UserName[];
  isFetching: {
    permissions: boolean;
  };

  errors: {
    permissions: string | null;
  };
}

export * from './actions';
export * from './reducer';
