import { StringMap } from './map.types';

export type CustomErrorMessage =
  | string
  | Map<string, string>
  | StringMap<string>;

export interface CustomError extends Error {
  status: number;
  name: string;
  customMessage: Array<{
    code: string;
    message: string;
  }>;
  stack: string | undefined;
  message: string;
}

// TODO - refactor; unnecessary complexity
const customErrorFactory = (
  name: string,
  errorObject: Record<string, any>,
  message?: CustomErrorMessage
): CustomError => {
  const customError = () => ({
    status: errorObject.statusCode,
    name: name,
    customMessage:
      errorObject.rawErrorRes.validation_errors ||
      errorObject.rawErrorRes.errors ||
      [],
    stack: new Error().stack,
    message: message?.toString() || 'An error occurred',
  });
  customError.prototype = Object.create(Error.prototype);
  customError.prototype.constructor = customError;
  return customError();
};

export const httpError = (
  statusCode: number,
  rawErrorRes: Record<string, any>
): CustomError => customErrorFactory('Http Error', { statusCode, rawErrorRes });

export const apiError = (statusCode: number, message: string): CustomError =>
  customErrorFactory('Api Error', { status: statusCode }, message);

export enum ErrorMessage {
  NOT_FOUND = 404,
  BAD_REQUEST = 400,
  INTERNAL_SERVER_ERROR = 500,
}

export interface ErrorBody {
  validationErrors: Map<string, string>;
}
