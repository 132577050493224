import styled, { css } from 'styled-components';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';

interface HeadingProps {
  noMargin?: boolean;
}

const DefaultStyles = css<HeadingProps>`
  margin: ${props => (props.noMargin ? '0' : '0 0 0.5em 0')};
  padding: 0;
  color: ${props => theme(props).COLOUR.TEXT_STANDARD};
  line-height: initial;
`;

export const Heading1 = styled.h1<HeadingProps>`
  ${DefaultStyles}
  font-size: ${px2rem(26)};
  font-weight: 900;
`;

export const Heading2 = styled.h2<HeadingProps>`
  ${DefaultStyles}
  font-size: ${px2rem(40)};
  font-weight: 500;
`;

export const Heading3 = styled.h3<HeadingProps>`
  ${DefaultStyles}
  font-size: ${px2rem(26)};
  font-weight: 900;
`;

export const Heading3Styles = css`
  ${DefaultStyles}
  font-size: ${px2rem(26)};
  font-weight: 900;
`;

export const Heading4 = styled.h4<HeadingProps>`
  ${DefaultStyles}
  font-size: ${px2rem(18)};
  font-weight: 900;
`;

export const Heading5 = styled.h5<HeadingProps>`
  ${DefaultStyles}
  font-size: ${px2rem(16)};
  font-weight: 900;
`;

export const Title = styled.span<HeadingProps>`
  ${DefaultStyles}
  font-size: ${px2rem(10)};
  font-weight: 900;
  text-transform: uppercase;
`;

export const SubHeading = styled.span<HeadingProps>`
  ${DefaultStyles}
  font-size: ${px2rem(12)};
  font-weight: 300;
`;
