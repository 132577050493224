import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, TooltipProps } from '@material-ui/core';
import React from 'react';
import { StyledComponentPropsWithRef } from 'styled-components';
import {
  IconButtonBorderless,
  IconButtonBorderlessAlt,
  IconButtonStyle,
  IconButtonCircle,
} from './IconButton.styles';

type IconButtonVariants =
  | 'default'
  | 'borderless'
  | 'borderless-alt'
  | 'circle';

interface IconButtonProps extends StyledComponentPropsWithRef<'button'> {
  tooltip?: string;
  tootlipPlacement?: TooltipProps['placement'];
  icon: IconProp;
  variant?: IconButtonVariants;
}

export const IconButton: React.FC<IconButtonProps> = ({
  tooltip,
  tootlipPlacement = 'top',
  icon,
  variant = 'default',
  ...intrinsic
}) => {
  const renderButton = () => {
    switch (variant) {
      case 'borderless':
        return (
          <IconButtonBorderless {...intrinsic}>
            <FontAwesomeIcon icon={icon} />
          </IconButtonBorderless>
        );

      case 'borderless-alt':
        return (
          <IconButtonBorderlessAlt {...intrinsic}>
            <FontAwesomeIcon icon={icon} />
          </IconButtonBorderlessAlt>
        );

      case 'circle':
        return (
          <IconButtonCircle {...intrinsic}>
            <FontAwesomeIcon icon={icon} />
          </IconButtonCircle>
        );

      default:
        return (
          <IconButtonStyle {...intrinsic}>
            <FontAwesomeIcon icon={icon} />
          </IconButtonStyle>
        );
    }
  };

  return tooltip ? (
    <Tooltip
      title={tooltip}
      placement={tootlipPlacement}
      disableHoverListener={intrinsic.disabled}
      disableFocusListener={intrinsic.disabled}
    >
      <span>{renderButton()}</span>
    </Tooltip>
  ) : (
    <>{renderButton()}</>
  );
};
