import React, { useEffect } from 'react';
import { useStore } from '../store/state';
import { RequestFeatureFlags } from '../store/Environments/actions';

const EnvironmentsInit = ({ children }: { children: React.ReactNode }) => {
  const [, dispatch] = useStore();

  useEffect(() => {
    RequestFeatureFlags(dispatch);
    const timeout = setInterval(() => RequestFeatureFlags(dispatch), 60_000);
    return () => clearInterval(timeout);
  }, [dispatch]);

  return <>{children}</>;
};

export default EnvironmentsInit;
