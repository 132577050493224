import dayjs from 'dayjs';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Insights } from '../../../store/Social/store';
import { useStore } from '../../../store/state';

const NoticeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const NoticeText = styled.p`
  text-align: center;
  font-size: 1rem;
`;

interface RefreshNoticeProps {
  updatedAt?: string;
}

export const RefreshNotice: FC<RefreshNoticeProps> = ({ updatedAt }) => {
  const [{ socialState }] = useStore();
  const userInsights: Insights[] | null = socialState.influencerInsights;
  const lastUpdatedDate =
    userInsights != undefined && userInsights != null && userInsights.length > 0
      ? userInsights.at(-1).created_at.format('DD/MM/YYYY')
      : dayjs(updatedAt || '').format('DD/MM/YYYY');

  return (
    <NoticeWrapper data-testid="influencer_refresh_notice">
      <NoticeText>
        {
          'Social data may not be accurate, influencer needs to refresh their \
            social connection.'
        }
      </NoticeText>
      <NoticeText>
        {`Limited insights available only for posts and \
            reels. Insights refresh every 24 hours.
            Stats last updated: ${lastUpdatedDate}`}
      </NoticeText>
    </NoticeWrapper>
  );
};
