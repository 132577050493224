import { ErrorBoundary as Boundary } from '@sentry/react';
import React, { ReactNode } from 'react';
import { useStore } from '../../store/state';
import { Fallback } from './Fallback/Fallback';
interface Props {
  children: ReactNode;
  pageName: string;
}

export const ErrorBoundary: React.FC<Props> = ({ children, pageName }) => {
  const [{ userState }] = useStore();

  return (
    <Boundary
      fallback={<Fallback pageName={pageName} />}
      beforeCapture={scope => {
        scope.setTag('location', window.location.pathname);
        scope.setTag('keycloak_id', userState.keycloakId);
        scope.setTag('page_name', pageName);
      }}
    >
      {children}
    </Boundary>
  );
};
