import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { PermissionGate } from '../../../auth';
import { getKeycloakInstance } from '../../../auth/keycloak-init';
import { UserPermission } from '../../../auth/PermissionsGate/types';
import { useResponsive } from '../../../hooks/useResponsive';
import { Breakpoint } from '../../../styling/breakpoints';
import { Box } from '../../common';
import {
  LinkText,
  LinkIcon,
  MenuButton,
  RouterLink,
  SkipToMainContent,
  LinkContainer,
} from './NavItems.styles';

interface NavItemsProps {
  toggle?: () => void;
  expanded?: boolean;
}

interface NavItemProps {
  expanded: boolean;
  icon: React.ReactNode;
  label: string;
  to?: string;
  onClick: () => void;
  mobileView: boolean;
  isExact?: boolean;
  matches: string[];
}

const NavItem: React.FC<NavItemProps> = ({
  to,
  expanded,
  icon,
  label,
  onClick,
  mobileView,
  isExact,
  matches,
  ...props
}) => {
  const isMatch = !!useRouteMatch(matches);
  const isExactMatch = useRouteMatch(matches)?.isExact;
  const isSelected = isExact ? isExactMatch : isMatch;
  return (
    <>
      {to ? (
        <RouterLink to={to} className={isSelected ? 'selected' : undefined}>
          <LinkContainer
            isMobile={mobileView}
            {...props}
            aria-label={label}
            onClick={onClick}
          >
            <LinkIcon>{icon}</LinkIcon>
            {expanded && <LinkText>{label}</LinkText>}
          </LinkContainer>
        </RouterLink>
      ) : (
        <MenuButton onClick={onClick}>
          <LinkContainer isMobile={mobileView} {...props} aria-label={label}>
            <LinkIcon>{icon}</LinkIcon>
            {expanded && <LinkText>{label}</LinkText>}
          </LinkContainer>
        </MenuButton>
      )}
    </>
  );
};

export const NavItems: React.FC<NavItemsProps> = ({
  toggle,
  expanded = true,
}) => {
  const { layout } = useResponsive();
  const isMobile = layout === Breakpoint.MOBILE;

  const kc = getKeycloakInstance();

  const handleLogout = () => kc && kc.logout();

  const handleClick = () => {
    toggle && toggle();
  };

  const handleSkip = () => {
    const main = document.querySelector('main');
    const firstFocusable = main?.querySelector(
      'a, button, input, select'
    ) as HTMLElement;
    firstFocusable?.focus();
  };

  return (
    <>
      <SkipToMainContent onClick={handleSkip}>
        Skip to main content
      </SkipToMainContent>
      <Box expand>
        <NavItem
          to="/"
          matches={['/']}
          icon={<FontAwesomeIcon icon={'home'} />}
          label="Home"
          expanded={expanded}
          onClick={handleClick}
          mobileView={isMobile}
          isExact
        />
        <NavItem
          to="/programmes"
          matches={['/programmes']}
          icon={<FontAwesomeIcon icon={'bullhorn'} />}
          expanded={expanded}
          label="Programmes"
          onClick={handleClick}
          mobileView={isMobile}
        />
        <NavItem
          to="/programme-groups"
          matches={['/programmes-groups']}
          icon={<FontAwesomeIcon icon={'tags'} />}
          expanded={expanded}
          label="Programme Groups"
          onClick={handleClick}
          mobileView={isMobile}
          isExact
        />
        <NavItem
          to="/discovery"
          matches={['/discovery', '/influencers']}
          icon={<FontAwesomeIcon icon={'users'} />}
          expanded={expanded}
          label="Influencers"
          onClick={handleClick}
          mobileView={isMobile}
        />
        {window._env.canSeeAgencies && (
          <PermissionGate permission={UserPermission.viewAgencies}>
            <NavItem
              to="/agencies/agencies-list"
              matches={['/agencies', '/agency-application', '/agency']}
              icon={<FontAwesomeIcon icon={'object-group'} />}
              expanded={expanded}
              label="Agencies"
              onClick={handleClick}
              mobileView={isMobile}
            />
          </PermissionGate>
        )}
        <NavItem
          to="/payments"
          matches={['/payments']}
          icon={<FontAwesomeIcon icon={'wallet'} />}
          expanded={expanded}
          label="Payments"
          onClick={handleClick}
          mobileView={isMobile}
        />
        <NavItem
          to="/notifications"
          matches={['/notifications']}
          icon={<FontAwesomeIcon icon={'cog'} />}
          expanded={expanded}
          label="Notification Settings"
          onClick={handleClick}
          mobileView={isMobile}
          isExact
        />
        <PermissionGate permission={UserPermission.engineer}>
          <NavItem
            to="/watchman/scheduled_task"
            matches={['/watchman/scheduled_task']}
            icon={<FontAwesomeIcon icon={'clock'} />}
            expanded={expanded}
            label="Watchman Settings"
            onClick={handleClick}
            mobileView={isMobile}
            isExact
          />
        </PermissionGate>
        <PermissionGate permission={UserPermission.managePermissions}>
          <NavItem
            to="/permissions"
            matches={['/permissions']}
            icon={<FontAwesomeIcon icon={'user-shield'} />}
            expanded={expanded}
            label="Permissions"
            onClick={handleClick}
            mobileView={isMobile}
          />
        </PermissionGate>
        <PermissionGate permission={UserPermission.editTranslations}>
          <NavItem
            to="/translations"
            matches={['/translations']}
            icon={<FontAwesomeIcon icon={'language'} />}
            expanded={expanded}
            label="Translations"
            onClick={handleClick}
            mobileView={isMobile}
          />
        </PermissionGate>
        <hr />
        <NavItem
          icon={<FontAwesomeIcon icon="sign-out-alt" />}
          matches={[]}
          expanded={expanded}
          label="Log Out"
          onClick={handleLogout}
          mobileView={isMobile}
        />
        <hr />
      </Box>
    </>
  );
};
