import { COLOUR, getStatusColour } from './COLOUR';
import { SIZE } from './SIZE';
import { TYPOGRAPHY } from './TYPOGRAPHY';
import { EFFECTS } from './EFFECTS';
import { LAYOUT } from './LAYOUT';

export const defaultTheme = {
  getStatusColour,
  COLOUR,
  SIZE,
  TYPOGRAPHY,
  EFFECTS,
  LAYOUT,
};

export type Theme = typeof defaultTheme;

interface Props {
  theme: Theme;
}

export const theme = (props: Props) => props.theme;
