import React from 'react';
import { TextError } from './ErrorMessage.styles';

export enum ErrorMessages {
  HANDLE_ERROR = 'The required handle does not need @.',
  EMPTY_NAME_ERROR = 'The name cannot be empty.',
  INFLUENCER_ALREADY_EXISTS = 'Influencer already exists.',
  CHRONOLOGICAL_DATE = 'The end date must be after the start date.',
  FUTURE_DATE = "The dates must not be past today's date.",
  PAST_DATE = "The dates must not be before today's date.",
  PAST_DATE_ONE_MONTH = 'The initial payment date must be in the last month.',
}

interface Props {
  error: ErrorMessages | string | null;
  testId?: string;
}

export const renderErrorMessage = (props: Props) => {
  return (
    props.error && <ErrorMessage error={props.error} testId={props.testId} />
  );
};

export const ErrorMessage: React.FC<Props> = ({
  error,
  testId = 't_error',
}: Props) => <TextError data-testid={testId}>{error}</TextError>;
