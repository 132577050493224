const COLOURS = {
  PURPLE_100: '#F5F2FF',
  PURPLE_200: '#CAB6FF',
  PURPLE_300: '#A579FF',
  PURPLE_400: '#844AFF',
  PURPLE_500: '#5622D8',
  PURPLE_600: '#500FD9',
  PURPLE_700: '#3A00B3',
  PURPLE_800: '#27008C',
  PURPLE_900: '#180066',

  BLUE_100: '#E3F7FF',
  BLUE_200: '#AADFFF',
  BLUE_300: '#72D3FF',
  BLUE_400: '#3AB9FF',
  BLUE_500: '#0397FF',
  BLUE_600: '#007FFF',
  BLUE_700: '#0066CC',
  BLUE_800: '#004C99',
  BLUE_900: '#003366',

  GREEN_900: '#006662',
  GREEN_800: '#008C7E',
  GREEN_700: '#00B397',
  GREEN_600: '#00D9AB',
  GREEN_500: '#00FFBA',
  GREEN_400: '#39FFD5',
  GREEN_300: '#72FFE9',
  GREEN_200: '#AAFFF7',
  GREEN_100: '#E3FFFE',

  RED: '#ff6663',
  RED_DARKER: '#d40400',
  RED_DARKEST: '#800200',

  PRIMARY: '#5622D8',
  SECONDARY: '#0397FF',

  BLACK: '#1E1E1E',
  WARM_NEUTRAL: '#CCCBC6',
  COOL_NEUTRAL: '#FAF9F2',

  GREY_50: '#F8F8F8',
  GREY_100: '#FAF9F2',
  GREY_300: '#E0E0E0',
  GREY_500: '#CCCBC6',
  GREY_700: '#8C8C8C',
  GREY_900: '#1E1E1E',

  GREEN_ALT: '#006662',
  GREEN_ALT_200: '#B0C14B',

  ORANGE: '#E48233',

  YELLOW: '#FFBB00',
};

const GRADIENTS = {
  STANDARD: `linear-gradient(175deg, ${COLOURS.PRIMARY} 59%, ${COLOURS.SECONDARY} 60%)`,
  FADED: `linear-gradient(175deg, ${COLOURS.PRIMARY}50 59%, ${COLOURS.SECONDARY}50 60%)`,
  BAR: `linear-gradient(161deg, ${COLOURS.PRIMARY} 11%, ${COLOURS.SECONDARY} 83%)`,
  TRANSPARENT:
    'linear-gradient(161deg, rgba(105, 44, 255, 0.75) 11%, rgba(0, 255, 186, 0.75) 83%)',
};

export const COLOUR = {
  // STATE COLOURS
  SUCCESS: COLOURS.GREEN_700,
  SUCCESS_DARK: COLOURS.GREEN_800,
  SUCCESS_DARKER: COLOURS.GREEN_900,
  WARNING: COLOURS.YELLOW,
  ALERT: COLOURS.RED,
  ALERT_DARK: COLOURS.RED_DARKER,
  ALERT_DARKER: COLOURS.RED_DARKEST,
  HIGHLIGHT: 'rgb(207, 102, 157)',
  ERROR: COLOURS.RED_DARKER,
  ORANGE: COLOURS.ORANGE,

  // ACTIONS
  PRIMARY_LIGHTEST: COLOURS.PURPLE_100,
  PRIMARY_LIGHTER: COLOURS.PURPLE_200,
  PRIMARY_LIGHT: COLOURS.PURPLE_300,
  PRIMARY: COLOURS.PURPLE_500,
  PRIMARY_DARK: COLOURS.PURPLE_700,
  PRIMARY_DARKER: COLOURS.PURPLE_800,
  PRIMARY_DARKEST: COLOURS.PURPLE_900,
  PRIMARY_FADED: `${COLOURS.PURPLE_500}50`,

  SECONDARY_LIGHTEST: COLOURS.BLUE_100,
  SECONDARY_LIGHTER: COLOURS.BLUE_200,
  SECONDARY_LIGHT: COLOURS.BLUE_300,
  SECONDARY: COLOURS.BLUE_500,
  SECONDARY_DARK: COLOURS.BLUE_700,
  SECONDARY_DARKER: COLOURS.BLUE_800,
  SECONDARY_DARKEST: COLOURS.BLUE_900,
  SECONDARY_FADED: `${COLOURS.BLUE_500}50`,
  SECONDARY_ALT: COLOURS.BLUE_900,

  // DESIGN ELEMENTS
  BACKGROUND_PRIMARY: 'white',
  BACKGROUND_HIGHLIGHT: 'rgba(255,255,255,0.2)',
  BACKGROUND_TRANSPARENT: 'rgba(255,255,255,0.5)',

  BACKGROUND_SHADOW: COLOURS.GREY_50,
  BACKGROUND_LIGHTEST: COLOURS.GREY_100,
  BACKGROUND_LIGHT: COLOURS.GREY_300,
  BACKGROUND_MEDIUM: COLOURS.GREY_500,
  BACKGROUND_DARK: COLOURS.GREY_700,
  BACKGROUND_DARKEST: COLOURS.GREY_900,

  TEXT: `#464646`,
  BACKGROUND_DARK_OPACITY: 'rgba(76,94,108,0.7)',

  TEXT_STANDARD: COLOURS.GREY_900,

  GRADIENT_STANDARD: GRADIENTS.STANDARD,
  GRADIENT_FADED: GRADIENTS.FADED,
  GRADIENT_BAR: GRADIENTS.BAR,
  GRADIENT_TRANSPARENT: GRADIENTS.TRANSPARENT,

  // ARBITRARY
  BRAND_FACEBOOK: '#1877f2',
  BRAND_YOUTUBE: '#ef0303',
  BRAND_INSTAGRAM: '#e42566',

  // AVATARS
  AVATAR_1: '#d40400', //red
  AVATAR_2: '#FFAB00', //yellow
  AVATAR_3: '#180066', //blue
  AVATAR_4: '#006662', //dark green
  AVATAR_5: '#cf669d', //pink
  AVATAR_6: '#00B397', //green

  // RATINGS
  HIGHLY_NEGATIVE: COLOURS.RED_DARKER,
  NEGATIVE: COLOURS.ORANGE,
  NEUTRAL: COLOURS.GREY_700,
  POSITIVE: COLOURS.GREEN_ALT,
  HIGHLY_POSITIVE: COLOURS.GREEN_ALT_200,

  WHITE: '#FFFFFF',
};

export const getStatusColour = (status: string) => {
  switch (status) {
    case 'pending':
      return COLOUR.WARNING;
    case 'approved':
      return COLOUR.PRIMARY;
    case 'cleared':
      return COLOUR.SUCCESS;
    case 'rejected':
      return COLOUR.ALERT;
    case 'mixed':
      return COLOUR.HIGHLIGHT;
    case 'live':
      return COLOUR.SECONDARY_ALT;
    case 'awaiting_live':
      return COLOUR.ALERT_DARK;
  }
};
