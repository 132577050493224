import {
  PaymentsActions,
  PaymentsState,
  defaultStandardPaymentQueries,
  defaultCommissionQueries,
  defaultScheduledPaymentQueries,
  defaultInvoiceQueries,
  defaultWithdrawalQueries,
} from './store';
import { Action } from '../state';
import {
  transformGetCommissionListPayload,
  transformGetPaymentsPayload,
  transformGetScheduledPaymentsPayload,
  updateCurrentPaginatedPage,
} from './payload';
import {
  transformCommissionFromResponse,
  transformPaginatedInvoicesFromApi,
  transformPaginatedWithdrawalsFromApi,
  transformSelectedPayments,
  transformWithdrawalToSelfbillMappingsPaginatedFromResponse,
} from './transformers';
import {
  transformGetInfluencersPayload,
  transformGetNonInfluencersPayload,
} from '../Discovery/transformers';

export const PaymentsReducer = (
  state: PaymentsState,
  action: Action
): PaymentsState => {
  switch (action.type) {
    // SelfBill Search
    case PaymentsActions.requestSelfbillSearchAttempt:
      return {
        ...state,
        isFetching: { ...state.isFetching, selfbillId: true },
        error: { ...state.error, selfbillId: null },
      };

    case PaymentsActions.requestSelfbillSearchSuccess:
      return {
        ...state,
        isFetching: { ...state.isFetching, selfbillId: false },
        withdrawalToSelfbillMappings:
          transformWithdrawalToSelfbillMappingsPaginatedFromResponse(
            action.payload
          ).selfbillMappings,
      };
    case PaymentsActions.requestSelfbillSearchFailure:
      return {
        ...state,
        isFetching: { ...state.isFetching, selfbillId: false },
        error: { ...state.error, selfbillId: action.payload },
      };

    case PaymentsActions.requestGetPaymentStatusesAttempt:
      return {
        ...state,
        isFetchingTransactionStatuses: true,
        fetchTransactionStatusesError: null,
      };
    case PaymentsActions.requestGetPaymentStatusesSuccess:
      return {
        ...state,
        isFetchingTransactionStatuses: false,
        transactionStatuses: action.payload,
      };
    case PaymentsActions.requestGetPaymentStatusesFailure:
      return {
        ...state,
        isFetchingTransactionStatuses: false,
        fetchTransactionStatusesError: action.payload,
      };
    //get request statuses
    case PaymentsActions.requestGetRequestStatusesAttempt:
      return {
        ...state,
        isFetchingRequestStatuses: true,
        requestStatusesError: null,
      };
    case PaymentsActions.requestGetRequestStatusesSuccess:
      return {
        ...state,
        isFetchingRequestStatuses: false,
        requestStatuses: action.payload,
      };
    case PaymentsActions.requestGetRequestStatusesFailure:
      return {
        ...state,
        isFetchingRequestStatuses: false,
        requestStatusesError: action.payload,
      };
    //get schedule statuses
    case PaymentsActions.requestGetScheduleStatusesAttempt:
      return {
        ...state,
        isFetchingScheduleStatuses: true,
        scheduleStatusesError: null,
      };
    case PaymentsActions.requestGetScheduleStatusesSuccess:
      return {
        ...state,
        isFetchingScheduleStatuses: false,
        scheduleStatuses: action.payload,
      };
    case PaymentsActions.requestGetScheduleStatusesFailure:
      return {
        ...state,
        isFetchingScheduleStatuses: false,
        scheduleStatusesError: action.payload,
      };
    case PaymentsActions.requestGetPaymentsAttempt:
      return {
        ...state,
        isFetchingPayments: true,
        fetchPaymentsError: null,
      };
    case PaymentsActions.requestGetPaymentsSuccess:
      return {
        ...state,
        isFetchingPayments: false,
        paginatedPayments: transformGetPaymentsPayload(
          action.payload,
          state.paginatedPayments
        ),
      };
    case PaymentsActions.requestGetPaymentsFailure:
      return {
        ...state,
        isFetchingPayments: false,
        fetchPaymentsError: action.payload,
      };
    //Get Scheduled Payments
    case PaymentsActions.requestGetScheduledPaymentsAttempt:
      return {
        ...state,
        isFetchingScheduledPayments: true,
        scheduledPaymentsError: null,
      };
    case PaymentsActions.requestGetScheduledPaymentsSuccess:
      return {
        ...state,
        isFetchingScheduledPayments: false,
        paginatedScheduledPayments: transformGetScheduledPaymentsPayload(
          action.payload,
          state.paginatedScheduledPayments
        ),
      };
    case PaymentsActions.requestGetScheduledPaymentsFailure:
      return {
        ...state,
        isFetchingScheduledPayments: false,
        scheduledPaymentsError: action.payload,
      };

    //Get Bonus Issuer Details
    case PaymentsActions.requestGetIssuerDetailsAttempt:
      return {
        ...state,
        isFetchingBonusIssuer: true,
        fetchingBonusIssuerError: null,
      };
    case PaymentsActions.requestGetIssuerDetailsSuccess:
      return {
        ...state,
        isFetchingBonusIssuer: false,
      };
    case PaymentsActions.requestGetIssuerDetailsFailure:
      return {
        ...state,
        isFetchingBonusIssuer: false,
        fetchingBonusIssuerError: action.payload,
      };
    case PaymentsActions.clearBulkPaymentsError:
      return {
        ...state,
        bulkRejectError: null,
        bulkApproveError: null,
      };
    case PaymentsActions.requestPostBulkPaymentsApproveAttempt:
      return {
        ...state,
        isBulkApproving: true,
        bulkApproveError: null,
      };
    case PaymentsActions.requestPostBulkPaymentsApproveSuccess:
      return {
        ...state,
        isBulkApproving: false,
        bulkApproveError: null,
      };
    case PaymentsActions.requestPostBulkPaymentsApproveFailure:
      return {
        ...state,
        isBulkApproving: false,
        bulkApproveError: action.payload,
      };
    case PaymentsActions.requestPostBulkPaymentsRejectAttempt:
      return {
        ...state,
        isBulkRejecting: true,
        bulkRejectError: null,
      };
    case PaymentsActions.requestPostBulkPaymentsRejectSuccess:
      return {
        ...state,
        isBulkRejecting: false,
        bulkRejectError: null,
      };
    case PaymentsActions.requestPostBulkPaymentsRejectFailure:
      return {
        ...state,
        isBulkRejecting: false,
        bulkRejectError: action.payload,
      };

    case PaymentsActions.requestPatchScheduleApprovalAttempt:
      return {
        ...state,
        isScheduleApproving: true,
        scheduleApproveError: null,
      };
    case PaymentsActions.requestPatchScheduleApprovalSuccess:
      return {
        ...state,
        isScheduleApproving: false,
        scheduleApproveError: null,
      };
    case PaymentsActions.requestPatchScheduleApprovalFailure:
      return {
        ...state,
        isScheduleApproving: false,
        scheduleApproveError: action.payload,
      };

    case PaymentsActions.requestPatchScheduleRejectionAttempt:
      return {
        ...state,
        isScheduleRejecting: true,
        scheduleRejectError: null,
      };
    case PaymentsActions.requestPatchScheduleRejectionSuccess:
      return {
        ...state,
        isScheduleRejecting: false,
        scheduleRejectError: null,
      };
    case PaymentsActions.requestPatchScheduleRejectionFailure:
      return {
        ...state,
        isScheduleRejecting: false,
        scheduleRejectError: action.payload,
      };

    case PaymentsActions.requestPatchScheduleCancellationAttempt:
      return {
        ...state,
        isScheduleCancelling: true,
        scheduleCancelError: null,
      };
    case PaymentsActions.requestPatchScheduleCancellationSuccess:
      return {
        ...state,
        isScheduleCancelling: false,
        scheduleCancelError: null,
      };
    case PaymentsActions.requestPatchScheduleCancellationFailure:
      return {
        ...state,
        isScheduleCancelling: false,
        scheduleCancelError: action.payload,
      };

    case PaymentsActions.requestPatchSchedulePauseAttempt:
      return {
        ...state,
        isSchedulePausing: true,
        schedulePauseError: null,
      };
    case PaymentsActions.requestPatchSchedulePauseSuccess:
      return {
        ...state,
        isSchedulePausing: false,
        schedulePauseError: null,
      };
    case PaymentsActions.requestPatchSchedulePauseFailure:
      return {
        ...state,
        isSchedulePausing: false,
        schedulePauseError: action.payload,
      };
    case PaymentsActions.requestGetBonusPaymentAsyncSuccess:
      return {
        ...state,
        paginatedPayments: state.paginatedPayments
          ? updateCurrentPaginatedPage(action.payload, state.paginatedPayments)
          : null,
      };
    case PaymentsActions.requestGetPaymentsCSVAttempt:
      return {
        ...state,
        isFetchingCSV: true,
        fetchCSVError: null,
      };
    case PaymentsActions.requestGetPaymentsCSVSuccess:
      return {
        ...state,
        isFetchingCSV: false,
        paymentsCSV: action.payload,
        fetchCSVError: null,
      };
    case PaymentsActions.requestGetPaymentsCSVFailure:
      return {
        ...state,
        isFetchingCSV: false,
        fetchCSVError: action.payload,
      };
    case PaymentsActions.clearStandardPayments:
      return {
        ...state,
        isFetchingPayments: false,
        paginatedPayments: null,
      };
    case PaymentsActions.clearScheduledPayments:
      return {
        ...state,
        isFetchingScheduledPayments: false,
        paginatedScheduledPayments: null,
      };
    case PaymentsActions.clearCommissions:
      return {
        ...state,
        isFetchingCommissionsList: false,
        paginatedCommissions: null,
      };
    case PaymentsActions.resetStandardFilters:
      return {
        ...state,
        standardPaymentFilters: {
          ...defaultStandardPaymentQueries,
          limit: state.standardPaymentFilters.limit,
        },
      };
    case PaymentsActions.resetScheduledFilters:
      return {
        ...state,
        scheduledPaymentFilters: {
          ...defaultScheduledPaymentQueries,
          limit: state.scheduledPaymentFilters.limit,
        },
      };
    case PaymentsActions.resetCommissionFilters:
      return {
        ...state,
        CommissionFilters: {
          ...defaultCommissionQueries,
          limit: state.CommissionFilters.limit,
        },
      };
    case PaymentsActions.resetInvoiceFilters:
      return {
        ...state,
        InvoiceFilters: {
          ...defaultInvoiceQueries,
        },
      };
    case PaymentsActions.resetWithdrawalFilters:
      return {
        ...state,
        WithdrawalFilters: {
          ...defaultWithdrawalQueries,
        },
      };
    case PaymentsActions.setStandardFilters:
      return {
        ...state,
        standardPaymentFilters: action.payload,
      };
    case PaymentsActions.setScheduledFilters:
      return {
        ...state,
        scheduledPaymentFilters: action.payload,
      };
    case PaymentsActions.setCommissionFilters:
      return {
        ...state,
        CommissionFilters: action.payload,
      };
    case PaymentsActions.setInvoiceFilters:
      return {
        ...state,
        InvoiceFilters: action.payload,
      };
    case PaymentsActions.setWithdrawalFilters:
      return {
        ...state,
        WithdrawalFilters: action.payload,
      };
    case PaymentsActions.setSelectedPayments:
      return {
        ...state,
        selectedPayments: transformSelectedPayments(
          state.selectedPayments,
          action.payload
        ),
      };
    case PaymentsActions.setAllSelectedPayments:
      return {
        ...state,
        selectedPayments: action.payload,
      };
    case PaymentsActions.clearSelectedPayments:
      return {
        ...state,
        selectedPayments: [],
      };
    case PaymentsActions.clearFixedPaymentsVat:
      return {
        ...state,
        fixedPaymentsVatAdjusted: null,
      };
    case PaymentsActions.requestPostRecurringPaymentAttempt:
      return {
        ...state,
        isPostingRecurringPayment: true,
        postingRecurringPaymentError: null,
      };
    case PaymentsActions.requestPostRecurringPaymentSuccess:
      return {
        ...state,
        isPostingRecurringPayment: false,
      };
    case PaymentsActions.requestPostRecurringPaymentFailure:
      return {
        ...state,
        isPostingRecurringPayment: false,
        postingRecurringPaymentError: action.payload,
      };
    case PaymentsActions.requestPatchDeleteBonusPaymentAttempt:
      return {
        ...state,
        isDeletingBonus: true,
        deletingBonusError: null,
      };
    case PaymentsActions.requestPatchDeleteBonusPaymentSuccess:
      return {
        ...state,
        isDeletingBonus: false,
      };
    case PaymentsActions.requestPatchDeleteBonusPaymentFailure:
      return {
        ...state,
        isDeletingBonus: false,
        deletingBonusError: action.payload,
      };
    case PaymentsActions.requestPatchDeleteSchedulePaymentAttempt:
      return {
        ...state,
        isDeletingSchedule: true,
        deletingScheduleError: null,
      };
    case PaymentsActions.requestPatchDeleteSchedulePaymentSuccess:
      return {
        ...state,
        isDeletingSchedule: false,
      };
    case PaymentsActions.requestPatchDeleteSchedulePaymentFailure:
      return {
        ...state,
        isDeletingSchedule: false,
        deletingScheduleError: action.payload,
      };
    case PaymentsActions.requestGetCommissionsListAttempt:
      return {
        ...state,
        isFetchingCommissionsList: true,
        commissionsListError: null,
      };
    case PaymentsActions.requestGetCommissionsListSuccess:
      return {
        ...state,
        isFetchingCommissionsList: false,
        paginatedCommissions: transformGetCommissionListPayload(
          action.payload,
          state.paginatedCommissions
        ),
      };
    case PaymentsActions.requestGetCommissionsListFailure:
      return {
        ...state,
        isFetchingCommissionsList: false,
        commissionsListError: action.payload,
      };
    case PaymentsActions.requestGetCommissionAttempt:
      return {
        ...state,
        isFetchingCommission: true,
        currentCommissionError: null,
      };
    case PaymentsActions.requestGetCommissionSuccess:
      return {
        ...state,
        isFetchingCommission: false,
        currentCommission: transformCommissionFromResponse(action.payload),
      };
    case PaymentsActions.requestGetCommissionFailure:
      return {
        ...state,
        isFetchingCommission: false,
        currentCommissionError: action.payload,
      };
    case PaymentsActions.requestPostDesiredCommissionStatusAttempt:
      return {
        ...state,
        isPostingCommissions: true,
        postingCommissionsError: null,
      };
    case PaymentsActions.requestPostDesiredCommissionStatusSuccess:
      return {
        ...state,
        isPostingCommissions: false,
      };
    case PaymentsActions.requestPostDesiredCommissionStatusFailure:
      return {
        ...state,
        isPostingCommissions: false,
        postingCommissionsError: action.payload,
      };
    case PaymentsActions.requestPutBonusPaymentAttempt:
      return {
        ...state,
        isEditingBonus: true,
        editBonusError: null,
      };
    case PaymentsActions.requestPutBonusPaymentSuccess:
      return {
        ...state,
        isEditingBonus: false,
      };
    case PaymentsActions.requestPutBonusPaymentFailure:
      return {
        ...state,
        isEditingBonus: false,
        editBonusError: action.payload,
      };

    case PaymentsActions.requestPutRecurringPaymentAttempt:
      return {
        ...state,
        isEditingSchedule: true,
        editScheduleError: null,
      };
    case PaymentsActions.requestPutRecurringPaymentSuccess:
      return {
        ...state,
        isEditingSchedule: false,
      };
    case PaymentsActions.requestPutRecurringPaymentFailure:
      return {
        ...state,
        isEditingSchedule: false,
        editScheduleError: action.payload,
      };
    case PaymentsActions.requestGetInfluencersAttempt:
      return {
        ...state,
        isFetchingInfluencers: true,
        influencersError: null,
      };
    case PaymentsActions.requestGetInfluencersSuccess:
      return {
        ...state,
        paginatedInfluencers: transformGetInfluencersPayload(
          action.payload,
          state.paginatedInfluencers?.content || null
        ),
        isFetchingInfluencers: false,
      };
    case PaymentsActions.requestGetInfluencersFailure:
      return {
        ...state,
        isFetchingInfluencers: false,
        influencersError: action.payload,
      };

    case PaymentsActions.requestGetNonInfluencersAttempt:
      return {
        ...state,
        isFetchingNonInfluencers: true,
        nonInfluencersError: null,
      };
    case PaymentsActions.requestGetNonInfluencersSuccess:
      return {
        ...state,
        paginatedNonInfluencers: transformGetNonInfluencersPayload(
          action.payload,
          state.paginatedNonInfluencers?.content || null
        ),
        isFetchingNonInfluencers: false,
      };
    case PaymentsActions.requestGetNonInfluencersFailure:
      return {
        ...state,
        isFetchingNonInfluencers: false,
        nonInfluencersError: action.payload,
      };

    case PaymentsActions.requestGetInvoicesAttempt:
      return {
        ...state,
        isFetchingInvoices: true,
        isFetchingInvoicesError: false,
      };
    case PaymentsActions.requestGetInvoicesSuccess:
      return {
        ...state,
        isFetchingInvoices: false,
        isFetchingInvoicesError: false,
        paginatedInvoices: transformPaginatedInvoicesFromApi(
          action.payload,
          state.paginatedInvoices?.content ?? null
        ),
      };
    case PaymentsActions.requestGetInvoicesFailure:
      return {
        ...state,
        isFetchingInvoices: false,
        isFetchingInvoicesError: true,
      };
    case PaymentsActions.clearInvoices:
      return {
        ...state,
        paginatedInvoices: null,
      };
    case PaymentsActions.requestGetWithdrawalsAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          withdrawals: true,
        },
        error: {
          ...state.error,
          withdrawals: null,
        },
      };
    case PaymentsActions.requestGetWithdrawalsSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          withdrawals: false,
        },
        paginatedWithdrawals: transformPaginatedWithdrawalsFromApi(
          action.payload,
          state.paginatedWithdrawals?.content ?? null
        ),
      };
    case PaymentsActions.requestGetWithdrawalsFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          withdrawals: false,
        },
        error: {
          ...state.error,
          withdrawals: action.payload,
        },
      };
    case PaymentsActions.clearWithdrawals:
      return {
        ...state,
        paginatedWithdrawals: null,
      };
    case PaymentsActions.setOrderIdentifierQueries:
      return {
        ...state,
        orderIdentifierQueries: {
          ...state.orderIdentifierQueries,
          ...action.payload,
        },
      };
    case PaymentsActions.requestGetOrderIdentifiersAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          orderIdentifiers: true,
        },
        error: {
          ...state.error,
          orderIdentifiers: null,
        },
      };
    case PaymentsActions.requestGetOrderIdentifiersSuccess:
      return {
        ...state,
        orderIdentifiers: action.payload.order_ids,
        isFetching: {
          ...state.isFetching,
          orderIdentifiers: false,
        },
      };
    case PaymentsActions.requestGetOrderIdentifiersFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          orderIdentifiers: false,
        },
        error: {
          ...state.error,
          orderIdentifiers: action.payload,
        },
      };
    default:
      return state;
  }
};
