export enum UserPermission {
  viewProgramme = 'view-programme',
  editProgramme = 'edit-programme',
  inviteInfluencer = 'invite-influencer',
  createBonusPayment = 'create-bonus-payment',
  createProgramme = 'create-programme',
  createProgrammeGroup = 'create-programme-group',
  viewInfluencers = 'view-influencers',
  manageHistoricId = 'manage-historic-id',
  approveRejectBonusPayment = 'approve-reject-bonus-payment',
  managePermissions = 'manage-permissions',
  editTranslations = 'edit-translations',
  overrideSocialRequirement = 'override-social-requirement',
  engineer = 'engineer',
  approveCommissions = 'approve-commissions',
  approveApplications = 'approve-applications',
  viewInvoices = 'view-invoice',
  createCampaign = 'create-campaign',
  editCampaign = 'edit-campaign',
  manageTaxInformation = 'manage-tax-information',
  viewWithdrawals = 'view-self-bill',
  viewAgencies = 'view-agency',
  approveAgencyApplications = 'approve-agency-application',
  exportWallets = 'export-wallets',
}

export interface RoleGateProps {
  permission: UserPermission;
  programmeGroup?: string | null;
  programme?: string | null;
  isPage?: boolean;
  unauthorizedMessage?: string;
}
