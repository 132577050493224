import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { theme, Theme } from '../../../styling/theme';
import { px2rem } from '../../../styling/sizing';
import { NotificationPriority } from '../../../store/Notification/store';

const getNotificationColour = (
  theme: Theme,
  priority: NotificationPriority
) => {
  switch (priority) {
    case NotificationPriority.HIGH:
      return theme.COLOUR.ALERT;

    case NotificationPriority.MEDIUM:
      return theme.COLOUR.WARNING;

    default:
      return theme.COLOUR.SUCCESS;
  }
};

export const NotificationItemContainer = styled(Link)<{ isMobile: boolean }>`
  padding: ${px2rem(10)} ${px2rem(20)};
  color: ${props => theme(props).COLOUR.TEXT_STANDARD};
  text-decoration: none;
  border: 1px solid ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
  border-radius: ${props => theme(props).LAYOUT.BORDER_RADIUS};
  width: ${props => (props.isMobile ? '100%' : px2rem(480))};
  height: auto;
  margin-bottom: ${px2rem(8)};
  background-color: white;

  display: grid;
  grid-template-columns: 1fr 6fr 1fr;
  ${props => !props.isMobile && 'align-items: center;'}

  &:hover, &:focus {
    border-color: ${props => theme(props).COLOUR.SECONDARY_DARK};
    cursor: pointer;
  }
`;

export const NotificationItemMessage = styled.div`
  ${props => theme(props).TYPOGRAPHY.BODY_TEXT};
  flex: 1;
  color: ${props => theme(props).COLOUR.TEXT_STANDARD};
  text-decoration: none;
  white-space: pre-wrap;
`;

export const NotificationItemDuration = styled.div`
  ${props => theme(props).TYPOGRAPHY.X_SMALL};
  color: ${props => theme(props).COLOUR.BACKGROUND_DARK};
`;

export const NotificationItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NotificationIconWrapper = styled.span<{
  priority: NotificationPriority;
}>`
  color: ${props => getNotificationColour(theme(props), props.priority)};
  font-size: ${px2rem(32)};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const AbsoluteCenterAlign = css`
  position: absolute;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const StatusTooltip = styled.span`
  ${props => theme(props).TYPOGRAPHY.X_SMALL_alt};
  visibility: hidden;
  width: ${px2rem(100)};
  background-color: ${props => theme(props).COLOUR.SECONDARY_DARKER};
  color: white;
  text-align: center;
  border-radius: ${props => theme(props).EFFECTS.RADIUS_STANDARD};
  padding: ${px2rem(4)};
  ${AbsoluteCenterAlign}
`;

export const StatusIcon = styled.div`
  width: ${px2rem(16)};
  height: ${px2rem(16)};
  position: relative;

  &:hover ${StatusTooltip} {
    visibility: visible;
  }
`;

export const StatusContainer = styled.div`
  color: ${props => theme(props).COLOUR.SECONDARY_DARKER};
  font-size: ${px2rem(16)};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

export const NotificationZeroContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: ${props => (props.isMobile ? '100%' : px2rem(480))};
  height: ${props => (props.isMobile ? '100%' : px2rem(400))};
`;

export const NotificationZeroIcon = styled.div`
  color: ${props => theme(props).COLOUR.BACKGROUND_MEDIUM};
  font-size: 10rem;
`;

export const NotificationZeroInfo = styled.div`
  color: ${props => theme(props).COLOUR.BACKGROUND_MEDIUM};
  ${props => theme(props).TYPOGRAPHY.SMALL_alt};
`;
