import styled, { css } from 'styled-components';
import { theme } from '../../../styling/theme';

export const CloseButton = styled.button<{ isAbsolute?: boolean }>`
  font-size: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: content-box;
  border: 2px solid ${props => theme(props).COLOUR.BACKGROUND_LIGHTEST};
  background-color: white;
  padding: 0;
  &:hover,
  &:focus {
    background-color: ${props => theme(props).COLOUR.PRIMARY};
    color: white;
    transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};
  }
  ${props =>
    props.isAbsolute &&
    css`
      position: absolute;
      top: ${theme(props).SIZE.XSMALL};
      right: ${theme(props).SIZE.XSMALL};
    `}

  & path {
    transform: translateX(5%);
  }
`;
export const ActionsWrapper = styled.div<{ isMobile: boolean }>`
  flex: 0 0 auto;
  display: flex;
  padding: ${props =>
    `${theme(props).SIZE.SMALL} ${theme(props).SIZE.AVERAGE}`};
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: ${props => props.isMobile && `${theme(props).SIZE.LARGE}`};
`;

export const ContentWrapper = styled.div<{
  isMobile: boolean;
  withPadding: boolean;
}>`
  flex: 1 1 auto;
  ${props =>
    props.withPadding &&
    `padding: ${theme(props).SIZE.XSMALL}
    ${theme(props).SIZE.AVERAGE};`}
  overflow-y: ${props => (props.isMobile ? 'none' : 'auto')};
  -webkit-overflow-scrolling: touch;
`;

export const NoticeWrapper = styled.div<{ isError: boolean }>`
  display: flex;
  justify-content: flex-end;
  padding: 0 ${props => theme(props).SIZE.AVERAGE};
  ${props => props.isError && `color: ${theme(props).COLOUR.ERROR}`}
`;
