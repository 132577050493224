import styled, { css } from 'styled-components';
import { theme } from '../../../styling/theme';
import { COLOUR } from '../../../styling/theme/COLOUR';
import { AvatarSize } from './Avatar';

export const avatarColours = [
  COLOUR.AVATAR_1,
  COLOUR.AVATAR_2,
  COLOUR.AVATAR_3,
  COLOUR.AVATAR_4,
  COLOUR.AVATAR_5,
  COLOUR.AVATAR_6,
];

const sizeMap: { [key in AvatarSize]: string } = {
  small: '3rem',
  medium: '5rem',
  large: '9rem',
};

interface AvatarThumbnailProps {
  index: number;
  url?: string | null | undefined;
  size: AvatarSize;
}

export const AvatarThumbnail = styled.div<AvatarThumbnailProps>`
  min-width: ${props => sizeMap[props.size]};
  min-height: ${props => sizeMap[props.size]};
  border-radius: ${props => theme(props).LAYOUT.BORDER_RADIUS_CIRCLE};
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.url
      ? css`
          background-image: url(${props.url});
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        `
      : css`
          background-color: ${avatarColours[props.index]};
        `}
  color: white;
  font-weight: 900;
  line-height: 1;
  padding-top: 2px;
`;
