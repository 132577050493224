import { css } from 'styled-components';
import { Breakpoint } from '../breakpoints';
import { GlobalFontFamily } from '../css/fontfamily';
import { px2rem } from '../sizing';

const createStyle = (
  fontSize: number,
  lineHeight: number,
  fontWeight: number | string
) => {
  return css`
    ${GlobalFontFamily}
    font-size: ${px2rem(fontSize)};
    line-height: ${px2rem(lineHeight)};
    font-weight: ${fontWeight};
  `;
};

const createMobileStyle = (
  fontSize: number,
  lineHeight: number,
  fontWeight: number | string
) => {
  return css`
    @media screen and (max-width: ${px2rem(Breakpoint.TABLET, 'em')}) {
      ${createStyle(fontSize, lineHeight, fontWeight)};
    }
  `;
};

export const TYPOGRAPHY = {
  FONT_FAMILY_STANDARD: "'Poppins-Light', Arial, sans-serif",
  FONT_FAMILY_BOLD: "'Poppins', Arial, sans-serif",
  FONT_FAMILY_BOLDER: "'Poppins-Medium', Arial, sans-serif",
  FONT_FAMILY_BOLDEST: "'Poppins-Bold', Arial, sans-serif",
  FONT_WEIGHT_LIGHT: 100,
  FONT_WEIGHT_STANDARD: 400,
  FONT_WEIGHT_BOLD: 800,
  X_LARGE_2: css`
    ${createStyle(45, 56, 300)};
    ${createMobileStyle(32, 40, 300)};
  `,
  X_LARGE_2_alt: css`
    ${createStyle(45, 56, 400)};
    ${createMobileStyle(32, 40, 400)};
  `,
  X_LARGE_1: css`
    ${createStyle(37, 48, 300)};
    ${createMobileStyle(28, 36, 300)};
  `,
  X_LARGE_1_alt: css`
    ${createStyle(37, 48, 400)};
    ${createMobileStyle(28, 36, 400)};
  `,
  LARGE_2: css`
    ${createStyle(30, 40, 300)};
    ${createMobileStyle(24, 32, 300)};
  `,
  LARGE_2_alt: css`
    ${createStyle(30, 40, 400)};
    ${createMobileStyle(24, 32, 400)};
  `,
  LARGE_1: css`
    ${createStyle(21, 28, 300)};
    ${createMobileStyle(24, 32, 300)};
  `,
  LARGE_1_alt: css`
    ${createStyle(21, 28, 400)};
    ${createMobileStyle(24, 32, 400)};
  `,
  MEDIUM_1: css`
    ${createStyle(20, 28, 300)};
    ${createMobileStyle(18, 24, 300)};
  `,
  MEDIUM_1_alt: css`
    ${createStyle(20, 28, 400)};
    ${createMobileStyle(18, 24, 400)};
  `,
  BODY_TEXT: css`
    ${createStyle(16, 24, 300)};
    ${createMobileStyle(16, 24, 300)};
  `,
  BODY_TEXT_alt: css`
    ${createStyle(16, 24, 400)};
    ${createMobileStyle(16, 24, 400)};
  `,
  SMALL: css`
    ${createStyle(14, 20, 300)};
    ${createMobileStyle(14, 20, 300)};
  `,
  SMALL_alt: css`
    ${createStyle(14, 20, 400)};
    ${createMobileStyle(14, 20, 400)};
  `,
  X_SMALL: css`
    ${createStyle(13, 20, 300)};
    ${createMobileStyle(13, 20, 300)};
  `,
  X_SMALL_alt: css`
    ${createStyle(13, 20, 400)};
    ${createMobileStyle(13, 20, 400)};
  `,
};
