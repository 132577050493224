import styled, { css } from 'styled-components';
import { theme } from '../../../styling/theme';
import { px2rem } from '../../../styling/sizing';

export enum SpaceSize {
  XSMALL = 'XSMALL',
  SMALL = 'SMALL',
  AVERAGE = 'AVERAGE',
  LARGE = 'LARGE',
  GIGANTIC = 'GIGANTIC',
}

export interface SpacerProps {
  size: SpaceSize | number;
  alignment?: 'HORIZONTAL' | 'VERTICAL';
}

const VerticalRule = css<{ size: SpaceSize | number }>`
  height: ${props =>
    typeof props.size === 'number'
      ? `${px2rem(props.size)}`
      : theme(props).SIZE[props.size]};
`;

const HorizontalRule = css<{ size: SpaceSize | number }>`
  height: 1px;
  width: ${props =>
    typeof props.size === 'number'
      ? `${px2rem(props.size)}`
      : theme(props).SIZE[props.size]};
`;

export const Spacer = styled.div<SpacerProps>`
  ${props =>
    props.alignment === 'HORIZONTAL' ? HorizontalRule : VerticalRule};
`;
