import ReactGA from 'react-ga4';
import { EnvironmentConfig } from 'environments/environment';

export const initialiseGA = () => {
  const envToTrackingId: Record<EnvironmentConfig['environment'], string> = {
    live: 'G-VZ24SZ4GDC',
    gb5mocked: 'G-12E3Z6E9BY',
    gb5: 'G-12E3Z6E9BY',
    local: 'G-12E3Z6E9BY',
    '': '',
  };

  const trackingId = envToTrackingId[window._env.environment];
  if (!trackingId) return;

  ReactGA.initialize(trackingId);
};
