import styled, { keyframes, css } from 'styled-components';
import { theme } from '../../../../styling/theme';
import { LoadingBoxColourVariants } from './LoadingBox';

const shineAnimate = () => keyframes`
  to {
    background-position-x: -200%;
  }
`;

const StandardLoadingBoxStyle = css`
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s ${shineAnimate()} linear infinite;
  width: 100%;
`;

const LightLoadingBoxStyle = css`
  background: ${props => theme(props).COLOUR.WHITE};
  background: ${props =>
    `linear-gradient(110deg, ${theme(props).COLOUR.WHITE} 8%, ${
      theme(props).COLOUR.BACKGROUND_HIGHLIGHT
    } 18%, ${theme(props).COLOUR.WHITE} 33%)`};
`;

const NormalLoadingBoxStyle = css`
  background: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
  background: ${props =>
    `linear-gradient(110deg, ${theme(props).COLOUR.BACKGROUND_LIGHTEST} 8%, ${
      theme(props).COLOUR.BACKGROUND_HIGHLIGHT
    } 18%, ${theme(props).COLOUR.BACKGROUND_LIGHTEST} 33%)`};
`;

export const StandardLoadingBox = styled.div<{
  variant: string;
  colourVariant: LoadingBoxColourVariants;
  overrideWidth?: boolean;
}>`
  ${props =>
    props.colourVariant === 'light'
      ? LightLoadingBoxStyle
      : NormalLoadingBoxStyle}
  ${StandardLoadingBoxStyle}
  ${props => {
    switch (props.variant) {
      case 'sm':
        return `min-height: 1rem`;
      case 'md':
        return `min-height: 6rem`;
      case 'full':
        return `height: inherit`;
      default:
        return `min-height: 10rem`;
    }
  }};
  ${props => {
    if (props.overrideWidth) return `width: 10rem`;
  }};
`;
