import Keycloak, { KeycloakError, KeycloakConfig } from 'keycloak-js';

const DEFAULT_EXP_TIME = 60;
const UPDATE_IN_LIFE = 0.8;

// Getters and setters for the keycloak window object
// Might wish to revist this in order to prevent polution of the window object
// For now, this method should be fine
export const getKeycloakInstance = () => window.Society.keycloak;

export const setKeycloakInstance = (instance: Keycloak) => {
  window.Society.keycloak = instance;
};

export const initiateAuthInstance = (
  config: KeycloakConfig,
  successCallback: () => void,
  errorCallback: () => void,
  refreshCallback: () => void
) => {
  return new AuthObject(
    config,
    successCallback,
    errorCallback,
    refreshCallback
  );
};

export class AuthObject {
  private keycloak: Keycloak;
  private timeout: number | null = null;

  public constructor(
    c: KeycloakConfig,
    onSuccess: () => void,
    onError: (e: KeycloakError) => void,
    onRefresh: () => void
  ) {
    this.keycloak = new Keycloak(c);
    this.init(onSuccess, onError, onRefresh);
  }

  private init = (
    onSuccess: () => void,
    onError: (e: KeycloakError) => void,
    onRefresh: () => void
  ) => {
    this.keycloak
      .init({ onLoad: 'login-required', checkLoginIframe: false })
      .then(() => {
        this.addBackgroundRefresh(this.keycloak, onRefresh);
        onSuccess();
      })
      .catch(onError);
  };

  public getAuthStatus = () => this.keycloak!.authenticated;

  private addBackgroundRefresh = (
    instance: Keycloak,
    onRefresh: () => void
  ) => {
    // Save to window object
    setKeycloakInstance(instance);
    if (instance && instance.tokenParsed) {
      const { exp, iat } = instance.tokenParsed;
      const lifetimeSeconds = exp && iat ? exp - iat : NaN;
      const expiresIn = isNaN(lifetimeSeconds)
        ? DEFAULT_EXP_TIME
        : Math.floor(lifetimeSeconds) * UPDATE_IN_LIFE;

      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      const timeoutLength = expiresIn * 1000;
      this.timeout = window.setTimeout(() => {
        instance.updateToken(-1).then(() => {
          onRefresh();
        });
        this.addBackgroundRefresh(instance, onRefresh);
      }, timeoutLength);
    }
  };

  public cleanup = () => {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
  };
}
