import styled from 'styled-components/macro';
import { px2rem } from '../../../styling/sizing';
import { Margin } from '../../common/Box';

export const NotificationContainer = styled.div<{ isMobile: boolean }>`
  ${props => !props.isMobile && `margin-right: ${Margin.SMALL};`}
  ${props => props.isMobile && `text-align: end;`}

  & .MuiBadge-root {
    transform: scale(0.9);
  }

  & .MuiBadge-anchorOriginTopRightRectangle {
    transform: scale(0.9) translate(50%, -50%);
  }
`;

export const NotificationIcon = styled.div`
  font-size: ${px2rem(28)};
`;

export const NotificationCount = styled.span`
  background: #e20057;
`;
