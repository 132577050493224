import { DispatchFn } from '../state';
import client, { Microservice } from '../../utils/http.utils';
import { captureException } from '../../utils/sentry.utils';
import { Task, WatchmanActions, WatchmanErrors } from './store';

export const RequestGetWatchmanTasksAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  try {
    dispatch({
      type: WatchmanActions.requestGetWatchmanTasksAttempt,
    });

    const response = await client.request(
      Microservice.WATCHMAN,
      '/watchman/scheduled_task',
      'GET'
    );

    dispatch({
      type: WatchmanActions.requestGetWatchmanTasksSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: WatchmanActions.requestGetWatchmanTasksFailure,
      payload: WatchmanErrors.requestGetWatchmanTasksError,
    });
  }
};

export const RequestPutWatchmanTasks = async (
  dispatch: DispatchFn,
  updatedTasks: Task[]
): Promise<void> => {
  try {
    dispatch({
      type: WatchmanActions.requestPutWatchmanTasksAttempt,
    });

    const response = await client.request(
      Microservice.WATCHMAN,
      '/watchman/scheduled_task',
      'PUT',
      updatedTasks
    );
    dispatch({
      type: WatchmanActions.requestPutWatchmanTasksSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: WatchmanActions.requestPutWatchmanTasksFailure,
      payload: WatchmanErrors.requestPutWatchmanTasksError,
    });
  }
};
