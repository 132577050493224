import styled from 'styled-components';

export const AppContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: stretch;
`;

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 225px);
  overflow-y: auto;
  padding-bottom: 100px;
`;

export const PageOuterContainer = styled.main`
  flex: 1;
  padding: 1.5rem;
  max-width: 80rem;
  margin: 0 auto;
  width: 100%;
`;
