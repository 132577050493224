import { captureException as capture, Severity } from '@sentry/react';

export const captureException = (
  error: any,
  severity: Severity = Severity.Error
) => {
  capture(error, { level: severity });
};

export { Severity } from '@sentry/react';
