import { Action } from '../state';
import { AgenciesState, AgencyActions } from './store';
import {
  transformAgencyApplicationFromAPI,
  transformAgencyFromAPI,
  transformPaginatedAgenciesListFromAPI,
  transformPaginatedAgencyApplicationsFromAPI,
  transformAgencyInfluencersFromAPI,
} from './transformers';

export const AgenciesReducer = (
  state: AgenciesState,
  action: Action
): AgenciesState => {
  switch (action.type) {
    case AgencyActions.requestPaginatedAgenciesAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          agenciesList: true,
        },
        error: {
          ...state.error,
          agenciesList: null,
        },
      };
    case AgencyActions.requestPaginatedAgenciesSuccess:
      return {
        ...state,
        paginatedAgencies: transformPaginatedAgenciesListFromAPI(
          action.payload,
          state.paginatedAgencies?.content || null
        ),
        isLoading: {
          ...state.isLoading,
          agenciesList: false,
        },
      };
    case AgencyActions.requestPaginatedAgenciesFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          agenciesList: false,
        },
        error: {
          ...state.error,
          agenciesList: action.payload,
        },
      };

    case AgencyActions.requestGetAgencyApplicationsAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          paginatedAgencyApplications: true,
        },
        error: {
          ...state.error,
          paginatedAgencyApplications: null,
        },
      };
    case AgencyActions.requestGetAgencyApplicationsSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          paginatedAgencyApplications: false,
        },
        paginatedAgencyApplications:
          transformPaginatedAgencyApplicationsFromAPI(
            action.payload,
            state.paginatedAgencyApplications?.content || null
          ),
      };
    case AgencyActions.requestGetAgencyApplicationsFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          paginatedAgencyApplications: false,
        },
        error: {
          ...state.error,
          paginatedAgencyApplications: action.payload,
        },
      };
    case AgencyActions.requestAgencyAttempt:
      return {
        ...state,
        currentAgency: null,
        isLoading: {
          ...state.isLoading,
          currentAgency: true,
        },
        error: {
          ...state.error,
          currentAgency: null,
        },
      };
    case AgencyActions.requestAgencySuccess:
      return {
        ...state,
        currentAgency: transformAgencyFromAPI(action.payload),
        isLoading: {
          ...state.isLoading,
          currentAgency: false,
        },
      };
    case AgencyActions.requestAgencyFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          currentAgency: false,
        },
        error: {
          ...state.error,
          currentAgency: action.payload,
        },
      };
    case AgencyActions.clearAgencyList:
      return {
        ...state,
        paginatedAgencies: null,
      };
    case AgencyActions.clearAgencyApplicationList:
      return {
        ...state,
        paginatedAgencyApplications: null,
      };
    case AgencyActions.setAgencyFilters:
      return {
        ...state,
        agenciesQuery: {
          ...state.agenciesQuery,
          ...action.payload,
        },
      };
    case AgencyActions.setAgencyApplicationFilters:
      return {
        ...state,
        agencyApplicationsQuery: {
          ...state.agencyApplicationsQuery,
          ...action.payload,
        },
      };
    case AgencyActions.requestGetAgencyApplicationAttempt:
      return {
        ...state,
        agencyApplication: null,
        isLoading: {
          ...state.isLoading,
          agencyApplication: true,
        },
        error: {
          ...state.error,
          agencyApplication: null,
        },
      };
    case AgencyActions.requestGetAgencyApplicationSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          agencyApplication: false,
        },
        agencyApplication: transformAgencyApplicationFromAPI(action.payload),
      };
    case AgencyActions.requestGetAgencyApplicationFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          agencyApplication: false,
        },
        error: {
          ...state.error,
          agencyApplication: action.payload,
        },
      };
    case AgencyActions.requestGetAgencyOptionsAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          agencyOptions: true,
        },
        error: {
          ...state.error,
          agencyOptions: null,
        },
      };
    case AgencyActions.requestGetAgencyOptionsSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          agencyOptions: false,
        },
        agencyOptions: action.payload,
      };
    case AgencyActions.requestGetAgencyOptionsFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          agencyOptions: false,
        },
        error: {
          ...state.error,
          agencyOptions: action.payload,
        },
      };

    case AgencyActions.requestGetInfluencerMappedAgencyAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          agencyInfluencers: true,
        },
        error: {
          ...state.error,
          agencyInfluencers: null,
        },
      };
    case AgencyActions.requestGetInfluencerMappedAgencySuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          agencyInfluencers: false,
        },
        agencyInfluencers: transformAgencyInfluencersFromAPI(
          action.payload['influencers']
        ),
      };
    case AgencyActions.requestGetInfluencerMappedAgencyFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          agencyInfluencers: false,
        },
        error: {
          ...state.error,
          agencyInfluencers: action.payload,
        },
      };

    case AgencyActions.resetAgencyApplication:
      return {
        ...state,
        agencyApplication: null,
      };
    default:
      return state;
  }
};
