import styled, { css } from 'styled-components';
import { theme } from '../../../styling/theme';
import { TextSmall } from '../../Typography';

interface InputProps {
  error?: boolean;
  maxWidth?: string;
  type?: string;
}

export const inputStyles = css<InputProps>`
  ${props => theme(props).TYPOGRAPHY.BODY_TEXT}
  box-sizing: border-box;
  border: 1px solid;
  border-color: ${props =>
    props.error
      ? theme(props).COLOUR.ERROR
      : theme(props).COLOUR.BACKGROUND_LIGHT};
  border-radius: ${props => theme(props).LAYOUT.BORDER_RADIUS};
  padding: 0.5rem;
  width: 100%;
  transition: border-color ${props => theme(props).EFFECTS.TRANSITION_QUICK};
  height: ${props => theme(props).SIZE.XLARGE};
  background-color: white;
  padding-right: ${props =>
    props.type === 'number' ? '0' : theme(props).SIZE.XLARGE};

  &:focus {
    outline: none;
    border-color: ${props => theme(props).COLOUR.PRIMARY};
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${props => theme(props).COLOUR.BACKGROUND_LIGHTEST};
  }
  &:invalid {
    border: 1px solid ${props => theme(props).COLOUR.ERROR};
  }

  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `}
`;

export const Input = styled.input<InputProps>`
  ${inputStyles}
`;

export const TextArea = styled.textarea<InputProps>`
  ${inputStyles}
  min-height: 100px;
  resize: vertical;
`;

export const InputError = styled(TextSmall)`
  color: ${props => theme(props).COLOUR.ALERT_DARK};
`;

export const InputWarning = styled(TextSmall)`
  color: ${props => theme(props).COLOUR.ALERT_DARK};
`;
