import {
  CampaignFormBody,
  CampaignImage,
  PostProgrammeImageResponsePayload,
  PostCampaignImageResponsePayload,
  ProgrammeFormBody,
  ProgrammeImage,
  ProgrammesActions,
  ProgrammesState,
} from './store';
import { Action } from '../state';
import {
  getAssociationsPaginatedPayload,
  transactionsFromApi,
  transactionFromApi,
  handleApproveTransaction,
  transformGetAgreementPayload,
  handleRejectTransaction,
  transformPaymentTypesPayload,
  transformProgrammeStateFromAPI,
  transformProgrammeGroupsFromAPI,
  transformProgrammeGroupFromAPI,
  handleUpdateNotification,
  transformGetAssociationAgreementPayload,
  transformToQueries,
  transformNewAssociation,
  getAssociationsPayload,
  getInvitationsPaginatedPayload,
  getCampaignAssociationsPaginatedPayload,
  transformToCampaignQueries,
  transformGetDiscountCodeListPayload,
  transformGetDiscountCodePayload,
} from './payload';
import {
  transformCommissionsSummaryFromAPI,
  transformPaginatedApplicationsFromApi,
  transformPaginatedProgrammePerformance,
  transformProgrammePerformanceDataFromAPI,
  transformReadCampaignFromApi,
} from './transformer';
import { transformGetInvitableInfluencersPayload } from '../Discovery/transformers';

const postImageFormReducer = (
  state: ProgrammesState,
  payload: PostProgrammeImageResponsePayload,
  isSuccess: boolean
): ProgrammeFormBody => {
  switch (payload.type) {
    case ProgrammeImage.BANNER:
      return {
        ...state.programmeFormBody,
        bannerImageId: isSuccess ? payload.response : null,
      };
    case ProgrammeImage.THUMBNAIL:
      return {
        ...state.programmeFormBody,
        thumbnailImageId: isSuccess ? payload.response : null,
      };
    case ProgrammeImage.LOGO:
      return {
        ...state.programmeFormBody,
        logoImageId: isSuccess ? payload.response : null,
      };
  }
};

const postCampaignImageFormReducer = (
  state: ProgrammesState,
  payload: PostCampaignImageResponsePayload,
  isSuccess: boolean
): CampaignFormBody => {
  switch (payload.type) {
    case CampaignImage.BANNER:
      return {
        ...state.campaignFormBody,
        campaign_banner_id: isSuccess ? payload.response : null,
      };
    case CampaignImage.VISUAL_DIRECTION: {
      const fileIndex = payload.imageIndex;
      return {
        ...state.campaignFormBody,
        visual_direction_ids: {
          ...state.campaignFormBody.visual_direction_ids,
          [fileIndex]: payload.response,
        },
      };
    }
    case CampaignImage.ADDITIONAL: {
      const fileIndex = payload.imageIndex;
      return {
        ...state.campaignFormBody,
        additional_images_ids: {
          ...state.campaignFormBody.additional_images_ids,
          [fileIndex]: payload.response,
        },
      };
    }
  }
};

export const ProgrammesReducer = (
  state: ProgrammesState,
  action: Action
): ProgrammesState => {
  switch (action.type) {
    case ProgrammesActions.clearInvitationTokens:
      return {
        ...state,
        invitationToken: null,
        isFetching: false,
        error: null,
      };
    case ProgrammesActions.clearInvitations:
      return {
        ...state,
        invitations: null,
      };
    //get programme
    case ProgrammesActions.requestGetProgrammeAttempt:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case ProgrammesActions.requestGetProgrammeSuccess:
      return {
        ...state,
        isFetching: false,
        invitationToken: null,
        currentProgramme: {
          ...action.payload,
        },
      };
    case ProgrammesActions.requestGetProgrammeFailure:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    //get influencer available programmes
    case ProgrammesActions.requestGetInvitableProgrammesAttempt:
      return {
        ...state,
        isFetchingInvitableProgrammes: true,
        invitableProgrammesError: null,
      };
    case ProgrammesActions.requestGetInvitableProgrammesSuccess:
      return {
        ...state,
        isFetchingInvitableProgrammes: false,
        invitableProgrammes: action.payload,
      };
    case ProgrammesActions.requestGetInvitableProgrammesFailure:
      return {
        ...state,
        isFetchingInvitableProgrammes: false,
        invitableProgrammesError: action.payload,
      };
    //clear influencer available programmes
    case ProgrammesActions.clearInvitableProgrammes:
      return {
        ...state,
        invitableProgrammes: null,
      };
    //post programme
    case ProgrammesActions.requestPostProgrammeAttempt:
      return {
        ...state,
        isCreatingProgramme: true,
        createProgrammeError: null,
      };
    case ProgrammesActions.requestPostProgrammeSuccess:
      return {
        ...state,
        isCreatingProgramme: false,
        programmes: state.programmes
          ? [...state.programmes, action.payload]
          : [action.payload],
      };
    case ProgrammesActions.requestPostProgrammeFailure:
      return {
        ...state,
        isCreatingProgramme: false,
        createProgrammeError: action.payload,
      };
    //put programme
    case ProgrammesActions.requestPutProgrammeAttempt:
      return {
        ...state,
        isUpdatingProgramme: true,
        updateProgrammeError: null,
      };
    case ProgrammesActions.requestPutProgrammeSuccess:
      return {
        ...state,
        isUpdatingProgramme: false,
        currentProgramme: action.payload,
      };
    case ProgrammesActions.requestPutProgrammeFailure:
      return {
        ...state,
        isUpdatingProgramme: false,
        updateProgrammeError: action.payload,
      };
    //clear current programme
    case ProgrammesActions.clearCurrentProgramme:
      return {
        ...state,
        currentProgramme: null,
        isFetching: false,
        error: null,
      };
    //set current programme
    case ProgrammesActions.setCurrentProgramme:
      return {
        ...state,
        currentProgramme: action.payload,
        isFetching: false,
        error: null,
      };
    //get programmes
    case ProgrammesActions.requestGetProgrammesAttempt:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case ProgrammesActions.requestGetProgrammesSuccess:
      return {
        ...state,
        isFetching: false,
        invitationToken: null,
        programmes: action.payload,
      };
    case ProgrammesActions.requestGetProgrammesFailure:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    //post invite
    case ProgrammesActions.requestPostInvitationAttempt:
      return {
        ...state,
        isCreatingInvitation: true,
        createInvitationError: null,
      };
    case ProgrammesActions.requestPostInvitationSuccess:
      return {
        ...state,
        isCreatingInvitation: false,
        invitationToken: action.payload,
      };
    case ProgrammesActions.requestPostInvitationFailure:
      return {
        ...state,
        isCreatingInvitation: false,
        createInvitationError: action.payload,
      };
    //bulk post invite
    case ProgrammesActions.requestPostBulkInviteAttempt:
      return {
        ...state,
        isBulkInvitingInfluencers: true,
        bulkInvitesError: null,
      };
    case ProgrammesActions.requestPostBulkInviteSuccess:
      return {
        ...state,
        isBulkInvitingInfluencers: false,
        bulkInvitesResponse: action.payload,
      };
    case ProgrammesActions.requestPostBulkInviteFailure:
      return {
        ...state,
        isBulkInvitingInfluencers: false,
        bulkInvitesError: action.payload,
      };
    //delete invite
    case ProgrammesActions.requestDeleteInvitationAttempt:
      return {
        ...state,
        isDeletingInvitation: true,
        deleteInvitationError: null,
      };
    case ProgrammesActions.requestDeleteInvitationSuccess:
      return {
        ...state,
        isDeletingInvitation: false,
      };
    case ProgrammesActions.requestDeleteInvitationFailure:
      return {
        ...state,
        isDeletingInvitation: false,
        deleteInvitationError: action.payload,
      };
    //get associations
    case ProgrammesActions.requestGetAssociationsAttempt:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case ProgrammesActions.requestGetAssociationsSuccess:
      return {
        ...state,
        isFetching: false,
        currentProgramme: state.currentProgramme && {
          ...state.currentProgramme,
          associations: getAssociationsPaginatedPayload(
            action.payload,
            state.currentProgramme.associations?.content || null
          ),
        },
      };
    case ProgrammesActions.requestGetAssociationsFailure:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    //get associations List
    case ProgrammesActions.requestGetAssociationsListAttempt:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case ProgrammesActions.requestGetAssociationsListSuccess:
      return {
        ...state,
        isFetching: false,
        currentProgramme: state.currentProgramme && {
          ...state.currentProgramme,
          associationsList: getAssociationsPayload(action.payload),
        },
      };
    case ProgrammesActions.requestGetAssociationsListFailure:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    //get association agreement
    case ProgrammesActions.requestGetAssociationAgreementAttempt:
      return {
        ...state,
        isFetchingAssociationAgreement: true,
        fetchAssociationAgreementError: null,
      };
    case ProgrammesActions.requestGetAssociationAgreementSuccess:
      return {
        ...state,
        isFetchingAssociationAgreement: false,
        currentAssociationAgreement: transformGetAssociationAgreementPayload(
          action.payload
        ),
        fetchAssociationAgreementError: null,
      };
    case ProgrammesActions.requestGetAssociationAgreementFailure:
      return {
        ...state,
        isFetchingAssociationAgreement: false,
        fetchAssociationAgreementError: action.payload,
      };

    //get discount code
    case ProgrammesActions.requestGetDiscountCodeAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, fetchDiscountCode: true },
        errors: { ...state.errors, fetchDiscountCode: null },
      };
    case ProgrammesActions.requestGetDiscountCodeSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, fetchDiscountCode: false },
        currentDiscountCodes: action.payload
          ? transformGetDiscountCodeListPayload(action.payload)
          : null,
      };
    case ProgrammesActions.requestGetDiscountCodeFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, fetchDiscountCode: false },
        errors: { ...state.errors, fetchDiscountCode: action.payload },
      };

    //post discount code
    case ProgrammesActions.requestPostDiscountCodeAttempt:
      return {
        ...state,
        isCreatingDiscountCode: true,
        errors: { ...state.errors, createDiscountCodeError: null },
      };
    case ProgrammesActions.requestPostDiscountCodeSuccess:
      return {
        ...state,
        isCreatingDiscountCode: false,
        currentDiscountCodes: [transformGetDiscountCodePayload(action.payload)],
      };
    case ProgrammesActions.requestPostDiscountCodeFailure:
      return {
        ...state,
        isCreatingDiscountCode: false,
        errors: { ...state.errors, createDiscountCodeError: action.payload },
      };

    //put discount code
    case ProgrammesActions.requestPutDiscountCodeAttempt:
      return {
        ...state,
        isCreatingDiscountCode: true,
        errors: { ...state.errors, updateDiscountCodeError: null },
      };
    case ProgrammesActions.requestPutDiscountCodeSuccess:
      return {
        ...state,
        isCreatingDiscountCode: false,
        currentDiscountCodes: [transformGetDiscountCodePayload(action.payload)],
      };
    case ProgrammesActions.requestPutDiscountCodeFailure:
      return {
        ...state,
        isCreatingDiscountCode: false,
        errors: { ...state.errors, updateDiscountCodeError: action.payload },
      };

    //delete discount code
    case ProgrammesActions.requestDeleteDiscountCodeAttempt:
      return {
        ...state,
        isDeletingDiscountCode: true,
        errors: { ...state.errors, deleteDiscountCodeError: action.payload },
      };
    case ProgrammesActions.requestDeleteDiscountCodeSuccess:
      return {
        ...state,
        isDeletingDiscountCode: false,
      };
    case ProgrammesActions.requestDeleteDiscountCodeFailure:
      return {
        ...state,
        isDeletingDiscountCode: false,
        errors: { ...state.errors, deleteDiscountCodeError: action.payload },
      };

    //clear discount code and discount validation messages
    case ProgrammesActions.clearCampaignDiscountCodeFromState:
      return {
        ...state,
        isLoading: { ...state.isLoading, fetchCampaignDiscountCode: false },
        campaign: state.campaign
          ? {
              ...state.campaign,
              currentDiscountCode: null,
            }
          : null,
        errors: {
          ...state.errors,
          fetchCampaignDiscountCode: null,
        },
      };

    case ProgrammesActions.clearProgrammeDiscountCodeFromState:
      return {
        ...state,
        currentDiscountCodes: null,
      };

    case ProgrammesActions.clearDiscountCodeApiCreateValidation:
      return {
        ...state,
        errors: {
          ...state.errors,
          createDiscountCodeError: null,
        },
      };
    case ProgrammesActions.clearDiscountCodeApiUpdateValidation:
      return {
        ...state,
        errors: {
          ...state.errors,
          updateDiscountCodeError: null,
        },
      };

    //get campaign discount code
    case ProgrammesActions.requestGetCampaignDiscountCodeAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, fetchCampaignDiscountCode: true },
        errors: { ...state.errors, fetchCampaignDiscountCode: null },
      };
    case ProgrammesActions.requestGetCampaignDiscountCodeSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, fetchCampaignDiscountCode: false },
        campaign: state.campaign
          ? {
              ...state.campaign,
              currentDiscountCode: action.payload
                ? transformGetDiscountCodePayload(action.payload)
                : null,
            }
          : null,
      };
    case ProgrammesActions.requestGetCampaignDiscountCodeFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, fetchCampaignDiscountCode: false },
        errors: { ...state.errors, fetchCampaignDiscountCode: action.payload },
      };
    //put association
    case ProgrammesActions.requestPutAssociationDiscountAttempt:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case ProgrammesActions.requestPutAssociationDiscountSuccess:
      return {
        ...state,
        isFetching: false,
        currentProgramme: state.currentProgramme &&
          state.currentProgramme.associations && {
            ...state.currentProgramme,
            associations: transformNewAssociation(
              state.currentProgramme.associations,
              action.payload
            ),
          },
      };
    case ProgrammesActions.requestPutAssociationDiscountFailure:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    //post payment
    case ProgrammesActions.requestPostPaymentAttempt:
      return {
        ...state,
        isCreatingPayment: true,
        createPaymentError: null,
        hasSubmittedPaymentRequest: false,
      };
    case ProgrammesActions.requestPostPaymentSuccess:
      return {
        ...state,
        currentProgramme: state.currentProgramme && {
          ...state.currentProgramme,
          transactions: state.currentProgramme.transactions
            ? [
                ...state.currentProgramme.transactions,
                transactionFromApi(action.payload),
              ]
            : [transactionFromApi(action.payload)],
        },
        isCreatingPayment: false,
        hasSubmittedPaymentRequest: true,
      };
    case ProgrammesActions.requestPostPaymentFailure:
      return {
        ...state,
        isCreatingPayment: false,
        createPaymentError: action.payload,
        hasSubmittedPaymentRequest: false,
      };
    //get payments
    case ProgrammesActions.requestGetProgrammePaymentsAttempt:
      return {
        ...state,
        isFetchingTransactions: true,
        fetchTransactionsError: null,
      };
    case ProgrammesActions.requestGetProgrammePaymentsSuccess:
      return {
        ...state,
        isFetchingTransactions: false,
        currentProgramme: state.currentProgramme && {
          ...state.currentProgramme,
          transactions: transactionsFromApi(action.payload),
        },
      };
    case ProgrammesActions.requestGetProgrammePaymentsFailure:
      return {
        ...state,
        isFetchingTransactions: false,
        fetchTransactionsError: action.payload,
      };
    //put payment approval
    case ProgrammesActions.requestPutPaymentApprovalAttempt:
      return {
        ...state,
        isApprovingTransaction: true,
        approveTransactionError: null,
      };
    case ProgrammesActions.requestPutPaymentApprovalSuccess:
      return {
        ...state,
        currentProgramme: state.currentProgramme &&
          state.currentProgramme.transactions && {
            ...state.currentProgramme,
            transactions: handleApproveTransaction(
              state.currentProgramme.transactions,
              action.payload
            ),
          },
        isApprovingTransaction: false,
      };
    case ProgrammesActions.requestPutPaymentApprovalFailure:
      return {
        ...state,
        isApprovingTransaction: false,
        approveTransactionError: action.payload,
      };
    //get agreement
    case ProgrammesActions.requestGetAgreementAttempt:
      return {
        ...state,
      };
    case ProgrammesActions.requestGetAgreementSuccess:
      return {
        ...state,
        currentProgramme: transformGetAgreementPayload(
          state.currentProgramme,
          action.payload
        ),
      };
    case ProgrammesActions.requestGetAgreementFailure:
      return {
        ...state,
      };
    //put reject payment
    case ProgrammesActions.requestPutPaymentRejectionAttempt:
      return {
        ...state,
        isRejectingTransaction: true,
        rejectTransactionError: null,
      };
    case ProgrammesActions.requestPutPaymentRejectionSuccess:
      return {
        ...state,
        currentProgramme: state.currentProgramme &&
          state.currentProgramme.transactions && {
            ...state.currentProgramme,
            transactions: handleRejectTransaction(
              state.currentProgramme.transactions,
              action.payload
            ),
          },
        isRejectingTransaction: false,
      };
    case ProgrammesActions.requestPutPaymentRejectionFailure:
      return {
        ...state,
        isRejectingTransaction: false,
        rejectTransactionError: action.payload,
      };
    //get invites
    case ProgrammesActions.requestGetInvitationsAttempt:
      return {
        ...state,
        isFetchingInvitations: true,
        error: null,
      };
    case ProgrammesActions.requestGetInvitationsSuccess:
      return {
        ...state,
        isFetchingInvitations: false,
        invitations: getInvitationsPaginatedPayload(
          action.payload,
          state.invitations?.content || null
        ),
      };
    case ProgrammesActions.requestGetInvitationsFailure:
      return {
        ...state,
        isFetchingInvitations: false,
        error: action.payload,
      };
    //update invitation
    case ProgrammesActions.requestPutInvitationAttempt:
      return {
        ...state,
        isUpdatingInvitation: true,
        updateInvitationError: null,
      };
    case ProgrammesActions.requestPutInvitationSuccess:
      return {
        ...state,
        isUpdatingInvitation: false,
      };
    case ProgrammesActions.requestPutInvitationFailure:
      return {
        ...state,
        isUpdatingInvitation: false,
        updateInvitationError: action.payload,
      };
    //update association
    case ProgrammesActions.requestPutAssociationAttempt:
      return {
        ...state,
        isUpdatingAssociation: true,
        updateAssociationError: null,
      };
    case ProgrammesActions.requestPutAssociationSuccess:
      return {
        ...state,
        isUpdatingAssociation: false,
      };
    case ProgrammesActions.requestPutAssociationFailure:
      return {
        ...state,
        isUpdatingAssociation: false,
        updateAssociationError: action.payload,
      };
    //get payment types
    case ProgrammesActions.requestGetPaymentTypesAttempt:
      return {
        ...state,
        isFetchingPaymentTypes: true,
        error: null,
      };
    case ProgrammesActions.requestGetPaymentTypesSuccess:
      return {
        ...state,
        paymentTypes: transformPaymentTypesPayload(action.payload),
        isFetchingPaymentTypes: false,
        error: null,
      };
    case ProgrammesActions.requestGetPaymentTypesFailure:
      return {
        ...state,
        isFetchingPaymentTypes: false,
        error: action.payload,
      };
    //get notifications
    case ProgrammesActions.requestGetProgrammeNotificationsAttempt:
      return {
        ...state,
        isFetchingNotifications: true,
        fetchNotificationsError: null,
      };
    case ProgrammesActions.requestGetProgrammeNotificationsSuccess:
      return {
        ...state,
        isFetchingNotifications: false,
        notifications: action.payload,
      };
    case ProgrammesActions.requestGetProgrammeNotificationsFailure:
      return {
        ...state,
        isFetchingNotifications: false,
        fetchNotificationsError: action.payload,
      };
    //put notifications
    case ProgrammesActions.requestPutProgrammeNotificationAttempt:
      return {
        ...state,
        isUpdatingNotification: true,
        updateNotificationError: null,
      };
    case ProgrammesActions.requestPutProgrammeNotificationSuccess:
      return {
        ...state,
        isUpdatingNotification: false,
        notifications: handleUpdateNotification(
          action.payload,
          state.notifications
        ),
      };
    case ProgrammesActions.requestPutProgrammeNotificationFailure:
      return {
        ...state,
        isUpdatingNotification: false,
        updateNotificationError: action.payload,
      };
    //patch programme state
    case ProgrammesActions.requestPatchProgrammeStateAttempt:
      return {
        ...state,
        isUpdatingState: true,
        updatingStateError: null,
      };
    case ProgrammesActions.requestPatchProgrammeStateSuccess:
      return {
        ...state,
        currentProgramme: state.currentProgramme && {
          ...state.currentProgramme,
          state: transformProgrammeStateFromAPI(action.payload),
        },
        isUpdatingState: false,
      };
    case ProgrammesActions.requestPatchProgrammeStateFailure:
      return {
        ...state,
        isUpdatingState: false,
        updatingStateError: action.payload,
      };
    //toggle filter group
    case ProgrammesActions.toggleFilterGroup:
      return {
        ...state,
        filterGroups: {
          ...state.filterGroups,
          [action.payload]: !state.filterGroups[action.payload],
        },
      };
    //get programme groups
    case ProgrammesActions.requestGetProgrammeGroupsAttempt:
      return {
        ...state,
        isFetchingProgrammeGroups: true,
        error: null,
      };
    case ProgrammesActions.requestGetProgrammeGroupsSuccess:
      return {
        ...state,
        programmeGroups: transformProgrammeGroupsFromAPI(action.payload),
        isFetchingProgrammeGroups: false,
        error: null,
      };
    case ProgrammesActions.requestGetProgrammeGroupsFailure:
      return {
        ...state,
        isFetchingProgrammeGroups: false,
        error: action.payload,
      };
    //post programme groups
    case ProgrammesActions.requestPostProgrammeGroupAttempt:
      return {
        ...state,
        isCreatingProgrammeGroup: true,
        createProgrammeGroupError: null,
      };
    case ProgrammesActions.requestPostProgrammeGroupSuccess:
      return {
        ...state,
        isCreatingProgrammeGroup: false,
        createProgrammeGroupError: null,
        programmeGroups: state.programmeGroups
          ? [
              ...state.programmeGroups,
              transformProgrammeGroupFromAPI(action.payload),
            ]
          : [transformProgrammeGroupFromAPI(action.payload)],
      };
    case ProgrammesActions.requestPostProgrammeGroupFailure:
      return {
        ...state,
        isCreatingProgrammeGroup: false,
        createProgrammeGroupError: action.payload,
      };
    //post programme image
    case ProgrammesActions.requestPostProgrammeImageAttempt:
      return {
        ...state,
        isUploadingImage: true,
        uploadImageError: null,
      };
    case ProgrammesActions.requestPostProgrammeImageSuccess:
      return {
        ...state,
        isUploadingImage: false,
        programmeFormBody: postImageFormReducer(state, action.payload, true),
      };
    case ProgrammesActions.requestPostProgrammeImageFailure:
      return {
        ...state,
        isUploadingImage: false,
        programmeFormBody: postImageFormReducer(state, action.payload, false),
        uploadImageError: action.payload.response,
      };
    //get campaign time zones
    case ProgrammesActions.requestGetCampaignTimeZonesAttempt:
      return {
        ...state,
        isFetchingCampaignTimeZones: true,
        fetchCampaignTimeZonesError: null,
      };
    case ProgrammesActions.requestGetCampaignTimeZonesSuccess:
      return {
        ...state,
        campaignTimeZones: action.payload,
        isFetchingCampaignTimeZones: false,
        fetchCampaignTimeZonesError: null,
      };
    case ProgrammesActions.requestGetCampaignTimeZonesFailure:
      return {
        ...state,
        isFetchingCampaignTimeZones: false,
        fetchCampaignTimeZonesError: action.payload,
      };
    //post campaign
    case ProgrammesActions.requestPostCampaignAttempt:
      return {
        ...state,
        isCreatingCampaign: true,
        createCampaignError: null,
      };
    case ProgrammesActions.requestPostCampaignSuccess:
      return {
        ...state,
        isCreatingCampaign: false,
        createCampaignError: null,
      };
    case ProgrammesActions.requestPostCampaignFailure:
      return {
        ...state,
        isCreatingCampaign: false,
        createCampaignError: action.payload,
      };
    //post campaign
    case ProgrammesActions.requestPutCampaignAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, putCampaign: true },
        errors: { ...state.errors, putCampaign: null },
      };
    case ProgrammesActions.requestPutCampaignSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, putCampaign: false },
      };
    case ProgrammesActions.requestPutCampaignFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, putCampaign: false },
        errors: { ...state.errors, putCampaign: action.payload },
      };
    //set campaign form page
    case ProgrammesActions.setCampaignFormPage:
      return {
        ...state,
        campaignFormPage: action.payload,
      };
    //set programme form
    case ProgrammesActions.setProgrammeForm:
      return {
        ...state,
        programmeFormBody: action.payload,
      };
    //set invitation form
    case ProgrammesActions.setInvitationForm:
      return {
        ...state,
        invitationFormBody: action.payload,
      };
    //set programme form page
    case ProgrammesActions.setProgrammeFormPage:
      return {
        ...state,
        programmeFormPage: action.payload,
      };
    //set invite influencer form page
    case ProgrammesActions.setInviteInfluencerFormPage:
      return {
        ...state,
        inviteInfluencerFormPage: action.payload,
      };
    //refresh invites
    case ProgrammesActions.setNeedsToRefreshInvitations:
      return {
        ...state,
        needsToRefreshInvitations: action.payload,
      };
    //refresh programme associations
    case ProgrammesActions.setNeedsToRefreshAssociations:
      return {
        ...state,
        needsRefreshAssociations: action.payload,
      };
    //clear programme form errors
    case ProgrammesActions.clearProgrammeFormErrors:
      return {
        ...state,
        createProgrammeError: null,
        updateProgrammeError: null,
      };
    case ProgrammesActions.setCommissionsForm:
      return {
        ...state,
        commissionsFormBody: action.payload,
      };
    case ProgrammesActions.setCampaignForm: {
      return {
        ...state,
        campaignFormBody: action.payload,
      };
    }
    case ProgrammesActions.setCampaignFormField: {
      return {
        ...state,
        campaignFormBody: {
          ...state.campaignFormBody,
          [action.payload.field]: action.payload.val,
        },
      };
    }
    //post campaign image
    case ProgrammesActions.requestPostCampaignImageAttempt:
      return {
        ...state,
        isUploadingCampaignImage: true,
        isUploadingCampaignImageError: null,
      };
    case ProgrammesActions.requestPostCampaignImageSuccess:
      return {
        ...state,
        isUploadingCampaignImage: false,
        campaignFormBody: postCampaignImageFormReducer(
          state,
          action.payload,
          true
        ),
      };
    case ProgrammesActions.requestPostCampaignImageFailure:
      return {
        ...state,
        isUploadingCampaignImage: false,
        campaignFormBody: postCampaignImageFormReducer(
          state,
          action.payload,
          false
        ),
        isUploadingCampaignImageError: action.payload.response,
      };
    case ProgrammesActions.setPaginationQueries:
      return {
        ...state,
        currentProgramme: state.currentProgramme
          ? {
              ...state.currentProgramme,
              queries: transformToQueries(
                action.payload,
                state.currentProgramme.queries
              ),
            }
          : null,
      };
    case ProgrammesActions.setCampaignPaginationQueries:
      return {
        ...state,
        campaign: state.campaign
          ? {
              ...state.campaign,
              queries: transformToCampaignQueries(
                action.payload,
                state.campaign.queries
              ),
            }
          : null,
      };
    case ProgrammesActions.clearPagination:
      return {
        ...state,
        currentProgramme: state.currentProgramme
          ? {
              ...state.currentProgramme,
              [action.payload]: null,
            }
          : null,
      };
    case ProgrammesActions.clearCampaignPagination:
      return {
        ...state,
        campaign: state.campaign
          ? {
              ...state.campaign,
              [action.payload]: null,
            }
          : null,
      };
    case ProgrammesActions.clearInviteErrors:
      return {
        ...state,
        bulkInvitesError: null,
        createInvitationError: null,
      };

    case ProgrammesActions.requestGetCampaignsAttempt:
      return {
        ...state,
        isFetchingCampaigns: true,
        isFetchingCampaignsError: false,
      };
    case ProgrammesActions.requestGetCampaignsFailure:
      return {
        ...state,
        isFetchingCampaigns: false,
        isFetchingCampaignsError: true,
      };
    case ProgrammesActions.requestGetCampaignsSuccess:
      return {
        ...state,
        isFetchingCampaigns: false,
        isFetchingCampaignsError: false,
        campaigns: action.payload,
      };
    case ProgrammesActions.requestGetCampaignAttempt:
      return {
        ...state,
        isFetchingCampaign: true,
        isFetchingCampaignError: false,
      };
    case ProgrammesActions.requestGetCampaignFailure:
      return {
        ...state,
        isFetchingCampaign: false,
        isFetchingCampaignError: true,
      };
    case ProgrammesActions.requestGetCampaignSuccess:
      return {
        ...state,
        isFetchingCampaign: false,
        isFetchingCampaignError: false,
        campaign: state.campaign
          ? {
              ...transformReadCampaignFromApi(action.payload.response),
              associations: action.payload.shouldClear
                ? null
                : state.campaign.associations,
              invitations: action.payload.shouldClear
                ? null
                : state.campaign.invitations,
              queries: state.campaign.queries,
              commissionsSummary: action.payload.shouldClear
                ? null
                : state.campaign.commissionsSummary,
              currentDiscountCode: null,
            }
          : {
              ...transformReadCampaignFromApi(action.payload.response),
              invitations: null,
              associations: null,
              commissionsSummary: null,
              queries: { associations: null, invitations: null },
              currentDiscountCode: null,
            },
      };
    case ProgrammesActions.requestDeleteCampaignAttempt:
      return {
        ...state,
        isDeletingCampaign: true,
      };
    case ProgrammesActions.requestDeleteCampaignFailure:
      return {
        ...state,
        isDeletingCampaign: false,
        isDeletingCampaignError: action.payload,
      };
    case ProgrammesActions.requestGetApplicationsAttempt:
      return {
        ...state,
        isFetchingProgrammeApplications: true,
        isErrorProgrammeApplications: false,
      };
    case ProgrammesActions.requestGetApplicationsFailure:
      return {
        ...state,
        isFetchingProgrammeApplications: false,
        isErrorProgrammeApplications: true,
      };
    case ProgrammesActions.requestGetApplicationsSuccess:
      return {
        ...state,
        isFetchingProgrammeApplications: false,
        isErrorProgrammeApplications: false,
        programmeApplications: transformPaginatedApplicationsFromApi(
          action.payload,
          state.programmeApplications?.content ?? null
        ),
      };
    case ProgrammesActions.clearProgrammeApplications:
      return {
        ...state,
        programmeApplications: null,
      };
    case ProgrammesActions.requestGetInvitableInfluencersAttempt:
      return {
        ...state,
        isFetchingInvitableInfluencers: true,
        isFetchingInvitableInfluencersError: false,
        error: null,
      };
    case ProgrammesActions.requestGetInvitableInfluencersSuccess:
      return {
        ...state,
        isFetchingInvitableInfluencers: false,
        invitableInfluencers: transformGetInvitableInfluencersPayload(
          action.payload,
          state.invitableInfluencers?.content || null
        ),
        error: null,
      };
    case ProgrammesActions.requestGetInvitableInfluencersFailure:
      return {
        ...state,
        isFetchingInvitableInfluencers: false,
        isFetchingInvitableInfluencersError: true,
        error: action.payload,
      };

    case ProgrammesActions.clearInvitableInfluencersPagination:
      return {
        ...state,
        invitableInfluencers: null,
      };
    case ProgrammesActions.requestPutRejectApplicationAttempt:
      return {
        ...state,
        isRejectingProgrammeApplication: true,
        isRejectingProgrammeApplicationSuccess: null,
      };
    case ProgrammesActions.requestPutRejectApplicationSuccess:
      return {
        ...state,
        isRejectingProgrammeApplication: false,
        isRejectingProgrammeApplicationSuccess: action.payload,
      };
    // Campaign Associations
    case ProgrammesActions.requestGetCampaignAssociationsAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          fetchCampaignAssociations: true,
        },
        errors: {
          ...state.errors,
          fetchCampaignAssociations: null,
        },
      };
    case ProgrammesActions.requestGetCampaignAssociationsSuccess:
      return {
        ...state,
        campaign: state.campaign && {
          ...state.campaign,
          associations: getCampaignAssociationsPaginatedPayload(
            action.payload,
            state.campaign?.associations?.content || null
          ),
        },
        isLoading: {
          ...state.isLoading,
          fetchCampaignAssociations: false,
        },
      };
    case ProgrammesActions.requestGetCampaignAssociationsFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          fetchCampaignAssociations: false,
        },
        errors: {
          ...state.errors,
          fetchCampaignAssociations: action.payload.content,
        },
      };
    // Campaign Invitations
    case ProgrammesActions.requestGetCampaignInvitationsAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          fetchCampaignInvitations: true,
        },
        errors: {
          ...state.errors,
          fetchCampaignInvitations: null,
        },
      };
    case ProgrammesActions.requestGetCampaignInvitationsSuccess:
      return {
        ...state,
        campaign: state.campaign && {
          ...state.campaign,
          invitations: getInvitationsPaginatedPayload(
            action.payload,
            state.campaign?.invitations?.content || null
          ),
        },
        isLoading: {
          ...state.isLoading,
          fetchCampaignInvitations: false,
        },
      };
    case ProgrammesActions.requestGetCampaignInvitationsFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          fetchCampaignInvitations: false,
        },
        errors: {
          ...state.errors,
          fetchCampaignInvitations: action.payload.content,
        },
      };
    // Campaign Summary
    case ProgrammesActions.requestGetCampaignCommissionsSummaryAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          fetchCampaignCommissionsSummary: true,
        },
      };
    case ProgrammesActions.requestGetCampaignCommissionsSummarySuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          fetchCampaignCommissionsSummary: false,
        },
        campaign: state.campaign && {
          ...state.campaign,
          commissionsSummary: transformCommissionsSummaryFromAPI(
            action.payload
          ),
        },
      };
    case ProgrammesActions.requestGetCampaignCommissionsSummaryFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          fetchCampaignCommissionsSummary: false,
        },
        errors: {
          ...state.errors,
          fetchCampaignCommissionsSummary: action.payload,
        },
      };
    case ProgrammesActions.resetCampaignFilters:
      return {
        ...state,
        campaign: state.campaign && {
          ...state.campaign,
          queries: {
            ...state.campaign.queries,
            [action.payload.field]: action.payload.queries,
          },
        },
      };
    case ProgrammesActions.requestGetProgrammePerformanceAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          programmePerformanceInfluencers: true,
        },
        errors: {
          ...state.errors,
          programmePerformanceInfluencers: null,
        },
      };
    case ProgrammesActions.requestGetProgrammePerformanceSuccess:
      return {
        ...state,
        programmePerformanceInfluencers: transformPaginatedProgrammePerformance(
          action.payload,
          state.programmePerformanceInfluencers?.content || null
        ),
        aggregatedProgrammePerformance:
          transformProgrammePerformanceDataFromAPI(
            action.payload.content.data.aggregate
          ),
        isLoading: {
          ...state.isLoading,
          programmePerformanceInfluencers: false,
        },
      };
    case ProgrammesActions.requestGetProgrammePerformanceFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          programmePerformanceInfluencers: false,
        },
        errors: {
          ...state.errors,
          programmePerformanceInfluencers: action.payload,
        },
      };
    case ProgrammesActions.requestGetProgrammePerformanceCSVAttempt:
      return {
        ...state,
        isFetchingCSV: true,
        fetchCSVError: null,
      };
    case ProgrammesActions.requestGetProgrammePerformanceCSVSuccess:
      return {
        ...state,
        isFetchingCSV: false,
        programmePerformanceCSV: action.payload,
        fetchCSVError: null,
      };
    case ProgrammesActions.requestGetProgrammePerformanceCSVFailure:
      return {
        ...state,
        isFetchingCSV: false,
        fetchCSVError: action.payload,
      };
    case ProgrammesActions.requestGetDiscountTrackingEnabledAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          discountTrackingEnabled: true,
        },
        errors: {
          ...state.errors,
          discountTrackingEnabled: null,
        },
        isDiscountTrackingEnabled: false,
      };
    case ProgrammesActions.requestGetDiscountTrackingEnabledSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          discountTrackingEnabled: false,
        },
        isDiscountTrackingEnabled: action.payload,
      };
    case ProgrammesActions.requestGetDiscountTrackingEnabledFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          discountTrackingEnabled: false,
        },
        errors: {
          ...state.errors,
          discountTrackingEnabled: action.payload,
        },
      };
    default:
      return state;
  }
};
