import React from 'react';
import { ExternalLink } from '../../common/Link';
import { Container, Text, Icon } from './Fallback.styles';
interface Props {
  pageName: string;
}

export const Fallback: React.FC<Props> = ({ pageName }) => {
  return (
    <Container data-testid="t_fallback">
      <Icon icon="exclamation-circle" />
      <Text>
        {`The ${pageName} page has encountered an unexpected error and our team has been notified.`}
        <br />
        {`Please refresh the page and try again.`}
        <br />
        {"If you'd like to tell us what happened please contact"}
        <br />
        <ExternalLink href={'mailto:DL-SocietyTech@thehutgroup.com'}>
          DL-SocietyTech@thehutgroup.com
        </ExternalLink>
      </Text>
    </Container>
  );
};
