import React from 'react';
import Pulses from '../../Pulses/Pulses';
import s from './LoadingView.styles';

export const LoadingView = () => {
  return (
    <s.Wrapper>
      <Pulses visible />
    </s.Wrapper>
  );
};
