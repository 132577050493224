import styled from 'styled-components';
import { theme } from '../../../styling/theme';
import { px2rem } from '../../../styling/sizing';

export const SearchContainer = styled.label<{ isMobile?: boolean }>`
  input {
    background-color: white;
    border: 1px solid ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    height: ${props => theme(props).SIZE.XLARGE};
    min-width: ${props =>
      props.isMobile ? theme(props).SIZE.SEARCH_WIDGET_WIDTH : 'auto'};
    ${props => props.isMobile && 'width: 100%'};
  }
`;
export const IconHolder = styled.span`
  position: absolute;
  right: ${px2rem(8)};
`;
export const SearchBox = styled.div<{ isMobile?: boolean }>`
  position: relative;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  ${props => props.isMobile && 'width: 100%'};
`;
