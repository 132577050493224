import React from 'react';
import { PulseBubble, PulseContainer } from './Pulses.styles';

const Pulses: React.FC<{ visible: boolean }> = ({ visible }) => (
  <>
    {visible && (
      <PulseContainer>
        <PulseBubble item={0} />
        <PulseBubble item={1} />
        <PulseBubble item={2} />
      </PulseContainer>
    )}
  </>
);

export default Pulses;
