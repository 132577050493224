import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { theme } from '../../../styling/theme';
import { px2rem } from '../../../styling/sizing';

export const LinkIcon = styled.div`
  border: ${px2rem(2)} solid white;
  border-radius: ${props => theme(props).LAYOUT.BORDER_RADIUS_CIRCLE};
  height: ${px2rem(30)};
  width: ${px2rem(30)};
  min-width: ${px2rem(30)};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
`;

export const LinkText = styled.span`
  color: white;
  margin-left: ${props => theme(props).SIZE.XSMALL};
  white-space: nowrap;
`;

export const LinkContainer = styled.span<{ isMobile: boolean }>`
  color: white;
  display: flex;
  align-items: center;
  list-style: none;
  padding: ${props => `${theme(props).SIZE.SMALL} ${theme(props).SIZE.SMALL}`};
  font-size: ${props =>
    props.isMobile
      ? theme(props).TYPOGRAPHY.BODY_TEXT
      : theme(props).TYPOGRAPHY.SMALL};
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
    transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};
  }
`;

export const SkipToMainContent = styled.button`
  all: unset;
  left: -100vw;
  position: fixed;
  top: ${props => theme(props).SIZE.XSMALL};
  background-color: white;
  color: ${props => theme(props).COLOUR.TEXT_STANDARD};
  padding: ${props =>
    `${theme(props).SIZE.XSMALL} ${theme(props).SIZE.XSMALL}`};
  border-radius: ${props => theme(props).LAYOUT.BORDER_RADIUS};

  &:focus {
    left: ${props => theme(props).SIZE.XSMALL};
  }
`;

const navItemStyles = css`
  color: white;
  display: block;

  &:hover,
  &:focus {
    background-color: ${props => theme(props).COLOUR.BACKGROUND_HIGHLIGHT};
  }

  &.selected,
  &:hover,
  &:focus {
    ${LinkIcon} {
      opacity: 1;
      background-color: white;
      color: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
      transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};
    }
  }
`;

export const RouterLink = styled(NavLink)`
  ${navItemStyles}
  list-style: none;
  text-decoration: none;
`;

export const MenuButton = styled.button`
  ${navItemStyles}
  all: unset;
  cursor: pointer;
  width: 100%;
  background: none;
  border: none;
`;
