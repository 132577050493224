import { PaginatedMap } from '../../types';
import {
  LevelPermissions,
  LevelRoles,
  ProfileUser,
  ProfileUserApiModel,
} from './store';

import { Paginated, Pagination } from '../../utils/pageControllers.utils';

interface TransformToPaginated {
  response: {
    users: ProfileUserApiModel[];
    pagination: Pagination;
  };
  currentContent: PaginatedMap<ProfileUser> | null;
}

export const transformProfileUsersFromApi = (
  users: ProfileUserApiModel[]
): ProfileUser[] =>
  users.map(user => {
    const transformedRoles: LevelRoles = {
      global: user.roles.global,
      programmeGroup: user.roles.programme_group,
      programme: user.roles.programme,
    };
    const transformedPermissions: LevelPermissions = {
      global: user.permissions.global,
      programmeGroup: user.permissions.programme_group,
      programme: user.permissions.programme,
    };
    return {
      keycloakId: user.keycloak_id,
      username: user.username,
      name: `${user.first_name} ${user.last_name}`,
      createdAt: user.created_at,
      roles: transformedRoles,
      permissions: transformedPermissions,
    };
  });

export const transformToPaginated = ({
  response,
  currentContent,
}: TransformToPaginated): Paginated<ProfileUser> => {
  const shouldClear = null;
  const { pagination, users } = response;
  const contentPageMap: PaginatedMap<ProfileUser> =
    currentContent && !shouldClear
      ? currentContent.set(pagination.page, transformProfileUsersFromApi(users))
      : new Map().set(0, transformProfileUsersFromApi(users));

  return {
    pagination: pagination,
    content: contentPageMap,
  };
};
