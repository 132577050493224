import dayjs from 'dayjs';
import { Withdrawal } from '../../components/Payments/Withdrawals/types';
import { PaginatedMap } from '../../types';
import { paginationTransformer } from '../../utils/pageControllers.utils';
import {
  CommissionDetails,
  CommissionDetailsAPI,
  CommissionItem,
  CommissionItemAPI,
  Invoice,
  InvoiceAPI,
  PaginatedInvoicesAPI,
  PaginatedWithdrawalsAPI,
  Payment,
  PaymentApproval,
  PaymentApprovalAPIModel,
  PaymentClearance,
  PaymentClearanceAPIModel,
  PaymentIds,
  PaymentIdsBody,
  VatSyncOverview,
  VatSyncOverviewAPI,
  WithdrawalAPI,
  WithdrawalToSelfbillMapping,
  WithdrawalToSelfbillMappingAPIModel,
  WithdrawalToSelfbillMappingPaginatedAPIModel,
  WithdrawalToSelfbillMappingPaginated,
} from './store';

export const transformSelectedPayments = (
  currentSelected: Payment[],
  payment: Payment
): Payment[] => {
  if (currentSelected.includes(payment)) {
    const spliced = [...currentSelected];
    spliced.splice(spliced.indexOf(payment), 1);
    return spliced;
  }

  return [...currentSelected, payment];
};

export const transformPaymentApprovalFromResponse = (
  approval: PaymentApprovalAPIModel
): PaymentApproval => {
  return {
    transactionId: approval.transaction_id,
    approvedById: approval.approved_by_marketer_id,
    approvedDate: approval.approved_date,
    approvedByFirstName: approval.approved_by_first_name,
    approvedByLastName: approval.approved_by_last_name,
  };
};

export const transformPaymentIdsToPaymentBody = (
  paymentIds: PaymentIds
): PaymentIdsBody => {
  return {
    payment_ids: paymentIds.paymentIds,
  };
};

export const transformFixedPaymentVatAdjustment = (
  approval: VatSyncOverviewAPI
): VatSyncOverview => {
  return {
    paymentId: approval.payment_id,
    paymentAmount: approval.payment_amount,
    vatAmount: approval.vat_amount,
  };
};

export const transformPaymentClearanceFromResponse = (
  clearance: PaymentClearanceAPIModel
): PaymentClearance => {
  return {
    transactionId: clearance.transaction_id,
    clearedDate: clearance.cleared_date,
  };
};

export const transformWithdrawalToSelfbillMappingsPaginatedFromResponse = (
  response: WithdrawalToSelfbillMappingPaginatedAPIModel
): WithdrawalToSelfbillMappingPaginated => {
  return {
    selfbillMappings: transformWithdrawalToSelfbillMappingsFromResponse(
      response.selfbill_mappings
    ),
    pagination: response.pagination,
  };
};

export const transformWithdrawalToSelfbillMappingsFromResponse = (
  response: WithdrawalToSelfbillMappingAPIModel[]
): WithdrawalToSelfbillMapping[] => {
  return response.map(withdrawalMapping => {
    return transformWithdrawalToSelfbillMappingFromResponse(withdrawalMapping);
  });
};

export const transformWithdrawalToSelfbillMappingFromResponse = (
  withdrawalMappingAPI: WithdrawalToSelfbillMappingAPIModel
): WithdrawalToSelfbillMapping => {
  return {
    withdrawalId: withdrawalMappingAPI.withdrawal_id,
    selfbillId: withdrawalMappingAPI.selfbill_id,
  };
};

export const transformCommissionFromResponse = (
  commission: CommissionDetailsAPI
): CommissionDetails => {
  return {
    id: commission.id,
    programmeId: commission.programme_id,
    influencerId: commission.influencer_id,
    programmeName: commission.programme_name,
    programmeCurrency: commission.programme_currency,
    influencerName: commission.influencer_name,
    influencerAvatar: commission.influencer_avatar,
    status: commission.status,
    customerType: commission.customer_type,
    createdAt: commission.created_at,
    orderIdentifier: commission.order_identifier,
    assistedSale: commission.assisted_sale,
    campaignId: commission.campaign_id,
    items: commission.items.map(item =>
      transformCommissionItemsFromResponse(item)
    ),
    autoApprovalErrors: commission.auto_approval_errors,
    saleSource: commission.sale_source,
  };
};

export const transformCommissionItemsFromResponse = (
  commissionList: CommissionItemAPI
): CommissionItem => {
  return {
    commissionItemId: commissionList.commission_item_id,
    sku: commissionList.sku,
    productName: commissionList.product_name,
    category: commissionList.category,
    subcategory: commissionList.subcategory,
    value: commissionList.value,
    commission: commissionList.commission,
    status: commissionList.status,
    desiredStatus: commissionList.desired_status,
    autoApproveStatus: commissionList.auto_approve_status,
    voucherCode: commissionList.voucher_code,
    rejectionReason: commissionList.rejection_reason,
  };
};

export const transformPaymentsToPayload = (payments: Payment[]): string[] => {
  if (!payments.length) return [];

  return payments.map(payment => payment.transaction_id);
};

export const transformInvoiceFromApi = (invoice: InvoiceAPI): Invoice => {
  return {
    id: invoice.id,
    partnerizeInvoiceId: invoice.partnerize_invoice_id,
    programmeId: invoice.programme_id,
    campaignId: invoice.campaign_id,
    invoiceCreatedAt: dayjs(invoice.invoice_created_at),
    status: invoice.status,
    amount: invoice.amount,
    currency: invoice.currency,
  };
};

export const transformInvoicesFromApi = (invoices: InvoiceAPI[]): Invoice[] => {
  return invoices.map(inv => transformInvoiceFromApi(inv));
};

export const transformPaginatedInvoicesFromApi = (
  invoices: PaginatedInvoicesAPI,
  currentContent: PaginatedMap<Invoice> | null
) => {
  return paginationTransformer<InvoiceAPI, Invoice>(
    invoices.pagination,
    invoices.invoices,
    currentContent,
    transformInvoicesFromApi
  );
};

export const transformWithdrawalFromApi = (
  withdrawal: WithdrawalAPI
): Withdrawal => {
  return {
    transactionId: withdrawal.transaction_id,
    influencerId: withdrawal.influencer_id,
    influencerName: withdrawal.influencer_name,
    influencerAvatar: withdrawal.influencer_avatar,
    amount: withdrawal.amount,
    currency: withdrawal.currency,
    date: dayjs(withdrawal.created_at),
    selfbillId: withdrawal.selfbill_id,
  };
};

export const transformWithdrawalsFromApi = (
  withdrawals: WithdrawalAPI[]
): Withdrawal[] => {
  return withdrawals.map(w => transformWithdrawalFromApi(w));
};

export const transformPaginatedWithdrawalsFromApi = (
  withdrawals: PaginatedWithdrawalsAPI,
  currentContent: PaginatedMap<Withdrawal> | null
) => {
  return paginationTransformer<WithdrawalAPI, Withdrawal>(
    withdrawals.pagination,
    withdrawals.withdrawals,
    currentContent,
    transformWithdrawalsFromApi
  );
};
