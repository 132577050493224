import React, { lazy, ReactNode } from 'react';
const Campaign = lazy(() => import('../Campaign'));
const CampaignInviteInfluencer = lazy(
  () => import('../Campaign/CampaignInviteInfluencer')
);
const Dashboard = lazy(() => import('../Dashboard'));
const Discovery = lazy(() => import('../Discovery'));
const Influencer = lazy(() => import('../Influencer/Influencer'));
const NotificationSettings = lazy(() => import('../NotificationSettings'));
const Commission = lazy(() => import('../Payments/Commission/Commission'));
const Payments = lazy(() => import('../Payments'));
const Permissions = lazy(() => import('../Permissions'));
const Programme = lazy(() => import('../Programme'));
const ProgrammeGroups = lazy(() => import('../ProgrammeGroups'));
const Programmes = lazy(() => import('../Programmes'));
const Instagram = lazy(() => import('../Social/Instagram/Instagram'));
const Youtube = lazy(() => import('../Social/Youtube/Youtube'));
const Translations = lazy(() => import('../Translations'));
const Watchman = lazy(() => import('../Watchman'));
const Agencies = lazy(() => import('../Agencies'));
const AgencyApplicationView = lazy(
  () => import('../Agencies/AgencyApplicationView')
);
const ProgrammeContentDownload = lazy(
  () => import('../Social/ProgrammeContentDownload/ProgrammeContentDownload')
);

const AgencyView = lazy(() => import('../Agencies/AgencyView'));

export interface AppRoute {
  path: string;
  component: ReactNode | null;
  exact?: boolean;
  pageName?: string;
  redirect?: {
    from: string;
    to: string;
    exact: boolean;
  };
  shouldShow: boolean;
}

export interface RoutePairs {
  [index: string]: AppRoute | null;
}

const standardRoutes: RoutePairs = {
  redirectInstagramToDefaultTab: {
    path: '',
    component: null,
    redirect: {
      from: '/influencers/:influencerId/social/instagram',
      to: '/influencers/:influencerId/social/instagram/details',
      exact: true,
    },
    shouldShow: true,
  },
  redirectCampaignInstagramToDefaultTab: {
    path: '',
    component: null,
    redirect: {
      from: '/programmes/:programmeId/campaigns/:campaignId/insights/instagram',
      to: '/programmes/:programmeId/campaigns/:campaignId/insights/instagram/content',
      exact: true,
    },
    shouldShow: window._env.canSeeCampaigns,
  },
  redirectProgrammeInstagramToDefaultTab: {
    path: '',
    component: null,
    redirect: {
      from: '/programmes/:programmeId/insights/instagram',
      to: '/programmes/:programmeId/insights/instagram/content',
      exact: true,
    },
    shouldShow: true,
  },
  dashboard: {
    path: '/',
    component: <Dashboard />,
    exact: true,
    pageName: 'Dashboard',
    shouldShow: true,
  },
  discovery: {
    path: '/discovery',
    component: <Discovery />,
    exact: true,
    pageName: 'Discovery',
    shouldShow: true,
  },
  programmes: {
    path: '/programmes',
    component: <Programmes />,
    exact: true,
    pageName: 'Programmes',
    shouldShow: true,
  },
  programmeGroups: {
    path: '/programme-groups',
    component: <ProgrammeGroups />,
    exact: true,
    pageName: 'Programme Groups',
    shouldShow: true,
  },
  campaignInstagram: {
    path: '/programmes/:programmeId/campaigns/:campaignId/insights/instagram/:tab',
    component: <Instagram />,
    exact: true,
    pageName: 'Campaign Instagram',
    shouldShow: window._env.canSeeCampaigns,
  },
  programmeInstagram: {
    path: '/programmes/:programmeId/insights/instagram/:tab',
    component: <Instagram />,
    exact: true,
    pageName: 'Programme Instagram',
    shouldShow: true,
  },
  influencerInstagram: {
    path: '/influencers/:influencerId/social/instagram/:tab',
    component: <Instagram />,
    exact: true,
    pageName: 'Influencer Instagram',
    shouldShow: true,
  },
  campaignYoutube: {
    path: '/programmes/:programmeId/campaigns/:campaignId/insights/youtube',
    component: <Youtube />,
    exact: true,
    pageName: 'Campaign Youtube',
    shouldShow: window._env.canSeeCampaigns,
  },
  programmeYoutube: {
    path: '/programmes/:programmeId/insights/youtube',
    component: <Youtube />,
    exact: true,
    pageName: 'Programme Youtube',
    shouldShow: true,
  },
  programme: {
    path: '/programmes/:programmeId/:tab',
    component: <Programme />,
    exact: true,
    pageName: 'Programme',
    shouldShow: true,
  },
  influencerYoutube: {
    path: '/influencers/:influencerId/social/youtube',
    component: <Youtube />,
    exact: true,
    pageName: 'Influencer YouTube',
    shouldShow: true,
  },
  influencer: {
    path: '/influencers/:influencerId/:tab',
    component: <Influencer />,
    exact: true,
    pageName: 'Influencer',
    shouldShow: true,
  },
  redirectPaymentsToDefaultTab: {
    path: '',
    component: null,
    redirect: {
      from: '/payments',
      to: '/payments/payments',
      exact: true,
    },
    shouldShow: true,
  },
  payments: {
    path: '/payments/:tab',
    component: <Payments />,
    exact: true,
    pageName: 'Payments',
    shouldShow: true,
  },
  commission: {
    path: '/payments/commissions/:id',
    component: <Commission />,
    shouldShow: true,
    exact: true,
    pageName: 'Commission',
  },
  permissions: {
    path: '/permissions',
    component: <Permissions />,
    exact: true,
    pageName: 'Permissions',
    shouldShow: true,
  },
  translations: {
    path: '/translations',
    component: <Translations />,
    exact: true,
    pageName: 'Translations',
    shouldShow: true,
  },
  notifications: {
    path: '/notifications',
    component: <NotificationSettings />,
    exact: true,
    pageName: 'Notifications',
    shouldShow: true,
  },
  redirectProgrammesToDefaultTab: {
    path: '',
    component: null,
    redirect: {
      from: '/programmes/:programmeId',
      to: '/programmes/:programmeId/details',
      exact: true,
    },
    shouldShow: true,
  },
  redirectInfluencersToDefaultTab: {
    path: '',
    component: null,
    redirect: {
      from: '/influencers/:influencerId',
      to: '/influencers/:influencerId/details',
      exact: true,
    },
    shouldShow: true,
  },
  watchman: {
    path: '/watchman/scheduled_task',
    component: <Watchman />,
    exact: true,
    pageName: 'Watchman',
    shouldShow: true,
  },
  campaignInviteInfluencer: {
    path: '/programmes/:programmeId/campaigns/:campaignId/invite-influencers',
    component: <CampaignInviteInfluencer />,
    exact: true,
    pageName: 'Invite Influencers',
    shouldShow: window._env.canSeeCampaigns,
  },
  campaigns: {
    path: '/programmes/:programmeId/campaigns/:campaignId/:tab',
    component: <Campaign />,
    exact: true,
    pageName: 'Campaigns',
    shouldShow: window._env.canSeeCampaigns,
  },
  redirectCampaignToDefaultTab: {
    path: '',
    component: null,
    redirect: {
      from: '/programmes/:programmeId/campaigns/:campaignId',
      to: '/programmes/:programmeId/campaigns/:campaignId/details',
      exact: true,
    },
    shouldShow: window._env.canSeeCampaigns,
  },
  redirectInfluencersToDiscovery: {
    path: '',
    component: null,
    redirect: {
      from: '/influencers',
      to: '/discovery',
      exact: true,
    },
    shouldShow: true,
  },
  agencies: {
    path: '/agencies/:tab',
    component: <Agencies />,
    exact: true,
    pageName: 'Agencies',
    shouldShow: window._env.canSeeAgencies,
  },
  agencyView: {
    path: '/agencies/agency/:agencyId/:tab',
    component: <AgencyView />,
    exact: true,
    pageName: 'Agency',
    shouldShow: window._env.canSeeAgencies,
  },
  singleAgencyApplication: {
    path: '/agencies/agency-application/:id/details',
    component: <AgencyApplicationView />,
    exact: true,
    pageName: 'Agency Application',
    shouldShow: window._env.canSeeAgencies,
  },
  redirectToAgency: {
    path: '',
    component: null,
    redirect: {
      from: '/agencies',
      to: '/agencies/agencies-list',
      exact: true,
    },
    shouldShow: window._env.canSeeAgencies,
  },
  fileDownload: {
    path: '/download/:fileId/:keycloakId/',
    component: <ProgrammeContentDownload />,
    exact: true,
    shouldShow: true,
  },
};

export const appRoutes = standardRoutes;
