import {
  CampaignInvitationsQuery,
  CampaignInvitationsQueryFilters,
} from './store';

const defaultPageLimits = [15, 30, 60];

const defaultCampaignInvitationsQueryFilters: CampaignInvitationsQueryFilters =
  {
    invitation_date: null,
    invited_by: null,
    invitation_status: null,
  };

const defaultCampaignInvitationsQuery: CampaignInvitationsQuery = {
  ...defaultCampaignInvitationsQueryFilters,
  name: null,
  page: 0,
  limit: defaultPageLimits[0],
};

export const CampaignsDefaults = {
  CampaignInvitationsQueryFilters: defaultCampaignInvitationsQueryFilters,
  CampaignInvitationsQuery: defaultCampaignInvitationsQuery,
  PageLimits: defaultPageLimits,
};
