import React, { createContext } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Breakpoint } from '../styling/breakpoints';

interface ResponsiveInfo {
  layout: Breakpoint;
}

const getLayout = (): Breakpoint => {
  if (!window?.matchMedia) {
    return Breakpoint.DESKTOP;
  }

  if (window.matchMedia(`(max-width: ${Breakpoint.MOBILE}px)`).matches) {
    return Breakpoint.MOBILE;
  }
  if (
    !window.matchMedia(`(max-width: ${Breakpoint.MOBILE}px)`).matches &&
    window.matchMedia(`(max-width: ${Breakpoint.TABLET}px)`).matches
  ) {
    return Breakpoint.TABLET;
  }
  return Breakpoint.DESKTOP;
};
const initialState: ResponsiveInfo = {
  layout: getLayout(),
};

export const ResponsiveContext = createContext<ResponsiveInfo>(initialState);

export const ResponsiveProvider: React.FC = ({ children }) => {
  const [info, setInfo] = useState<ResponsiveInfo>(initialState);

  const updateLayout = () => {
    const newLayout = getLayout();
    setInfo({ ...info, layout: newLayout });
  };

  useEffect(() => {
    window.addEventListener('resize', updateLayout);
    return () => {
      window.removeEventListener('resize', updateLayout);
    };
  });

  return (
    <ResponsiveContext.Provider value={info}>
      {children}
    </ResponsiveContext.Provider>
  );
};

export const useResponsive = () => {
  return useContext(ResponsiveContext);
};
