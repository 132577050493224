import React, { useEffect, useState, useCallback } from 'react';
import environment from '../environments/environment';
import { initiateAuthInstance, getKeycloakInstance } from './keycloak-init';
import { useStore } from '../store/state';
import {
  SetKeycloakProfile,
  SetRoles,
  SetKeycloakId,
  RequestGetProfileTokenAsync,
} from '../store/User/actions';
import { RoleMap } from '../store/User/store';
import { LoadingPage } from '../components/common/LoadingPage';

interface Props {
  children: JSX.Element | JSX.Element[];
}
export const AuthInit: React.FC<Props> = (props: Props) => {
  const [{ userState }, dispatch] = useStore();
  const [authenticated, setAuthenticated] = useState(false);
  const hasProfileToken = window.profileToken;

  const onAuthSuccess = useCallback((): void => {
    setAuthenticated(true);
    const kc = getKeycloakInstance();
    kc!
      .loadUserProfile()
      .then(profile => {
        SetKeycloakProfile(dispatch, profile);
        SetKeycloakId(dispatch, kc!.subject!);
        const roles: RoleMap = {
          marketer: kc!.hasRealmRole(environment.marketerRole),
          approver: kc!.hasRealmRole(environment.approverRole),
          admin: kc!.hasRealmRole(environment.adminRole),
          permissionManager: kc!.hasRealmRole(
            environment.permissionManagerRole
          ),
        };
        SetRoles(dispatch, roles);
        RequestGetProfileTokenAsync(dispatch).catch(e => {
          void e;
        });
      })
      .catch(e => void e);
  }, [dispatch]);

  const onAuthRefresh = useCallback(async () => {
    await RequestGetProfileTokenAsync(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (!hasProfileToken && authenticated) {
      setAuthenticated(false);
    }
  }, [hasProfileToken, authenticated]);

  useEffect(() => {
    if (environment.authConfig && !authenticated) {
      initiateAuthInstance(
        environment.authConfig,
        onAuthSuccess,
        () => setAuthenticated(false),
        onAuthRefresh
      );
    } else {
      setAuthenticated(true);
    }
  }, [authenticated, onAuthSuccess, onAuthRefresh, setAuthenticated]);

  const handleDisplay = () => {
    if (userState.isFetching.permissions) {
      return <LoadingPage />;
    } else if (userState.fetchError) {
      return (
        <>Could not get response from API: {userState.fetchError.message}</>
      );
    } else {
      return (
        <>
          {authenticated && userState.keycloakProfile && hasProfileToken
            ? props.children
            : null}
        </>
      );
    }
  };

  return handleDisplay();
};
