import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

const Spinning = keyframes`
   0% {
     stroke-dashoffset: 0;
     stroke-dasharray: 150.6 100.4;
   }
   50% {
     stroke-dasharray: 1 250;
   }
   100% {
     stroke-dashoffset: 502;
     stroke-dasharray: 150.6 100.4;
   }
`;

const AnimatedSVG = styled.svg`
  & > circle {
    animation: 2s linear ${Spinning} infinite;
  }
`;

interface SpinnerProps {
  size?: number;
  testId?: string;
  colour?: string;
}

export const Spinner: FC<SpinnerProps> = ({
  size = 30,
  testId,
  colour = '#999999',
}) => {
  return (
    <AnimatedSVG
      data-testid={testId}
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        r="40"
        stroke={colour}
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
      ></circle>
    </AnimatedSVG>
  );
};
