export { WatchmanReducer } from './reducer';

export * from './actions';

export interface WatchmanAPIModel {
  name: string;
  description: string;
  status: boolean;
  updatedAt: string;
}

export interface Task {
  name: string;
  status: string;
}

export interface WatchmanState {
  tasks: Task[] | null;

  isLoading: {
    getTasks: boolean;
    putTasks: boolean;
  };
  errors: {
    getTasks: string | null;
    putTasks: string | null;
  };
}

export const defaultWatchmanState: WatchmanState = {
  tasks: null,

  isLoading: {
    getTasks: false,
    putTasks: false,
  },
  errors: {
    getTasks: null,
    putTasks: null,
  },
};

export enum WatchmanActions {
  requestGetWatchmanTasksAttempt = 'REQUEST_GET_WATCHMAN_TASKS_ATTEMPT',
  requestGetWatchmanTasksSuccess = 'REQUEST_GET_WATCHMAN_TASKS_SUCCESS',
  requestGetWatchmanTasksFailure = 'REQUEST_GET_WATCHMAN_TASKS_FAILURE',

  requestPutWatchmanTasksAttempt = 'REQUEST_PUT_WATCHMAN_TASKS_ATTEMPT',
  requestPutWatchmanTasksSuccess = 'REQUEST_PUT_WATCHMAN_TASKS_SUCCESS',
  requestPutWatchmanTasksFailure = 'REQUEST_PUT_WATCHMAN_TASKS_FAILURE',
}

export enum WatchmanErrors {
  requestGetWatchmanTasksError = 'An error occurred while fetching Tasks.',
  requestPutWatchmanTasksError = 'An error occurred while updating Tasks.',
}
