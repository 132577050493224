import React, { useState } from 'react';
import { Input } from '../Input';
import { IconButton } from '../IconButton';
import { IconHolder, SearchBox, SearchContainer } from './Search.styles';

export interface SearchProps {
  placeholder?: string;
  onChange?: (val: string) => any;
  handleClear?: () => any;
  isMobile?: boolean;
  searchValue: string;
  ariaLabel?: string;
  testId?: string;
}

export const Search: React.FC<SearchProps> = ({
  placeholder,
  onChange,
  isMobile,
  handleClear,
  searchValue,
  ariaLabel,
  testId = 't_search_bar_input',
}) => {
  const [isSearching, setIsSearching] = useState<string>('');
  const onChangeEvent = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    !!onChange && onChange(e.currentTarget.value);
    setIsSearching(e.currentTarget.value);
  };

  const onClear = (e: React.MouseEvent) => {
    e.preventDefault();
    !!handleClear && handleClear();
    setIsSearching('');
  };

  return (
    <SearchBox isMobile={isMobile}>
      <SearchContainer
        tab-index="0"
        isMobile={isMobile}
        data-testid={'t_search_bar'}
      >
        <Input
          type="text"
          name="Search"
          placeholder={placeholder}
          onChange={onChangeEvent}
          onInput={onChangeEvent}
          value={searchValue}
          aria-label={ariaLabel}
          data-testid={testId}
        />
        <IconHolder>
          {isSearching ? (
            !!handleClear && (
              <IconButton
                icon={'times'}
                onClick={onClear}
                tooltip={'Clear'}
                variant={'borderless-alt'}
                data-testid={'t_search_clear_btn'}
              />
            )
          ) : (
            <IconButton
              icon={'search'}
              variant={'borderless-alt'}
              disabled={true}
              aria-disabled={true}
              aria-label={'Search'}
              data-testid={'t_search_confirm_btn'}
            />
          )}
        </IconHolder>
      </SearchContainer>
    </SearchBox>
  );
};
