export * from './actions';
export * from './reducer';

export * from './types';

import { AgenciesState } from './types';

export const defaultAgenciesState: AgenciesState = {
  paginatedAgencies: null,
  paginatedAgencyApplications: null,
  agencyApplication: null,
  agencyOptions: null,
  currentAgency: null,
  status: null,
  agencyInfluencers: null,

  isLoading: {
    currentAgency: false,
    agenciesList: false,
    agencyApplication: false,
    paginatedAgencyApplications: false,
    agencyOptions: false,
    status: false,
    agencyInfluencers: false,
  },
  error: {
    agencyApplication: null,
    paginatedAgencyApplications: null,
    currentAgency: null,
    agenciesList: null,
    agencyOptions: null,
    status: null,
    agencyInfluencers: null,
  },
  agenciesQuery: {
    page: 0,
    limit: 15,
  },
  agencyApplicationsQuery: {
    page: 0,
    limit: 15,
  },
};
