export const parseJws = <T>(token: string): T => {
  let jwsPayload = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');

  switch (jwsPayload.length % 4) {
    case 0:
      break;
    case 2:
      jwsPayload += '==';
      break;
    case 3:
      jwsPayload += '=';
      break;
    default:
      throw 'Invalid token';
  }

  const jsonPayload = decodeURIComponent(escape(atob(jwsPayload)));
  return JSON.parse(jsonPayload);
};

export const setProfileToken = (token: string) => {
  window.profileToken = token;
};
