import { captureException } from '@sentry/react';
import { DispatchFn } from '../../store/state';
import client, { Microservice } from '../../utils/http.utils';
import { queryBuilder } from '../../utils/pageControllers.utils';
import { associationToApi, postCampaignPayload } from './payload';
import {
  ApplicationQuery,
  ApproveTransactionApiModel,
  Association,
  AssociationAPIModel,
  CampaignFormBody,
  CampaignImage,
  CampaignSummaryAPI,
  CommissionsFormBody,
  CreatePaymentRequest,
  CreateProgrammeGroup,
  CreateProgrammeRequest,
  FailurePayload,
  ImageUploadAPIResponse,
  Invitation,
  InvitationFormBody,
  Notification,
  PaginatedApplicationsAPI,
  PaginatedAssociationApi,
  PostCampaignImageResponsePayload,
  PostProgrammeImageResponsePayload,
  Programme,
  ProgrammeFormBody,
  ProgrammeGroupAPIResponse,
  ProgrammeImage,
  ProgrammeQuery,
  BulkInviteBody,
  ReadCampaignAPI,
  InfluencerQuery,
  RejectTransactionApiModel,
  TransactionApiModel,
  TransactionStatus,
  UpdateAssociationBody,
  UpdateInvitationBody,
  UpdateProgrammeRequest,
  CreateInvitationResponse,
  BulkInviteResponse,
  PaginatedCampaignAssociationAPI,
  CampaignAssociationsQuery,
  CampaignInvitationsQuery,
  InvitationRequest,
  DiscountCodeApi,
  WriteDiscountCode,
  DeleteDiscountBody,
  ProgrammePerformanceQueries,
} from './store';
import { transformCampaignListItemsFromAPI } from './transformer';
import { CampaignsDefaults } from './defaults';
import { CustomError, apiError } from '../../types/error.types';

export enum ProgrammesActions {
  clearInvitationTokens = 'CLEAR_INVITATION_TOKENS',
  clearInvitations = 'CLEAR_INVITATIONS',

  requestGetProgrammeAttempt = 'REQUEST_GET_PROGRAMME_ATTEMPT',
  requestGetProgrammeSuccess = 'REQUEST_GET_PROGRAMME_SUCCESS',
  requestGetProgrammeFailure = 'REQUEST_GET_PROGRAMME_FAILURE',

  requestPostProgrammeAttempt = 'REQUEST_POST_PROGRAMME_ATTEMPT',
  requestPostProgrammeSuccess = 'REQUEST_POST_PROGRAMME_SUCCESS',
  requestPostProgrammeFailure = 'REQUEST_POST_PROGRAMME_FAILURE',

  requestPutProgrammeAttempt = 'REQUEST_PUT_PROGRAMME_ATTEMPT',
  requestPutProgrammeSuccess = 'REQUEST_PUT_PROGRAMME_SUCCESS',
  requestPutProgrammeFailure = 'REQUEST_PUT_PROGRAMME_FAILURE',

  requestGetProgrammesAttempt = 'REQUEST_GET_PROGRAMMES_ATTEMPT',
  requestGetProgrammesSuccess = 'REQUEST_GET_PROGRAMMES_SUCCESS',
  requestGetProgrammesFailure = 'REQUEST_GET_PROGRAMMES_FAILURE',

  requestPostInvitationAttempt = 'REQUEST_POST_INVITATION_ATTEMPT',
  requestPostInvitationSuccess = 'REQUEST_POST_INVITATION_SUCCESS',
  requestPostInvitationFailure = 'REQUEST_POST_INVITATION_FAILURE',

  requestDeleteInvitationAttempt = 'REQUEST_DELETE_INVITATION_ATTEMPT',
  requestDeleteInvitationSuccess = 'REQUEST_DELETE_INVITATION_SUCCESS',
  requestDeleteInvitationFailure = 'REQUEST_DELETE_INVITATION_FAILURE',

  requestGetAssociationsAttempt = 'REQUEST_GET_ASSOCIATIONS_ATTEMPT',
  requestGetAssociationsSuccess = 'REQUEST_GET_ASSOCIATIONS_SUCCESS',
  requestGetAssociationsFailure = 'REQUEST_GET_ASSOCIATIONS_FAILURE',

  requestGetAssociationsListAttempt = 'REQUEST_GET_ASSOCIATIONS_LIST_ATTEMPT',
  requestGetAssociationsListSuccess = 'REQUEST_GET_ASSOCIATIONS_LIST_SUCCESS',
  requestGetAssociationsListFailure = 'REQUEST_GET_ASSOCIATIONS_LIST_FAILURE',

  requestPutAssociationDiscountAttempt = 'REQUEST_PUT_ASSOCIATION_DISCOUNT_ATTEMPT',
  requestPutAssociationDiscountSuccess = 'REQUEST_PUT_ASSOCIATION_DISCOUNT_SUCCESS',
  requestPutAssociationDiscountFailure = 'REQUEST_PUT_ASSOCIATION_DISCOUNT_FAILURE',

  requestGetAssociationAgreementAttempt = 'REQUEST_GET_ASSOCIATION_AGREEMENT_ATTEMPT',
  requestGetAssociationAgreementSuccess = 'REQUEST_GET_ASSOCIATION_AGREEMENT_SUCCESS',
  requestGetAssociationAgreementFailure = 'REQUEST_GET_ASSOCIATION_AGREEMENT_FAILURE',

  requestPostPaymentAttempt = 'REQUEST_POST_PAYMENT_ATTEMPT',
  requestPostPaymentSuccess = 'REQUEST_POST_PAYMENT_SUCCESS',
  requestPostPaymentFailure = 'REQUEST_POST_PAYMENT_FAILURE',

  requestGetProgrammePaymentsAttempt = 'REQUEST_GET_PROGRAMME_PAYMENTS_ATTEMPT',
  requestGetProgrammePaymentsSuccess = 'REQUEST_GET_PROGRAMME_PAYMENTS_SUCCESS',
  requestGetProgrammePaymentsFailure = 'REQUEST_GET_PROGRAMME_PAYMENTS_FAILURE',

  requestPutPaymentApprovalAttempt = 'REQUEST_PUT_PAYMENT_APPROVAL_ATTEMPT',
  requestPutPaymentApprovalSuccess = 'REQUEST_PUT_PAYMENT_APPROVAL_SUCCESS',
  requestPutPaymentApprovalFailure = 'REQUEST_PUT_PAYMENT_APPROVAL_FAILURE',

  requestGetAgreementAttempt = 'REQUEST_GET_AGREEMENT_ATTEMPT',
  requestGetAgreementSuccess = 'REQUEST_GET_AGREEMENT_SUCCESS',
  requestGetAgreementFailure = 'REQUEST_GET_AGREEMENT_FAILURE',

  requestGetPaymentTypesAttempt = 'REQUEST_GET_PAYMENT_TYPES_ATTEMPT',
  requestGetPaymentTypesSuccess = 'REQUEST_GET_PAYMENT_TYPES_SUCCESS',
  requestGetPaymentTypesFailure = 'REQUEST_GET_PAYMENT_TYPES_FAILURE',

  requestPutPaymentRejectionAttempt = 'REQUEST_PUT_PAYMENT_REJECTION_ATTEMPT',
  requestPutPaymentRejectionSuccess = 'REQUEST_PUT_PAYMENT_REJECTION_SUCCESS',
  requestPutPaymentRejectionFailure = 'REQUEST_PUT_PAYMENT_REJECTION_FAILURE',

  requestGetInvitationsAttempt = 'REQUEST_GET_INVITATIONS_ATTEMPT',
  requestGetInvitationsSuccess = 'REQUEST_GET_INVITATIONS_SUCCESS',
  requestGetInvitationsFailure = 'REQUEST_GET_INVITATIONS_FAILURE',

  requestGetProgrammeGroupsAttempt = 'REQUEST_GET_PROGRAMME_GROUPS_ATTEMPT',
  requestGetProgrammeGroupsSuccess = 'REQUEST_GET_PROGRAMME_GROUPS_SUCCESS',
  requestGetProgrammeGroupsFailure = 'REQUEST_GET_PROGRAMME_GROUPS_FAILURE',

  requestPostProgrammeGroupAttempt = 'REQUEST_POST_PROGRAMME_GROUP_ATTEMPT',
  requestPostProgrammeGroupSuccess = 'REQUEST_POST_PROGRAMME_GROUP_SUCCESS',
  requestPostProgrammeGroupFailure = 'REQUEST_POST_PROGRAMME_GROUP_FAILURE',

  requestPostProgrammeImageAttempt = 'REQUEST_POST_PROGRAMME_IMAGE_ATTEMPT',
  requestPostProgrammeImageSuccess = 'REQUEST_POST_PROGRAMME_IMAGE_SUCCESS',
  requestPostProgrammeImageFailure = 'REQUEST_POST_PROGRAMME_IMAGE_FAILURE',

  requestGetProgrammeNotificationsAttempt = 'REQUEST_GET_PROGRAMME_NOTIFICATIONS_ATTEMPT',
  requestGetProgrammeNotificationsSuccess = 'REQUEST_GET_PROGRAMME_NOTIFICATIONS_SUCCESS',
  requestGetProgrammeNotificationsFailure = 'REQUEST_GET_PROGRAMME_NOTIFICATIONS_FAILURE',

  requestPutProgrammeNotificationAttempt = 'REQUEST_PUT_PROGRAMME_NOTIFICATION_ATTEMPT',
  requestPutProgrammeNotificationSuccess = 'REQUEST_PUT_PROGRAMME_NOTIFICATION_SUCCESS',
  requestPutProgrammeNotificationFailure = 'REQUEST_PUT_PROGRAMME_NOTIFICATION_FAILURE',

  requestPutInvitationAttempt = 'REQUEST_PUT_INVITATION_ATTEMPT',
  requestPutInvitationSuccess = 'REQUEST_PUT_INVITATION_SUCCESS',
  requestPutInvitationFailure = 'REQUEST_PUT_INVITATION_FAILURE',

  requestPostBulkInviteAttempt = 'REQUEST_POST_BULK_INVITE_ATTEMPT',
  requestPostBulkInviteSuccess = 'REQUEST_POST_BULK_INVITE_SUCCESS',
  requestPostBulkInviteFailure = 'REQUEST_POST_BULK_INVITE_FAILURE',

  requestPutAssociationAttempt = 'REQUEST_PUT_ASSOCIATION_ATTEMPT',
  requestPutAssociationSuccess = 'REQUEST_PUT_ASSOCIATION_SUCCESS',
  requestPutAssociationFailure = 'REQUEST_PUT_ASSOCIATION_FAILURE',

  requestGetInvitableProgrammesAttempt = 'REQUEST_GET_INVITABLE_PROGRAMMES_ATTEMPT',
  requestGetInvitableProgrammesSuccess = 'REQUEST_GET_INVITABLE_PROGRAMMES_SUCCESS',
  requestGetInvitableProgrammesFailure = 'REQUEST_GET_INVITABLE_PROGRAMMES_FAILURE',

  requestGetCampaignsAttempt = 'REQUEST_GET_CAMPAIGNS_ATTEMPT',
  requestGetCampaignsSuccess = 'REQUEST_GET_CAMPAIGNS_SUCCESS',
  requestGetCampaignsFailure = 'REQUEST_GET_CAMPAIGNS_FAILURE',

  requestGetCampaignAttempt = 'REQUEST_GET_CAMPAIGN_ATTEMPT',
  requestGetCampaignSuccess = 'REQUEST_GET_CAMPAIGN_SUCCESS',
  requestGetCampaignFailure = 'REQUEST_GET_CAMPAIGN_FAILURE',

  requestGetCampaignAssociationsAttempt = 'REQUEST_GET_CAMPAIGN_ASSOCIATIONS_ATTEMPT',
  requestGetCampaignAssociationsSuccess = 'REQUEST_GET_CAMPAIGN_ASSOCIATIONS_SUCCESS',
  requestGetCampaignAssociationsFailure = 'REQUEST_GET_CAMPAIGN_ASSOCIATIONS_FAILURE',

  requestDeleteCampaignAttempt = 'REQUEST_DELETE_CAMPAIGN_ATTEMPT',
  requestDeleteCampaignFailure = 'REQUEST_DELETE_CAMPAIGN_FAILURE',

  clearInvitableProgrammes = 'CLEAR_INVITABLE_PROGRAMMES',

  clearCurrentProgramme = 'CLEAR_CURRENT_PROGRAMME',
  setCurrentProgramme = 'SET_CURRENT_PROGRAMME',

  clearProgrammeFormErrors = 'CLEAR_PROGRAMME_FORM_ERRORS',

  toggleFilterGroup = 'TOGGLE_FILTER_GROUP',

  setProgrammeForm = 'SET_PROGRAMME_FORM',
  setProgrammeFormPage = 'SET_PROGRAMME_FORM_PAGE',
  setInviteInfluencerFormPage = 'SET_INVITE_INFLUENCER_FORM_PAGE',

  setCampaignForm = 'SET_CAMPAIGN_FORM',
  setCampaignFormField = 'SET_CAMPAIGN_FORM_FIELD',

  requestPostCampaignImageAttempt = 'REQUEST_POST_CAMPAIGN_IMAGE_ATTEMPT',
  requestPostCampaignImageSuccess = 'REQUEST_POST_CAMPAIGN_IMAGE_SUCCESS',
  requestPostCampaignImageFailure = 'REQUEST_POST_CAMPAIGN_IMAGE_FAILURE',

  requestGetCampaignTimeZonesAttempt = 'REQUEST_GET_CAMPAIGN_TIME_ZONES_ATTEMPT',
  requestGetCampaignTimeZonesSuccess = 'REQUEST_GET_CAMPAIGN_TIME_ZONES_SUCCESS',
  requestGetCampaignTimeZonesFailure = 'REQUEST_GET_CAMPAIGN_TIME_ZONES_FAILURE',

  requestPostCampaignAttempt = 'REQUEST_POST_CAMPAIGN_ATTEMPT',
  requestPostCampaignSuccess = 'REQUEST_POST_CAMPAIGN_SUCCESS',
  requestPostCampaignFailure = 'REQUEST_POST_CAMPAIGN_FAILURE',

  requestPutCampaignAttempt = 'REQUEST_PUT_CAMPAIGN_ATTEMPT',
  requestPutCampaignSuccess = 'REQUEST_PUT_CAMPAIGN_SUCCESS',
  requestPutCampaignFailure = 'REQUEST_PUT_CAMPAIGN_FAILURE',

  requestGetApplicationsAttempt = 'REQUEST_GET_APPLICATIONS_ATTEMPT',
  requestGetApplicationsSuccess = 'REQUEST_GET_APPLICATIONS_SUCCESS',
  requestGetApplicationsFailure = 'REQUEST_GET_APPLICATIONS_FAILURE',
  clearProgrammeApplications = 'CLEAR_PROGRAMME_APPLICATIONS',

  requestPutRejectApplicationAttempt = 'REQUEST_PUT_REJECT_APPLICATION_ATTEMPT',
  requestPutRejectApplicationSuccess = 'REQUEST_PUT_REJECT_APPLICATION_SUCCESS',

  requestPutAcceptApplicationAttempt = 'REQUEST_PUT_ACCEPT_APPLICATION_ATTEMPT',
  requestPutAcceptApplicationSuccess = 'REQUEST_PUT_ACCEPT_APPLICATION_SUCCESS',

  setCampaignFormPage = 'SET_CAMPAIGN_FORM_PAGE',

  setInvitationForm = 'SET_INVITATION_FORM',

  setCommissionsForm = 'SET_COMMISSIONS_FORM',

  setNeedsToRefreshInvitations = 'SET_NEEDS_TO_REFRESH_INVITATIONS',

  setNeedsToRefreshAssociations = 'SET_NEEDS_TO_REFRESH_PROGRAMME_ASSOCIATIONS',

  setPaginationQueries = 'SET_PAGINATION_QUERIES',
  clearPagination = 'CLEAR_PAGINATION',

  requestGetInvitableInfluencersAttempt = 'REQUEST_GET_INVITABLE_INFLUENCERS_ATTEMPT',
  requestGetInvitableInfluencersSuccess = 'REQUEST_GET_INVITABLE_INFLUENCERS_SUCCESS',
  requestGetInvitableInfluencersFailure = 'REQUEST_GET_INVITABLE_INFLUENCERS_FAILURE',

  setInvitableInfluencersQueries = 'SET_INVITABLE_INFLUENCERS_QUERIES',

  clearInvitableInfluencersPagination = 'CLEAR_INVITABLE_INFLUENCERS_PAGINATION',
  setCampaignPaginationQueries = 'SET_CAMPAIGN_PAGINATION_QUERIES',
  clearCampaignPagination = 'CLEAR_CAMPAIGN_PAGINATION',
  resetCampaignFilters = 'RESET_CAMPAIGN_FILTERS',

  clearInviteErrors = 'CLEAR_INVITE_ERRORS',

  requestGetCampaignInvitationsAttempt = 'REQUEST_GET_CAMPAIGN_INVITATIONS_ATTEMPT',
  requestGetCampaignInvitationsSuccess = 'REQUEST_GET_CAMPAIGN_INVITATIONS_SUCCESS',
  requestGetCampaignInvitationsFailure = 'REQUEST_GET_CAMPAIGN_INVITATIONS_FAILURE',

  requestGetCampaignCommissionsSummaryAttempt = 'REQUEST_GET_CAMPAIGN_COMMISSIONS_SUMMARY_ATTEMPT',
  requestGetCampaignCommissionsSummarySuccess = 'REQUEST_GET_CAMPAIGN_COMMISSIONS_SUMMARY_SUCCESS',
  requestGetCampaignCommissionsSummaryFailure = 'REQUEST_GET_CAMPAIGN_COMMISSIONS_SUMMARY_FAILURE',

  requestPatchProgrammeStateAttempt = 'REQUEST_PUT_PROGRAMME_STATE_ATTEMPT',
  requestPatchProgrammeStateSuccess = 'REQUEST_PUT_PROGRAMME_STATE_SUCCESS',
  requestPatchProgrammeStateFailure = 'REQUEST_PUT_PROGRAMME_STATE_FAILURE',

  requestGetDiscountCodeAttempt = 'REQUEST_GET_DISCOUNT_CODE_ATTEMPT',
  requestGetDiscountCodeSuccess = 'REQUEST_GET_DISCOUNT_CODE_SUCCESS',
  requestGetDiscountCodeFailure = 'REQUEST_GET_DISCOUNT_CODE_FAILURE',

  requestPostDiscountCodeAttempt = 'REQUEST_POST_DISCOUNT_CODE_ATTEMPT',
  requestPostDiscountCodeSuccess = 'REQUEST_POST_DISCOUNT_CODE_SUCCESS',
  requestPostDiscountCodeFailure = 'REQUEST_POST_DISCOUNT_CODE_FAILURE',

  requestPutDiscountCodeAttempt = 'REQUEST_PUT_DISCOUNT_CODE_ATTEMPT',
  requestPutDiscountCodeSuccess = 'REQUEST_PUT_DISCOUNT_CODE_SUCCESS',
  requestPutDiscountCodeFailure = 'REQUEST_PUT_DISCOUNT_CODE_FAILURE',

  requestDeleteDiscountCodeAttempt = 'REQUEST_DELETE_DISCOUNT_CODE_ATTEMPT',
  requestDeleteDiscountCodeSuccess = 'REQUEST_DELETE_DISCOUNT_CODE_SUCCESS',
  requestDeleteDiscountCodeFailure = 'REQUEST_DELETE_DISCOUNT_CODE_FAILURE',

  requestGetCampaignDiscountCodeAttempt = 'REQUEST_GET_CAMPAIGN_DISCOUNT_CODE_ATTEMPT',
  requestGetCampaignDiscountCodeSuccess = 'REQUEST_GET_CAMPAIGN_DISCOUNT_CODE_SUCCESS',
  requestGetCampaignDiscountCodeFailure = 'REQUEST_GET_CAMPAIGN_DISCOUNT_CODE_FAILURE',

  clearCampaignDiscountCodeFromState = 'CLEAR_CAMPAIGN_DISCOUNT_CODE_FROM_STATE',
  clearDiscountCodeApiUpdateValidation = 'CLEAR_DISCOUNT_CODE_API_UPDATE_VALIDATION',
  clearDiscountCodeApiCreateValidation = 'CLEAR_DISCOUNT_CODE_API_CREATE_VALIDATION',
  clearProgrammeDiscountCodeFromState = 'CLEAR_PROGRAMME_DISCOUNT_CODE_FROM_STATE',

  requestGetProgrammePerformanceAttempt = 'REQUEST_GET_PROGRAMME_PERFORMANCE_ATTEMPT',
  requestGetProgrammePerformanceSuccess = 'REQUEST_GET_PROGRAMME_PERFORMANCE_SUCCESS',
  requestGetProgrammePerformanceFailure = 'REQUEST_GET_PROGRAMME_PERFORMANCE_FAILURE',

  setInfluencerQueries = 'SET_INFLUENCER_QUERIES',

  requestGetProgrammePerformanceCSVAttempt = 'REQUEST_GET_PROGRAMME_PERFORMANCE_CSV_ATTEMPT',
  requestGetProgrammePerformanceCSVSuccess = 'REQUEST_GET_PROGRAMME_PERFORMANCE_CSV_SUCCESS',
  requestGetProgrammePerformanceCSVFailure = 'REQUEST_GET_PROGRAMME_PERFORMANCE_CSV_FAILURE',
  clearProgrammePerformance = 'CLEAR_PROGRAMME_PERFORMANCE',

  requestGetDiscountTrackingEnabledAttempt = 'REQUEST_GET_DISCOUNT_TRACKING_ENABLED_ATTEMPT',
  requestGetDiscountTrackingEnabledSuccess = 'REQUEST_GET_DISCOUNT_TRACKING_ENABLED_SUCCESS',
  requestGetDiscountTrackingEnabledFailure = 'REQUEST_GET_DISCOUNT_TRACKING_ENABLED_FAILURE',
}

export const RequestProgramme = async (
  dispatch: DispatchFn,
  id: string
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestGetProgrammeAttempt });

    const response = await client.request(
      Microservice.PROGRAMME,
      `/programme/${id}`,
      'GET'
    );

    dispatch({
      type: ProgrammesActions.requestGetProgrammeSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestGetProgrammeFailure,
      payload: 'An error occurred while fetching Programmes.',
    });
    throw new Error();
  }
};

export const CreateProgramme = async (
  dispatch: DispatchFn,
  programme: CreateProgrammeRequest
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestPostProgrammeAttempt });

    await client
      .request<
        Programme,
        CreateProgrammeRequest
      >(Microservice.PROGRAMME, `/programme`, 'POST', programme)
      .then(response => {
        dispatch({
          type: ProgrammesActions.requestPostProgrammeSuccess,
          payload: response,
        });
      });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestPostProgrammeFailure,
      payload: 'An error occurred while creating the Programme.',
    });
  }
};

export const UpdateProgramme = async (
  dispatch: DispatchFn,
  programmeId: string,
  programme: UpdateProgrammeRequest
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestPutProgrammeAttempt });

    const response = await client.request<Programme, UpdateProgrammeRequest>(
      Microservice.PROGRAMME,
      `/programme/${programmeId}`,
      'PUT',
      programme
    );
    dispatch({
      type: ProgrammesActions.requestPutProgrammeSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestPutProgrammeFailure,
      payload: 'An error occurred while updating the Programme.',
    });
    throw new Error();
  }
};

export const RequestProgrammes = (dispatch: DispatchFn) => {
  dispatch({ type: ProgrammesActions.requestGetProgrammesAttempt });
  client
    .request(Microservice.PROGRAMME, `/programme`, 'GET')
    .then(response =>
      dispatch({
        type: ProgrammesActions.requestGetProgrammesSuccess,
        payload: response,
      })
    )
    .catch(error => {
      captureException(error);
      dispatch({
        type: ProgrammesActions.requestGetProgrammesFailure,
        payload: 'An error occurred while fetching Programmes.',
      });
    });
};

export const RequestInvitableProgrammes = (
  dispatch: DispatchFn,
  influencerId: string
) => {
  dispatch({
    type: ProgrammesActions.requestGetInvitableProgrammesAttempt,
  });
  client
    .request(
      Microservice.PROGRAMME,
      `/programme/influencer/${influencerId}/available`,
      'GET'
    )
    .then(response =>
      dispatch({
        type: ProgrammesActions.requestGetInvitableProgrammesSuccess,
        payload: response,
      })
    )
    .catch(error => {
      captureException(error);
      dispatch({
        type: ProgrammesActions.requestGetInvitableProgrammesFailure,
        payload:
          'An error occurred while fetching the influencers available programmes.',
      });
    });
};

export const ClearInvitableProgrammes = (dispatch: DispatchFn) => {
  dispatch({
    type: ProgrammesActions.clearInvitableProgrammes,
  });
};

export const RequestInvitationTokenAsync = async (
  dispatch: DispatchFn,
  body: InvitationRequest
) => {
  try {
    dispatch({ type: ProgrammesActions.requestPostInvitationAttempt });
    const response = await client.request<
      CreateInvitationResponse,
      InvitationRequest
    >(Microservice.INVITATION, `/invitation`, 'POST', body);
    dispatch({
      type: ProgrammesActions.requestPostInvitationSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestPostInvitationFailure,
      payload:
        error.customMessage['InvitationCreateErrors'] ||
        'An error occurred while fetching invitation token.',
    });
    throw apiError(error.status, error.message);
  }
};

export const RequestPostBulkInviteAsync = async (
  dispatch: DispatchFn,
  body: BulkInviteBody
) => {
  try {
    dispatch({ type: ProgrammesActions.requestPostBulkInviteAttempt });
    const response = await client.request<BulkInviteResponse, BulkInviteBody>(
      Microservice.INVITATION,
      `/invitation/bulk`,
      'POST',
      body
    );
    dispatch({
      type: ProgrammesActions.requestPostBulkInviteSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestPostBulkInviteFailure,
      payload: 'An error occurred while bulk posting the invitations.',
    });
    throw new Error();
  }
};

export const RequestDeleteInvitationAsync = async (
  dispatch: DispatchFn,
  inviteId: string
) => {
  try {
    dispatch({ type: ProgrammesActions.requestDeleteInvitationAttempt });
    await client.request(
      Microservice.INVITATION,
      `/invitation/${inviteId}`,
      'DELETE'
    );
    dispatch({
      type: ProgrammesActions.requestDeleteInvitationSuccess,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestDeleteInvitationFailure,
      payload: 'An error occurred while deleting the invitation.',
    });
    throw new Error();
  }
};

export const SetNeedsToRefreshInvitations = (
  dispatch: DispatchFn,
  needsRefresh: boolean
) => {
  dispatch({
    type: ProgrammesActions.setNeedsToRefreshInvitations,
    payload: needsRefresh,
  });
};

export const SetNeedsToRefreshAssociations = (
  dispatch: DispatchFn,
  needsRefresh: boolean
) => {
  dispatch({
    type: ProgrammesActions.setNeedsToRefreshAssociations,
    payload: needsRefresh,
  });
};

export const ClearInvitationTokens = (dispatch: DispatchFn) => {
  dispatch({ type: ProgrammesActions.clearInvitationTokens });
};

export const ClearInvitations = (dispatch: DispatchFn) => {
  dispatch({ type: ProgrammesActions.clearInvitations });
};

export const ClearCurrentProgramme = (dispatch: DispatchFn) => {
  dispatch({ type: ProgrammesActions.clearCurrentProgramme });
};

export const RequestProgAssociationsList = async (
  dispatch: DispatchFn,
  programmeId: string
): Promise<void> => {
  dispatch({ type: ProgrammesActions.requestGetAssociationsListAttempt });

  client
    .request<AssociationAPIModel[]>(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/association`,
      'GET'
    )
    .then((response: AssociationAPIModel[]) => {
      dispatch({
        type: ProgrammesActions.requestGetAssociationsListSuccess,
        payload: response,
      });
    })
    .catch(error => {
      captureException(error);
      dispatch({
        type: ProgrammesActions.requestGetAssociationsListFailure,
        payload: 'An error occurred while fetching Associations.',
      });
    });
};

export const RequestGetDiscountCodeAsync = async (
  dispatch: DispatchFn,
  influencerId: string,
  programmeId: string
) => {
  try {
    dispatch({ type: ProgrammesActions.requestGetDiscountCodeAttempt });

    const response: DiscountCodeApi[] = await client.request(
      Microservice.COMMISSION,
      `/discount/influencer/${influencerId}/programme/${programmeId}`,
      'GET'
    );
    dispatch({
      type: ProgrammesActions.requestGetDiscountCodeSuccess,
      payload: response,
    });
  } catch (error: unknown) {
    const customError = error as CustomError;
    if (customError.status === 404) {
      dispatch({
        type: ProgrammesActions.requestGetDiscountCodeFailure,
        payload: 'Not Found',
      });
      return;
    }
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestGetDiscountCodeFailure,
      payload: 'An error occurred trying to fetch associated discount code.',
    });
  }
};

export const RequestGetCampaignDiscountCodeAsync = async (
  dispatch: DispatchFn,
  influencerId: string,
  programmeId: string,
  campaignId: string
) => {
  try {
    dispatch({ type: ProgrammesActions.requestGetCampaignDiscountCodeAttempt });

    const response: DiscountCodeApi = await client.request(
      Microservice.COMMISSION,
      `/discount/influencer/${influencerId}/programme/${programmeId}/campaign/${campaignId}`,
      'GET'
    );
    dispatch({
      type: ProgrammesActions.requestGetCampaignDiscountCodeSuccess,
      payload: response,
    });
  } catch (error: unknown) {
    const customError = error as CustomError;
    if (customError.status === 404) {
      dispatch({
        type: ProgrammesActions.requestGetCampaignDiscountCodeFailure,
        payload: 'Not Found',
      });
    } else {
      captureException(error);
      dispatch({
        type: ProgrammesActions.requestGetCampaignDiscountCodeFailure,
        payload: 'An error occurred trying to fetch associated discount code.',
      });
    }
  }
};

export const RequestPostDiscountCodeAsync = async (
  dispatch: DispatchFn,
  writeDiscountCode: WriteDiscountCode
) => {
  try {
    dispatch({ type: ProgrammesActions.requestPostDiscountCodeAttempt });

    const response: DiscountCodeApi[] = await client.request(
      Microservice.COMMISSION,
      `/discount`,
      'POST',
      writeDiscountCode
    );
    dispatch({
      type: ProgrammesActions.requestPostDiscountCodeSuccess,
      payload: response,
    });
  } catch (error: unknown) {
    const customError = error as CustomError;
    if (customError.status === 400) {
      dispatch({
        type: ProgrammesActions.requestPostDiscountCodeFailure,
        payload: customError.customMessage[0],
      });
    } else {
      captureException(error);
      dispatch({
        type: ProgrammesActions.requestPostDiscountCodeFailure,
        payload: 'An error occurred attempting to submit the discount code.',
      });
    }
    throw new Error();
  }
};

export const ClearDiscountCodeApiValidationAsync = async (
  dispatch: DispatchFn,
  clearCampaignDiscountCode?: boolean,
  clearProgrammeDiscountCode?: boolean,
  clearUpdateError?: boolean,
  clearCreateError?: boolean
) => {
  if (clearCampaignDiscountCode) {
    dispatch({
      type: ProgrammesActions.clearCampaignDiscountCodeFromState,
    });
  }
  if (clearProgrammeDiscountCode) {
    dispatch({
      type: ProgrammesActions.clearProgrammeDiscountCodeFromState,
    });
  }
  if (clearUpdateError) {
    dispatch({
      type: ProgrammesActions.clearDiscountCodeApiUpdateValidation,
    });
  }
  if (clearCreateError) {
    dispatch({
      type: ProgrammesActions.clearDiscountCodeApiCreateValidation,
    });
  }
};

export const RequestPutDiscountCodeAsync = async (
  dispatch: DispatchFn,
  writeDiscountCode: WriteDiscountCode
) => {
  try {
    dispatch({ type: ProgrammesActions.requestPutDiscountCodeAttempt });

    const response: DiscountCodeApi[] = await client.request(
      Microservice.COMMISSION,
      `/discount`,
      'PUT',
      writeDiscountCode
    );
    dispatch({
      type: ProgrammesActions.requestPutDiscountCodeSuccess,
      payload: response,
    });
  } catch (error: unknown) {
    const customError = error as CustomError;
    if (customError.status === 400) {
      dispatch({
        type: ProgrammesActions.requestPutDiscountCodeFailure,
        payload: customError.customMessage[0],
      });
    } else {
      captureException(error);
      dispatch({
        type: ProgrammesActions.requestPutDiscountCodeFailure,
        payload: 'An error occurred attempting to submit the discount code.',
      });
    }
    throw new Error();
  }
};

export const RequestDeleteDiscountCodeAsync = async (
  dispatch: DispatchFn,
  deleteDiscountBody: DeleteDiscountBody
) => {
  try {
    dispatch({ type: ProgrammesActions.requestDeleteDiscountCodeAttempt });
    await client.request(
      Microservice.COMMISSION,
      `/discount`,
      'DELETE',
      deleteDiscountBody
    );
    dispatch({
      type: ProgrammesActions.requestDeleteDiscountCodeSuccess,
    });
  } catch (error: unknown) {
    const customError = error as CustomError;
    if (customError.status === 400) {
      dispatch({
        type: ProgrammesActions.requestDeleteDiscountCodeFailure,
        payload: customError.customMessage[0],
      });
    } else {
      captureException(error);
      dispatch({
        type: ProgrammesActions.requestDeleteDiscountCodeFailure,
        payload: 'An error occurred while deleting the discount code.',
      });
    }
    throw new Error();
  }
};

export const RequestAssociations = (
  dispatch: DispatchFn,
  programmeId: string,
  queries: ProgrammeQuery,
  shouldClear?: boolean
) => {
  dispatch({ type: ProgrammesActions.requestGetAssociationsAttempt });
  client
    .request<PaginatedAssociationApi>(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/association-paginated${queryBuilder(queries)}`,
      'GET'
    )
    .then((response: PaginatedAssociationApi) => {
      dispatch({
        type: ProgrammesActions.requestGetAssociationsSuccess,
        payload: { content: response, shouldClear: shouldClear },
      });
    })
    .catch(error => {
      captureException(error);
      dispatch({
        type: ProgrammesActions.requestGetAssociationsFailure,
        payload: 'An error occurred while fetching Associations.',
      });
    });
};

export const RequestGetAssociationAgreementAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  influencerId: string
): Promise<any> => {
  dispatch({ type: ProgrammesActions.requestGetAssociationAgreementAttempt });

  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/association/${influencerId}/agreement`,
      'GET'
    );
    dispatch({
      type: ProgrammesActions.requestGetAssociationAgreementSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ProgrammesActions.requestGetAssociationsFailure,
      payload: 'An error occurred trying to fetch associated agreement.',
    });
  }
};

export const UpdateAssociationAsync = async (
  dispatch: DispatchFn,
  association: Association,
  page: number
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestPutAssociationDiscountAttempt });
    await client.request(
      Microservice.PROGRAMME,
      `/programme/${association.programmeId}/association/${association.influencerId}/discount_code`,
      'PUT',
      associationToApi(association)
    );
    dispatch({
      type: ProgrammesActions.requestPutAssociationDiscountSuccess,
      payload: { page: page, association: association },
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestPutAssociationDiscountFailure,
      payload: 'An error occurred while updating the association.',
    });
  }
};

export const CreateBonusPaymentAsync = async (
  dispatch: DispatchFn,
  payment: CreatePaymentRequest
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestPostPaymentAttempt });

    const response = await client.request<
      TransactionApiModel,
      CreatePaymentRequest
    >(Microservice.TRANSACTION, `/transaction`, 'POST', payment);

    const approvePayload: TransactionApiModel = {
      ...response,
      transaction_status: TransactionStatus.PENDING_APPROVAL,
    };

    dispatch({
      type: ProgrammesActions.requestPostPaymentSuccess,
      payload: approvePayload,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestPostPaymentFailure,
      payload: 'An error occurred while creating the Payment.',
    });
    throw new Error();
  }
};

export const ApproveBonusPaymentAsync = async (
  dispatch: DispatchFn,
  approval: ApproveTransactionApiModel
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestPutPaymentApprovalAttempt });

    await client.request(
      Microservice.TRANSACTION,
      `/transaction/${approval.transaction_id}/approval`,
      'PUT',
      approval
    );

    dispatch({
      type: ProgrammesActions.requestPutPaymentApprovalSuccess,
      payload: approval.transaction_id,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestPutPaymentApprovalFailure,
      payload: 'An error occurred while approving the Payment.',
    });
    throw new Error();
  }
};

export const RejectBonusPaymentAsync = async (
  dispatch: DispatchFn,
  rejection: RejectTransactionApiModel
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestPutPaymentRejectionAttempt });

    await client.request(
      Microservice.TRANSACTION,
      `/transaction/${rejection.transaction_id}/rejection`,
      'PUT',
      rejection
    );

    dispatch({
      type: ProgrammesActions.requestPutPaymentRejectionSuccess,
      payload: rejection.transaction_id,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestPutPaymentRejectionFailure,
      payload: 'An error occurred while rejecting the Payment.',
    });
    throw new Error();
  }
};

export const RequestBonusPaymentsAsync = async (
  dispatch: DispatchFn,
  programmeId: string
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestGetProgrammePaymentsAttempt });
    const response = await client.request(
      Microservice.TRANSACTION,
      `/transaction?programme_id=${programmeId}`,
      'GET'
    );
    dispatch({
      type: ProgrammesActions.requestGetProgrammePaymentsSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestGetProgrammePaymentsFailure,
      payload: 'An error occurred while fetching Payments.',
    });
    throw new Error();
  }
};

export const RequestGetAgreementAsync = async (
  dispatch: DispatchFn,
  programmeId: string
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestGetAgreementAttempt });
    const response = await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/agreement`,
      'GET'
    );
    dispatch({
      type: ProgrammesActions.requestGetAgreementSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestGetAgreementFailure,
      payload: 'An error occurred while fetching agreement.',
    });
  }
};

export const RequestGetInvitationsAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  queries: ProgrammeQuery,
  shouldClear?: boolean
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestGetInvitationsAttempt });

    const response = await client.request<Invitation[]>(
      Microservice.INVITATION,
      `/invitation/paginated${queryBuilder(
        queries
      )}&programme_id=${programmeId}`,
      'GET'
    );

    dispatch({
      type: ProgrammesActions.requestGetInvitationsSuccess,
      payload: { content: response, shouldClear: shouldClear },
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestGetInvitationsFailure,
      payload: 'An error occurred while fetching invitations.',
    });
  }
};

export const RequestGetCampaignInvitationsAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  campaignId: string,
  queries: CampaignInvitationsQuery,
  shouldClear?: boolean
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestGetCampaignInvitationsAttempt });

    const response = await client.request(
      Microservice.INVITATION,
      `/invitation/paginated${queryBuilder(
        queries
      )}&programme_id=${programmeId}&campaign_id=${campaignId}`,
      'GET'
    );

    dispatch({
      type: ProgrammesActions.requestGetCampaignInvitationsSuccess,
      payload: { content: response, shouldClear: shouldClear },
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestGetCampaignInvitationsFailure,
      payload: 'An error occurred while fetching invitations.',
    });
  }
};

export const RequestGetPaymentTypesAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestGetPaymentTypesAttempt });
    const response = await client.request<string[]>(
      Microservice.TRANSACTION,
      '/transaction/payment_types',
      'GET'
    );
    dispatch({
      type: ProgrammesActions.requestGetPaymentTypesSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestGetPaymentTypesFailure,
      payload: 'An error occurred while fetching payment types',
    });
  }
};

export const RequestGetNotificationsAsync = async (
  dispatch: DispatchFn,
  programmeId: string
): Promise<void> => {
  try {
    dispatch({
      type: ProgrammesActions.requestGetProgrammeNotificationsAttempt,
    });
    const response = await client.request<Notification[]>(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/notification`,
      'GET'
    );
    dispatch({
      type: ProgrammesActions.requestGetProgrammeNotificationsSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestGetProgrammeNotificationsFailure,
      payload: 'An error occurred while fetching notifications',
    });
  }
};

export const requestPutNotificationAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  notification: Notification
): Promise<void> => {
  try {
    dispatch({
      type: ProgrammesActions.requestPutProgrammeNotificationAttempt,
    });
    await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/notification`,
      'PUT',
      notification
    );
    dispatch({
      type: ProgrammesActions.requestPutProgrammeNotificationSuccess,
      payload: notification,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestPutProgrammeNotificationFailure,
      payload: 'An error occurred while updating the notification',
    });
  }
};

export const RequestPatchProgrammeStateAsync = async (
  dispatch: DispatchFn,
  programmeId: string
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestPatchProgrammeStateAttempt });
    const response: CreateProgrammeRequest = await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/state`,
      'PATCH'
    );
    dispatch({
      type: ProgrammesActions.requestPatchProgrammeStateSuccess,
      payload: response.state,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestPatchProgrammeStateFailure,
      payload:
        'An error occurred while trying to update the status of this programme.',
    });
  }
};

export const ToggleFilterGroup = (dispatch: DispatchFn, groupId: string) => {
  dispatch({ type: ProgrammesActions.toggleFilterGroup, payload: groupId });
};

export const RequestGetProgrammeGroupsAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestGetProgrammeGroupsAttempt });
    const response: ProgrammeGroupAPIResponse = await client.request(
      Microservice.PROGRAMME,
      `/programme-group`,
      'GET'
    );
    dispatch({
      type: ProgrammesActions.requestGetProgrammeGroupsSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestGetProgrammeGroupsFailure,
      payload: 'An error occurred while fetching Programme Groups.',
    });
  }
};

export const RequestPostProgrammeGroupAsync = async (
  dispatch: DispatchFn,
  progGroup: CreateProgrammeGroup
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestPostProgrammeGroupAttempt });
    const response = await client.request(
      Microservice.PROGRAMME,
      `/programme-group`,
      'POST',
      progGroup
    );
    dispatch({
      type: ProgrammesActions.requestPostProgrammeGroupSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    const failure: FailurePayload = {
      type: 'error',
      res: 'An error occurred while trying to create the group. Please try again later.',
    };
    dispatch({
      type: ProgrammesActions.requestPostProgrammeGroupFailure,
      payload: failure.res,
    });
    throw new Error();
  }
};

export const RequestPostProgrammeImageAsync = async (
  dispatch: DispatchFn,
  data: FormData,
  imageType: ProgrammeImage
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestPostProgrammeImageAttempt });
    const response: ImageUploadAPIResponse =
      await client.requestMinusContentType(
        Microservice.IMAGE,
        `/file?purpose=programme_imagery`,
        'POST',
        data
      );
    const responsePayload: PostProgrammeImageResponsePayload = {
      response: response.file_id,
      type: imageType,
    };
    dispatch({
      type: ProgrammesActions.requestPostProgrammeImageSuccess,
      payload: responsePayload,
    });
  } catch (error) {
    captureException(error);
    const failurePayload: PostProgrammeImageResponsePayload = {
      response:
        'An error occurred while trying to upload the image. Please try again later.',
      type: imageType,
    };
    dispatch({
      type: ProgrammesActions.requestPostProgrammeImageFailure,
      payload: failurePayload,
    });
  }
};

export const RequestPostCampaignImageAsync = async (
  dispatch: DispatchFn,
  data: FormData,
  imageType: CampaignImage,
  programmeId: string,
  imageIndex: number
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestPostCampaignImageAttempt });
    const response: ImageUploadAPIResponse =
      await client.requestMinusContentType(
        Microservice.IMAGE,
        `/file?purpose=campaign_imagery&programme_id=${programmeId}`,
        'POST',
        data
      );
    const responsePayload: PostCampaignImageResponsePayload = {
      response: response.file_id,
      type: imageType,
      imageIndex: imageIndex,
    };
    dispatch({
      type: ProgrammesActions.requestPostCampaignImageSuccess,
      payload: responsePayload,
    });
  } catch (error) {
    captureException(error);
    const failurePayload: PostCampaignImageResponsePayload = {
      response:
        'An error occurred while trying to upload the image. Please try again later.',
      type: imageType,
      imageIndex: imageIndex,
    };
    dispatch({
      type: ProgrammesActions.requestPostCampaignImageFailure,
      payload: failurePayload,
    });
  }
};

export const RequestPutInvitationAsync = async (
  dispatch: DispatchFn,
  updatedInvite: UpdateInvitationBody,
  inviteId: string
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestPutInvitationAttempt });
    const response = await client.request(
      Microservice.INVITATION,
      `/invitation/${inviteId}`,
      'PUT',
      updatedInvite
    );

    dispatch({
      type: ProgrammesActions.requestPutInvitationSuccess,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: ProgrammesActions.requestPutInvitationFailure,
      payload:
        'An error occurred while updating the invitation. Please try again later.',
    });
    throw new Error();
  }
};

export const RequestPutAssociationAsync = async (
  dispatch: DispatchFn,
  updatedAssociation: UpdateAssociationBody,
  programmeId: string,
  influencerId: string,
  campaignId: string | null
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestPutAssociationAttempt });
    const response = await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/association/${influencerId}${
        campaignId ? `?campaign_id=${campaignId}` : ''
      }`,
      'PUT',
      updatedAssociation
    );

    dispatch({
      type: ProgrammesActions.requestPutAssociationSuccess,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: ProgrammesActions.requestPutAssociationFailure,
      payload:
        'An error occurred while updating the association. Please try again later.',
    });
    throw new Error();
  }
};

export const RequestGetCampaignTimeZonesAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestGetCampaignTimeZonesAttempt });
    const response = await client.request(
      Microservice.PROGRAMME,
      `/timezone`,
      'GET'
    );

    dispatch({
      type: ProgrammesActions.requestGetCampaignTimeZonesSuccess,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: ProgrammesActions.requestGetCampaignTimeZonesFailure,
      payload:
        'An error occurred while fetching time zones. Please try again later.',
    });
    throw new Error();
  }
};

export const RequestPostCampaignAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  campaignBody: CampaignFormBody
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestPostCampaignAttempt });
    const payload = postCampaignPayload(campaignBody);
    const response = await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/campaign`,
      'POST',
      payload
    );

    dispatch({
      type: ProgrammesActions.requestPostCampaignSuccess,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: ProgrammesActions.requestPostCampaignFailure,
      payload:
        'An error occurred while creating the campaign. Please try again later.',
    });
    throw new Error();
  }
};

export const RequestPutCampaignAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  campaignId: string,
  campaignBody: CampaignFormBody
): Promise<void> => {
  try {
    dispatch({ type: ProgrammesActions.requestPutCampaignAttempt });
    const payload = postCampaignPayload(campaignBody);
    const response = await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/campaign/${campaignId}`,
      'PUT',
      payload
    );

    dispatch({
      type: ProgrammesActions.requestPutCampaignSuccess,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: ProgrammesActions.requestPutCampaignFailure,
      payload:
        'An error occurred while creating the campaign. Please try again later.',
    });
    throw new Error();
  }
};

export const SetProgrammeForm = (
  dispatch: DispatchFn,
  form: ProgrammeFormBody
) => {
  dispatch({ type: ProgrammesActions.setProgrammeForm, payload: form });
};

export const SetCampaignForm = (
  dispatch: DispatchFn,
  form: CampaignFormBody
) => {
  dispatch({ type: ProgrammesActions.setCampaignForm, payload: form });
};

export const SetCampaignFormPage = (dispatch: DispatchFn, page: number) => {
  dispatch({ type: ProgrammesActions.setCampaignFormPage, payload: page });
};

export const SetCampaignFormField = (
  dispatch: DispatchFn,
  field: keyof CampaignFormBody,
  value: string | number | null
) => {
  dispatch({
    type: ProgrammesActions.setCampaignFormField,
    payload: { field: field, val: value },
  });
};

export const SetInvitationForm = (
  dispatch: DispatchFn,
  form: InvitationFormBody
) => {
  dispatch({ type: ProgrammesActions.setInvitationForm, payload: form });
};

export const SetProgrammeFormPage = (dispatch: DispatchFn, page: number) => {
  dispatch({ type: ProgrammesActions.setProgrammeFormPage, payload: page });
};

export const SetInviteInfluencerFormPage = (
  dispatch: DispatchFn,
  page: number
) => {
  dispatch({
    type: ProgrammesActions.setInviteInfluencerFormPage,
    payload: page,
  });
};

export const ClearProgrammeFormErrors = (dispatch: DispatchFn) => {
  dispatch({ type: ProgrammesActions.clearProgrammeFormErrors });
};

export const SetCurrentProgramme = (
  dispatch: DispatchFn,
  programme: Programme | null
) => {
  dispatch({ type: ProgrammesActions.setCurrentProgramme, payload: programme });
};

export const SetCommissionsForm = (
  dispatch: DispatchFn,
  commissions: CommissionsFormBody
) => {
  dispatch({
    type: ProgrammesActions.setCommissionsForm,
    payload: commissions,
  });
};

export const SetProgrammeQueries = async (
  dispatch: DispatchFn,
  field: 'associations' | 'invitations',
  queries: ProgrammeQuery
) => {
  dispatch({
    type: ProgrammesActions.setPaginationQueries,
    payload: { field: field, queries: queries },
  });
};

export const SetCampaignQueries = async (
  dispatch: DispatchFn,
  field: 'associations' | 'invitations',
  queries: CampaignAssociationsQuery | CampaignInvitationsQuery
) => {
  dispatch({
    type: ProgrammesActions.setCampaignPaginationQueries,
    payload: { field: field, queries: queries },
  });
};

export const ResetCampaignFilters = async (
  dispatch: DispatchFn,
  field: 'associations' | 'invitations',
  queries: CampaignAssociationsQuery | CampaignInvitationsQuery
) => {
  const getQueriesWithClearedFilters = ():
    | CampaignAssociationsQuery
    | CampaignInvitationsQuery => {
    switch (field) {
      case 'invitations':
        return {
          ...queries,
          ...CampaignsDefaults.CampaignInvitationsQueryFilters,
        };
      case 'associations':
        return {
          ...queries,
        };
    }
  };

  dispatch({
    type: ProgrammesActions.resetCampaignFilters,
    payload: { field: field, queries: getQueriesWithClearedFilters() },
  });
};

export const ClearPagination = async (
  dispatch: DispatchFn,
  field: 'associations' | 'invitations' | 'programmeApplications'
) => {
  dispatch({
    type: ProgrammesActions.clearPagination,
    payload: field,
  });
};

export const ClearCampaignPagination = async (
  dispatch: DispatchFn,
  field: 'associations' | 'invitations'
) => {
  dispatch({
    type: ProgrammesActions.clearCampaignPagination,
    payload: field,
  });
};

export const ClearInviteErrors = async (dispatch: DispatchFn) => {
  dispatch({
    type: ProgrammesActions.clearInviteErrors,
  });
};

export const RequestGetCampaignsAsync = async (
  dispatch: DispatchFn,
  programmeId: string
) => {
  dispatch({ type: ProgrammesActions.requestGetCampaignsAttempt });
  try {
    const response = await client.request<CampaignSummaryAPI[]>(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/campaign`,
      'GET'
    );
    dispatch({
      type: ProgrammesActions.requestGetCampaignsSuccess,
      payload: transformCampaignListItemsFromAPI(response),
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestGetCampaignsFailure,
      payload:
        'An error occurred while fetching campaigns. Please try again later.',
    });
  }
};

export const RequestGetCampaignAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  campaignId: string,
  shouldClear: boolean
) => {
  dispatch({ type: ProgrammesActions.requestGetCampaignAttempt });
  try {
    const response = await client.request<ReadCampaignAPI>(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/campaign/${campaignId}`,
      'GET'
    );
    dispatch({
      type: ProgrammesActions.requestGetCampaignSuccess,
      payload: { response: response, shouldClear: shouldClear },
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestGetCampaignFailure,
      payload:
        'An error occurred while fetching the campaign. Please try again later.',
    });
    throw new Error();
  }
};

export const RequestGetCampaignAssociationsAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  campaignId: string,
  queries: CampaignAssociationsQuery,
  shouldClear?: boolean
) => {
  dispatch({ type: ProgrammesActions.requestGetCampaignAssociationsAttempt });
  try {
    const response = await client.request<PaginatedCampaignAssociationAPI>(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/campaign/${campaignId}/association-paginated${queryBuilder(
        queries
      )}`,
      'GET'
    );
    dispatch({
      type: ProgrammesActions.requestGetCampaignAssociationsSuccess,
      payload: {
        content: response,
        shouldClear: shouldClear,
      },
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestGetCampaignAssociationsFailure,
      payload:
        'An error occurred while fetching campaign associations. Please try again later.',
    });
    throw new Error();
  }
};

export const RequestDeleteCampaignAsync = async (
  dispatch: DispatchFn,
  campaignId: string,
  programmeId: string
) => {
  dispatch({ type: ProgrammesActions.requestDeleteCampaignAttempt });

  try {
    await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/campaign/${campaignId}`,
      'DELETE'
    );
    dispatch({
      type: ProgrammesActions.requestDeleteCampaignFailure,
      payload: false,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestDeleteCampaignFailure,
      payload: true,
    });
    throw new Error();
  }
};

export const RequestGetInvitableInfluencersAsync = async (
  dispatch: DispatchFn,
  campaignId: string,
  queries: InfluencerQuery,
  shouldClear?: boolean
) => {
  dispatch({ type: ProgrammesActions.requestGetInvitableInfluencersAttempt });
  try {
    const response = await client.request(
      Microservice.INFLUENCER,
      `/influencer/campaign/pagination${queryBuilder(
        queries
      )}&campaign_id=${campaignId}`,
      'GET'
    );
    dispatch({
      type: ProgrammesActions.requestGetInvitableInfluencersSuccess,
      payload: {
        content: response,
        shouldClear: shouldClear,
      },
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestGetInvitableInfluencersFailure,
      payload:
        'An error occurred while getting influencers. Please try again later.',
    });
    throw new Error();
  }
};

export const ClearInvitableInfluencersPagination = (dispatch: DispatchFn) => {
  dispatch({
    type: ProgrammesActions.clearInvitableInfluencersPagination,
  });
};

export const RequestGetProgrammeApplications = async (
  dispatch: DispatchFn,
  programmeId: string,
  queries: ApplicationQuery
) => {
  dispatch({ type: ProgrammesActions.requestGetApplicationsAttempt });
  try {
    const response = await client.request<PaginatedApplicationsAPI>(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/application${queryBuilder(queries)}`,
      'GET'
    );
    const paginatedApplications = response;
    dispatch({
      type: ProgrammesActions.requestGetApplicationsSuccess,
      payload: paginatedApplications,
    });
  } catch (error) {
    captureException(error);
    dispatch({ type: ProgrammesActions.requestGetApplicationsFailure });
  }
};

export const ClearProgrammeApplications = (dispatch: DispatchFn) => {
  dispatch({ type: ProgrammesActions.clearProgrammeApplications });
};

export const RejectApplication = async (
  dispatch: DispatchFn,
  programmeId: string,
  applicationId: string,
  reason: string | null
) => {
  dispatch({ type: ProgrammesActions.requestPutRejectApplicationAttempt });
  try {
    await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/application/${applicationId}/reject`,
      'PUT',
      { reason: reason ?? null }
    );
    dispatch({
      type: ProgrammesActions.requestPutRejectApplicationSuccess,
      payload: true,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestPutRejectApplicationSuccess,
      payload: false,
    });
  }
};
export const AcceptApplication = async (
  dispatch: DispatchFn,
  programmeId: string,
  applicationId: string,
  body: BulkInviteBody
) => {
  dispatch({ type: ProgrammesActions.requestPutAcceptApplicationAttempt });
  dispatch({ type: ProgrammesActions.requestPostBulkInviteAttempt });
  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/application/${applicationId}/accept`,
      'PUT',
      { ...body, send_email: false }
    );
    dispatch({
      type: ProgrammesActions.requestPutAcceptApplicationSuccess,
      payload: true,
    });
    dispatch({
      type: ProgrammesActions.requestPostBulkInviteSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestPutAcceptApplicationSuccess,
      payload: false,
    });
    dispatch({
      type: ProgrammesActions.requestPostBulkInviteFailure,
      payload: 'An error occurred while bulk posting the invitations.',
    });
    throw new Error();
  }
};

export const RequestGetCampaignCommissionsSummaryAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  campaignId: string
) => {
  dispatch({
    type: ProgrammesActions.requestGetCampaignCommissionsSummaryAttempt,
  });
  try {
    const response = await client.request(
      Microservice.COMMISSION,
      `/commission/programme/${programmeId}/campaign/${campaignId}/summary`,
      'GET'
    );
    dispatch({
      type: ProgrammesActions.requestGetCampaignCommissionsSummarySuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestGetCampaignCommissionsSummaryFailure,
      payload:
        'An error occurred while fetching the campaign summary. Please try again later.',
    });
    throw new Error();
  }
};

export const RequestGetProgrammePerformance = async (
  dispatch: DispatchFn,
  programmeId: string,
  queries: ProgrammePerformanceQueries,
  shouldClear?: boolean
) => {
  dispatch({
    type: ProgrammesActions.requestGetProgrammePerformanceAttempt,
  });
  try {
    const response = await client.request(
      Microservice.REPORTING,
      `/reporting/programme/${programmeId}/performance_data${queryBuilder(
        queries
      )}`,
      'GET'
    );
    dispatch({
      type: ProgrammesActions.requestGetProgrammePerformanceSuccess,
      payload: { content: response, shouldClear: shouldClear },
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestGetProgrammePerformanceFailure,
      payload:
        'An error occurred while fetching the programme performance. Please try again later.',
    });
    throw new Error();
  }
};

export const RequestGetProgrammePerformanceCSVAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  programmePerformanceQueries: ProgrammePerformanceQueries
): Promise<void> => {
  const queries = queryBuilder(programmePerformanceQueries);
  try {
    dispatch({
      type: ProgrammesActions.requestGetProgrammePerformanceCSVAttempt,
    });
    const response = await client.request(
      Microservice.REPORTING,
      `/reporting/programme/${programmeId}/programme_data/csv${queries}`,
      'GET',
      null,
      'text/csv'
    );
    dispatch({
      type: ProgrammesActions.requestGetProgrammePerformanceCSVSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ProgrammesActions.requestGetProgrammePerformanceCSVFailure,
      payload: 'An error occurred while fetching the programme performance',
    });
  }
};

export const ClearProgrammePerformance = (dispatch: DispatchFn) => {
  dispatch({
    type: ProgrammesActions.clearProgrammePerformance,
  });
};

export const RequestGetDiscountTrackingEnabled = (
  dispatch: DispatchFn,
  programmeId: string
) => {
  dispatch({
    type: ProgrammesActions.requestGetDiscountTrackingEnabledAttempt,
  });
  client
    .request<boolean>(
      Microservice.PROGRAMME,
      `/programme-group/programme/${programmeId}/discount-enabled`,
      'GET'
    )
    .then((response: boolean) => {
      dispatch({
        type: ProgrammesActions.requestGetDiscountTrackingEnabledSuccess,
        payload: response,
      });
    })
    .catch(error => {
      captureException(error);
      dispatch({
        type: ProgrammesActions.requestGetDiscountTrackingEnabledFailure,
        payload: 'An error occurred while fetching discount code tracking.',
      });
    });
};
