import React, { FC, ReactNode } from 'react';
import { Dialog } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionsWrapper,
  CloseButton,
  ContentWrapper,
  NoticeWrapper,
} from './Modal.styles';
import { useResponsive } from '../../../hooks/useResponsive';
import { Heading3 } from '../../Typography';
import { Alignment, Box, Padding } from '../Box';
import { Breakpoint } from '../../../styling/breakpoints';

export interface ModalProps {
  isOpen?: boolean;
  fullScreen?: boolean;
  onClose: () => void;
  actions?: ReactNode;
  title?: JSX.Element | string;
  maxWidth?: 'md' | 'xs' | 'sm' | 'lg' | 'xl';
  notice?: ReactNode | string | null;
  testId?: string;
  withActions?: boolean;
  withPadding?: boolean;
  contentKey?: string;
  className?: string;
}

export const Modal: FC<ModalProps> = ({
  children,
  isOpen = true,
  fullScreen,
  onClose,
  actions,
  title,
  maxWidth,
  notice,
  testId = 'modal-container',
  withActions = true,
  withPadding = true,
  contentKey = 'model-content',
  className,
}) => {
  const { layout } = useResponsive();

  return (
    <Dialog
      className={className}
      data-testid={testId}
      open={isOpen}
      fullScreen={
        fullScreen !== undefined ? fullScreen : layout === Breakpoint.MOBILE
      }
      maxWidth={maxWidth || 'md'}
      fullWidth
      aria-labelledby="dialog_label"
    >
      {title && (
        <Box
          flex
          justify={Alignment.SPACE_BETWEEN}
          align={Alignment.CENTER}
          padding={Padding.SMALL}
        >
          <Heading3 data-testid="modal-title" id="dialog_label" noMargin>
            {title}
          </Heading3>
          <CloseButton
            data-testid="t_modal_close_btn"
            onClick={onClose}
            aria-label="close modal"
            title="close"
          >
            <FontAwesomeIcon icon="times" />
          </CloseButton>
        </Box>
      )}

      <ContentWrapper
        isMobile={layout === Breakpoint.MOBILE}
        data-testid="modal-content"
        withPadding={withPadding}
        key={contentKey}
      >
        {children}
        {!title && (
          <CloseButton
            isAbsolute
            data-testid="t_modal_close_btn"
            onClick={onClose}
            aria-label="close modal"
            title="close"
          >
            <FontAwesomeIcon icon="times" />
          </CloseButton>
        )}
      </ContentWrapper>

      {withActions && (
        <>
          {notice && (
            <NoticeWrapper isError={true} data-testid={'modal-notice'}>
              {notice}
            </NoticeWrapper>
          )}
          <ActionsWrapper
            isMobile={layout === Breakpoint.MOBILE}
            data-testid="modal-actions"
          >
            {actions}
          </ActionsWrapper>
        </>
      )}
    </Dialog>
  );
};
