import { css } from 'styled-components';
import '../fonts/Poppins.css';
import { px2rem } from '../sizing';

export const NotificationPaper = css`
  * .Society-Notifications-Paper {
    &.MuiDrawer-paperAnchorRight {
      right: 1%;
      @media screen and (max-width: 480px) {
        right: 0px;
      }
    }

    &.MuiDrawer-paper {
      height: auto;
      max-height: 75%;
      top: ${px2rem(64)};
      border-radius: ${px2rem(8)};
      width: auto;

      @media screen and (max-width: 480px) {
        width: 100%;
        height: auto;
        max-height: auto;
      }
    }

    &.MuiPaper-root {
      background-color: #f0f4f7;
    }
  }
`;
