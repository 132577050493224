import styled from 'styled-components';
import { theme } from '../../../styling/theme';

export const IconButtonStyle = styled.button`
  min-width: ${props => theme(props).SIZE.XLARGE};
  height: ${props => theme(props).SIZE.XLARGE};
  border: 1px solid ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
  border-radius: ${props => theme(props).LAYOUT.BORDER_RADIUS};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  background-color: white;
  color: ${props => theme(props).COLOUR.BACKGROUND_DARK};
  font-size: 1.5rem;

  &:not(:disabled):hover {
    color: white;
    transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};
    background-color: ${props => theme(props).COLOUR.PRIMARY};
  }

  &:not(:disabled):focus {
    color: white;
    transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};
    background-color: ${props => theme(props).COLOUR.PRIMARY};
  }

  &:disabled {
    background-color: white;
    cursor: not-allowed;
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    border-color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
  }
`;

export const IconButtonCircle = styled(IconButtonStyle)`
  min-width: ${props => theme(props).SIZE.LARGE};
  height: ${props => theme(props).SIZE.LARGE};
  border: transparent;
  border-radius: ${props => theme(props).LAYOUT.BORDER_RADIUS_CIRCLE};
  color: ${props => theme(props).COLOUR.BACKGROUND_DARK};
  font-size: 1.25rem;
`;

export const IconButtonBorderless = styled(IconButtonStyle)`
  border: none;
  background-color: transparent;
  color: ${props => theme(props).COLOUR.PRIMARY};
  font-size: 1.5rem;
  min-width: auto;

  &:not(:disabled):hover {
    color: ${props => theme(props).COLOUR.PRIMARY_DARK};
    transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};
    background-color: transparent;
  }

  &:not(:disabled):focus {
    color: ${props => theme(props).COLOUR.PRIMARY_DARK};
    transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};
    background-color: transparent;
  }

  &:disabled {
    background-color: transparent;
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    cursor: not-allowed;
  }
`;

export const IconButtonBorderlessAlt = styled(IconButtonBorderless)`
  color: ${props => theme(props).COLOUR.BACKGROUND_MEDIUM};

  &:not(:disabled):hover {
    color: ${props => theme(props).COLOUR.BACKGROUND_DARK};
  }

  &:not(:disabled):focus {
    color: ${props => theme(props).COLOUR.BACKGROUND_DARK};
  }

  &:disabled {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
  }
`;
