import styled from 'styled-components/macro';
import { px2rem } from '../../../styling/sizing';
import { theme } from '../../../styling/theme';
import { Margin } from '../../common/Box';

export const NotificationContainer = styled.div`
  margin-right: ${Margin.SMALL};
`;

export const NotificationItemsContainer = styled.div`
  margin: ${Margin.XSMALL};
  max-height: 30rem;
  overflow-y: auto;
`;

export const NotificationListItem = styled.li`
  list-style: none;
`;

export const NotificationsList = styled.ul``;

export const NotificationsHeader = styled.div`
  margin: ${Margin.XSMALL};
  display: flex;
  justify-content: space-between;
`;

export const NotificationsTitle = styled.span`
  ${props => theme(props).TYPOGRAPHY.LARGE_1};
  margin-right: ${px2rem(8)};
`;

export const NotificationsTitleContainer = styled.div`
  display: flex;
`;

export const NotificationDateDivider = styled.div`
  ${props => theme(props).TYPOGRAPHY.MEDIUM_1};
  padding-left: ${px2rem(12)};
  color: ${props => theme(props).COLOUR.BACKGROUND_DARK};
`;

export const NotificationCenterContent = styled.div`
  width: auto;
  right: ${px2rem(16)};
  height: auto;
  margin-top: ${props => theme(props).SIZE.XSMALL};
  background-color: white;
  box-shadow: 5px 5px 19px 0px rgba(179, 177, 179, 0.78);
  position: absolute;
  border-radius: ${props => theme(props).LAYOUT.BORDER_RADIUS};
  overflow-y: hidden;
  z-index: 999;
`;

export const MarkAllButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
