import React from 'react';
import styled from 'styled-components';
import Component, {
  CheckboxProps as ComponentProps,
} from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { theme } from '../../../styling/theme';
import { GlobalFontFamily } from '../../../styling/css/fontfamily';

const CheckboxWrapper = styled.div<{ labelIsSpaced: boolean }>`
  width: 100%;
  & > label {
    width: 100%;
    display: flex;
    justify-content: ${props =>
      props.labelIsSpaced ? 'space-between' : 'start'};
    ${props => theme(props).TYPOGRAPHY.BODY_TEXT}
  }
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: ${props => theme(props).COLOUR.PRIMARY};
  }
  .MuiFormControlLabel-labelPlacementStart {
    margin: 0;
  }

  .MuiTypography-body1 {
    ${GlobalFontFamily}
    font-size: 1rem;
    font-weight: 300;
  }

  .MuiCheckbox-root {
    width: fit-content;
  }

  .MuiFormControlLabel-root.Mui-disabled {
    cursor: not-allowed;
  }
`;

interface CheckboxProps extends ComponentProps {
  label: string;
  testId: string;
  ariaLabel?: string;
  labelPlacement?: 'start' | 'end';
  labelIsSpaced?: boolean;
}

interface CheckboxInputProps extends React.HTMLAttributes<HTMLInputElement> {
  'data-testid'?: string;
  'aria-label'?: string;
}

export const Checkbox: React.FC<CheckboxProps> = props => {
  const {
    label,
    testId,
    ariaLabel,
    labelPlacement = 'end',
    labelIsSpaced = false,
    ...nativeProps
  } = props;

  return (
    <CheckboxWrapper labelIsSpaced={labelIsSpaced}>
      <FormControlLabel
        labelPlacement={labelPlacement}
        control={
          <Component
            {...nativeProps}
            inputProps={
              {
                'data-testid': testId,
                'aria-label': ariaLabel,
              } as CheckboxInputProps
            }
          />
        }
        label={label}
      />
    </CheckboxWrapper>
  );
};
