export const EFFECTS = {
  RADIUS_STANDARD: '0.5rem',
  RADIUS_DOUBLE: '1rem',
  RADIUS_FULL: '100%',
  RADIUS_HALF: '50%',

  TRANSITION_QUICK: '0.3s ease',
  TRANSITION_SLOW: '0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)',

  TRANSITION_QUICK_LINEAR: '0.35s linear',

  BOX_SHADOW: '0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15)',
  POPOUT_DROPSHADOW: '5px 5px 19px 0px rgba(179, 177, 179, 0.78);',
};
