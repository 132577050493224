import { Action } from '../state';
import { PermissionsActions } from './actions';
import { transformToPaginated, transformProfileUsersFromApi } from './payload';
import { PermissionsState } from './store';

export const PermissionsReducer = (
  state: PermissionsState,
  action: Action
): PermissionsState => {
  switch (action.type) {
    case PermissionsActions.requestGetUsersAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          users: true,
        },
        errors: {
          ...state.errors,
          users: null,
        },
      };
    case PermissionsActions.setUserQueries:
      return {
        ...state,
        userQueries: { ...state.userQueries, ...action.payload },
      };
    case PermissionsActions.clearUsers:
      return {
        ...state,
        paginatedUsers: null,
      };
    case PermissionsActions.requestGetUsersSingleSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          users: false,
        },
        users: transformProfileUsersFromApi(action.payload),
      };
    case PermissionsActions.requestGetUsersSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          users: false,
        },
        paginatedUsers: transformToPaginated({
          response: action.payload,
          currentContent: state.paginatedUsers?.content || null,
        }),
      };
    case PermissionsActions.requestGetUsersFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          users: false,
        },
        errors: {
          ...state.errors,
          users: action.payload,
        },
      };
    case PermissionsActions.requestGetPermissionsAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          permissionDefinitions: true,
        },
        errors: {
          ...state.errors,
          permissionDefinitions: null,
        },
      };
    case PermissionsActions.requestGetPermissionsSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          permissionDefinitions: false,
        },
        permissionDefinitions: action.payload,
      };
    case PermissionsActions.requestGetPermissionsFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          permissionDefinitions: false,
        },
        errors: {
          ...state.errors,
          permissionDefinitions: action.payload,
        },
      };
    case PermissionsActions.requestGetRolesAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          roleDefinitions: true,
        },
        errors: {
          ...state.errors,
          roleDefinitions: null,
        },
      };
    case PermissionsActions.requestGetRolesSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          roleDefinitions: false,
        },
        roleDefinitions: action.payload,
      };
    case PermissionsActions.requestGetRolesFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          roleDefinitions: false,
        },
        errors: {
          ...state.errors,
          roleDefinitions: action.payload,
        },
      };
    case PermissionsActions.requestPutUserAssignmentsAttempt:
      return {
        ...state,
        isUpdating: {
          ...state.isUpdating,
          userAssignments: true,
        },
        errors: {
          ...state.errors,
          userAssignments: null,
        },
      };
    case PermissionsActions.requestPutUserAssignmentsSuccess:
      return {
        ...state,
        isUpdating: {
          ...state.isUpdating,
          userAssignments: false,
        },
      };
    case PermissionsActions.requestPutUserAssignmentsFailure:
      return {
        ...state,
        isUpdating: {
          ...state.isUpdating,
          userAssignments: false,
        },
        errors: {
          ...state.errors,
          userAssignments: action.payload,
        },
      };
    default:
      return state;
  }
};
