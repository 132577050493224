import { DispatchFn } from '../state';
import {
  SocialActions,
  InstagramInsights,
  SocialErrors,
  InstagramQueries,
  InstagramPaginationQueries,
  InstaContent,
  SocialQueries,
  SocialPaginationQueries,
  InstagramKey,
} from './store';
import client, { Microservice } from '../../utils/http.utils';
import { captureException } from '../../utils/sentry.utils';

const getQuerySuffix = (queryName: string): string => {
  if (queryName === 'date_from' || queryName === 'date_to') {
    return 'T00:00:00Z';
  } else {
    return '';
  }
};
const queryBuilder = (queries: InstagramQueries | SocialQueries): string => {
  let query = '';
  Object.entries(queries).forEach(
    ([queryName, queryValue]: [string, string | string[] | number | null]) => {
      if (queryValue != null) {
        if (queryName === 'type') {
          queryValue &&
            (queryValue as string[]).forEach(
              (interest: string) => (query += `&${queryName}=${interest}`)
            );
        } else {
          query += `&${queryName}=${queryValue}${getQuerySuffix(queryName)}`;
        }
      }
    }
  );
  return query.length > 0 ? `?${query.substr(1, query.length)}` : '';
};

export const RequestInstagramInsights = async (
  dispatch: DispatchFn,
  queries: InstagramQueries,
  key: InstagramKey
) => {
  try {
    dispatch({
      type: SocialActions.requestInstagramInsightsAttempt,
      payload: {
        key: key,
      },
    });

    const response = await client.request<InstagramInsights>(
      Microservice.INFLUENCER,
      `/instagram/statistics${queryBuilder(queries)}`,
      'GET'
    );

    dispatch({
      type: SocialActions.requestInstagramInsightsSuccess,
      payload: { content: response, key: key },
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: SocialActions.requestInstagramInsightsFailure,
      payload: {
        content: SocialErrors.requestInstagramInsightsError,
        key: key,
      },
    });
  }
};

export const RequestInstagramContent = async (
  dispatch: DispatchFn,
  queries: InstagramPaginationQueries,
  key: InstagramKey
) => {
  try {
    dispatch({
      type: SocialActions.requestInstagramContentAttempt,
      payload: {
        key: key,
      },
    });

    const response = await client.request<InstaContent>(
      Microservice.INFLUENCER,
      `/instagram/content${queryBuilder(queries)}`,
      'GET'
    );

    dispatch({
      type: SocialActions.requestInstagramContentSuccess,
      payload: { content: response, key: key },
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: SocialActions.requestInstagramContentFailure,
      payload: { content: SocialErrors.requestInstagramContentError, key: key },
    });
  }
};

export const RequestInfluencerInsights = (
  dispatch: DispatchFn,
  userId: string
) => {
  dispatch({ type: SocialActions.requestInfluencerInstaInsightsAttempt });
  client
    .request(
      Microservice.INFLUENCER,
      `/influencer/${userId}/instagram_user_insights`,
      'GET'
    )
    .then(json =>
      dispatch({
        type: SocialActions.requestInfluencerInstaInsightsSuccess,
        payload: json,
      })
    )
    .catch(error => {
      captureException(error);
      dispatch({
        type: SocialActions.requestInfluencerInstaInsightsFailure,
      });
    });
};
//Youtube
export const RequestYoutubeInsights = async (
  dispatch: DispatchFn,
  queries: SocialQueries
) => {
  try {
    dispatch({ type: SocialActions.requestYoutubeInsightsAttempt });

    const response = await client.request<InstagramInsights>(
      Microservice.INFLUENCER,
      `/youtube/statistics${queryBuilder(queries)}`,
      'GET'
    );
    dispatch({
      type: SocialActions.requestYoutubeInsightsSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: SocialActions.requestYoutubeInsightsFailure,
      payload: SocialErrors.requestYoutubeInsightsError,
    });
  }
};

export const RequestYoutubeContent = async (
  dispatch: DispatchFn,
  queries: SocialPaginationQueries
) => {
  try {
    dispatch({ type: SocialActions.requestYoutubeContentAttempt });

    const response = await client.request<InstaContent>(
      Microservice.INFLUENCER,
      `/youtube/content${queryBuilder(queries)}`,
      'GET'
    );
    dispatch({
      type: SocialActions.requestYoutubeContentSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: SocialActions.requestYoutubeContentFailure,
      payload: SocialErrors.requestYoutubeContentError,
    });
  }
};

export const ClearInstagramContent = (
  dispatch: DispatchFn,
  key?: InstagramKey
) => {
  dispatch({
    type: SocialActions.clearInstagramContent,
    payload: key,
  });
};

export const ClearInstagramInsights = (
  dispatch: DispatchFn,
  key?: InstagramKey
) => {
  dispatch({
    type: SocialActions.clearInstagramInsights,
    payload: key,
  });
};
export const ClearYoutubeContent = (dispatch: DispatchFn) => {
  dispatch({
    type: SocialActions.clearYoutubeContent,
  });
};

export const SetInstagramQueries = (
  dispatch: DispatchFn,
  queries: InstagramPaginationQueries,
  type: InstagramKey
) => {
  dispatch({
    type: SocialActions.setInstagramQueries,
    payload: { queries: queries, type: type },
  });
};

export const SetYoutubeQueries = (
  dispatch: DispatchFn,
  queries: SocialPaginationQueries
) => {
  dispatch({
    type: SocialActions.setYoutubeQueries,
    payload: queries,
  });
};
