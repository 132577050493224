import { Dayjs } from 'dayjs';
import { Agency } from '../../components/Agencies/AgencyView/types';
import { AgenciesQuery, AgencySummary } from '../../components/Agencies/types';
import { Paginated, Pagination } from '../../utils/pageControllers.utils';

export interface KeyName {
  id: string;
  name: string;
}

export interface AgencyApplicationsApi {
  id: string;
  name: string;
  created_at: string;
  active_influencers: number;
  location: string;
  industries: KeyName[];
}

export interface AgencyApplicationsItem {
  id: string;
  name: string;
  createdAt: Dayjs;
  activeInfluencers: number;
  location: string;
  industries: KeyName[];
}
export interface AgencyQueries {
  limit: number;
  page: number;
  search: string | null;
  min_active_influencers: number | null;
  location: string | null;
  industry: KeyName[] | null;
}

export interface SelectForms {
  markets: KeyName[];
  social_platforms: KeyName[];
  industries: KeyName[];
}

export interface AgenciesState {
  paginatedAgencyApplications: Paginated<AgencyApplicationsItem> | null;
  agencyApplication: AgencyApplicationSingle | null;
  agencyOptions: SelectForms | null;
  paginatedAgencies: Paginated<AgencySummary> | null;
  currentAgency: Agency | null;
  status: ApplicationStatus | null;
  agencyInfluencers: AgencyInfluencer[] | null;

  agenciesQuery: AgenciesQuery;
  agencyApplicationsQuery: AgenciesQuery;
  isLoading: {
    paginatedAgencyApplications: boolean;
    agencyApplication: boolean;
    agenciesList: boolean;
    agencyOptions: boolean;
    currentAgency: boolean;
    status: boolean;
    agencyInfluencers: boolean;
  };
  error: {
    paginatedAgencyApplications: string | null;
    agencyApplication: string | null;
    currentAgency: string | null;
    agenciesList: string | null;
    agencyOptions: string | null;
    status: string | null;
    agencyInfluencers: string | null;
  };
}
export interface AgencyInfluencerApi {
  influencer_id: string;
  influencer_first_name: string;
  influencer_last_name: string;
  email_address: string;
  instagram_followers: number;
  youtube_subscribers: number;
  inst_connected: boolean;
  yt_connected: boolean;
  avatar_url: string;
}
export interface AgencyInfluencer {
  influencerId: string;
  influencerFirstName: string;
  influencerLastName: string;
  emailAddress: string;
  instagramFollowers: number;
  youtubeSubscribers: number;
  instConnected: boolean;
  ytConnected: boolean;
  avatarUrl: string;
}
export interface AgencyApplicationApi {
  active_influencers: number;
  additional_information: string | null;
  address_line_1: string;
  address_line_2: string | null;
  city: string;
  postcode: string;
  company_name: string | null;
  company_number: string | null;
  created_at: string;
  email_address: string;
  id: string;
  industries: KeyName[];
  last_updated: string;
  limited_company: boolean;
  location: string;
  markets: KeyName[];
  name: string;
  reject_reason: string | null;
  social_platforms: KeyName[];
  status: ApplicationStatus;
  status_changed_at: string | null;
  status_changed_by: string | null;
  vat_number: string;
  vat_registered: boolean;
}
export enum ApplicationStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export interface AgencyApplicationSingle {
  activeInfluencers: number;
  additionalInformation: string | null;
  addressLine1: string;
  addressLine2: string | null;
  city: string;
  postcode: string;
  companyName: string | null;
  companyNumber: string | null;
  createdAt: string;
  emailAddress: string;
  id: string;
  industries: KeyName[];
  lastUpdated: string;
  limitedCompany: boolean;
  location: string;
  markets: KeyName[];
  name: string;
  rejectReason: string | null;
  socialPlatforms: KeyName[];
  status: ApplicationStatus;
  statusChangedAt: string | null;
  statusChangedBy: string | null;
  vatNumber: string;
  vatRegistered: boolean;
}

export interface IndustryAPI extends KeyName {}

export interface AgencySummaryAPI {
  id: string;
  name: string;
  active_influencers: number;
  created_at: string;
  industries: IndustryAPI[];
  location: string;
}

export interface PaginatedAgenciesAPI {
  pagination: Pagination;
  agencies: AgencySummaryAPI[];
}

export interface SocialPlatform {
  id: string;
  name: string;
}

export interface Market {
  id: string;
  name: string;
}

export interface AgencyAPI extends AgencySummaryAPI {
  email_address: string;
  address_line_1: string;
  address_line_2: string | null;
  city: string;
  postcode: string;
  vat_registered: boolean;
  vat_number: string;
  limited_company: boolean;
  company_name: string;
  company_number: string;
  additional_information: string;
  last_updated: string;
  social_platforms: SocialPlatform[];
  markets: Market[];
  identifier_code: string;
  country_code: string;
  country_name: string;
}

export interface PaginatedAgencyApplicationsAPI {
  pagination: Pagination;
  agency_applications: AgencyApplicationsApi[];
}
