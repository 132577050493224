import React, { Suspense } from 'react';
import {
  Route,
  Router,
  Switch,
  Redirect as ReactRedirect,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { AuthInit } from '../../auth/AuthInit';
import { ResponsiveProvider } from '../../hooks/useResponsive';
import { StoreProvider } from '../../store/state';
import { defaultTheme } from '../../styling/theme';
import { BaseStyles, ResetStyles } from '../../styling/css';
import buildIconLibrary from '../../utils/icons.utils';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { Navigation } from '../Navigation';
import { Redirect } from '../Redirect';
import { AppRoute, appRoutes } from './routes';
import { withSentryRouting } from '@sentry/react';
import { browserHistory } from '../../';
import { LoadingView } from '../common/Loading';
import EnvironmentsInit from '../../environments/EnvironmentsInit';

const MarketerRoute: React.FC<{ appRoute: AppRoute }> = ({ appRoute }) => {
  const { component, pageName } = appRoute;
  return (
    <ErrorBoundary pageName={pageName || ''}>
      {<Suspense fallback={<LoadingView />}>{component}</Suspense>}
    </ErrorBoundary>
  );
};

// Enables Sentry to track performance for template routes
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const SentryRoute = withSentryRouting(Route);

const App: React.FC = () => {
  buildIconLibrary();

  return (
    <Router history={browserHistory}>
      <ResetStyles />
      <BaseStyles />

      <StoreProvider>
        <ThemeProvider theme={defaultTheme}>
          <AuthInit>
            <EnvironmentsInit>
              <ResponsiveProvider>
                <Redirect>
                  <Navigation>
                    <Switch>
                      {Object.entries(appRoutes).map(entry => {
                        const [key, route] = entry;
                        if (!route) return null;
                        if (route.shouldShow) {
                          return route.redirect ? (
                            <ReactRedirect
                              key={key}
                              from={route.redirect.from}
                              to={route.redirect.to}
                              exact={route.redirect.exact}
                            />
                          ) : (
                            <SentryRoute
                              key={key}
                              path={route.path}
                              exact={route.exact}
                              component={() => (
                                <MarketerRoute appRoute={route} />
                              )}
                            />
                          );
                        }
                      })}
                      <ReactRedirect from="*" to="/" />
                    </Switch>
                  </Navigation>
                </Redirect>
              </ResponsiveProvider>
            </EnvironmentsInit>
          </AuthInit>
        </ThemeProvider>
      </StoreProvider>
    </Router>
  );
};

export default App;
