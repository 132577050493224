import React, { useMemo } from 'react';
import { AvatarThumbnail, avatarColours } from './Avatar.styles';
import seedRandom from 'seedrandom';

export type AvatarSize = 'small' | 'medium' | 'large';

interface AvatarProps {
  name: string;
  colourIndex?: number;
  url?: string | null | undefined;
  size?: AvatarSize;
}

export const Avatar: React.FC<AvatarProps> = ({
  name,
  colourIndex,
  url,
  size = 'small',
}) => {
  const colourRandomiser = useMemo(() => {
    const randomGen = seedRandom(name);
    return Math.floor(randomGen() * avatarColours.length);
  }, [name]);

  const initials = useMemo(() => {
    const nameArray = name.split(' ');
    const firstInitial = nameArray[0].charAt(0);
    const lastInitial = nameArray[nameArray.length - 1].charAt(0);

    return `${firstInitial}${lastInitial}`.toUpperCase();
  }, [name]);

  return (
    <AvatarThumbnail
      index={colourIndex ? colourIndex : colourRandomiser}
      url={url}
      size={size}
      data-testid="thumbnail-container"
    >
      {!url && initials}
    </AvatarThumbnail>
  );
};
