import { Action } from '../state';
import { ReportingState, ReportingActions } from './store';
import {
  transformGetReportConversionPerformancePayload,
  transformGetReportFinancialsPayload,
  transformGetReportPaymentOverviewPayload,
  transformGetReportSocialsPayload,
} from './transformers';

export const ReportingReducer = (
  state: ReportingState,
  action: Action
): ReportingState => {
  switch (action.type) {
    // Get finances report
    case ReportingActions.requestReportingFinancesAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, getReportFinancials: true },
        errors: { ...state.errors, getReportFinancials: null },
      };
    case ReportingActions.requestReportingFinancesSuccess:
      return {
        ...state,
        reportFinancials: transformGetReportFinancialsPayload(action.payload),
        isLoading: { ...state.isLoading, getReportFinancials: false },
      };
    case ReportingActions.requestReportingFinancesFailure:
      return {
        ...state,
        errors: { ...state.errors, getReportFinancials: action.payload },
        isLoading: { ...state.isLoading, getReportFinancials: false },
      };
    // Get socials report
    case ReportingActions.requestReportingSocialsAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, getReportSocials: true },
        errors: { ...state.errors, getReportSocials: null },
      };
    case ReportingActions.requestReportingSocialsSuccess:
      return {
        ...state,
        reportSocials: transformGetReportSocialsPayload(action.payload),
        isLoading: { ...state.isLoading, getReportSocials: false },
      };
    case ReportingActions.requestReportingSocialsFailure:
      return {
        ...state,
        errors: { ...state.errors, getReportSocials: action.payload },
        isLoading: { ...state.isLoading, getReportSocials: false },
      };
    // Get payment overview report
    case ReportingActions.requestReportingPaymentOverviewAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, getPaymentOverview: true },
        errors: { ...state.errors, getPaymentOverview: null },
      };
    case ReportingActions.requestReportingPaymentOverviewSuccess:
      return {
        ...state,
        reportPaymentOverview: transformGetReportPaymentOverviewPayload(
          action.payload
        ),
        isLoading: { ...state.isLoading, getPaymentOverview: false },
      };
    case ReportingActions.requestReportingPaymentOverviewFailure:
      return {
        ...state,
        errors: { ...state.errors, getPaymentOverview: action.payload },
        isLoading: { ...state.isLoading, getPaymentOverview: false },
      };
    // Get conversion performance report
    case ReportingActions.requestReportingConversionPerformanceAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, getConversionPerformance: true },
        errors: { ...state.errors, getConversionPerformance: null },
      };
    case ReportingActions.requestReportingConversionPerformanceSuccess:
      return {
        ...state,
        reportConversionPerformance:
          transformGetReportConversionPerformancePayload(action.payload),
        isLoading: { ...state.isLoading, getConversionPerformance: false },
      };
    case ReportingActions.requestReportingConversionPerformanceFailure:
      return {
        ...state,
        errors: { ...state.errors, getConversionPerformance: action.payload },
        isLoading: { ...state.isLoading, getConversionPerformance: false },
      };
    //set programme
    case ReportingActions.setSelectedReportingProgramme:
      return {
        ...state,
        selectedReportingProgramme: action.payload,
      };
    //set time period
    case ReportingActions.setSelectedTimePeriod:
      return {
        ...state,
        selectedTimePeriod: action.payload,
      };
    default:
      return state;
  }
};
