import dayjs from 'dayjs';
import { CommissionTarget } from '../../components/common/Commissions/types';
import { PaginatedMap } from '../../types';
import {
  Paginated,
  paginationTransformer,
} from '../../utils/pageControllers.utils';
import { getCommissionPayload } from './payload';
import {
  Application,
  ApplicationAPI,
  Association,
  CampaignAssociation,
  CampaignAssociationAPI,
  CampaignLink,
  CampaignLinkAPI,
  CampaignSummary,
  CampaignSummaryAPI,
  CommissionsSummaryAPI,
  PaginatedApplicationsAPI,
  Pagination,
  ProgrammePerformanceData,
  ProgrammePerformanceDataAPI,
  ProgrammePerformanceInfluencersAPI,
  ReadCampaign,
  ReadCampaignAPI,
} from './store';

export const transformCampaignListItemsFromAPI = (
  items: CampaignSummaryAPI[]
): CampaignSummary[] =>
  items.map(item => transformCampaignListItemFromAPI(item));

export const transformCampaignListItemFromAPI = (
  item: CampaignSummaryAPI
): CampaignSummary => {
  return {
    campaignId: item.id,
    campaignBannerId: item.campaign_banner_id,
    name: item.name,
    activeInfluencers: item.active_influencers,
    startDate: dayjs(item.start_date),
    endDate: dayjs(item.end_date),
    isActive: item.is_active,
    hasExpired: item.has_expired,
    daysToExpiry: item.days_to_expiry,
  };
};

export const transformReadCampaignFromApi = (
  campaign: ReadCampaignAPI
): ReadCampaign => ({
  name: campaign.name,
  brandName: campaign.brand_name,
  brief: campaign.brief,
  startDate: dayjs(campaign.start_date),
  endDate: dayjs(campaign.end_date),
  timeZone: campaign.time_zone,
  budget: campaign.budget,
  campaignBannerId: campaign.campaign_banner_id,
  visualDirection: campaign.visual_direction,
  connectors: campaign.connectors,
  links: transformCampaignLinksFromApi(campaign.links),
  dos: campaign.dos,
  donts: campaign.donts,
  additionalImages: campaign.additional_images,
  isActive: campaign.is_active,
  hasExpired: campaign.has_expired,
  daysToExpiry: campaign.days_to_expiry,
  programmeId: campaign.programme_id,
  campaignId: campaign.campaign_id,
});

const transformCampaignAssociationFromApi = (
  campaignAssociationResponse: CampaignAssociationAPI
): CampaignAssociation => ({
  programmeId: campaignAssociationResponse.programme_id,
  invitedDate: campaignAssociationResponse.invited_date,
  acceptedDate: campaignAssociationResponse.accepted_date,
  createdByMarketerId: campaignAssociationResponse.created_by_marketer_id,
  agreementVersionAcception:
    campaignAssociationResponse.agreement_version_accepted,
  viewCommissions: campaignAssociationResponse.view_commissions,
  commissions: getCommissionPayload(
    campaignAssociationResponse.commissions,
    CommissionTarget.CAMPAIGN
  ),
  influencerDetails: {
    influencerId: campaignAssociationResponse.influencer_id,
    keyCloakId: campaignAssociationResponse.key_cloak_id,
    influencerDiscountCode:
      campaignAssociationResponse.influencer_discount_code,
    firstName: campaignAssociationResponse.first_name,
    lastName: campaignAssociationResponse.last_name,
    avatarUrl: campaignAssociationResponse.avatar_url,
    instagramConnected: campaignAssociationResponse.instagram_connected,
    youtubeConnected: campaignAssociationResponse.youtube_connected,
  },
});

export const transformCampaignAssociationsFromApi = (
  campaignAssociations: CampaignAssociationAPI[]
): CampaignAssociation[] =>
  campaignAssociations.map(association =>
    transformCampaignAssociationFromApi(association)
  );

const transformCampaignLinkFromApi = (link: CampaignLinkAPI): CampaignLink => ({
  title: link.title,
  description: link.description,
  destinationUrl: link.destination_url,
});

const transformCampaignLinksFromApi = (
  links: CampaignLinkAPI[]
): CampaignLink[] => links.map(x => transformCampaignLinkFromApi(x));

export const transformApplicationFromApi = (
  application: ApplicationAPI
): Application => {
  return {
    applicationId: application.application_id,
    programmeId: application.programme_id,
    firstName: application.first_name,
    lastName: application.last_name,
    emailAddress: application.email_address,
    primaryAudienceRegion: application.primary_audience_region,
    secondaryAudienceRegion: application.secondary_audience_region,
    categoriesNeedHelp: application.categories_need_help,
    timeCreating: application.time_creating,
    socialChannels: application.social_channels,
    createdAt: dayjs(application.created_at),
  };
};

export const transformApplicationsFromApi = (
  applications: ApplicationAPI[]
): Application[] => {
  return applications.map(application =>
    transformApplicationFromApi(application)
  );
};

export const transformPaginatedApplicationsFromApi = (
  applications: PaginatedApplicationsAPI,
  currentContent: PaginatedMap<Application> | null
): Paginated<Application> => {
  return paginationTransformer<ApplicationAPI, Application>(
    applications.pagination,
    applications.applications,
    currentContent,
    transformApplicationsFromApi
  );
};

export const transformCommissionsSummaryFromAPI = (
  commissions: CommissionsSummaryAPI
) => {
  return {
    totalCount: commissions.total_count,
    totalCommission: commissions.total_commission,
  };
};

export const transformCampaignAssociationToAssociation = (
  ca: CampaignAssociation
): Partial<Association> => {
  return {
    firstName: ca.influencerDetails.firstName,
    lastName: ca.influencerDetails.lastName,
    influencerId: ca.influencerDetails.influencerDiscountCode,
    programmeId: ca.programmeId,
    influencerDiscountCode: ca.influencerDetails.influencerDiscountCode,
    createdByMarketerId: ca.createdByMarketerId,
    invitedDate: ca.invitedDate,
    acceptedDate: ca.acceptedDate,
    newCustomerCommissionRate: 0,
    returningCustomerCommissionRate: 0,
    commissions: ca.commissions,
    viewCommissions: ca.viewCommissions,
  };
};

export const transformProgrammePerformanceDataFromAPI = (
  programmePerformanceData: ProgrammePerformanceDataAPI
): ProgrammePerformanceData => {
  return {
    clicks: programmePerformanceData?.clicks,
    conversionRate: programmePerformanceData?.conversion_rate,
    codeUsage: programmePerformanceData?.code_usage,
    totalOrders: programmePerformanceData?.total_orders,
    averageOrderValue: programmePerformanceData?.average_order_value,
    revenue: programmePerformanceData?.revenue,
    commission: programmePerformanceData?.commission,
  };
};

export interface ProgrammePerformanceInfluencerAPI
  extends ProgrammePerformanceDataAPI {
  influencer_id: string;
}

export interface ProgrammePerformanceInfluencer
  extends ProgrammePerformanceData {
  influencerId: string;
}

export interface ProgrammePerformanceAPI {
  data: {
    aggregate: ProgrammePerformanceDataAPI;
    influencers: ProgrammePerformanceInfluencersAPI;
  };
  pagination: Pagination;
}

const transformProgrammePerformanceInfluencerAPI = (
  data: ProgrammePerformanceInfluencerAPI
) => {
  return {
    influencerId: data.influencer_id,
    clicks: data.clicks,
    conversionRate: data.conversion_rate,
    codeUsage: data.code_usage,
    totalOrders: data.total_orders,
    averageOrderValue: data.average_order_value,
    revenue: data.revenue,
    commission: data.commission,
  };
};

export const transformPaginatedProgrammePerformance = (
  response: {
    content: ProgrammePerformanceAPI;
    shouldClear: boolean;
  },
  currentContent: PaginatedMap<ProgrammePerformanceInfluencer> | null
): Paginated<ProgrammePerformanceInfluencer> => {
  const transformer = (
    data: ProgrammePerformanceInfluencerAPI[]
  ): ProgrammePerformanceInfluencer[] => {
    return data.map(item => transformProgrammePerformanceInfluencerAPI(item));
  };
  const influencers = response.content.data.influencers;
  const apiData = Object.keys(influencers).map(key => {
    return {
      ...influencers[key],
      influencer_id: key,
    };
  });
  return paginationTransformer<
    ProgrammePerformanceInfluencerAPI,
    ProgrammePerformanceInfluencer
  >(response.content.pagination, apiData, currentContent, transformer);
};
