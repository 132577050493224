import React from 'react';
import { Avatar } from '../Avatar';
import { NotificationController } from '../../Notifications';
import {
  HeadingContainer,
  HeadingContent,
  User,
  UserName,
} from './Header.styles';
import { useHeader } from './useHeader';

export const Header: React.FC = ({ children }) => {
  const { name, keycloakProfile } = useHeader();

  return (
    <HeadingContainer>
      {children}
      <HeadingContent>
        <NotificationController />
        <User>
          <UserName>{keycloakProfile && keycloakProfile.firstName}</UserName>
          {name && <Avatar name={name} />}
        </User>
      </HeadingContent>
    </HeadingContainer>
  );
};
