import React from 'react';
import styled from 'styled-components';
import { theme } from '../../styling/theme';
import { UnauthorizedPage } from '../UnauthorizedPage';
import { RoleGateProps } from './types';
import { usePermissionGate } from './usePermissionsGate';

const UnauthorizedMessage = styled.div`
  padding-top: ${props => theme(props).SIZE.AVERAGE};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${props => theme(props).COLOUR.TEXT_STANDARD};
`;

export const PermissionGate: React.FC<RoleGateProps> = ({
  children,
  permission,
  programmeGroup,
  programme,
  isPage,
  unauthorizedMessage,
}) => {
  const hasPermission = usePermissionGate(
    permission,
    programmeGroup,
    programme
  );

  const renderUnauthorized = () => {
    if (isPage) return <UnauthorizedPage />;
    if (unauthorizedMessage)
      return (
        <UnauthorizedMessage data-testid={'t_unauthorized_message'}>
          {unauthorizedMessage}
        </UnauthorizedMessage>
      );
    return null;
  };

  return <>{hasPermission ? children : renderUnauthorized()}</>;
};
