import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { px2rem } from '../../../styling/sizing';
import { theme } from '../../../styling/theme';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 20%;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${props => theme(props).COLOUR.ALERT_DARK};
  margin-bottom: ${props => theme(props).SIZE.XSMALL};
  font-size: ${px2rem(24)};
`;

export const Text = styled.div`
  width: 60%;
`;
