import styled from 'styled-components';
import { theme } from '../../../styling/theme';

export const SidebarNav = styled.nav<{ isExpanded: boolean }>`
  background-color: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
  width: ${props => (props.isExpanded ? '225px' : '85px')};
  transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};
  overflow-y: auto;

  hr {
    border: 0.5px solid rgba(255, 255, 255, 0.1);
    width: 85%;
  }

  h5 {
    color: rgba(255, 255, 255, 0.3);
    text-transform: uppercase;
    margin: 0.5em 1.5em;
  }
`;

export const SidebarLogo = styled.div<{ collapsed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => (props.collapsed ? '1rem' : '2.5rem 1.5rem')};
`;

export const CollapseButton = styled.button<{ isExpanded: boolean }>`
  background-color: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
  color: ${props => theme(props).COLOUR.WHITE};
  border: 4px solid ${props => theme(props).COLOUR.WHITE};
  border-radius: ${props => theme(props).LAYOUT.BORDER_RADIUS_CIRCLE};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  position: fixed;
  top: 95%;
  left: ${props => (props.isExpanded ? '208px' : '68px')};
  transition: left ${props => theme(props).EFFECTS.TRANSITION_QUICK};
  cursor: pointer;

  &:hover {
    border: 3px solid ${props => theme(props).COLOUR.BACKGROUND_MEDIUM};
    transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};
  }
  z-index: 1;
`;
