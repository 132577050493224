import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { Notification } from '../../../store/Notification/store';
import { Heading3 } from '../../Typography';
import { Modal } from '../../common/Modal';
import { NotificationItem } from '../NotificationItem/NotificationItem';
import { NotificationZero } from '../NotificationItem/NotificationZero';
import {
  NotificationDateDivider,
  NotificationCenterContent,
  NotificationItemsContainer,
  NotificationListItem,
  NotificationsHeader,
  NotificationsList,
  NotificationsTitle,
  NotificationsTitleContainer,
  MarkAllButtonContainer,
} from './NotificationCenter.styles';
import { useNotificationCenter } from './useNotificationCenter';
import { LinkButton } from '../../common';

export const NotificationCenter: React.FC = () => {
  const {
    notifications,
    dispatch,
    isMobile,
    toggleHandler,
    handleMarkAsRead,
    hasUnread,
  } = useNotificationCenter();

  const renderDateDivider = (title: string, key: string) => {
    return (
      <NotificationDateDivider key={key} data-testid={`t_${key}`}>
        {title.toUpperCase()}
      </NotificationDateDivider>
    );
  };

  const renderNotificationItems = (notifications: Notification[]) => {
    const dividers = [
      {
        component: renderDateDivider('today', 'today-divider'),
        comparator: (date: Dayjs) => {
          return date.isSame(dayjs(), 'day');
        },
        used: false,
      },
      {
        component: renderDateDivider('this week', 'week-divider'),
        comparator: (date: Dayjs) => {
          return date.isSame(dayjs(), 'week') && !date.isSame(dayjs(), 'day');
        },
        used: false,
      },
      {
        component: renderDateDivider('older', 'older-divider'),
        comparator: (date: Dayjs) => {
          return !date.isSame(dayjs(), 'week');
        },
        used: false,
      },
    ];

    const componentsToRender: JSX.Element[] = [];

    notifications.forEach((notif, index) => {
      dividers
        .filter(div => !div.used)
        .forEach(divider => {
          if (divider.comparator(notif.createdAt) && !divider.used) {
            componentsToRender.push(divider.component);
            divider.used = true;
          }
        });

      componentsToRender.push(
        <NotificationListItem key={`notification-list-item-${index}`}>
          <NotificationItem
            notification={notif}
            dispatch={dispatch}
            testId={`t_notification_item_${index}`}
          />
        </NotificationListItem>
      );
    });

    return componentsToRender;
  };

  const renderMarkAllReadButton = () => {
    return (
      hasUnread && (
        <LinkButton
          onClick={e => {
            e.preventDefault();
            handleMarkAsRead();
          }}
          role={'button'}
          data-testid={'t_notifications_mark_all_read_btn'}
        >
          Mark all as read
        </LinkButton>
      )
    );
  };

  const renderNotificationsHeader = () => {
    return (
      <Heading3>
        <NotificationsHeader>
          <NotificationsTitleContainer>
            <NotificationsTitle>Notifications</NotificationsTitle>
            <FontAwesomeIcon icon={'bell'} />
          </NotificationsTitleContainer>
          {!isMobile && renderMarkAllReadButton()}
        </NotificationsHeader>
      </Heading3>
    );
  };

  const renderNotificationsList = () => {
    return (
      <NotificationsList>
        {notifications.length > 0 && (
          <>{renderNotificationItems(notifications)}</>
        )}
      </NotificationsList>
    );
  };

  const renderNotificationsContent = () => {
    return notifications.length ? (
      !isMobile ? (
        <NotificationItemsContainer data-testid={'t_desktop_notifications'}>
          {renderNotificationsList()}
        </NotificationItemsContainer>
      ) : (
        <>
          <MarkAllButtonContainer>
            {renderMarkAllReadButton()}
          </MarkAllButtonContainer>
          {renderNotificationsList()}
        </>
      )
    ) : (
      <NotificationZero isMobile={isMobile} />
    );
  };

  const renderMobileNotifications = () => {
    return (
      <Modal
        fullScreen
        onClose={() => toggleHandler(false)}
        title={renderNotificationsHeader()}
        data-testid={'t_mobile_notifications'}
      >
        {renderNotificationsContent()}
      </Modal>
    );
  };

  const renderDesktopNotifications = () => {
    return (
      <NotificationCenterContent>
        {renderNotificationsHeader()}
        {renderNotificationsContent()}
      </NotificationCenterContent>
    );
  };

  return (
    <>{isMobile ? renderMobileNotifications() : renderDesktopNotifications()}</>
  );
};
