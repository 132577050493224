import styled, { css } from 'styled-components';
import { theme } from '../../../styling/theme';
import { px2rem } from '../../../styling/sizing';
import { NotificationContainer } from '../../Notifications/NotificationController/NotificationController.styles';

export const TopbarContainer = styled.div`
  box-sizing: border-box;
  height: 7vh;
  padding: 0 ${px2rem(12)};
  align-items: center;
  background-color: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  color: white;

  & ${NotificationContainer} {
    margin-top: 2px;
  }
`;

export const LogoContainer = styled.div`
  justify-self: center;
  align-self: center;
  height: 50%;
`;

export const AvatarContainer = styled.div`
  justify-self: end;
  align-self: center;
`;

const TopBarOverlay = css`
  position: fixed;
  height: 93vh;
  top: 7vh;
  z-index: 999;
`;

export const TopBarMenuContainer = styled.div<{ open: boolean }>`
  ${TopBarOverlay}
  width: 100vw;
  background-color: ${props =>
    props.open ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0)'};
  pointer-events: ${props => (props.open ? 'auto' : 'none')};
  transition: background-color ${props => theme(props).EFFECTS.TRANSITION_QUICK};
`;

export const MenuItemsContainer = styled.div<{ open: boolean }>`
  ${TopBarOverlay}
  width: 60vw;
  background-color: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
  left: ${props => (props.open ? '0' : '-100vw')};
  transition: left ${props => theme(props).EFFECTS.TRANSITION_QUICK};
`;

export const Hamburger = styled.button<{ isChanging: boolean }>`
  all: unset;
  width: ${px2rem(30)};
  height: ${px2rem(35)};
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: white;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  span:nth-child(1) {
    top: 5px;
    transform-origin: left center;
  }

  span:nth-child(2) {
    top: 15px;
    transform-origin: left center;
  }

  span:nth-child(3) {
    top: 25px;
    transform-origin: left center;
  }
  ${props =>
    props.isChanging &&
    css`
      span:nth-child(1) {
        transform: rotate(45deg);
        top: 5px;
        left: 4px;
      }
      span:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      span:nth-child(3) {
        transform: rotate(-45deg);
        top: 26px;
        left: 4px;
      }
    `}
`;
