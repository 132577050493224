import { Action } from '../state';
import { TranslationsActions, TranslationsAPIActions } from './actions';
import { TranslationsState } from './store';
import {
  transformLocalesFromApi,
  transformPutActiveLocaleResponse,
  transformSingleLocalisedMessageResponse,
  transformSingleMessageResponse,
} from './transformers';

export const TranslationsReducer = (
  state: TranslationsState,
  action: Action
): TranslationsState => {
  switch (action.type) {
    case TranslationsAPIActions.requestGetAvailableLocalesAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          availableLocales: true,
        },
        errors: {
          ...state.errors,
          fetchAvailableLocales: null,
        },
      };
    case TranslationsAPIActions.requestGetAvailableLocalesSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          availableLocales: false,
        },
        availableLocales: transformLocalesFromApi(action.payload),
      };
    case TranslationsAPIActions.requestGetAvailableLocalesFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          availableLocales: false,
        },
        errors: {
          ...state.errors,
          fetchAvailableLocales: action.payload,
        },
      };
    case TranslationsAPIActions.requestGetLocalisedMessagesAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          localisedMessages: true,
        },
        errors: {
          ...state.errors,
          fetchLocalisedMessages: null,
        },
      };
    case TranslationsAPIActions.requestGetLocalisedMessagesSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          localisedMessages: false,
        },
        currentLocaleMessages: action.payload,
      };
    case TranslationsAPIActions.requestGetLocalisedMessagesFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          localisedMessages: false,
        },
        errors: {
          ...state.errors,
          fetchLocalisedMessages: action.payload,
        },
      };
    case TranslationsAPIActions.requestGetCurrentLocaleCountAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          currentLocaleCount: true,
        },
        errors: {
          ...state.errors,
          fetchCurrentLocaleCount: null,
        },
      };
    case TranslationsAPIActions.requestGetCurrentLocaleCountSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          currentLocaleCount: false,
        },
        currentLocaleCount: action.payload,
      };
    case TranslationsAPIActions.requestGetCurrentLocaleCountFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          currentLocaleCount: false,
        },
        errors: {
          ...state.errors,
          fetchCurrentLocaleCount: action.payload,
        },
      };
    case TranslationsAPIActions.requestPutMessageAttempt:
      return {
        ...state,
        isUpdating: {
          ...state.isUpdating,
          message: true,
        },
        errors: {
          ...state.errors,
          updateMessage: null,
        },
      };
    case TranslationsAPIActions.requestPutMessageSuccess:
      return {
        ...state,
        isUpdating: {
          ...state.isUpdating,
          message: false,
        },
        currentLocaleMessages: transformSingleMessageResponse(
          state.currentLocaleMessages,
          action.payload
        ),
      };
    case TranslationsAPIActions.requestPutMessageFailure:
      return {
        ...state,
        isUpdating: {
          ...state.isUpdating,
          message: false,
        },
        errors: {
          ...state.errors,
          updateMessage: action.payload,
        },
      };
    case TranslationsAPIActions.requestPostLocalisedMessageAttempt:
      return {
        ...state,
        isUpdating: {
          ...state.isUpdating,
          localisedMessage: true,
        },
        errors: {
          ...state.errors,
          updateLocalisedMessage: null,
        },
      };
    case TranslationsAPIActions.requestPostLocalisedMessageSuccess:
      return {
        ...state,
        isUpdating: {
          ...state.isUpdating,
          localisedMessage: false,
        },
        currentLocaleMessages: transformSingleLocalisedMessageResponse(
          state.currentLocaleMessages,
          action.payload
        ),
      };
    case TranslationsAPIActions.requestPostLocalisedMessageFailure:
      return {
        ...state,
        isUpdating: {
          ...state.isUpdating,
          localisedMessage: false,
        },
        errors: {
          ...state.errors,
          updateLocalisedMessage: action.payload,
        },
      };
    case TranslationsAPIActions.requestPutLocaleActiveAttempt:
      return {
        ...state,
        isUpdating: {
          ...state.isUpdating,
          localeStatus: true,
        },
        errors: {
          ...state.errors,
          updateLocaleStatus: null,
        },
      };
    case TranslationsAPIActions.requestPutLocaleActiveSuccess:
      return {
        ...state,
        isUpdating: {
          ...state.isUpdating,
          localeStatus: false,
        },
        selectedLocale: transformPutActiveLocaleResponse(
          state.selectedLocale,
          action.payload
        ),
      };
    case TranslationsAPIActions.requestPutLocaleActiveFailure:
      return {
        ...state,
        isUpdating: {
          ...state.isUpdating,
          localeStatus: false,
        },
        errors: {
          ...state.errors,
          updateLocaleStatus: action.payload,
        },
      };
    case TranslationsActions.setFilterMessageSearch:
      return {
        ...state,
        filters: {
          ...state.filters,
          messageSearch: action.payload,
        },
      };
    case TranslationsActions.setSelectedLocale:
      return {
        ...state,
        selectedLocale: action.payload,
      };
    case TranslationsActions.setSelectedLocalisedMessage:
      return {
        ...state,
        selectedLocalisedMessage: action.payload,
      };
    default:
      return state;
  }
};
