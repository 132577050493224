import { useStore } from '../../store/state';
import {
  hasGlobalLevelPermissions,
  hasGroupLevelPermissions,
  hasProgrammeLevelPermissions,
} from './PermissionsGate.utils';
import { UserPermission } from './types';

export const usePermissionGate = (
  permission: UserPermission,
  programmeGroup?: string | null,
  programme?: string | null
) => {
  const [{ userState }] = useStore();

  const userPermissions = userState.permissions;

  if (userPermissions) {
    const groupLevel = userPermissions.groupPermissions;
    const programmeLevel = userPermissions.programmePermissions;

    const isAnyLevelRequest = !programmeGroup && !programme;

    const hasGlobalPermissions = hasGlobalLevelPermissions(
      userPermissions,
      permission
    );

    const hasForGroupPermissionRequest =
      groupLevel &&
      !!programmeGroup &&
      hasGroupLevelPermissions(userPermissions, programmeGroup, permission);

    const hasForProgrammePermissionRequest =
      programmeLevel &&
      !!programme &&
      hasProgrammeLevelPermissions(userPermissions, programme, permission);

    if (isAnyLevelRequest) {
      const hasPermissionInAnyGroup = Object.values(groupLevel).some(
        (list: string[]) => list.includes(permission)
      );

      const hasPermissionInAnyProg = Object.values(programmeLevel).some(
        (list: string[]) => list.includes(permission)
      );

      return (
        hasGlobalPermissions ||
        hasPermissionInAnyGroup ||
        hasPermissionInAnyProg
      );
    }

    return (
      hasGlobalPermissions ||
      hasForGroupPermissionRequest ||
      hasForProgrammePermissionRequest
    );
  }

  return false;
};
