import React from 'react';
import { BreadcrumbContainer, BreadcrumbItem } from './Breadcrumbs.styles';
import { RouterLink } from '../common/Link';
import { BreadcrumbsProps } from './useBreadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const renderBreadcrumbDesktop = (path: string[], displayedPath: string[]) => {
  return displayedPath.map((element, index) => {
    const to = `/${path.slice(0, index + 1).join('/')}`;
    const isLast = index === displayedPath.length - 1;
    return isLast ? (
      <BreadcrumbItem key={element}>{element}</BreadcrumbItem>
    ) : (
      <BreadcrumbItem key={element}>
        <RouterLink to={to}>{element}</RouterLink>
        <FontAwesomeIcon icon="chevron-right" size="xs" />
      </BreadcrumbItem>
    );
  });
};

const renderBreadcrumbMobile = (path: string, displayedPath: string) => {
  return (
    <>
      <FontAwesomeIcon icon="arrow-left" />
      <BreadcrumbItem>
        <RouterLink to={`/${path}`}>{displayedPath}</RouterLink>
      </BreadcrumbItem>
    </>
  );
};

const renderBreadcrumb = (props: BreadcrumbsProps) => {
  const { path, pathMobile, displayedPath, displayedPathMobile, isMobile } =
    props;
  return isMobile
    ? renderBreadcrumbMobile(pathMobile, displayedPathMobile)
    : renderBreadcrumbDesktop(path, displayedPath);
};

export const Breadcrumbs: React.FC<BreadcrumbsProps> = props => {
  const { showBreadcrumb, isMobile } = props;
  return showBreadcrumb ? (
    <nav aria-label="Breadcrumb">
      <BreadcrumbContainer isMobile={isMobile}>
        {renderBreadcrumb(props)}
      </BreadcrumbContainer>
    </nav>
  ) : (
    <div data-testid={'t_not_breadcrumb'} aria-hidden={true}></div>
  );
};
