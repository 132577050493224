import React from 'react';
import { LinkButton } from '../common/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  RedirectTitle,
  RedirectContainer,
  RedirectText,
  LogoutBox,
  RedirectContent,
  TextButtonLogo,
} from './Redirect.styles';
import { Alignment, Box, Direction, Padding } from '../common/Box';
import environment from '../../environments/environment';
import { useStore } from '../../store/state';
import Keycloak from 'keycloak-js';
import { getKeycloakInstance } from '../../auth/keycloak-init';

export const Redirect: React.FC<{ kc?: Keycloak }> = ({
  kc = getKeycloakInstance(),
  children,
}) => {
  const [{ userState }] = useStore();

  const logout = kc?.logout;

  const hasValidRole = userState.permissions?.type === 'non_influencer';

  const hasPermissionsError =
    userState.errors.permissions ?? userState.permissions == null;

  const refreshPage = () => {
    window.location.reload();
  };
  const renderRedirect = () => {
    return (
      <RedirectContainer
        flex
        justify={Alignment.CENTER}
        align={Alignment.CENTER}
      >
        <RedirectContent
          flex
          align={Alignment.CENTER}
          direction={Direction.COLUMN}
          padding={Padding.LARGE}
        >
          <RedirectTitle>Welcome to the society.</RedirectTitle>

          {!hasValidRole && !hasPermissionsError && (
            <>
              <RedirectText>
                Please follow the link below to be redirected to the Influencer
                portal
              </RedirectText>

              <LinkButton
                href={environment.influencerUrl}
                direction={'left'}
                data-testid={'t_redirect_button'}
                variant={'primary'}
              >
                <span>Influencer Portal</span>
                <FontAwesomeIcon icon="arrow-right" />
              </LinkButton>
            </>
          )}

          {hasPermissionsError && (
            <>
              <RedirectText>Sorry something went wrong.</RedirectText>
              <Box flex direction={Direction.COLUMN} justify={Alignment.CENTER}>
                <TextButtonLogo onClick={() => refreshPage()}>
                  {'Click here to try again'}
                </TextButtonLogo>
              </Box>
            </>
          )}

          <LogoutBox>
            <LinkButton
              onClick={() => logout && logout()}
              direction="right"
              variant={'primary'}
              tabIndex={0}
            >
              <FontAwesomeIcon icon="arrow-left" />
              <span>Logout</span>
            </LinkButton>
          </LogoutBox>
        </RedirectContent>
      </RedirectContainer>
    );
  };
  return (
    <>{hasPermissionsError || !hasValidRole ? renderRedirect() : children}</>
  );
};
