import React, { useState, FC, SyntheticEvent, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import styled, { keyframes } from 'styled-components/macro';
import { px2rem } from '../../../styling/sizing';
import { theme } from '../../../styling/theme';

export type ToastType = 'message' | 'error';
export interface ToastProps {
  message: string;
  testId?: string;
  duration?: number;
  type?: ToastType;
  callback?: () => void;
}

const SlideDown = keyframes`
  0% {
    height: 0;
  }
  100% {
    height: ${px2rem(36)};
  }
`;

const ToastWrapper = styled.div<{ type: ToastType }>`
  & .MuiSnackbar-root {
    background-color: white;
    border-radius: ${px2rem(4)};
    color: ${props =>
      props.type === 'error'
        ? theme(props).COLOUR.ALERT_DARK
        : theme(props).COLOUR.SECONDARY_DARKER};
    border: 2px solid;
    padding: 0 ${px2rem(10)};
    animation-fill-mode: forwards;
    animation: ${SlideDown} 0.3s ease-out;
    overflow: hidden;
  }
`;

const Message = styled.div`
  min-height: ${px2rem(36)};
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: ${px2rem(5)} 0;
`;

const CloseButton = styled.button<{ toastType: ToastType }>`
  all: unset;
  margin: 0 0 0 ${px2rem(10)};
  border-radius: 100%;
  font-family: unset;
  border: ${px2rem(1)} solid;
  width: ${px2rem(20)};
  height: ${px2rem(20)};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: white;
  color: ${props =>
    props.toastType === 'error'
      ? theme(props).COLOUR.ALERT_DARK
      : theme(props).COLOUR.SECONDARY_DARKER};
  font-size: 12px;
  padding-top: 2px;
`;

export const Toast: FC<ToastProps> = ({
  message,
  testId = 't_error_msg',
  duration,
  type = 'message',
  callback,
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = (_?: SyntheticEvent, reason?: string) => {
    reason !== 'clickaway' && setOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      callback && callback();
    }, duration);
  }, [callback, duration]);

  return (
    <ToastWrapper data-testid={testId} role="alert" type={type}>
      <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
        <>
          <Message>{message}</Message>
          {!duration && (
            <CloseButton onClick={handleClose} toastType={type}>
              X
            </CloseButton>
          )}
        </>
      </Snackbar>
    </ToastWrapper>
  );
};
