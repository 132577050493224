import { UserPermissionMap } from '../../store/User/store';
import { UserPermission } from './types';

export const hasGlobalLevelPermissions = (
  userPermissions: UserPermissionMap | null,
  permission: UserPermission
) => userPermissions?.globalPermissions.includes(permission);

export const hasGroupLevelPermissions = (
  userPermissions: UserPermissionMap | null,
  programmeGroupId: string | null,
  permission: UserPermission
) =>
  !!programmeGroupId &&
  userPermissions?.groupPermissions[programmeGroupId]?.includes(permission);

export const hasProgrammeLevelPermissions = (
  userPermissions: UserPermissionMap | null,
  programmeId: string,
  permission: UserPermission
) => userPermissions?.programmePermissions[programmeId]?.includes(permission);

export const hasPermission = (
  userPermissions: UserPermissionMap | null,
  programmeId: string,
  groupId: string | null,
  permission: UserPermission
) =>
  hasProgrammeLevelPermissions(userPermissions, programmeId, permission) ||
  hasGroupLevelPermissions(userPermissions, groupId, permission) ||
  hasGlobalLevelPermissions(userPermissions, permission);
