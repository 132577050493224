import {
  MediaObject,
  MediaObjectAPI,
  ReportConversionPerformance,
  ReportConversionPerformanceAPI,
  ReportFinancials,
  ReportFinancialsAPI,
  ReportingSocialPosts,
  ReportingSocialPostsAPI,
  ReportPaymentOverview,
  ReportPaymentOverviewAPI,
  ReportSocials,
  ReportSocialsAPI,
} from './store';

export const transformGetReportFinancialsPayload = (
  response: ReportFinancialsAPI
): ReportFinancials => {
  return {
    overview: {
      influencers: response.overview.influencers,
      connectedInfluencers: response.overview.connected_influencers,
      invites: response.overview.invites,
      activeNetworks: response.overview.active_networks,
      status: response.overview.status,
    },
    financial: {
      clicks: response.financial.clicks,
      totalOrders: response.financial.total_orders,
      conversionRate: response.financial.conversion_rate,
      orderValue: response.financial.order_value,
    },
    totalSales: response.total_sales,
    topEarners: response.top_earners,
  };
};

export const transformGetReportSocialsPayload = (
  response: ReportSocialsAPI
): ReportSocials => {
  return {
    topPosts: transformReportingSocialPostsFromApi(response.top_posts),
    socialInsights: {
      instagram: {
        impressions: response.social_insights.instagram.impressions,
        reach: response.social_insights.instagram.reach,
        engagement: response.social_insights.instagram.engagement,
        likes: response.social_insights.instagram.likes,
        reelReach: response.social_insights.instagram.reel_reach,
        storyReach: response.social_insights.instagram.story_reach,
      },
      youtube: {
        views: response.social_insights.youtube.views,
        comments: response.social_insights.youtube.comments,
        likes: response.social_insights.youtube.likes,
        dislikes: response.social_insights.youtube.dislikes,
        engagement: response.social_insights.youtube.engagement,
        likesDislikes: response.social_insights.youtube.likes_dislikes,
      },
    },
  };
};

export const transformGetReportPaymentOverviewPayload = (
  response: ReportPaymentOverviewAPI
): ReportPaymentOverview => {
  return {
    bonusPayment: response.bonus_payment,
    commissionPayment: response.commission_payment,
  };
};

export const transformGetReportConversionPerformancePayload = (
  response: ReportConversionPerformanceAPI
): ReportConversionPerformance => {
  return {
    clicks: response.clicks,
    codeUsage: response.code_usage,
    totalOrders: response.total_orders,
    totalItems: response.total_items,
    totalOrderValue: response.total_order_value,
    averageOrderValue: response.average_order_value,
  };
};

const transformReportingSocialPostsFromApi = (
  report: ReportingSocialPostsAPI
): ReportingSocialPosts => {
  return {
    instagram: transformMediaObjectsFromApi(report['instagram']),
    youtube: transformMediaObjectsFromApi(report['youtube']),
  };
};

const transformMediaObjectsFromApi = (
  mediaObjs: MediaObjectAPI[]
): MediaObject[] => {
  return mediaObjs.map(media => {
    return {
      link: media.link,
      fileUploadId: media.file_upload_id,
      thumbnailFileUploadId: media.thumbnail_file_upload_id,
    };
  });
};
