import React, { useState } from 'react';
import { Box, Direction } from '../../common';
import { Logo } from '../../common/Logo';
import { NotificationController } from '../../Notifications';
import { NavItems } from '../NavItems';
import {
  LogoContainer,
  TopbarContainer,
  MenuItemsContainer,
  TopBarMenuContainer,
  Hamburger,
} from './Topbar.styles';

export const Topbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <TopbarContainer data-testid="navigation-topbar">
        <Hamburger
          data-testid="topbar-menu-toggle"
          aria-label="toggle menu"
          aria-expanded={isOpen}
          onClick={toggleIsOpen}
          isChanging={isOpen}
        >
          <span></span>
          <span></span>
          <span></span>
        </Hamburger>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <NotificationController />
      </TopbarContainer>

      <TopBarMenuContainer open={isOpen} onClick={toggleIsOpen} />
      <MenuItemsContainer open={isOpen}>
        <Box flex direction={Direction.COLUMN} style={{ height: '100%' }}>
          <NavItems toggle={toggleIsOpen} />
        </Box>
      </MenuItemsContainer>
    </>
  );
};
