import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Box, Direction } from '../../common';
import { Logo } from '../../common/Logo';
import { NavItems } from '../NavItems';
import { CollapseButton, SidebarLogo, SidebarNav } from './Sidebar.styles';

export const Sidebar: React.FC = ({ ...props }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  return (
    <SidebarNav
      isExpanded={isExpanded}
      {...props}
      data-testid="navigation-sidebar"
    >
      <Box flex direction={Direction.COLUMN} style={{ height: '100%' }}>
        <SidebarLogo collapsed={!isExpanded}>
          <Logo collapsed={!isExpanded} />
        </SidebarLogo>

        <hr />

        <NavItems expanded={isExpanded} />

        <CollapseButton
          onClick={() => setIsExpanded(!isExpanded)}
          isExpanded={isExpanded}
          aria-label="toggle sidebar"
          aria-expanded={isExpanded}
        >
          <FontAwesomeIcon icon={isExpanded ? 'chevron-left' : 'bars'} />
        </CollapseButton>
      </Box>
    </SidebarNav>
  );
};
