import dayjs from 'dayjs';
import { Agency } from '../../components/Agencies/AgencyView/types';
import { AgencySummary } from '../../components/Agencies/types';
import { PaginatedMap } from '../../types';
import {
  Paginated,
  paginationTransformer,
} from '../../utils/pageControllers.utils';

import {
  AgencyAPI,
  AgencyApplicationsItem,
  AgencyApplicationsApi,
  PaginatedAgencyApplicationsAPI,
  AgencyApplicationApi,
  PaginatedAgenciesAPI,
  AgencyApplicationSingle,
  AgencySummaryAPI,
  AgencyInfluencerApi,
  AgencyInfluencer,
} from './types';

export const transformAgencyInfluencersFromAPI = (
  influencers: AgencyInfluencerApi[]
): AgencyInfluencer[] => {
  return influencers.map(influencer => {
    return {
      influencerId: influencer.influencer_id,
      influencerFirstName: influencer.influencer_first_name,
      influencerLastName: influencer.influencer_last_name,
      emailAddress: influencer.email_address,
      instagramFollowers: influencer.instagram_followers,
      youtubeSubscribers: influencer.youtube_subscribers,
      instConnected: influencer.inst_connected,
      ytConnected: influencer.yt_connected,
      avatarUrl: influencer.avatar_url,
    };
  });
};

const transformAgencySummaryFromAPI = (
  agency: AgencySummaryAPI
): AgencySummary => {
  return {
    ...agency,
    activeInfluencers: agency.active_influencers,
    joinedAt: dayjs(agency.created_at),
  };
};

const transformAgencyApplicationsFromAPI = (
  agencies: AgencyApplicationsApi[]
): AgencyApplicationsItem[] => {
  return agencies.map(agency => {
    return {
      id: agency.id,
      name: agency.name,
      location: agency.location,
      industries: agency.industries,
      activeInfluencers: agency.active_influencers,
      createdAt: dayjs(agency.created_at),
    };
  });
};

const transformAgenciesFromAPI = (
  agencies: AgencySummaryAPI[]
): AgencySummary[] => {
  return agencies.map(agency => transformAgencySummaryFromAPI(agency));
};

export const transformPaginatedAgenciesListFromAPI = (
  response: { content: PaginatedAgenciesAPI; shouldClear: boolean },
  current: PaginatedMap<AgencySummary> | null
): Paginated<AgencySummary> => {
  return paginationTransformer<AgencySummaryAPI, AgencySummary>(
    response.content.pagination,
    response.content.agencies,
    current,
    transformAgenciesFromAPI,
    response.shouldClear
  );
};

export const transformPaginatedAgencyApplicationsFromAPI = (
  response: {
    content: PaginatedAgencyApplicationsAPI;
    shouldClear: boolean;
  },
  current: PaginatedMap<AgencyApplicationsItem> | null
): Paginated<AgencyApplicationsItem> => {
  return paginationTransformer<AgencyApplicationsApi, AgencyApplicationsItem>(
    response.content.pagination,
    response.content.agency_applications,
    current,
    transformAgencyApplicationsFromAPI,
    response.shouldClear
  );
};

export const transformAgencyApplicationFromAPI = (
  agency: AgencyApplicationApi
): AgencyApplicationSingle => {
  return {
    activeInfluencers: agency.active_influencers,
    additionalInformation: agency.additional_information,
    addressLine1: agency.address_line_1,
    addressLine2: agency.address_line_2,
    city: agency.city,
    postcode: agency.postcode,
    companyName: agency.company_name,
    companyNumber: agency.company_number,
    createdAt: agency.created_at,
    emailAddress: agency.email_address,
    id: agency.id,
    industries: agency.industries,
    lastUpdated: agency.last_updated,
    limitedCompany: agency.limited_company,
    location: agency.location,
    markets: agency.markets,
    name: agency.name,
    rejectReason: agency.reject_reason,
    socialPlatforms: agency.social_platforms,
    status: agency.status,
    statusChangedAt: agency.status_changed_at,
    statusChangedBy: agency.status_changed_by,
    vatNumber: agency.vat_number,
    vatRegistered: agency.vat_registered,
  };
};
export const transformAgencyFromAPI = (apiAgency: AgencyAPI): Agency => {
  return {
    id: apiAgency.id,
    name: apiAgency.name,
    industries: apiAgency.industries,
    addressLine1: apiAgency.address_line_1,
    addressLine2: apiAgency.address_line_2,
    city: apiAgency.city,
    postcode: apiAgency.postcode,
    markets: apiAgency.markets,
    location: apiAgency.location,
    joinedAt: dayjs(apiAgency.created_at),
    activeInfluencers: apiAgency.active_influencers,
    emailAddress: apiAgency.email_address,
    vatRegistered: apiAgency.vat_registered,
    vatNumber: apiAgency.vat_number,
    limitedCompany: apiAgency.limited_company,
    companyName: apiAgency.company_name,
    companyNumber: apiAgency.company_number,
    additionalInformation: apiAgency.additional_information,
    lastUpdated: apiAgency.last_updated,
    socialPlatforms: apiAgency.social_platforms,
    identifierCode: apiAgency.identifier_code,
    countryCode: apiAgency.country_code,
    countryName: apiAgency.country_name,
  };
};
