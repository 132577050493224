import { DispatchFn } from '../state';
import { ReportingPeriod, ReportingQueries } from './store';
import client, { Microservice } from '../../utils/http.utils';
import { Programme } from '../Programmes/store';
import { captureException } from '../../utils/sentry.utils';

export enum ReportingActions {
  requestReportingFinancesAttempt = 'REQUEST_REPORTING_FINANCES_ATTEMPT',
  requestReportingFinancesSuccess = 'REQUEST_REPORTING_FINANCES_SUCCESS',
  requestReportingFinancesFailure = 'REQUEST_REPORTING_FINANCES_FAILURE',

  requestReportingSocialsAttempt = 'REQUEST_REPORTING_SOCIALS_ATTEMPT',
  requestReportingSocialsSuccess = 'REQUEST_REPORTING_SOCIALS_SUCCESS',
  requestReportingSocialsFailure = 'REQUEST_REPORTING_SOCIALS_FAILURE',

  requestReportingPaymentOverviewAttempt = 'REQUEST_REPORTING_PAYMENT_OVERVIEW_ATTEMPT',
  requestReportingPaymentOverviewSuccess = 'REQUEST_REPORTING_PAYMENT_OVERVIEW_SUCCESS',
  requestReportingPaymentOverviewFailure = 'REQUEST_REPORTING_PAYMENT_OVERVIEW_FAILURE',

  requestReportingConversionPerformanceAttempt = 'REQUEST_REPORTING_CONVERSION_PERFORMANCE_ATTEMPT',
  requestReportingConversionPerformanceSuccess = 'REQUEST_REPORTING_CONVERSION_PERFORMANCE_SUCCESS',
  requestReportingConversionPerformanceFailure = 'REQUEST_REPORTING_CONVERSION_PERFROMANCE_FAILURE',

  setSelectedReportingProgramme = 'SET_SELECTED_REPORTING_PROGRAMME',
  setSelectedTimePeriod = 'SET_SELECTED_TIME_PERIOD',
}

export enum ReportingErrors {
  requestReportFinancesError = 'An error occurred while fetching the finances report.',
  requestReportSocialsError = 'An error occurred while fetching the socials report.',
  requestPaymentOverviewError = 'An error occured while fetching the payment overview report',
  requestConversionPerformanceError = 'An error occured while fetching the conversion performance report',
}

export const RequestReportingFinancesAsync = async (
  dispatch: DispatchFn,
  queries: ReportingQueries
) => {
  try {
    dispatch({
      type: ReportingActions.requestReportingFinancesAttempt,
    });

    const response = await client.request(
      Microservice.REPORTING,
      `/dashboard/programme/${queries.programme}/finances?period=${queries.period}`,
      'GET'
    );

    dispatch({
      type: ReportingActions.requestReportingFinancesSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ReportingActions.requestReportingFinancesFailure,
      payload: ReportingErrors.requestReportFinancesError,
    });
  }
};

export const RequestReportingSocialsAsync = async (
  dispatch: DispatchFn,
  queries: ReportingQueries
) => {
  try {
    dispatch({
      type: ReportingActions.requestReportingSocialsAttempt,
    });

    const response = await client.request(
      Microservice.REPORTING,
      `/dashboard/socials?programme_id=${queries.programme}&period=${queries.period}`,
      'GET'
    );

    dispatch({
      type: ReportingActions.requestReportingSocialsSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ReportingActions.requestReportingSocialsFailure,
      payload: ReportingErrors.requestReportSocialsError,
    });
  }
};

export const RequestReportingPaymentOverviewAsync = async (
  dispatch: DispatchFn,
  queries: ReportingQueries
) => {
  try {
    dispatch({
      type: ReportingActions.requestReportingPaymentOverviewAttempt,
    });

    const response = await client.request(
      Microservice.REPORTING,
      `/dashboard/payment_overview?programme_id=${queries.programme}&period=${queries.period}`,
      'GET'
    );

    dispatch({
      type: ReportingActions.requestReportingPaymentOverviewSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ReportingActions.requestReportingPaymentOverviewFailure,
      payload: ReportingErrors.requestPaymentOverviewError,
    });
  }
};

export const RequestReportingConversionPerformanceAsync = async (
  dispatch: DispatchFn,
  queries: ReportingQueries
) => {
  try {
    dispatch({
      type: ReportingActions.requestReportingConversionPerformanceAttempt,
    });

    const response = await client.request(
      Microservice.REPORTING,
      `/dashboard/conversion_performance?programme_id=${queries.programme}&period=${queries.period}`,
      'GET'
    );

    dispatch({
      type: ReportingActions.requestReportingConversionPerformanceSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: ReportingActions.requestReportingConversionPerformanceFailure,
      payload: ReportingErrors.requestConversionPerformanceError,
    });
  }
};

export const SetSelectedReportingProgramme = (
  dispatch: DispatchFn,
  selectedProgramme: Programme
) => {
  dispatch({
    type: ReportingActions.setSelectedReportingProgramme,
    payload: selectedProgramme,
  });
};
export const SetSelectedTimePeriod = (
  dispatch: DispatchFn,
  timePeriod: ReportingPeriod
) => {
  dispatch({
    type: ReportingActions.setSelectedTimePeriod,
    payload: timePeriod,
  });
};
