import styled from 'styled-components';
import { theme } from '../../../styling/theme';
import { inputStyles } from '../Input';

export const Select = styled.select`
  ${inputStyles}
  cursor: pointer;
  color: ${props => theme(props).COLOUR.TEXT_STANDARD};
`;

export const ButtonSelect = styled.select`
  ${inputStyles}
  cursor: pointer;
  color: ${props => theme(props).COLOUR.TEXT_STANDARD};
  width: fit-content;
  font-size: 1rem;
  padding: 0.65rem;
  height: ${props => theme(props).SIZE.XLARGE};
`;
