import { UserPermissionMapApi, UserPermissionMap, UserName } from './store';

export const parseUserPermissionMap = (
  payload: UserPermissionMapApi
): UserPermissionMap => {
  const {
    global_permissions,
    group_permissions,
    programme_permissions,
    keycloak_id,
    keycloak_token_id,
    type,
  } = payload;
  return {
    globalPermissions: global_permissions,
    groupPermissions: group_permissions,
    programmePermissions: programme_permissions,
    keycloakId: keycloak_id,
    keycloakTokenId: keycloak_token_id,
    type: type,
  };
};

export const transformToPaymentUserName = (
  currentUserNames: UserName[],
  userName: UserName
): UserName[] => {
  if (currentUserNames.some(e => e.keycloakId == userName.keycloakId)) {
    return currentUserNames;
  }
  return [...currentUserNames, userName];
};
