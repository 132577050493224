import styled, { css } from 'styled-components/macro';
import { px2rem } from '../../../styling/sizing';
import { Link } from 'react-router-dom';
import { theme } from '../../../styling/theme';

export const StandardLinkStyles = css`
  color: ${props => theme(props).COLOUR.PRIMARY};
  :active {
    text-decoration: none;
    color: ${props => theme(props).COLOUR.PRIMARY_DARKEST};
  }
  :visited {
    color: ${props => theme(props).COLOUR.PRIMARY_DARKEST};
  }
  :hover {
    text-decoration: none;
  }
  :disabled {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
  }
`;

export const LinkFocusOverlayStyles = css`
  position: relative;
  &:focus {
    overflow: hidden;
    &:before {
      content: '';
      opacity: 0.3;
      background: white;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
`;

export const ExternalLink = styled.a`
  ${StandardLinkStyles};
  ${LinkFocusOverlayStyles};
  border: none;
  color: ${props => theme(props).COLOUR.PRIMARY};
  word-break: break-all;
  &:hover {
    color: ${props => theme(props).COLOUR.PRIMARY_DARK};
  }

  &:focus {
    color: ${props => theme(props).COLOUR.PRIMARY_DARK};
  }

  &:active {
    color: ${props => theme(props).COLOUR.PRIMARY_DARKER};
  }

  &:disabled {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    background-color: transparent;
    border: none;
  }

  svg {
    margin-right: ${px2rem(8)};
  }
`;

export const RouterLink = styled(Link)<{
  active?: boolean;
  isMobile?: boolean;
}>`
  text-decoration: none;
  ${StandardLinkStyles};
  ${LinkFocusOverlayStyles};

  border: none;

  &:hover {
    color: ${props => theme(props).COLOUR.PRIMARY_DARK};
    text-decoration: underline;
  }

  &:focus {
    color: ${props => theme(props).COLOUR.PRIMARY_DARK};
  }

  &:active {
    color: ${props => theme(props).COLOUR.PRIMARY_DARKER};
  }

  &:disabled {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    background-color: transparent;
    border: none;
  }
  ${props =>
    props.active &&
    css`
      :visited {
        color: ${props => theme(props).COLOUR.PRIMARY};
      }
    `}

  svg {
    margin-right: ${px2rem(8)};
  }
`;
