import { Dayjs } from 'dayjs';
import {
  Commission,
  CommissionTarget,
  CommissionType,
} from '../../components/common/Commissions/types';
import { StringMap, StringStringMap } from '../../types';
import { Currency } from '../../utils/localisation.utils';
import { Paginated, PaginationQuery } from '../../utils/pageControllers.utils';
import { InfluencerData } from '../Discovery/store';
import { VatPayment } from '../Payments/store';
import { ProgrammePerformanceInfluencer } from './transformer';

export const ProgrammeFormTabs = [
  'Details',
  'Images',
  'URLs',
  'Tags',
  'Description',
  'Commission',
  'Agreement',
  'Review',
];

export const CampaignFormTabs = [
  'Details',
  'Images',
  'Tags',
  'URLs',
  'Additional Info',
  'Review',
];

export interface ValidationErrorsAPIModel {
  name?: string;
}

export interface ValidationErrorsAPIResponse {
  validationErrors: ValidationErrorsAPIModel;
}

export interface FailurePayload {
  type: 'validation' | 'error';
  res: string;
}

export enum ProgrammeImage {
  BANNER = 'banner',
  THUMBNAIL = 'thumbnail',
  LOGO = 'logo',
}

export enum CampaignImage {
  BANNER = 'banner',
  VISUAL_DIRECTION = 'visual_direction',
  ADDITIONAL = 'additional',
}

export interface CreateInvitationResponse {
  success: boolean;
  error_message?: StringMap<string>;
}

export interface PostProgrammeImageResponsePayload {
  response: string;
  type: ProgrammeImage;
}
export interface PostCampaignImageResponsePayload {
  response: string;
  type: CampaignImage;
  imageIndex: number;
}

export interface TimeZone {
  id: string;
  name: string;
  offset: string;
}

export interface DiscountCodeApi {
  id: string;
  name: string;
  code: string;
  influencer_id: string;
  valid_from: string;
  valid_to?: string;
  is_commissionable: boolean;
  programme_id: string;
  campaign_id?: string;
  deleted_at?: string;
}

export interface DiscountCode {
  id: string;
  name: string;
  code: string;
  influencerId: string;
  validFrom: string;
  validTo?: string;
  isCommissionable: boolean;
  programmeId: string;
  campaignId?: string;
  deletedAt?: string;
}

export interface WriteDiscountCode {
  id?: string;
  name: string;
  code: string;
  influencer_id: string;
  valid_from: string | null;
  valid_to: string | null;
  is_commissionable: boolean;
  programme_id: string;
  campaign_id?: string | null;
}

export interface DeleteDiscountBody {
  id: string;
  programme_id: string;
}
export interface SocialsTab {
  partnership: string;
  handle: string | null;
}

export interface InvitationRequest {
  programme_id: string;
  influencer_name: string;
  created_by_marketer_name: string;
  created_by_marketer_id: string;
  agreement: string | null;
  keycloak_id?: string;
  commissions: ProgrammeCommissionsApi;
  view_commissions: boolean;
  campaign_id?: string;
  socials: SocialsTab;
}

export interface Association {
  firstName: string;
  lastName: string;
  influencerId: string;
  programmeId: string;
  influencerDiscountCode: string;
  createdByMarketerId: string | null;
  invitedDate: string | null;
  acceptedDate: string | null;
  newCustomerCommissionRate: number;
  returningCustomerCommissionRate: number;
  commissions: ProgrammeCommissions;
  viewCommissions: boolean;
  avatarUrl: string | null;
  youtubeConnected: boolean | null;
  instagramConnected: boolean | null;
}

export interface Influencer {
  influencerFirstName: string;
  influencerLastName: string;
  influencerAddress: string;
  influencerCountry: string;
  influencerPostcode: string;
  influencerPhoneNumber: string;
  influencerInterests: string[];
  keyCloakId: string;
  influencerId: string;
  avatarUrl: string;
}

export interface InfluencerAPIModel {
  influencer_first_name: string;
  influencer_last_name: string;
  influencer_address: string;
  influencer_country: string;
  influencer_postcode: string;
  influencer_phone_number: string;
  influencer_interests: string[];
  key_cloak_id: string;
  influencer_id: string;
  avatar_url: string;
}
export interface Pagination {
  total_count: number;
  limit: number;
  page: number;
}
export interface PaginatedAssociationApi {
  pagination: Pagination;
  associations: AssociationAPIModel[];
}

export interface PaginatedInvitationsApi {
  pagination: Pagination;
  invitations: InvitationApi[];
}

export interface AssociationAPIModel {
  first_name: string;
  last_name: string;
  influencer_id: string;
  programme_id: string;
  influencer_discount_code: string;
  invited_date: string;
  accepted_date: string;
  created_by_marketer_id: string;
  new_customer_commission_rate: number;
  returning_customer_commission_rate: number;
  commissions: ProgrammeCommissionsApi;
  view_commissions: boolean;
  avatar_url: string | null;
  youtube_connected: boolean | null;
  instagram_connected: boolean | null;
}
export interface ProgrammeCommission {
  type: CommissionType;
  new: number;
  returning: number;
  target?: CommissionTarget | null;
  category?: string;
}

export interface ProgrammeCommissionApi {
  type: CommissionType;
  new: number;
  returning: number;
  target?: CommissionTarget | null;
}

export interface ProgrammeCommissionsApi {
  fallback?: ProgrammeCommission | null;
  campaign_fallback?: ProgrammeCommission[] | null;
  categories: ProgrammeCommission[];
}

export interface ProgrammeCommissions {
  programmeFallback: ProgrammeCommission | null;
  campaignFallback: ProgrammeCommission | null;
  categories: ProgrammeCommission[];
}

export interface ProgrammeFormCommissions {
  programmeFallback: ProgrammeCommission;
  categories: Commission[];
}

export interface CreateProgrammeRequest {
  brand: string;
  title: string;
  description: string;
  urls: string[];
  connectors: string[];
  created_by_admin: string;
  agreement: string;
  default_currency: string;
  state: ProgrammeState;
  programme_group_id: string | null;
  banner_image_id: string | null;
  logo_image_id: string | null;
  thumbnail_image_id: string | null;
  commissions: {
    fallback: ProgrammeCommission;
    categories: ProgrammeCommission[];
  };
}

export interface UpdateProgrammeRequest {
  brand: string;
  title: string;
  description: string;
  urls: string[];
  connectors: string[];
  agreement: string;
  programme_group_id: string | null;
  logo_image_id: string | null;
  thumbnail_image_id: string | null;
  banner_image_id: string | null;
  commissions: ProgrammeCommissionsApi;
}

export enum TransactionStatus {
  PENDING_APPROVAL = 'pending_approval',
  PENDING_PAYMENT = 'pending_payment',
  COMPLETE = 'complete',
  REJECTED = 'rejected',
}

export enum PaymentType {
  PAID = 'One Off Bonus',
  REGULAR = 'Recurring Payment',
}

export enum PaymentScheduleType {
  PAID = 'paid',
  REGULAR = 'regular',
}

export enum ProgrammeState {
  PENDING = 'pending',
  GO_LIVE = 'go_live',
  AWAITING_LIVE = 'awaiting_live',
  UNDER_TESTING = 'under_testing',
  LIVE = 'live',
}

export interface ProgrammeStateAPIModel {
  state: ProgrammeState;
}

export interface CreatePaymentRequest {
  influencer_id: string;
  programme_id: string;
  transaction_type: string;
  payment_type: string;
  payment_ref?: string | null;
  transaction_currency: string;
  transaction_amount: number;
  transaction_commission: number;
  transaction_status: TransactionStatus;
  transaction_created: string;
  additional_comments: string | null;
  campaign_id: string | null;
}

export interface TransactionApiModel {
  transaction_id: string;
  influencer_id: string;
  programme_id: string;
  transaction_type: string;
  payment_type?: string | null;
  payment_ref: string;
  transaction_currency: string;
  transaction_amount: number;
  transaction_commission: number;
  transaction_status: string;
  transaction_created: string;
  vat: VatPayment | null;
}

export interface Transaction {
  transactionId: string;
  influencerId: string;
  programmeId: string;
  transactionType: string;
  paymentType?: PaymentType | null;
  paymentRef: string;
  transactionCurrency: string;
  transactionAmount: number;
  transactionCommission: number;
  transactionStatus: TransactionStatus;
  transactionCreated: Dayjs;
  vat: VatPayment | null;
}

export interface ApproveTransactionApiModel {
  transaction_id: string;
  approved_by_marketer_id: string;
  approved_date: string;
}

export interface RejectTransactionApiModel {
  transaction_id: string;
  rejected_by_marketer_id: string;
  rejected_date: string;
  rejected_reason: string;
}

export interface ProgrammeAgreementApiModel {
  programme_id: string;
  version: string;
  created_at: string;
  created_by_admin: string;
  agreement: string;
}

export interface ProgrammeQuery extends PaginationQuery {
  name: string | null;
}

export interface ProgrammePerformanceQueries extends PaginationQuery {
  influencer_id: string | null;
  period: string;
  sort_by: string;
}

export interface CampaignAssociationsQuery extends PaginationQuery {
  influencer_name: string | null;
}

export interface CampaignInvitationsQueryFilters {
  invited_by: string | null;
  invitation_status: string | null;
  invitation_date: string | null;
}

export interface CampaignInvitationsQuery
  extends PaginationQuery,
    CampaignInvitationsQueryFilters {
  name: string | null;
}

export interface ProgrammeQueries {
  associations: ProgrammeQuery | null;
  invitations: ProgrammeQuery | null;
}

export interface CampaignQueries {
  associations: CampaignAssociationsQuery | null;
  invitations: CampaignInvitationsQuery | null;
}

export interface Programme extends CreateProgrammeRequest {
  id: string;
  associations: Paginated<Association> | null;
  associationsList: Association[] | null;
  influencers: Influencer[] | null;
  transactions: Transaction[] | null;
  queries: ProgrammeQueries;
}

export enum SortByField {
  INFLUENCER_NAME = 'influencer_name',
  INSTAGRAM_FOLLOWERS = 'instagram_num_subs',
  YOUTUBE_SUBSCRIBERS = 'youtube_num_subs',
}

export enum SortDirection {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export interface InfluencerQuery extends PaginationQuery {
  name: string | null;
  sort_field?: string | null;
  sort_dir?: SortDirection | null;
}

export type InvitationToken = {
  token: string;
};

export enum InviteStatus {
  PENDING = 'pending',
  VIEWED = 'viewed',
  DECLINED = 'declined',
}

export interface InvitationApi {
  id: string;
  created_timestamp: string;
  keycloak_id: string | null;
  programme_id: string;
  influencer_name: string;
  created_by_marketer_name: string;
  created_by_marketer_id: string;
  status: InviteStatus;
  commissions: ProgrammeCommissionsApi;
  agreement: string | null;
  view_commissions: boolean;
  campaign_id: string | null;
}

export interface Invitation {
  id: string;
  createdTimestamp: string;
  keycloakId: string | null;
  programmeId: string;
  influencerName: string;
  createdByMarketerName: string;
  createdByMarketerId: string;
  status: InviteStatus;
  commissions: ProgrammeCommissions;
  agreement: string | null;
  viewCommissions: boolean;
  campaignId: string | null;
}

export interface BulkInviteBody {
  programme_id: string;
  influencers: StringStringMap;
  agreement: null | string;
  commissions: ProgrammeCommissionsApi;
  view_commissions: boolean;
  send_email: boolean;
  email_locale: string | null;
  campaign_id: string | null;
  socials: SocialsTab;
}

export enum SuccessfulInvitationStatus {
  NOTIFICATION_SENT = 'NOTIFICATION_SENT',
  EMAIL_SENT = 'EMAIL_SENT',
  INVITE_CREATED = 'INVITE_CREATED',
}
export enum UnsuccessfulInvitationStatus {
  ALREADY_IN_PROGRAMME = 'ALREADY_IN_PROGRAMME',
  NON_INFLUENCER = 'NON_INFLUENCER',
  INVITE_FOR_EMAIL_EXISTS = 'INVITE_FOR_EMAIL_EXISTS',
}
export type InvitationStatus =
  | SuccessfulInvitationStatus
  | UnsuccessfulInvitationStatus;

export interface InviteResponse {
  status: InvitationStatus;
  token: string | null;
}
export interface BulkInviteResponse {
  invite_tokens: StringMap<InviteResponse>;
}

export interface ProgrammeGroupAPIResponse {
  programme_groups: ProgrammeGroupAPIModel[];
}

export interface ProgrammeGroupAPIModel {
  id: string;
  name: string;
  created_by_admin: string;
}

export interface ProgrammeGroup {
  id: string;
  name: string;
  createdByAdmin: string;
}

export interface CreateProgrammeGroup {
  name: string;
}

export interface Notification {
  programme_id: string;
  message: string;
  created_at: string;
  read: boolean;
}

export interface ImageUploadAPIResponse {
  file_id: string;
}

export interface ProgrammeAgreement {
  programmeId: string;
  version: string;
  createdAt: string;
  createdByAdmin: string;
  agreement: string;
}

export interface CampaignSummary {
  campaignId: string;
  campaignBannerId: string;
  name: string;
  activeInfluencers: number;
  startDate: Dayjs;
  endDate: Dayjs;
  isActive: boolean;
  hasExpired: boolean;
  daysToExpiry?: number;
}

export interface CampaignSummaryAPI {
  id: string;
  name: string;
  start_date: string;
  end_date: string;
  active_influencers: number;
  campaign_banner_id: string;
  is_active: boolean;
  has_expired: boolean;
  days_to_expiry?: number;
}

export interface CampaignLinkAPI {
  title: string;
  description: string;
  destination_url: string;
}

export interface ReadCampaignAPI {
  name: string;
  brand_name: string;
  brief: string;
  start_date: string;
  end_date: string;
  time_zone: string;
  budget: number;
  campaign_banner_id: string;
  visual_direction: string[];
  connectors: string[];
  links: CampaignLinkAPI[];
  dos: string[];
  donts: string[];
  additional_images: string[];
  campaign_id: string;
  programme_id: string;
  is_active: boolean;
  has_expired: boolean;
  days_to_expiry?: number;
}

export interface CampaignAssociationAPI {
  influencer_id: string;
  programme_id: string;
  key_cloak_id: string;
  influencer_discount_code: string;
  invited_date: string;
  accepted_date: string;
  created_by_marketer_id: string;
  agreement_version_accepted: number;
  view_commissions: boolean;
  first_name: string;
  last_name: string;
  avatar_url: string;
  instagram_connected: boolean;
  youtube_connected: boolean;
  commissions: ProgrammeCommissionsApi;
}

export interface PaginatedCampaignAssociationAPI {
  pagination: Pagination;
  associations: CampaignAssociationAPI[];
}

export interface CommissionsSummaryAPI {
  total_count: number;
  total_commission: number;
}

export interface CampaignAssociationInfluencer {
  influencerId: string;
  keyCloakId: string;
  influencerDiscountCode: string;
  firstName: string;
  lastName: string;
  avatarUrl: string | null;
  instagramConnected: boolean;
  youtubeConnected: boolean;
}

export interface CampaignAssociation {
  influencerDetails: CampaignAssociationInfluencer;
  programmeId: string;
  invitedDate: string;
  acceptedDate: string;
  createdByMarketerId: string;
  agreementVersionAcception: number;
  viewCommissions: boolean;
  commissions: ProgrammeCommissions;
}

export interface CampaignLink {
  title: string;
  description: string;
  destinationUrl: string;
}

export interface ReadCampaign {
  [key: string]: any;
  name: string;
  brandName: string;
  brief: string;
  startDate: Dayjs;
  endDate: Dayjs;
  timeZone: string;
  budget: number;
  campaignBannerId: string;
  visualDirection: string[];
  connectors: string[];
  links: CampaignLink[];
  dos: string[];
  donts: string[];
  additionalImages: string[];
  campaignId: string;
  programmeId: string;
  isActive: boolean;
  hasExpired: boolean;
  daysToExpiry?: number;
}

export interface CommissionsSummary {
  totalCount: number;
  totalCommission: number;
}
export interface CampaignState extends ReadCampaign {
  associations: Paginated<CampaignAssociation> | null;
  invitations: Paginated<Invitation> | null;
  queries: CampaignQueries;
  commissionsSummary: CommissionsSummary | null;
  currentDiscountCode: DiscountCode | null;
}

export interface ProgrammesState {
  pageLimits: number[];
  currentProgramme: Programme | null;
  programmes: Programme[] | null;

  invitationToken: InvitationToken | null;
  isFetching: boolean;
  isFetchingInfluencers: boolean;
  error: string | null;

  programmeFormBody: ProgrammeFormBody;
  programmeFormPage: number;
  isCreatingProgramme: boolean;
  createProgrammeError: string | null;
  isUpdatingProgramme: boolean;
  updateProgrammeError: string | null;

  campaignFormBody: CampaignFormBody;
  isUploadingCampaignImage: boolean;
  isUploadingCampaignImageError: boolean | null;

  campaignTimeZones: TimeZone[];
  isFetchingCampaignTimeZones: boolean;
  fetchCampaignTimeZonesError: string | null;

  isDeletingCampaign: boolean;
  isDeletingCampaignError: boolean;

  isCreatingCampaign: boolean;
  createCampaignError: string | null;

  needsToRefreshInvitations: boolean;
  needsRefreshAssociations: boolean;

  inviteInfluencerFormPage: number;

  isCreatingPayment: boolean;
  createPaymentError: string | null;

  isFetchingAssociationAgreement: boolean;
  fetchAssociationAgreementError: string | null;

  currentAssociationAgreement: ProgrammeAgreement | null;

  currentDiscountCodes: DiscountCode[] | null;

  isFetchingTransactions: boolean;
  fetchTransactionsError: string | null;

  isApprovingTransaction: boolean;
  approveTransactionError: string | null;

  isRejectingTransaction: boolean;
  rejectTransactionError: string | null;

  isUpdatingAssociation: boolean;
  updateAssociationError: string | null;

  invitations: Paginated<Invitation> | null;

  isFetchingInvitations: boolean;
  isCreatingInvitation: boolean;
  isUpdatingInvitation: boolean;
  isDeletingInvitation: boolean;
  createInvitationError: string | null;
  updateInvitationError: string | null;
  deleteInvitationError: string | null;
  invitationFormBody: InvitationFormBody;
  commissionsFormBody: CommissionsFormBody;

  paymentTypes: PaymentType[] | null;
  isFetchingPaymentTypes: boolean;

  notifications: Notification[] | null;
  isFetchingNotifications: boolean;
  fetchNotificationsError: string | null;

  isUpdatingNotification: boolean;
  updateNotificationError: string | null;

  isUpdatingState: boolean;
  updatingStateError: string | null;

  filterGroups: { [index: string]: boolean };

  programmeGroups: ProgrammeGroup[] | null;
  isFetchingProgrammeGroups: boolean;
  isCreatingProgrammeGroup: boolean;
  createProgrammeGroupError: string | null;

  isUploadingImage: boolean;
  uploadImageError: string | null;

  hasSubmittedPaymentRequest: boolean;

  invitableProgrammes: Programme[] | null;
  isFetchingInvitableProgrammes: boolean;
  invitableProgrammesError: string | null;

  bulkInvitesResponse: BulkInviteResponse | null;
  isBulkInvitingInfluencers: boolean;
  bulkInvitesError: string | null;

  isFetchingCampaigns: boolean;
  isFetchingCampaignsError: boolean;
  campaigns: CampaignSummary[] | null;

  isFetchingCampaign: boolean;
  isFetchingCampaignError: boolean;
  campaign: CampaignState | null;
  campaignFormPage: number;

  isCreatingDiscountCode: boolean;
  isDeletingDiscountCode: boolean;

  isLoading: {
    putCampaign: boolean;
    fetchCampaignAssociations: boolean;
    fetchCampaignInvitations: boolean;
    fetchCampaignCommissionsSummary: boolean;
    fetchInfluencerCommissionsSummary: boolean;
    fetchDiscountCode: boolean;
    fetchCampaignDiscountCode: boolean;
    programmePerformanceInfluencers: boolean;
    aggregatedProgrammePerformance: boolean;
    discountTrackingEnabled: boolean;
  };

  isDiscountTrackingEnabled: boolean;

  isFetchingProgrammeApplications: boolean;
  isErrorProgrammeApplications: boolean;
  programmeApplications: Paginated<Application> | null;

  errors: {
    putCampaign: string | null;
    fetchCampaignAssociations: string | null;
    fetchCampaignInvitations: string | null;
    fetchCampaignCommissionsSummary: string | null;
    fetchInfluencerCommissionsSummary: string | null;
    fetchDiscountCode: string | null;
    fetchCampaignDiscountCode: string | null;

    createDiscountCodeError: DiscountCodeBadRequestError | null;
    updateDiscountCodeError: DiscountCodeBadRequestError | null;
    deleteDiscountCodeError: DiscountCodeBadRequestError | null;

    programmePerformanceInfluencers: string | null;
    aggregatedProgrammePerformance: string | null;

    discountTrackingEnabled: string | null;
  };

  campaignInvitations: Invitation[];
  campaignInvitationsError: string | null;

  isFetchingInvitableInfluencers: boolean;
  isFetchingInvitableInfluencersError: boolean;

  invitableInfluencers: Paginated<InfluencerData> | null;

  isRejectingProgrammeApplication: boolean;
  isRejectingProgrammeApplicationSuccess: boolean | null;

  programmePerformanceInfluencers: Paginated<ProgrammePerformanceInfluencer> | null;
  aggregatedProgrammePerformance: ProgrammePerformanceData | null;

  isFetchingCSV: boolean;
  programmePerformanceCSV: string | null;
  fetchCSVError: string | null;
}

export type ProgrammePerformanceInfluencersAPI = Record<
  string,
  ProgrammePerformanceDataAPI
>;

export type ProgrammePerformanceInfluencers = Record<
  string,
  ProgrammePerformanceData
>;

export interface ProgrammePerformanceDataAPI {
  clicks: number | null;
  conversion_rate: number | null;
  code_usage: number | null;
  total_orders: number | null;
  average_order_value: number | null;
  revenue: number | null;
  commission: number | null;
}

export interface ProgrammePerformanceData {
  clicks: number | null;
  conversionRate: number | null;
  codeUsage: number | null;
  totalOrders: number | null;
  averageOrderValue: number | null;
  revenue: number | null;
  commission: number | null;
}

export enum PerformanceSortByField {
  CLICKS = 'clicks',
  CONVERSION_RATE = 'conversion_rate',
  CODE_USAGE = 'code_usage',
  TOTAL_ORDERS = 'total_orders',
  AVERAGE_ORDER_VALUE = 'average_order_value',
  REVENUE = 'revenue',
  COMMISSION = 'commission',
}

export const defaultCreateProgrammeRequest: CreateProgrammeRequest = {
  brand: '',
  title: '',
  description: '',
  agreement: '',
  urls: [''],
  connectors: [''],
  created_by_admin: '',
  default_currency: Currency.GBP,
  state: ProgrammeState.PENDING,
  programme_group_id: null,
  banner_image_id: null,
  logo_image_id: null,
  thumbnail_image_id: null,
  commissions: {
    fallback: {
      type: CommissionType.fixed,
      new: 0,
      returning: 0,
      target: CommissionTarget.PROGRAMME,
    },
    categories: [],
  },
};
export interface CampaignURLs {
  title: string;
  description: string;
  destination_url: string;
}

export interface CampaignImages {
  [index: number]: string | null;
}

export const defaultCampaignImages: CampaignImages = {
  [0]: '',
};

export interface CampaignFormBody {
  [key: string]: any;
  campaign_name: string;
  brand_name: string;
  campaign_brief: string;
  start_date: string;
  end_date: string;
  start_time: string;
  end_time: string;
  time_zone: string;
  budget: number | null;
  campaign_banner_id: string | null;
  visual_direction_ids: CampaignImages;
  additional_images_ids: CampaignImages;
  urls: CampaignURLs[];
  connectors: string[];
  campaign_dos: string[];
  campaign_donts: string[];
}

export interface CampaignBody {
  name: string;
  brand_name: string;
  brief: string;
  start_date: string;
  end_date: string;
  time_zone: string;
  budget: number;
  campaign_banner_id: string | null;
  visual_direction: string[];
  additional_images: string[];
  links: CampaignURLs[];
  connectors: string[];
  dos: string[];
  donts: string[];
}

export const defaultCampaign: CampaignFormBody = {
  campaign_name: '',
  brand_name: '',
  campaign_brief: '',
  start_date: '',
  end_date: '',
  start_time: '',
  end_time: '',
  time_zone: '',
  budget: null,
  campaign_banner_id: null,
  visual_direction_ids: defaultCampaignImages,
  additional_images_ids: defaultCampaignImages,
  urls: [{ title: '', description: '', destination_url: 'https://' }],
  connectors: [''],
  campaign_dos: [''],
  campaign_donts: [''],
};

export interface ProgrammeFormBody {
  brand: string;
  title: string;
  description: string;
  defaultCurrency: string;
  urls: string[];
  connectors: string[];
  createdByAdmin: string;
  commissions: ProgrammeFormCommissions;
  agreement: string;
  programmeGroupId: string;
  bannerImageId: string | null;
  logoImageId: string | null;
  thumbnailImageId: string | null;
}

export const defaultCommissions: ProgrammeCommissions = {
  programmeFallback: {
    type: CommissionType.percentage,
    new: 0,
    returning: 0,
    target: CommissionTarget.PROGRAMME,
  },
  campaignFallback: null,
  categories: [
    {
      type: CommissionType.percentage,
      new: 0,
      returning: 0,
      target: CommissionTarget.PROGRAMME,
      category: 'default',
    },
  ],
};

export const defaultCommisisonFormBody: CommissionsFormBody = {
  programmeFallback: {
    type: CommissionType.percentage,
    new: 0,
    returning: 0,
    target: CommissionTarget.PROGRAMME,
  },
  campaignFallback: null,
  categories: [],

  viewCommissions: '',
};

export const defaultInfluencerEmail = {
  name: '',
  email: '',
  validEmail: false,
  validName: false,
};
export const defaultInvitationBody: InvitationFormBody = {
  commissions: defaultCommisisonFormBody,
  agreement: '',
  influencerName: '',
  programmeName: '',
  emailLanguage: 'en-GB',
  sendEmail: true,
  influencers: [defaultInfluencerEmail],
};

export const defaultProgrammeFormBody: ProgrammeFormBody = {
  brand: '',
  title: '',
  description: '',
  defaultCurrency: Currency.GBP.toString(),
  urls: ['https://'],
  connectors: [''],
  createdByAdmin: '',
  commissions: {
    programmeFallback: {
      type: CommissionType.percentage,
      new: 0,
      returning: 0,
      target: CommissionTarget.PROGRAMME,
    },
    categories: [
      {
        category: '',
        type: CommissionType.percentage,
        new: 0,
        returning: 0,
        target: CommissionTarget.PROGRAMME,
      },
    ],
  },
  agreement: '',
  programmeGroupId: '',
  bannerImageId: null,
  logoImageId: null,
  thumbnailImageId: null,
};
export interface CommissionsFormBody {
  viewCommissions?: string;
  programmeFallback: ProgrammeCommission | null;
  campaignFallback: ProgrammeCommission | null;
  categories: Commission[];
}

export interface InfluencerEmail {
  name: string;
  email: string;
  validName: boolean;
  validEmail: boolean;
}
export interface InvitationFormBody {
  commissions: CommissionsFormBody;
  agreement: string;
  influencerName: string;
  programmeName: string;
  emailLanguage: string;
  sendEmail: boolean;
  influencers: InfluencerEmail[];
}

export interface UpdateInvitationBody {
  influencer_name: string;
  commissions: ProgrammeCommissionsApi;
  agreement: string | null;
  keycloak_id: string | null;
  view_commissions: boolean;
}

export interface UpdateAssociationBody {
  influencer_discount_code: string;
  commissions: ProgrammeCommissionsApi;
  view_commissions: boolean;
}

export const defaultCommissionsForm: CommissionsFormBody = {
  programmeFallback: {
    type: CommissionType.percentage,
    new: 0,
    returning: 0,
    target: CommissionTarget.PROGRAMME,
  },
  campaignFallback: null,
  categories: [
    {
      category: '',
      type: CommissionType.percentage,
      new: 0,
      returning: 0,
      target: CommissionTarget.PROGRAMME,
    },
  ],
  viewCommissions: '',
};

export interface ApplicationAPI {
  application_id: string;
  programme_id: string;
  first_name: string;
  last_name: string;
  email_address: string;
  social_channels: StringStringMap;
  primary_audience_region: string;
  secondary_audience_region?: string;
  categories_need_help?: string;
  time_creating?: string;
  created_at: string;
}
export interface Application {
  applicationId: string;
  programmeId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  socialChannels: StringStringMap;
  primaryAudienceRegion: string;
  secondaryAudienceRegion?: string;
  categoriesNeedHelp?: string;
  timeCreating?: string;
  createdAt: Dayjs;
}
export interface PaginatedApplicationsAPI {
  pagination: Pagination;
  applications: ApplicationAPI[];
}

export interface ApplicationQuery extends PaginationQuery {
  name: string | null;
}

export interface DiscountCodeBadRequestError {
  code: string | number;
  message: string;
}

export const defaultProgrammesState: ProgrammesState = {
  pageLimits: [15, 30, 60],
  currentProgramme: null,
  programmes: null,
  invitationToken: null,
  isFetching: false,
  isFetchingInfluencers: false,
  error: null,

  programmeFormBody: defaultProgrammeFormBody,
  programmeFormPage: 0,
  isCreatingProgramme: false,
  createProgrammeError: null,
  isUpdatingProgramme: false,
  updateProgrammeError: null,

  campaignFormBody: defaultCampaign,
  isUploadingCampaignImage: false,
  isUploadingCampaignImageError: null,

  campaignTimeZones: [],
  isFetchingCampaignTimeZones: false,
  fetchCampaignTimeZonesError: null,

  isCreatingCampaign: false,
  createCampaignError: null,

  inviteInfluencerFormPage: 0,

  needsToRefreshInvitations: false,
  needsRefreshAssociations: false,

  isFetchingAssociationAgreement: false,
  fetchAssociationAgreementError: null,
  currentAssociationAgreement: null,

  isUpdatingAssociation: false,
  updateAssociationError: null,

  isCreatingPayment: false,
  createPaymentError: null,

  isFetchingTransactions: false,
  fetchTransactionsError: null,

  isApprovingTransaction: false,
  approveTransactionError: null,

  isRejectingTransaction: false,
  rejectTransactionError: null,

  invitations: null,

  isFetchingInvitations: false,
  isCreatingInvitation: false,
  isUpdatingInvitation: false,
  isDeletingInvitation: false,
  createInvitationError: null,
  updateInvitationError: null,
  deleteInvitationError: null,
  invitationFormBody: defaultInvitationBody,

  commissionsFormBody: defaultCommissionsForm,

  paymentTypes: null,
  isFetchingPaymentTypes: false,

  notifications: null,
  isFetchingNotifications: false,
  fetchNotificationsError: null,

  isUpdatingNotification: false,
  updateNotificationError: null,
  updatingStateError: null,
  isUpdatingState: false,

  filterGroups: {},

  programmeGroups: null,
  isFetchingProgrammeGroups: false,
  isCreatingProgrammeGroup: false,
  createProgrammeGroupError: null,

  isUploadingImage: false,
  uploadImageError: null,

  hasSubmittedPaymentRequest: false,

  invitableProgrammes: null,
  isFetchingInvitableProgrammes: false,
  invitableProgrammesError: null,

  isFetchingCampaigns: false,
  isFetchingCampaignsError: false,
  campaigns: null,

  isFetchingCampaign: false,
  isFetchingCampaignError: false,
  campaign: null,

  campaignFormPage: 0,

  isCreatingDiscountCode: false,

  isLoading: {
    putCampaign: false,
    fetchCampaignAssociations: false,
    fetchCampaignInvitations: false,
    fetchCampaignCommissionsSummary: false,
    fetchInfluencerCommissionsSummary: false,
    fetchDiscountCode: false,
    fetchCampaignDiscountCode: false,
    programmePerformanceInfluencers: false,
    aggregatedProgrammePerformance: false,
    discountTrackingEnabled: false,
  },
  errors: {
    putCampaign: null,
    fetchCampaignAssociations: null,
    fetchCampaignInvitations: null,
    fetchCampaignCommissionsSummary: null,
    fetchInfluencerCommissionsSummary: null,
    fetchDiscountCode: null,
    fetchCampaignDiscountCode: null,

    createDiscountCodeError: null,
    updateDiscountCodeError: null,
    deleteDiscountCodeError: null,

    programmePerformanceInfluencers: null,
    aggregatedProgrammePerformance: null,
    discountTrackingEnabled: null,
  },

  isDiscountTrackingEnabled: false,

  isDeletingCampaign: false,
  isDeletingCampaignError: false,
  campaignInvitations: [],
  campaignInvitationsError: null,

  isFetchingProgrammeApplications: false,
  isErrorProgrammeApplications: false,
  programmeApplications: null,
  isFetchingInvitableInfluencers: false,
  isFetchingInvitableInfluencersError: false,
  invitableInfluencers: null,

  bulkInvitesResponse: null,
  isBulkInvitingInfluencers: false,
  bulkInvitesError: null,

  isRejectingProgrammeApplication: false,
  isRejectingProgrammeApplicationSuccess: true,
  isDeletingDiscountCode: false,
  currentDiscountCodes: null,

  programmePerformanceInfluencers: null,
  aggregatedProgrammePerformance: null,

  isFetchingCSV: false,
  programmePerformanceCSV: null,
  fetchCSVError: null,
};

export * from './reducer';
export * from './actions';
export * from './requests';
