import { Dayjs } from 'dayjs';
import { AudienceGenderAgeMap, StringMap, StringStringMap } from '../../types';

export { SocialReducer } from './reducer';

export * from './actions';

export enum MediaType {
  image = 'image',
  video = 'video',
  story = 'story',
  carousel = 'carousel',
  reels = 'reels',
}

export enum StoryType {
  image = 'image',
  video = 'video',
}

export enum SentimentScoreCategory {
  HIGHLY_POSITIVE = 'highly-positive',
  POSITIVE = 'positive',
  NEUTRAL = 'neutral',
  NEGATIVE = 'negative',
  HIGHLY_NEGATIVE = 'highly-negative',
}

export interface SocialQueries {
  programme_id: string | null;
  influencer_id: string | null;
  campaign_id: string | null;
  date_from: string;
  date_to: string;
}
export interface SocialPaginationQueries extends SocialQueries {
  limit: number;
  page: number;
}

export interface InstagramQueries extends SocialQueries {
  type: MediaType[];
}

export interface InstagramPaginationQueries extends InstagramQueries {
  limit: number;
  page: number;
}

export interface Pagination {
  total_count: number;
  limit: number;
  page: number;
}

//Insta insights
export interface InstagramPostInsights {
  followers: number | null;
  average_reach: number | null;
  impressions: number | null;
  engagement_rate: number | null;
  total_items: number | null;
  sentiment_score: number | null;
}
export interface InstagramStoryInsights {
  average_reach: number | null;
  impressions: number | null;
  replies: number | null;
  completion_rate: number | null;
  total_items: number | null;
}

export interface InstagramReelsInsights {
  average_reach: number | null;
  plays: number | null;
  likes: number | null;
  comment_count: number | null;
  save_count: number | null;
  shares: number | null;
  total_items: number | null;
  sentiment_score: number | null;
}

export interface InstagramInsights {
  accumulated_sentiment_score: number | null;
  posts: InstagramPostInsights;
  stories: InstagramStoryInsights;
  reels: InstagramReelsInsights;
}

export interface CarouselItem {
  id: string;
  type: string;
  file_upload_id: string;
  thumbnail_file_upload_id: string;
}

//Insta content

export interface InstagramSentimentScore {
  run_date: string;
  score: number;
}
export interface InstaContent {
  id: string;
  type: keyof typeof MediaType;
  created_at: string;
  influencer_id: string;
  influencer_first_name: string;
  influencer_last_name: string;
  instagram_link: string;
  caption_text: string;
  reach: number;
  impressions: number;
  thumbnail_file_upload_id: string;
  sentiment_score: number;
  sentiment_scores: InstagramSentimentScore[];
}
export interface InstaCarouselContent extends InstaContent {
  type: 'carousel';
  carousel_items: CarouselItem[];
  like_count: number;
  comment_count: number;
  engagement: number;
  saved: number;
  is_business_discovery_post: boolean;
}
export interface InstaStandardContent extends InstaContent {
  type: 'image' | 'video';
  file_upload_id: string;
  like_count: number;
  comment_count: number;
  engagement: number;
  saved: number;
  is_business_discovery_post: boolean;
}

export interface InstaStoryContent extends InstaContent {
  type: 'story';
  replies: number;
  exits: number;
  taps_back: number;
  taps_forward: number;
  file_upload_id: string;
  last_updated_at: string;
  status: string;
  story_type: string;
}

export interface InstaReelsContent extends InstaContent {
  type: 'reels';
  replies: number;
  exits: number;
  taps_back: number;
  taps_forward: number;
  file_upload_id: string;
  last_updated_at: string;
  status: string;
  plays: number;
  like_count: number;
  comment_count: number;
  saved: number;
  reach: number;
  shares: number;
  total_items: number;
  engagement: number;
  is_business_discovery_post: boolean;
}

export type InstagramContent =
  | InstaCarouselContent
  | InstaStandardContent
  | InstaStoryContent
  | InstaReelsContent;

export interface InstagramPaginatedContent {
  pagination: Pagination;
  content: InstagramContent[];
}

export type InstagramByPageNumber = Map<number, InstagramContent[]>;

export interface PaginatedInstagram {
  content: InstagramByPageNumber;
  pagination: Pagination;
}

export interface Insights {
  insight_id: string;
  audience_city: StringStringMap;
  audience_country: StringStringMap;
  audience_gender_age: AudienceGenderAgeMap | StringStringMap;
  audience_locale: StringStringMap;
  email_contacts: number;
  daily_new_followers: number;
  get_directions_clicks: number;
  impressions: number;
  total_followers: number;
  media_count: number;
  phone_call_clicks: number;
  profile_views: number;
  reach: number;
  text_message_clicks: number;
  website_clicks: number;
  created_at: Dayjs;
}
export interface InsightsAPI {
  insight_id: string;
  audience_city: string;
  audience_country: string;
  audience_gender_age: string;
  audience_locale: string;
  email_contacts: number;
  daily_new_followers: number;
  get_directions_clicks: number;
  impressions: number;
  total_followers: number;
  media_count: number;
  phone_call_clicks: number;
  profile_views: number;
  reach: number;
  text_message_clicks: number;
  website_clicks: number;
  created_at: string;
}

//Youtube content

export interface YoutubeContent {
  id: string;
  type: 'video';
  created_at: string;
  influencer_id: string;
  influencer_first_name: string;
  influencer_last_name: string;
  youtube_link: string;
  title: string;
  description: string;
  thumbnail: string;
  view_count: number;
  like_count: number;
  dislike_count: number;
  favourite_count: number;
  comment_count: number;
  engagement_rate: number;
}

export interface YoutubePaginatedContent {
  pagination: Pagination;
  content: YoutubeContent[];
}

export type YoutubeByPageNumber = Map<number, YoutubeContent[]>;

export interface PaginatedYoutube {
  content: YoutubeByPageNumber;
  pagination: Pagination;
}

//Youtube Insights

export interface YoutubeInsights {
  subscribers: number;
  views: number;
  engagement_per_video: number;
  engagement_rate: number;
  like_dislike: number;
}

export enum InstagramKey {
  influencerContent = 'influencerContent',
  programmeContent = 'programmeContent',
  posts = 'posts',
  stories = 'stories',
  reels = 'reels',
}

//maps
export type InstaKeyMap<V> = {
  [index in InstagramKey]: V;
};
//Social State
export interface SocialState {
  instagramInsights: InstaKeyMap<InstagramInsights | null>;
  instagramContent: InstaKeyMap<PaginatedInstagram | null>;
  instagramQueries: StringMap<InstagramPaginationQueries | null>;

  influencerInsights: Insights[] | null;

  youtubeInsights: YoutubeInsights | null;
  youtubeContent: PaginatedYoutube | null;
  youtubeQueries: SocialPaginationQueries | null;

  pageLimits: number[];

  isLoading: {
    getInstagramInsights: InstaKeyMap<boolean>;
    getInstagramContent: InstaKeyMap<boolean>;

    getInfluencerInstaInsights: boolean;

    getYoutubeInsights: boolean;
    getYoutubeContent: boolean;
  };
  errors: {
    getInstagramInsights: InstaKeyMap<string | null>;
    getInstagramContent: InstaKeyMap<string | null>;

    getInfluencerInstaInsights: string | null;

    getYoutubeInsights: string | null;
    getYoutubeContent: string | null;
  };
}

export const defaultNullKeys: InstaKeyMap<null> = {
  influencerContent: null,
  programmeContent: null,
  posts: null,
  stories: null,
  reels: null,
};
export const defaultFalseKeys: InstaKeyMap<boolean> = {
  influencerContent: false,
  programmeContent: false,
  posts: false,
  stories: false,
  reels: false,
};

export const defaultSocialState: SocialState = {
  instagramInsights: defaultNullKeys,
  instagramContent: defaultNullKeys,
  instagramQueries: defaultNullKeys,

  influencerInsights: null,

  youtubeInsights: null,
  youtubeContent: null,

  youtubeQueries: null,

  pageLimits: [20, 40, 80],

  isLoading: {
    getInstagramInsights: defaultFalseKeys,
    getInstagramContent: defaultFalseKeys,

    getInfluencerInstaInsights: false,

    getYoutubeInsights: false,
    getYoutubeContent: false,
  },
  errors: {
    getInstagramInsights: defaultNullKeys,
    getInstagramContent: defaultNullKeys,

    getInfluencerInstaInsights: null,

    getYoutubeInsights: null,
    getYoutubeContent: null,
  },
};

export enum SocialActions {
  requestInstagramInsightsAttempt = 'REQUEST_INSTAGRAM_INSIGHTS_ATTEMPT',
  requestInstagramInsightsSuccess = 'REQUEST_INSTAGRAM_INSIGHTS_SUCCESS',
  requestInstagramInsightsFailure = 'REQUEST_INSTAGRAM_INSIGHTS_FAILURE',

  requestInstagramContentAttempt = 'REQUEST_INSTAGRAM_CONTENT_ATTEMPT',
  requestInstagramContentSuccess = 'REQUEST_INSTAGRAM_CONTENT_SUCCESS',
  requestInstagramContentFailure = 'REQUEST_INSTAGRAM_CONTENT_FAILURE',

  requestInfluencerInstaInsightsAttempt = 'REQUEST_INFLUENCER_INSTA_INSIGHTS_ATTEMPT',
  requestInfluencerInstaInsightsSuccess = 'REQUEST_INFLUENCER_INSTA_INSIGHTS_SUCCESS',
  requestInfluencerInstaInsightsFailure = 'REQUEST_INFLUENCER_INSTA_INSIGHTS_FAILURE',

  requestYoutubeInsightsAttempt = 'REQUEST_YOUTUBE_INSIGHTS_ATTEMPT',
  requestYoutubeInsightsSuccess = 'REQUEST_YOUTUBE_INSIGHTS_SUCCESS',
  requestYoutubeInsightsFailure = 'REQUEST_YOUTUBE_INSIGHTS_FAILURE',

  requestYoutubeContentAttempt = 'REQUEST_YOUTUBE_CONTENT_ATTEMPT',
  requestYoutubeContentSuccess = 'REQUEST_YOUTUBE_CONTENT_SUCCESS',
  requestYoutubeContentFailure = 'REQUEST_YOUTUBE_CONTENT_FAILURE',

  clearInstagramContent = 'CLEAR_INSTAGRAM_CONTENT',
  clearInstagramInsights = 'CLEAR_INSTAGRAM_INSIGHTS',
  setInstagramQueries = 'SET_INSTAGRAM_QUERIES',

  clearYoutubeContent = 'CLEAR_YOUTUBE_CONTENT',
  setYoutubeQueries = 'SET_YOUTUBE_QUERIES',
}

export enum SocialErrors {
  requestInstagramInsightsError = 'An error occurred while fetching instagram insights.',
  requestInstagramContentError = 'An error occurred while fetching instagram content.',

  requestYoutubeInsightsError = 'An error occurred while fetching Youtube insights.',
  requestYoutubeContentError = 'An error occurred while fetching Youtube content.',
}
