import { DispatchFn } from '../state';
import {
  Metadata,
  InfluencerQueries,
  InfluencerData,
  WriteInfluencerTaxApi,
  MetadataApi,
  CreatedByQueries,
  MetadataPerProgrammeApi,
  EditMetadataPerProgramme,
} from './store';
import client, { Microservice } from '../../utils/http.utils';
import {
  InfluencerDataAPI,
  InfluencersAPI,
  transformEditMetadataPayload,
  transformToMetadata,
  transformToMetadataApi,
  transformToMetadataPerProgramme,
} from './transformers';
import {
  InfluencerSocialAccessModel,
  RequestDeleteMetadataAsyncData,
  VATCountry,
} from './types';
import { captureException } from '../../utils/sentry.utils';
import { queryBuilder } from '../../utils/pageControllers.utils';

export enum DiscoveryActions {
  requestInfluencersData = 'REQUEST_INFLUENCERS_DATA',
  setInfluencersData = 'SET_INFLUENCERS_DATA',
  requestInfluencersDataFailure = 'REQUEST_INFLUENCERS_DATA_FAILURE',

  requestInfluencersPaginatedAttempt = 'REQUEST_INFLUENCERS_PAGINATED_ATTEMPT',
  requestInfluencersPaginatedSuccess = 'REQUEST_INFLUENCERS_PAGINATED_SUCCESS',
  requestInfluencersPaginatedFailure = 'REQUEST_INFLUENCERS_PAGINATED_FAILURE',

  requestInfluencerData = 'REQUEST_INFLUENCER_DATA',
  requestInfluencerDataFailure = 'REQUEST_INFLUENCER_DATA_FAILURE',

  requestMetadataAttempt = 'REQUEST_METADATA_ATTEMPT',
  requestMetadataSuccess = 'REQUEST_METADATA_SUCCESS',
  requestMetadataFailure = 'REQUEST_METADATA_FAILURE',

  requestMetadataPerProgrammeAttempt = 'REQUEST_METADATA_PER_PROGRAMME_ATTEMPT',
  requestMetadataPerProgrammeSuccess = 'REQUEST_METADATA_PER_PROGRAMME_SUCCESS',
  requestMetadataPerProgrammeFailure = 'REQUEST_METADATA_PER_PROGRAMME_FAILURE',

  requestPostMetadataAttempt = 'REQUEST_POST_METADATA_ATTEMPT',
  requestPostMetadataSuccess = 'REQUEST_POST_METADATA_SUCCESS',
  requestPostMetadataFailure = 'REQUEST_POST_METADATA_FAILURE',

  requestPutMetadataAttempt = 'REQUEST_PUT_METADATA_ATTEMPT',
  requestPutMetadataSuccess = 'REQUEST_PUT_METADATA_SUCCESS',
  requestPutMetadataFailure = 'REQUEST_PUT_METADATA_FAILURE',

  requestPutMetadataPerProgrammeAttempt = 'REQUEST_PUT_METADATA_PER_PROGRAMME_ATTEMPT',
  requestPutMetadataPerProgrammeSuccess = 'REQUEST_PUT_METADATA_PER_PROGRAMME_SUCCESS',
  requestPutMetadataPerProgrammeFailure = 'REQUEST_PUT_METADATA_PER_PROGRAMME_FAILURE',

  requestDeleteMetadataAttempt = 'REQUEST_DELETE_METADATA_ATTEMPT',
  requestDeleteMetadataSuccess = 'REQUEST_DELETE_METADATA_SUCCESS',
  requestDeleteMetadataFailure = 'REQUEST_DELETE_METADATA_FAILURE',

  requestMetadataTypesAttempt = 'REQUEST_METADATA_TYPES_ATTEMPT',
  requestMetadataTypesSuccess = 'REQUEST_METADATA_TYPES_SUCCESS',
  requestMetadataTypesFailure = 'REQUEST_METADATA_TYPES_FAILURE',

  requestClearCurrentInfluencer = 'REQUEST_CLEAR_CURRENT_INFLUENCER',

  requestGetInstagramDetailsAttempt = 'REQUEST_GET_INFLUENCER_PROFILE_PICTURE_ATTEMPT',
  requestGetInstagramDetailsSuccess = 'REQUEST_GET_INFLUENCER_PROFILE_PICTURE_SUCCESS',
  requestGetInstagramDetailsFailure = 'REQUEST_GET_INFLUENCER_PROFILE_PICTURE_FAILURE',

  requestInfluencerInterestTypesAttempt = 'REQUEST_INFLUENCER_INTEREST_ATTEMPT',
  requestInfluencerInterestTypesSuccess = 'REQUEST_INFLUENCER_INTEREST_SUCCESS',
  requestInfluencerInterestTypesFailure = 'REQUEST_INFLUENCER_INTEREST_FAILURE',

  requestGetSocialAccessAttempt = 'REQUEST_GET_SOCIAL_ACCESS_ATTEMPT',
  requestGetSocialAccessSuccess = 'REQUEST_GET_SOCIAL_ACCESS_SUCCESS',
  requestGetSocialAccessFailure = 'REQUEST_GET_SOCIAL_ACCESS_FAILURE',

  requestPutSocialAccessAttempt = 'REQUEST_PUT_SOCIAL_ACCESS_ATTEMPT',
  requestPutSocialAccessSuccess = 'REQUEST_PUT_SOCIAL_ACCESS_SUCCESS',
  requestPutSocialAccessFailure = 'REQUEST_PUT_SOCIAL_ACCESS_FAILURE',

  requestGetAdminProfileAttempt = 'REQUEST_GET_ADMIN_PROFILE_ATTEMPT',
  requestGetAdminProfileSuccess = 'REQUEST_GET_ADMIN_PROFILE_SUCCESS',
  requestGetAdminProfileFailure = 'REQUEST_GET_ADMIN_PROFILE_FAILURE',

  requestPostInfluencerTaxInformationAttempt = 'REQUEST_POST_INFLUENCER_TAX_INFORMATION_ATTEMPT',
  requestPostInfluencerTaxInformationSuccess = 'REQUEST_POST_INFLUENCER_TAX_INFORMATION_SUCCESS',
  requestPostInfluencerTaxInformationFailure = 'REQUEST_POST_INFLUENCER_TAX_INFORMATION_FAILURE',

  requestGetInfluencerTaxInformationAttempt = 'REQUEST_GET_INFLUENCER_TAX_INFORMATION_ATTEMPT',
  requestGetInfluencerTaxInformationSuccess = 'REQUEST_GET_INFLUENCER_TAX_INFORMATION_SUCCESS',
  requestGetInfluencerTaxInformationFailure = 'REQUEST_GET_INFLUENCER_TAX_INFORMATION_FAILURE',

  requestPutInfluencerTaxInformationAttempt = 'REQUEST_PUT_INFLUENCER_TAX_INFORMATION_ATTEMPT',
  requestPutInfluencerTaxInformationSuccess = 'REQUEST_PUT_INFLUENCER_TAX_INFORMATION_SUCCESS',
  requestPutInfluencerTaxInformationFailure = 'REQUEST_PUT_INFLUENCER_TAX_INFORMATION_FAILURE',

  requestDeleteInfluencerTaxInformationAttempt = 'REQUEST_DELETE_INFLUENCER_TAX_INFORMATION_ATTEMPT',
  requestDeleteInfluencerTaxInformationSuccess = 'REQUEST_DELETE_INFLUENCER_TAX_INFORMATION_SUCCESS',
  requestDeleteInfluencerTaxInformationFailure = 'REQUEST_DELETE_INFLUENCER_TAX_INFORMATION_FAILURE',

  requestGetInfluencerRegisteredAtAttempt = 'REQUEST_GET_INFLUENCER_REGISTERED_AT_ATTEMPT',
  requestGetInfluencerRegisteredAtSuccess = 'REQUEST_GET_INFLUENCER_REGISTERED_AT_SUCCESS',
  requestGetInfluencerRegisteredAtFailure = 'REQUEST_GET_INFLUENCER_REGISTERED_AT_FAILURE',

  requestGetVatCountriesAttempt = 'REQUEST_GET_VAT_COUNTRIES_ATTEMPT',
  requestGetVatCountriesSuccess = 'REQUEST_GET_VAT_COUNTRIES_SUCCESS',
  requestGetVatCountriesFailure = 'REQUEST_GET_VAT_COUNTRIES_FAILURE',

  setSelectedInfluencer = 'SET_SELECTED_INFLUENCER',
  clearSelectedInfluencer = 'CLEAR_SELECTED_INFLUENCER',
  clearInfluencers = 'CLEAR_INFLUENCERS',
  setInfluencerQueries = 'SET_INFLUENCER_QUERIES',
  setCreatedByQueries = 'SET_CREATED_BY_QUERIES',
  resetInfluencersFilter = 'RESET_INFLUENCERS_FILTER',
  setInfluencerData = 'SET_INFLUENCER_DATA',
  setInfluencerQueryName = 'SET_INFLUENCER_QUERY_NAME',

  clearCurrentTaxInformation = 'CLEAR_CURRENT_TAX_INFO',
}

export enum DiscoveryActionErrors {
  requestInfluencersError = 'An error occurred while fetching influencers.',
  requestInfluencerPostsError = 'An error occurred while fetching posts.',
  requestYoutubeSubscribersError = 'An error occurred while fetching YouTube subscribers.',
  requestYoutubeVideosError = 'An error occurred while fetching YouTube video insights.',
  requestMetadataTypesError = 'An error occurred while fetching metadata types.',
  requestMetadataError = 'An error occurred while fetching metadata.',
  requestMetadataPerProgrammeError = 'An error occurred while fetching metadata per programme',
  requestPostMetadataError = 'An error occurred while creating the metadata.',
  requestPutMetadataError = 'An error occurred while updating the metadata.',
  requestDeleteMetadataError = 'An error occurred while deleting the metadata.',
  requestInfluencerInterestTypesError = 'An error occurred while fetching influencer interest types.',
  requestGetSocialAccessError = 'An error occurred while getting access for this influencer.',
  requestPutSocialAccessError = 'An error occurred while setting access for this influencer.',
}

export const RequestInfluencersData = (dispatch: DispatchFn) => {
  dispatch({ type: DiscoveryActions.requestInfluencersData });
  client
    .request<InfluencerDataAPI[]>(Microservice.INFLUENCER, '/influencer', 'GET')
    .then(json => {
      dispatch({
        type: DiscoveryActions.setInfluencersData,
        payload: json,
      });
    })
    .catch(error => {
      captureException(error);
      dispatch({
        type: DiscoveryActions.requestInfluencersDataFailure,
        payload: DiscoveryActionErrors.requestInfluencersError,
      });
    });
};

export const RequestInfluencerData = (
  dispatch: DispatchFn,
  username: string
) => {
  dispatch({ type: DiscoveryActions.requestInfluencerData });
  client
    .request(Microservice.INFLUENCER, `/influencer/${username}`, 'GET')
    .then(json => {
      dispatch({
        type: DiscoveryActions.setInfluencerData,
        payload: json,
      });
    })
    .catch(error => {
      captureException(error);
      dispatch({
        type: DiscoveryActions.requestInfluencerDataFailure,
      });
    });
};

export const RequestPaginatedInfluencersAsync = async (
  dispatch: DispatchFn,
  queries: InfluencerQueries,
  shouldClear?: boolean
) => {
  try {
    dispatch({ type: DiscoveryActions.requestInfluencersPaginatedAttempt });
    const response = await client.request<InfluencersAPI>(
      Microservice.INFLUENCER,
      `/influencer/discovery/pagination${queryBuilder<InfluencerQueries>(
        queries
      )}`,
      'GET'
    );
    dispatch({
      type: DiscoveryActions.requestInfluencersPaginatedSuccess,
      payload: { content: response, shouldClear: shouldClear },
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: DiscoveryActions.requestInfluencersPaginatedFailure,
      payload: DiscoveryActionErrors.requestInfluencersError,
    });
  }
};

export const ResetFilters = (dispatch: DispatchFn) => {
  dispatch({
    type: DiscoveryActions.resetInfluencersFilter,
  });
};

export const SetInfluencerQueries = async (
  dispatch: DispatchFn,
  filters: InfluencerQueries
): Promise<void> => {
  dispatch({
    type: DiscoveryActions.setInfluencerQueries,
    payload: filters,
  });
};

export const SetCreatedByQueries = async (
  dispatch: DispatchFn,
  filters: CreatedByQueries
): Promise<void> => {
  dispatch({
    type: DiscoveryActions.setCreatedByQueries,
    payload: filters,
  });
};

export const ClearInfluencers = (dispatch: DispatchFn) => {
  dispatch({
    type: DiscoveryActions.clearInfluencers,
  });
};

export const RequestInstagramDetails = async (
  dispatch: DispatchFn,
  influencerId: string
): Promise<void> => {
  try {
    dispatch({
      type: DiscoveryActions.requestGetInstagramDetailsAttempt,
    });

    const response = await client.request(
      Microservice.INSTAGRAM,
      `/instagram/${influencerId}`,
      'GET'
    );

    dispatch({
      type: DiscoveryActions.requestGetInstagramDetailsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: DiscoveryActions.requestGetInstagramDetailsFailure,
      payload: 'An error occurred while fetching Instagram details',
    });
  }
};

export const RequestMetadataTypesAsync = async (dispatch: DispatchFn) => {
  dispatch({ type: DiscoveryActions.requestMetadataTypesAttempt });

  try {
    const response = await client.request(
      Microservice.INFLUENCER,
      `/metadata_type`,
      'GET'
    );

    dispatch({
      type: DiscoveryActions.requestMetadataTypesSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: DiscoveryActions.requestMetadataTypesFailure,
      payload: DiscoveryActionErrors.requestMetadataTypesError,
    });
  }
};

export const RequestMetadataAsync = async (
  dispatch: DispatchFn,
  influencerId: string
) => {
  dispatch({ type: DiscoveryActions.requestMetadataAttempt });

  try {
    const response = await client.request<MetadataApi[]>(
      Microservice.INFLUENCER,
      `/influencer/${influencerId}/metadata`,
      'GET'
    );

    dispatch({
      type: DiscoveryActions.requestMetadataSuccess,
      payload: response.map(transformToMetadata),
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: DiscoveryActions.requestMetadataFailure,
      payload: DiscoveryActionErrors.requestMetadataError,
    });
  }
};

export const RequestMetadataPerProgrammeAsync = async (
  dispatch: DispatchFn,
  influencerId: string
) => {
  dispatch({ type: DiscoveryActions.requestMetadataPerProgrammeAttempt });

  try {
    const response = await client.request<MetadataPerProgrammeApi[]>(
      Microservice.INFLUENCER,
      `/metadata/influencer/${influencerId}/programme`,
      'GET'
    );

    dispatch({
      type: DiscoveryActions.requestMetadataPerProgrammeSuccess,
      payload: response.map(transformToMetadataPerProgramme),
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: DiscoveryActions.requestMetadataPerProgrammeFailure,
      payload: DiscoveryActionErrors.requestMetadataPerProgrammeError,
    });
  }
};

export const RequestPostMetadataAsync = async (
  dispatch: DispatchFn,
  influencerId: string,
  metadata: Metadata[]
) => {
  dispatch({ type: DiscoveryActions.requestPostMetadataAttempt });

  try {
    await client.request(
      Microservice.INFLUENCER,
      `/metadata/${influencerId}`,
      'POST',
      metadata.map(transformToMetadataApi)
    );

    dispatch({
      type: DiscoveryActions.requestPostMetadataSuccess,
      payload: metadata,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: DiscoveryActions.requestPostMetadataFailure,
      payload: DiscoveryActionErrors.requestPostMetadataError,
    });
    throw new Error(e);
  }
};

export const RequestPutMetadataAsync = async (
  dispatch: DispatchFn,
  influencerId: string,
  metadata: Metadata[]
) => {
  dispatch({ type: DiscoveryActions.requestPutMetadataAttempt });

  try {
    const requests = metadata.map(({ type, value, programmeId }) => {
      const params = new URLSearchParams({
        type,
        ...(programmeId ? { programme_id: programmeId } : {}),
      });
      return client.request(
        Microservice.INFLUENCER,
        `/metadata/${influencerId}?${params.toString()}`,
        'PUT',
        { value }
      );
    });
    await Promise.all(requests);

    dispatch({
      type: DiscoveryActions.requestPutMetadataSuccess,
      payload: metadata,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: DiscoveryActions.requestPutMetadataFailure,
      payload: DiscoveryActionErrors.requestPutMetadataError,
    });
    throw new Error(e);
  }
};

export const RequestPutUpdateMetadataPerProgrammeAsync = async (
  dispatch: DispatchFn,
  influencerId: string,
  programmeId: string,
  metadata: EditMetadataPerProgramme
) => {
  dispatch({ type: DiscoveryActions.requestPutMetadataPerProgrammeAttempt });
  try {
    return client
      .request<
        Metadata[],
        EditMetadataPerProgramme
      >(Microservice.INFLUENCER, `/metadata/influencer/${influencerId}/programme/${programmeId}`, 'PUT', metadata)
      .then(res => {
        dispatch({
          type: DiscoveryActions.requestPutMetadataPerProgrammeSuccess,
          payload: transformEditMetadataPayload(metadata, programmeId),
        });
        return res;
      });
  } catch (e) {
    captureException(e);
    dispatch({
      type: DiscoveryActions.requestPutMetadataPerProgrammeFailure,
      payload: DiscoveryActionErrors.requestPutMetadataError,
    });
    throw e;
  }
};

export const RequestDeleteMetadataAsync = async (
  dispatch: DispatchFn,
  { influencerId, type, programmeId }: RequestDeleteMetadataAsyncData
) => {
  dispatch({ type: DiscoveryActions.requestDeleteMetadataAttempt });

  try {
    const params = new URLSearchParams({
      type,
      ...(programmeId ? { programme_id: programmeId } : {}),
    });

    await client.request(
      Microservice.INFLUENCER,
      `/metadata/${influencerId}?${params.toString()}`,
      'DELETE'
    );

    dispatch({
      type: DiscoveryActions.requestDeleteMetadataSuccess,
      payload: { type, programmeId },
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: DiscoveryActions.requestDeleteMetadataFailure,
      payload: DiscoveryActionErrors.requestDeleteMetadataError,
    });
    throw new Error(e);
  }
};

export const ClearCurrentInfluencer = async (dispatch: DispatchFn) => {
  dispatch({ type: DiscoveryActions.requestClearCurrentInfluencer });
};

export const SetSelectedInfluencer = async (
  dispatch: DispatchFn,
  influencer: InfluencerData
) => {
  dispatch({
    type: DiscoveryActions.setSelectedInfluencer,
    payload: influencer,
  });
};

export const ClearSelectedInfluencer = async (dispatch: DispatchFn) => {
  dispatch({ type: DiscoveryActions.clearSelectedInfluencer });
};

export const RequestInfluencerInterestTypesAsync = async (
  dispatch: DispatchFn
) => {
  try {
    dispatch({
      type: DiscoveryActions.requestInfluencerInterestTypesAttempt,
    });
    const response = await client.request(
      Microservice.INFLUENCER,
      `/influencer/interests`,
      'GET'
    );
    dispatch({
      type: DiscoveryActions.requestInfluencerInterestTypesSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: DiscoveryActions.requestInfluencerInterestTypesFailure,
      payload: DiscoveryActionErrors.requestInfluencersError,
    });
  }
};

export const RequestGetSocialAccess = async (
  influencerId: string,
  dispatch: DispatchFn
) => {
  try {
    dispatch({
      type: DiscoveryActions.requestGetSocialAccessAttempt,
    });
    const response = await client.request(
      Microservice.INFLUENCER,
      `/influencer/${influencerId}/social_requirement`,
      'GET'
    );
    dispatch({
      type: DiscoveryActions.requestGetSocialAccessSuccess,
      payload: response as InfluencerSocialAccessModel,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: DiscoveryActions.requestGetSocialAccessFailure,
      payload: DiscoveryActionErrors.requestGetSocialAccessError,
    });
  }
};

export const RequestPutSocialAccessOverride = async (
  influencerId: string,
  body: InfluencerSocialAccessModel,
  dispatch: DispatchFn
) => {
  try {
    dispatch({
      type: DiscoveryActions.requestPutSocialAccessAttempt,
    });
    const response = await client.request(
      Microservice.INFLUENCER,
      `/influencer/${influencerId}/social_requirement`,
      'PUT',
      body
    );
    dispatch({
      type: DiscoveryActions.requestPutSocialAccessSuccess,
      payload: response as InfluencerSocialAccessModel,
    });
  } catch (e) {
    dispatch({
      type: DiscoveryActions.requestPutSocialAccessFailure,
      payload: DiscoveryActionErrors.requestPutSocialAccessError,
    });
    throw new Error(e);
  }
};

export const RequestGetUserProfileAsync = async (
  dispatch: DispatchFn,
  keycloak_id: string
): Promise<void> => {
  try {
    dispatch({ type: DiscoveryActions.requestGetAdminProfileAttempt });
    const response = await client.request(
      Microservice.PROFILE,
      `/user/${keycloak_id}`,
      'GET'
    );
    dispatch({
      type: DiscoveryActions.requestGetAdminProfileSuccess,
      payload: response,
    });
  } catch {
    dispatch({
      type: DiscoveryActions.requestGetAdminProfileFailure,
    });
  }
};

export const SetInfluencerQueryName = async (
  dispatch: DispatchFn,
  search: string | null
) => {
  dispatch({ type: DiscoveryActions.setInfluencerQueryName, payload: search });
};

export const RequestPostInfluencerTaxInformation = async (
  dispatch: DispatchFn,
  influencerId: string,
  taxInformation: WriteInfluencerTaxApi
): Promise<void> => {
  try {
    dispatch({
      type: DiscoveryActions.requestPostInfluencerTaxInformationAttempt,
    });
    const response = await client.request(
      Microservice.TRANSACTION,
      `/tax_information/influencer/${influencerId}`,
      'POST',
      taxInformation
    );
    dispatch({
      type: DiscoveryActions.requestPostInfluencerTaxInformationSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: DiscoveryActions.requestPostInfluencerTaxInformationFailure,
      payload: error.status,
    });
    throw new Error(error.status);
  }
};

export const RequestGetInfluencerTaxInformation = async (
  dispatch: DispatchFn,
  influencerId: string
): Promise<void> => {
  try {
    dispatch({
      type: DiscoveryActions.requestGetInfluencerTaxInformationAttempt,
    });
    const response = await client.request(
      Microservice.TRANSACTION,
      `/tax_information/influencer/${influencerId}`,
      'GET'
    );
    dispatch({
      type: DiscoveryActions.requestGetInfluencerTaxInformationSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: DiscoveryActions.requestGetInfluencerTaxInformationFailure,
      payload: error,
    });
    throw new Error();
  }
};

export const RequestPutInfluencerTaxInformation = async (
  dispatch: DispatchFn,
  influencerId: string,
  taxId: string,
  taxInformation: WriteInfluencerTaxApi
): Promise<void> => {
  try {
    dispatch({
      type: DiscoveryActions.requestPutInfluencerTaxInformationAttempt,
    });
    const response = await client.request(
      Microservice.TRANSACTION,
      `/tax_information/${taxId}/influencer/${influencerId}`,
      'PUT',
      taxInformation
    );
    dispatch({
      type: DiscoveryActions.requestPutInfluencerTaxInformationSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: DiscoveryActions.requestPutInfluencerTaxInformationFailure,
      payload: error.status,
    });
    throw new Error(error.status);
  }
};

export const RequestDeleteInfluencerTaxInformation = async (
  dispatch: DispatchFn,
  influencerId: string,
  taxId: string
): Promise<void> => {
  try {
    dispatch({
      type: DiscoveryActions.requestDeleteInfluencerTaxInformationAttempt,
    });
    const response = await client.request(
      Microservice.TRANSACTION,
      `/tax_information/${taxId}/influencer/${influencerId}`,
      'DELETE'
    );
    dispatch({
      type: DiscoveryActions.requestDeleteInfluencerTaxInformationSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: DiscoveryActions.requestDeleteInfluencerTaxInformationFailure,
      payload: error,
    });
    throw new Error();
  }
};

export const RequestGetInfluencerRegisteredAt = async (
  dispatch: DispatchFn,
  influencerId: string
): Promise<void> => {
  try {
    dispatch({
      type: DiscoveryActions.requestGetInfluencerRegisteredAtAttempt,
    });
    const response = await client.request(
      Microservice.INFLUENCER,
      `/influencer/${influencerId}/registered_at`,
      'GET'
    );
    dispatch({
      type: DiscoveryActions.requestGetInfluencerRegisteredAtSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: DiscoveryActions.requestGetInfluencerRegisteredAtFailure,
      payload: error,
    });
    throw new Error();
  }
};

export const RequestGetVatCountries = async (
  dispatch: DispatchFn
): Promise<void> => {
  const time = new Date().toISOString();
  try {
    dispatch({
      type: DiscoveryActions.requestGetVatCountriesAttempt,
    });
    const response = await client.request<VATCountry[]>(
      Microservice.TRANSACTION,
      `/tax_information/vat_countries?current_datetime=${time}`,
      'GET'
    );
    dispatch({
      type: DiscoveryActions.requestGetVatCountriesSuccess,
      payload: response,
    });
  } catch (error) {
    captureException(error);
    dispatch({
      type: DiscoveryActions.requestGetVatCountriesFailure,
      payload: error,
    });
    throw new Error();
  }
};

export const ClearCurrentTaxInformation = async (dispatch: DispatchFn) => {
  dispatch({ type: DiscoveryActions.clearCurrentTaxInformation });
};
