/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useReducer, Dispatch } from 'react';
import {
  DiscoveryState,
  defaultDiscoveryState,
  DiscoveryReducer,
} from './Discovery/store';
import {
  ProgrammesState,
  defaultProgrammesState,
  ProgrammesReducer,
} from './Programmes/store';
import {
  PaymentsState,
  defaultPaymentsState,
  PaymentsReducer,
} from './Payments/store';
import {
  PermissionsState,
  defaultPermissionsState,
  PermissionsReducer,
} from './Permissions/store';
import {
  NotificationState,
  defaultNotificationState,
  NotificationReducer,
} from './Notification/store';
import {
  TranslationsState,
  defaultTranslationsState,
  TranslationsReducer,
} from './Translations/store';
import {
  ReportingState,
  defaultReportingState,
  ReportingReducer,
} from './Reporting/store';
import {
  WatchmanState,
  defaultWatchmanState,
  WatchmanReducer,
} from './Watchman/store';
import { SocialState, defaultSocialState, SocialReducer } from './Social/store';

import { UserReducer } from './User/reducer';
import { UserState, defaultUserState } from './User/store';
import {
  AgenciesReducer,
  defaultAgenciesState,
  AgenciesState,
} from './Agencies/store';
import { EnvironmentsState } from './Environments/types';
import {
  EnvironmentsReducer,
  defaultEnvironmentsState,
} from './Environments/store';

export interface Action<T = any> {
  type: string;
  payload?: T;
}

export type DispatchFn = (action: Action) => void;

interface Props {
  children: JSX.Element[] | JSX.Element;
}

export interface State {
  environmentsState: EnvironmentsState;
  discoveryState: DiscoveryState;
  userState: UserState;
  programmesState: ProgrammesState;
  paymentsState: PaymentsState;
  permissionsState: PermissionsState;
  notificationState: NotificationState;
  translationsState: TranslationsState;
  reportingState: ReportingState;
  socialState: SocialState;
  watchmanState: WatchmanState;
  agenciesState: AgenciesState;
}

export const defaultState: State = {
  environmentsState: defaultEnvironmentsState,
  discoveryState: defaultDiscoveryState,
  userState: defaultUserState,
  programmesState: defaultProgrammesState,
  paymentsState: defaultPaymentsState,
  permissionsState: defaultPermissionsState,
  notificationState: defaultNotificationState,
  translationsState: defaultTranslationsState,
  reportingState: defaultReportingState,
  socialState: defaultSocialState,
  watchmanState: defaultWatchmanState,
  agenciesState: defaultAgenciesState,
};

const rootReducer = (state: State = defaultState, action: Action): State => {
  return {
    environmentsState: EnvironmentsReducer(state.environmentsState, action),
    discoveryState: DiscoveryReducer(state.discoveryState, action),
    userState: UserReducer(state.userState, action),
    programmesState: ProgrammesReducer(state.programmesState, action),
    paymentsState: PaymentsReducer(state.paymentsState, action),
    permissionsState: PermissionsReducer(state.permissionsState, action),
    notificationState: NotificationReducer(state.notificationState, action),
    translationsState: TranslationsReducer(state.translationsState, action),
    reportingState: ReportingReducer(state.reportingState, action),
    socialState: SocialReducer(state.socialState, action),
    watchmanState: WatchmanReducer(state.watchmanState, action),
    agenciesState: AgenciesReducer(state.agenciesState, action),
  };
};

type Store = [State, Dispatch<any>];

const StoreContext = createContext<Store>([defaultState, () => {}]);

export const StoreProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(rootReducer, defaultState);
  return (
    <StoreContext.Provider value={[state, dispatch]}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
