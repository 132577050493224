import React, { FC } from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  height: 100%;
  .cls-1 {
    fill: #fff;
  }

  .cls-1,
  .cls-2 {
    stroke-width: 0px;
  }

  .cls-2 {
    fill: #0292fc;
  }
`;

export const LogoSVG: FC = () => (
  <StyledSVG viewBox="0 0 1396.39 165.46">
    <g>
      <path
        className="cls-2"
        d="M918.16,69.15h136.61c-.08-.61-.16-1.25-.29-1.86-1.04-6.81-2.96-13.3-5.67-19.42-12.29-28.19-40.4-47.87-73.12-47.87-21.49,0-40.99,8.51-55.32,22.34-5.35,5.08-9.95,10.93-13.64,17.37-1.57,2.63-2.95,5.35-4.15,8.17-1.65,3.7-2.98,7.53-4.02,11.46-.85,3.19-1.52,6.46-1.94,9.81-.24,1.68-.4,3.35-.51,5.03-.19,2.37-.27,4.76-.19,7.15.03,1.54.11,3.06.21,4.57.03.96.13,1.94.27,2.93.08.53.13,1.06.21,1.6.13.93.27,1.86.43,2.79.16.91.32,1.84.51,2.74.37,1.83.83,3.62,1.3,5.4.27.9.53,1.78.8,2.66.85,2.61,1.81,5.19,2.93,7.69,12.29,28.19,40.4,47.87,73.11,47.87,21.49,0,40.98-8.51,55.32-22.34,5.35-5.08,9.95-10.93,13.65-17.37l-19.71-8.17h-.05c-6.23,9.68-15.4,17.66-26.89,22.39-26.54,10.93-56.47.96-71.47-22.37-1.91-2.9-3.56-6.09-4.95-9.42l-4.07-9.87-8.09-19.68,8.72-3.56v-.05ZM926.67,47.87c4.2-6.44,9.63-11.99,15.96-16.33,9.42-6.46,20.8-10.27,33.06-10.27,20.48,0,38.57,10.59,49.02,26.6h-98.04Z"
      />
      <path
        className="cls-2"
        d="M812.41,111.86h-.03c-22.69,35.45-75.62,35.37-98.17-.13-8.91-13.54-11.65-30.91-7.47-46.54,1.09-4.31,2.77-8.46,4.81-12.42,3.09-5.8,7.13-11.09,11.94-15.61,0-.03.03-.05.05-.05,7.95-7.37,17.95-12.47,28.57-14.52,15.24-3.14,31.2.56,44.18,8.88,3.62,2.02,19.84,11.3,23.72,13.54l12.29-5.08c-21.44-37.87-72.18-51.2-109.42-28.67-40.11,23.17-51.68,77.58-24.5,115.06,27.55,39.42,85.88,44.95,120.22,11.17,5.35-5.05,10.03-11.01,13.7-17.39-.05,0-19.84-8.19-19.89-8.22Z"
      />
      <path
        className="cls-2"
        d="M622.23.93c-3.48-.48-7.02-.72-10.61-.72s-7.18.24-10.67.75c-7.47.96-14.63,3.01-21.28,5.93-28.17,12.31-47.85,40.43-47.85,73.12,0,44.07,35.72,79.79,79.79,79.79s79.79-35.72,79.79-79.79c0-40.45-30.13-73.89-69.18-79.07ZM622.23,137.53c-3.43.66-6.99.98-10.61.98h-31.94v-9.47c-16.01-10.45-26.57-28.51-26.57-49.04s10.56-38.59,26.57-49.05c6.36-4.15,13.54-7.07,21.28-8.48,3.46-.66,7.02-.98,10.67-.98s7.18.32,10.61.98c27.26,4.97,47.9,28.83,47.9,57.53s-20.64,52.56-47.9,57.53Z"
      />
      <path
        className="cls-2"
        d="M853.34,124.15V2.13h21.28v154.26h-21.28v-32.23Z"
      />
      <path
        className="cls-2"
        d="M1205.27,111.71h-.05c-6.23,9.68-15.4,17.66-26.89,22.39-26.55,10.93-56.47.96-71.47-22.37-1.91-2.9-3.56-6.09-4.95-9.42l-4.07-9.87-.35-.85-7.73-18.83,7.73-3.17.99-.4v-.05h115.32c-.08-.61-.16-1.25-.29-1.86-1.04-6.81-2.95-13.3-5.67-19.41h-110.35V2.13h-21.28v77.32c0,.64,0,1.25.03,1.89.03,1.54.11,3.06.21,4.57,0,.96.13,1.94.27,2.93.08.53.13,1.06.21,1.6.13.93.27,1.86.43,2.79.16.9.32,1.84.51,2.74.37,1.83.83,3.62,1.31,5.4.27.9.53,1.78.8,2.66.84,2.61,1.8,5.19,2.92,7.69,3.59,8.24,8.53,15.77,14.6,22.29,14.52,15.75,35.37,25.59,58.52,25.59,21.48,0,40.97-8.51,55.32-22.34,5.35-5.08,9.95-10.93,13.64-17.37l-19.71-8.17Z"
      />
      <path
        className="cls-2"
        d="M1395.5,2.13h-20.32v45.75h-58.39c-19.36,0-37.89-9.65-48.83-26.15-2.05-3.08-3.83-6.41-5.29-9.97-1.31-3.17-2.35-6.38-3.04-9.63l-19.87,8.25c.85,3.19,1.95,6.38,3.25,9.52,1.45,3.51,3.13,6.89,5.03,10.08,4.39,7.45,9.87,13.96,16.12,19.42.31.29.6.56.92.8.77.69,1.55,1.33,2.35,1.91.67.56,1.36,1.09,2.05,1.57.6.45,1.19.91,1.8,1.28,2.93,2.05,5.96,3.88,9.15,5.48.75.37,1.52.75,2.29,1.12v.03c.19.11.37.19.56.24,0,.05.03.05.05.03.77.35,1.53.69,2.31,1.04,1.79.77,3.57,1.46,5.4,2.05.91.32,1.84.61,2.77.88.91.27,1.83.53,2.76.77.45.16.93.27,1.41.37.48.11.96.21,1.41.32,1.48.35,3,.64,4.55.85.56.11,1.11.19,1.67.27.21.05.45.08.67.08.96.16,1.92.27,2.91.35,1.21.11,2.44.21,3.69.27,1.25.05,2.49.08,3.75.08h57.53v.05l.99.4,7.73,3.17-7.73,18.83-.35.85-4.07,9.87c-1.39,3.33-3.04,6.52-4.96,9.42-15,23.33-44.92,33.3-71.45,22.37-11.49-4.73-20.67-12.71-26.89-22.42h-.05l-19.71,8.19c3.69,6.44,8.29,12.29,13.64,17.37,14.33,13.83,33.83,22.34,55.32,22.34,23.15,0,44-9.84,58.52-25.59,6.05-6.52,11-14.04,14.6-22.29,1.12-2.5,2.07-5.08,2.92-7.69.27-.88.53-1.76.8-2.66.48-1.78.93-3.56,1.31-5.4.19-.9.35-1.84.51-2.74.16-.93.29-1.86.43-2.79.08-.53.13-1.06.21-1.6.13-.98.23-1.97.25-2.93.11-1.52.19-3.03.21-4.57V2.13h-.95.05Z"
      />
      <path
        className="cls-1"
        d="M312.3,158.84h9.92l29.73-78.51h-9.89l-29.76,78.51Z"
      />
      <path
        className="cls-1"
        d="M0,95.32h24.89v63.14h18.01v-63.14h24.89v-14.68H0v14.68Z"
      />
      <path
        className="cls-1"
        d="M144.98,111.36h-35.35v-30.69h-18.01v77.8h18.01v-31.89h35.35v31.89h18.01v-77.8h-18.01v30.69Z"
      />
      <path
        className="cls-1"
        d="M216.55,97.8c10.56-5.61,25.05-3.67,33.35,5.67l11.57-10.67c-17.53-20.27-56.33-17.37-69.84,6.06-24.18,44.68,31.84,78.97,68.41,51.07v-31.57h-16.44v22.79c-29.26,16.65-55.93-25.61-27-43.35h-.05Z"
      />
      <path
        className="cls-2"
        d="M536.78,76.18l.02-2.07-136.43-1.16c.24-1.36.54-2.74.91-4.14.52-1.98,1.87-6.77,4.92-12.38,3.13-5.77,7.22-11.03,12.07-15.51,0-.03.03-.05.05-.05,8.02-7.3,18.06-12.32,28.69-14.28,15.27-3.01,31.19.82,44.1,9.26,3.6,2.05,19.74,11.47,23.61,13.74l12.33-4.98c-21.11-38.05-71.74-51.81-109.17-29.6-10.35,6.14-23.59,17.24-31.8,34.16-9.6,19.78-8.24,37.86-7.34,44.88h.21c.07,0,.15,0,.24,0h.76c.82.01,1.92.02,2.95.03h1.22c.84.02,1.44.02,1.44.02h0s0,0,0,0l118.08,1.01c.06,0,6.14.05,12.05.1-.16.96-.36,1.9-.57,2.85-.25.96-.5,1.96-.76,3-5.29,18.95-19.99,34.81-40.31,40.6-12.92,3.68-26.05,2.73-37.64-1.93-.8-.27-1.62-.62-2.42-1.02-1.6-.68-3.19-1.49-4.75-2.41-.68-.36-1.38-.77-2.06-1.21-.07-.06-.27-.17-.27-.17l-1.69-1.07-7.35-4.62-18.02-11.32-10.37,8.24c3.68,6.2,8.15,11.81,13.22,16.67,5.1,4.92,10.82,9.12,16.98,12.51-.2,0-.4.01-.6.01,11.31,6.34,24.34,10,38.22,10.12,37,.32,68.33-24.61,77.63-58.7.67-2.42,1.22-4.9,1.66-7.41,0-.04-.01-.09-.02-.13.88-5.02,1.31-10.15,1.2-15.34-.05-2.51-.21-5.04-.51-7.58-.02-.04-.04-.08-.05-.12h-.43Z"
      />
    </g>
  </StyledSVG>
);
