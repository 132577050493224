import dayjs from 'dayjs';
import { AudienceGenderAgeMap, StringStringMap } from '../../types';
import {
  defaultSocialState,
  Insights,
  InsightsAPI,
  InstagramByPageNumber,
  InstagramInsights,
  InstagramKey,
  InstagramPaginatedContent,
  InstaKeyMap,
  PaginatedInstagram,
  PaginatedYoutube,
  YoutubeByPageNumber,
  YoutubePaginatedContent,
} from './store';

export const transformGetContent = (
  currentContent: InstaKeyMap<PaginatedInstagram | null>,
  key: InstagramKey,
  newContent: InstagramInsights
): InstaKeyMap<PaginatedInstagram | null> => {
  return {
    ...currentContent,
    [key]: newContent,
  };
};

export const transformGetInstaContentPayload = (
  response: InstagramPaginatedContent,
  currentInstagram: InstaKeyMap<PaginatedInstagram | null>,
  key: InstagramKey
): InstaKeyMap<PaginatedInstagram | null> => {
  const currentInstagramPagination = currentInstagram[key];
  const instagramPageMap: InstagramByPageNumber = currentInstagramPagination
    ? currentInstagramPagination.content.set(
        response.pagination.page,
        response.content
      )
    : new Map().set(0, response.content);
  return {
    ...currentInstagram,
    [key]: {
      content: instagramPageMap,
      pagination: response.pagination,
    },
  };
};

export const transformGetYoutubeContentPayload = (
  response: YoutubePaginatedContent,
  currentYoutube: PaginatedYoutube | null
): PaginatedYoutube => {
  const youtubePageMap: YoutubeByPageNumber = currentYoutube
    ? currentYoutube.content.set(response.pagination.page, response.content)
    : new Map().set(0, response.content);
  return {
    content: youtubePageMap,
    pagination: response.pagination,
  };
};

export const transformGetInsights = (
  currentInsights: InstaKeyMap<InstagramInsights | null>,
  key: InstagramKey,
  newInsights: InstagramInsights
): InstaKeyMap<InstagramInsights | null> => {
  return {
    ...currentInsights,
    [key]: newInsights,
  };
};

export const transformGetError = (
  currentErrors: InstaKeyMap<string | null>,
  key: InstagramKey,
  payload: string | null
): InstaKeyMap<string | null> => {
  return {
    ...currentErrors,
    [key]: payload,
  };
};
export const transformGetLoading = (
  currentLoading: InstaKeyMap<boolean>,
  key: InstagramKey,
  isLoading: boolean
): InstaKeyMap<boolean> => {
  return {
    ...currentLoading,
    [key]: isLoading,
  };
};

export const clearInstaContent = (
  currentContent: InstaKeyMap<PaginatedInstagram | null>,
  key?: InstagramKey
): InstaKeyMap<PaginatedInstagram | null> => {
  return key !== undefined
    ? { ...currentContent, [key]: null }
    : defaultSocialState.instagramContent;
};

export const clearInstaInsights = (
  currentInsights: InstaKeyMap<InstagramInsights | null>,
  key?: InstagramKey
): InstaKeyMap<InstagramInsights | null> => {
  return key !== undefined
    ? { ...currentInsights, [key]: null }
    : defaultSocialState.instagramInsights;
};

export const parseInsight = (insight: InsightsAPI) => {
  const {
    audience_city,
    audience_country,
    audience_gender_age,
    audience_locale,
    created_at,
  } = insight;
  return {
    ...insight,
    audience_city: JSON.parse(audience_city) as StringStringMap,
    audience_country: JSON.parse(audience_country) as StringStringMap,
    audience_gender_age: JSON.parse(audience_gender_age) as
      | StringStringMap
      | AudienceGenderAgeMap,
    audience_locale: JSON.parse(audience_locale) as StringStringMap,
    created_at: dayjs(created_at),
  };
};

export const parseInsightsPayload = (response: InsightsAPI[]): Insights[] => {
  const parsedResult = response.reduce(
    (acc, insight) => {
      const parsedInsight = parseInsight(insight);
      const insightDate = parsedInsight.created_at.format('DD/MM/YYYY');
      const existingInsight = acc[insightDate];
      return !existingInsight ||
        existingInsight.created_at.isBefore(parsedInsight.created_at)
        ? { ...acc, [insightDate]: parsedInsight }
        : acc;
    },
    {} as { [index: string]: Insights }
  );

  return Object.values(parsedResult).sort((a, b) => {
    return a.created_at.isBefore(b.created_at) ? -1 : 1;
  });
};
